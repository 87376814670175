import * as React from "react";
import { Text, View, TouchableOpacity, Platform } from "react-native";
import { t } from "../services/i18n";
import ActionSheet from "./ActionSheet";
import { connect, shallowEqual } from "react-redux";
import mqttClient from "../services/mqtt";
import { useNavigation, useRoute } from "@react-navigation/native";

import { ComponentHeaderText, PrimaryColorText, StyledIcon, TextInputStyled } from "../components/UI/styledComponents";
import { addDeviceToFavourites, removeDeviceFromFavourites, changeParamsOfDevice } from "../store/actions";
import DeviceInfoDialog from "./DeviceInfoDialog";
import DeviceChangePinDialog from "./DeviceChangePinDialog";
import DeviceTimeControlDialog from "./DeviceTimeControlDialog";
import { store } from "../store/configureStore";
class HeaderWithDotsDevicesX extends React.Component {
  constructor(props) {
    super(props);

    this.removeFromFavouritesOption = t("REMOVE_FROM_FAVOURITES");
    this.addToFavouritesOption = t("ADD_TO_FAVOURITES");
    this.showPinChangeOption = t("CHANGE_PASSWORD");
    this.showInfoOption = t("SHOW_INFO");
    this.setOnTime = t("SET_ON_TIME");
    this.changeToHeating = t("CHANGE_TO_HEATING");
    this.changeToCooling = t("CHANGE_TO_COOLING");
    this.removeFromScenes = t("scenes:REMOVE_DEVICE");
    this.chart = t("SHOW_CHART");
    this.usageChart = t("SHOW_BAR_CHART");
    this.removeFromActiveOption = t("active:REMOVE_DEVICE");
    this.addToActiveOption = t("active:ADD_DEVICE");
    this.enableFlux = t("ENABLE_FLUX");
    this.disableFlux = t("DISABLE_FLUX");

    this.state = {
      showInfoModalVisible: false,
      showPinChangeModalVisible: false,
      showSetOnTimeModalVisible: false,
      deviceName: props.device.get("opis_menu"),
      showWebActionSheet: false,
    };

    this.options = [];
  }

  componentDidMount() {
    this.declareOptions();
  }

  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  doAction = (index) => {
    let { device, params_device, deviceInScene } = this.props;
    switch (this.options[index]) {
      case this.showPinChangeOption: {
        this.setState({ showPinChangeModalVisible: true });
        break;
      }
      case this.showInfoOption: {
        this.setState({ showInfoModalVisible: true });
        break;
      }
      case this.setOnTime: {
        this.setState({ showSetOnTimeModalVisible: true });
        break;
      }
      case this.removeFromFavouritesOption: {
        this.props.onRemoveDeviceFromFavourites(device.get("id"));
        break;
      }
      case this.addToFavouritesOption: {
        this.props.onAddDeviceToFavourites(device.get("id"));
        break;
      }
      case this.removeFromScenes: {
        this.props.removeDeviceFromScene(deviceInScene.get("temporary_id"));
        break;
      }
      case this.chart: {
        this.props.navigation.navigate("ChartScreen", {
          placeName: "CHART",
          deviceID: device.get("id"),
          deviceName: device.get("opis_menu"),
          deviceType: device.get("typ_komponentu"),
        });
        break;
      }
      case this.usageChart: {
        this.props.navigation.navigate("ChartScreen", {
          placeName: "BAR_CHART",
          deviceID: device.get("id"),
          deviceName: device.get("opis_menu"),
          deviceType: device.get("typ_komponentu"),
        });
        break;
      }

      case this.changeToHeating:
        {
          mqttClient.setHeatingType(device.get("id"), 0);
        }
        break;

      case this.changeToCooling:
        {
          mqttClient.setHeatingType(device.get("id"), 1);
        }
        break;

      case this.removeFromActiveOption: {
        mqttClient.removeDeviceFromActive(device.get("id"));
        let newParam = params_device.get("params");
        newParam &= ~0b1;
        // #define AKT_DEV_MASK 0x01
        // newParam = newParam & ~(PARAM_AKT_DEV_MASK);
        let newParams = params_device.set("params", newParam);
        this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.addToActiveOption: {
        mqttClient.addDeviceToActive(device.get("id"));
        let newParam = params_device.get("params");
        newParam = newParam | 0b1;
        let newParams = params_device.set("params", newParam);
        this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.enableFlux: {
        mqttClient.addDeviceToFluxSimulation(device.get("id"));
        // let newParam = params_device.get("params");
        // newParam = newParam | 0x8000000;
        // let newParams = params_device.set("params", newParam);
        // this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.disableFlux: {
        mqttClient.removeDeviceFromFluxSimulation(device.get("id"));
        // let newParam = params_device.get("params");
        // newParam &= ~0x8000000;
        // let newParams = params_device.set("params", newParam);
        // this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
    }
  };
  handleOnPressAction = (index) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false }, () => {
        this.doAction(index);
      });
    } else {
      this.doAction(index);
    }
  };

  declareOptions = () => {
    let { params_device, device, upTime, state, permission, route, noStateDevice, loggingData } = this.props;
    let routeName = route.name;
    let permissionForActives = (permission & 0x04) == 0;
    let option1 = params_device ? params_device.get("params") & 0x8000 : false;
    let options = [];

    let logging = loggingData.find((el) => el.get("id_obiektu") == device.get("id"));
    if (routeName === "FavouritesScreen") {
      options.push(this.removeFromFavouritesOption);
    } else {
      if (!noStateDevice) {
        options.push(this.addToFavouritesOption);
      }
    }
    if (routeName === "SceneDetails") {
      options.push(this.removeFromScenes);
    }
    if (["led", "przekaznik", "flaga"].includes(device.get("typ_komponentu"))) {
      options.push(this.setOnTime);
    }

    if (["led", "przekaznik", "flaga", "rgb", "rgbw", "ledww", "rgbww", "wej", "ip_radio", "radio"].includes(device.get("typ_komponentu"))) {
      if (upTime != null && upTime != undefined) {
        options.push(this.showInfoOption);
      }
    }

    if (!["roleta", "roleta_procenty", "roleta_lamelki"].includes(device.get("typ_komponentu"))) {
      if (logging || device.get("czas_logowania") >= 0) {
        options.push(this.chart);

        if (
          device.get("typ_komponentu") !== "temp" &&
          device.get("typ_komponentu") !== "reg" &&
          device.get("typ_komponentu") !== "roleta_lamelki" &&
          device.get("typ_komponentu") !== "roleta_procent" &&
          device.get("typ_komponentu") !== "roleta" &&
          device.get("typ_komponentu") !== "rgb" &&
          device.get("typ_komponentu") !== "rgbw" &&
          device.get("typ_komponentu") !== "rgbww"
        ) {
          options.push(this.usageChart);
        }
      }
      if (device.get("bezpieczne") || (device.get("typ_komponentu") == "satel_alarm" && option1)) {
        options.push(this.showPinChangeOption);
      }
      if (state && state.get("cooling") && device.get("typ_komponentu") == "reg" && params_device && !(params_device.get("params") & 0x8000)) {
        if (state.get("cooling") == 0) {
          options.push(this.changeToCooling);
        } else if (state.get("cooling") == 1) {
          options.push(this.changeToHeating);
        }
      }
    }
    if (routeName == "ActiveScreen" && permissionForActives) {
      options.push(this.removeFromActiveOption);
    }
    if (permissionForActives && params_device && (params_device.get("params") & 0x1) == 0) {
      options.push(this.addToActiveOption);
    }
    if (device.get("typ_komponentu") == "ledww") {
      if (store.getState().smartHomeData.get(store.getState().profilesSettings.get("currentProfile")).get("params_devices") && device) {
        const params_device2 = store
          .getState()
          .smartHomeData.get(store.getState().profilesSettings.get("currentProfile"))
          .get("params_devices")
          .get(device.get("id"));
        if (device.get("typ_komponentu") == "ledww" && params_device2 && (params_device2.get("params") & 0x8000000) == 0) {
          options.push(this.enableFlux);
        }
        if (device.get("typ_komponentu") == "ledww" && params_device2 && (params_device2.get("params") & 0x8000000) > 0) {
          options.push(this.disableFlux);
        }
      }
    }

    this.options = options;
    return options;
    //this.setState({ options });
  };

  render() {
    let { showInfoModalVisible, showPinChangeModalVisible, deviceName, showSetOnTimeModalVisible } = this.state;
    let {
      device,
      params_device,
      deviceFromCustom,
      noStateDevice,
      upTime,
      deviceIdShown,
      deviceNameToShow,
      deviceInScene,
      deviceFromAutomatization,
      route,
    } = this.props;
    let options = this.declareOptions();

    let headerHidden = params_device && (params_device.get("params") & 0x4000) > 0;
    let threeDotsHidden = params_device && (params_device.get("params") & 0x1000000) > 0;

    let threeDotsDisable = false;
    if ((noStateDevice && !deviceInScene) || threeDotsHidden) {
      threeDotsDisable = true;
    }
    if (deviceInScene || deviceFromAutomatization) {
      headerHidden = false;
    }
    if (route && route.name == "Favourites") {
      headerHidden = false;
      threeDotsDisable = false;
    }

    const deviceFromCustomComponent = !headerHidden && (
      <TouchableOpacity
        onPress={this.showActionSheet}
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 5,
          flex: 1,
        }}
        disabled={threeDotsDisable}
      >
        {!threeDotsDisable && <StyledIcon color="secondary" size={14} name="more-vertical" />}
        <PrimaryColorText
          adjustsFontSizeToFit
          numberOfLines={1}
          bold={true}
          style={{
            marginLeft: -2,
            fontSize: 14,
            textAlign: "center",
          }}
        >
          {deviceNameToShow}
          {deviceIdShown && ` (${device.get("id")})`}
        </PrimaryColorText>
      </TouchableOpacity>
    );

    const deviceNotFromCustomComponent = !headerHidden && (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {!threeDotsDisable && (
          <TouchableOpacity onPress={this.showActionSheet}>
            <StyledIcon color="secondary" size={20} name="more-vertical" />
          </TouchableOpacity>
        )}

        <View
          style={{
            paddingLeft: threeDotsDisable ? 11 : null,
            marginVertical: 5,
            marginRight: 5,
          }}
        >
          <ComponentHeaderText numberOfLines={1}>
            {deviceNameToShow}
            {deviceIdShown && ` (${device.get("id")})`}
          </ComponentHeaderText>
        </View>
      </View>
    );

    const deviceFromAutomatizationComponent = (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PrimaryColorText bold>{t("DEVICE")}: </PrimaryColorText>

        <PrimaryColorText numberOfLines={1}>
          {deviceNameToShow}
          {deviceIdShown && ` (${device.get("id")})`}
        </PrimaryColorText>
      </View>
    );

    return (
      <View
        style={{
          justifyContent: "center",
          width: deviceFromCustom ? "100%" : null,
          marginRight: deviceFromCustom ? null : 10,
          paddingRight: deviceFromCustom ? null : 10,
          flex: 1,
        }}
      >
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={options ? [...options, t("CLOSE")] : ["CLOSE"]}
          cancelButtonIndex={options ? options.length : 0}
          onPress={(index) => {
            this.handleOnPressAction(index);
          }}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />

        {showInfoModalVisible && (
          <DeviceInfoDialog
            deviceID={device.get("id")}
            dialogVisible={showInfoModalVisible}
            upTime={upTime}
            deviceType={device.get("typ_komponentu")}
            handleCloseDialog={() => {
              this.setState({ showInfoModalVisible: false });
            }}
          />
        )}
        {showPinChangeModalVisible && (
          <DeviceChangePinDialog
            device={device}
            params_device={params_device}
            dialogVisible={showPinChangeModalVisible}
            handleCloseDialog={() => {
              this.setState({ showPinChangeModalVisible: false });
            }}
          />
        )}

        {showSetOnTimeModalVisible && (
          <DeviceTimeControlDialog
            device={device}
            dialogVisible={showSetOnTimeModalVisible}
            handleCloseDialog={() => {
              this.setState({ showSetOnTimeModalVisible: false });
            }}
          />
        )}

        {deviceFromAutomatization ? deviceFromAutomatizationComponent : !deviceFromCustom ? deviceNotFromCustomComponent : deviceFromCustomComponent}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceIdShown: state.globalSettings.get("deviceIdShown"),
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
    loggingData: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("logging"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeviceToFavourites: (deviceID) => dispatch(addDeviceToFavourites(deviceID)),
    onRemoveDeviceFromFavourites: (deviceID) => dispatch(removeDeviceFromFavourites(deviceID)),
    onChangeParamsOfDevice: (deviceID, params) => dispatch(changeParamsOfDevice(deviceID, params)),
  };
};

const HeaderWithDotsDevices = (props) => {
  const navigation = useNavigation();
  const route = useRoute();

  return <HeaderWithDotsDevicesX {...props} navigation={navigation} route={route} />;
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithDotsDevices);
