import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator, Text } from "react-native";
import { connect } from "react-redux";
import { ThemeContext } from "../../../ThemeContext";
import { t } from "../../services/i18n";
import { ScreenView } from "../../components/UI/screenViewX";
import {
  StyledIcon,
  PrimaryColorText,
  ComponentContainerTouchable,
  ScreenViewWithoutScroll,
  NoDataScreen,
  SwitchStyled,
} from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import ActionSheet from "../../utils/ActionSheet";
import ModalSimple from "../../components/UI/ModalSimple";
import { SafeAreaView } from "react-native-safe-area-context";
import { Platform } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

import { automatizationsReceived } from "../../store/actions";
import { List, Map } from "immutable";

class SingleAutomatization extends Component {
  state = {
    automatization: this.props.automatization,
    name: "",
    nameToChange: "",
    showWebActionSheet: false,
    restToChange: 0,
  };
  handleOnPressAction = (index, automatizationIndex) => {
    if (index == 0) {
      this.props.removeItem(automatizationIndex);
    } else if (index == 1) {
      this.props.renameItem(automatizationIndex);
    } else if (index == 2) {
      this.props.changeRestTime(automatizationIndex);
    }
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
  };
  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.automatization) {
      this.setState({ automatization: nextProps.automatization });
    }
  }

  toggleActiveValue = (value) => {
    if (this.state.automatization) {
      let automatization = this.state.automatization.set("active", value ? 1 : 0);
      this.setState({ automatization }, () => {
        mqttClient.sendCondition(automatization, 2);
      });
    }
  };

  render() {
    const { automatizationIndex, navigation } = this.props;
    const { automatization } = this.state;
    return (
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        style={{
          flexDirection: "column",
        }}
        onPress={() => {
          navigation.navigate("AutomatizationDetails", {
            title: automatization.get("name"),
            automatization: automatization,
            automatizations: this.props.automatizations,
          });
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <TouchableOpacity onPress={this.showActionSheet} style={{ paddingHorizontal: 10, marginLeft: -5 }}>
              <StyledIcon name={"more-vertical"} size={18} color={"secondary"} />
            </TouchableOpacity>
            <PrimaryColorText style={{ fontWeight: "bold" }}>{automatization.get("name")}</PrimaryColorText>
          </View>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => mqttClient.runCondition(automatization.get("id"))}>
              <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 30,
            marginTop: 10,
            // flex: 1,
          }}
        >
          <PrimaryColorText style={{ paddingRight: 10 }}>{t("ACTIVE")}:</PrimaryColorText>
          <SwitchStyled
            // style={{ marginHorizontal: 20 }}
            value={automatization.get("active") == 1 ? true : false}
            onValueChange={this.toggleActiveValue}
          />
        </View>
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={[t("conditions:REMOVE_AUTOMATIZATION"), t("conditions:RENAME_AUTOMATIZATION"), t("CLOSE")]}
          cancelButtonIndex={3}
          onPress={(index) => this.handleOnPressAction(index, automatizationIndex)}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
      </ComponentContainerTouchable>
    );
  }
}
class AutomatizationsScreen extends Component {
  static contextType = ThemeContext;

  state = {
    automatizations: null,
    loading: true,
    addDialogVisible: false,
    newName: "",
    changeNameDialogVisible: false,
    changeRestTimeDialogVisible: false,
    clickedAutomatizationIndex: -1,
    restToChange: 0,
  };

  componentDidMount() {
    mqttClient.askForConditions(2);
    this.props.navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => this.showDialog()}>
          <StyledIcon name={"plus"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }

  showDialog = () => {
    this.setState({ addDialogVisible: true });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.automatizations) {
      let automatizations = nextProps.automatizations;
      if (automatizations) {
        this.setState({
          loading: false,
          automatizations: automatizations,
        });
      }
    }
  }

  removeItem = (automatizationIndex) => {
    let { automatizations } = this.state;

    let automatizationToRemove = automatizations.get(automatizationIndex);
    if (automatizationToRemove) {
      automatizationToRemove = automatizationToRemove.set("active", 2);
      mqttClient.sendCondition(automatizationToRemove, 2);
      automatizations = automatizations.delete(automatizationIndex);
      this.setState({ automatizations });
    }
  };

  changeRestTime = (automatizationIndex) => {
    let { automatizations } = this.state;
    this.setState({
      changeRestTimeDialogVisible: true,
      clickedAutomatizationIndex: automatizationIndex,
      restToChange: automatizations.get(automatizationIndex).get("rest"),
    });
  };

  renameItem = (automatizationIndex) => {
    let { automatizations } = this.state;
    this.setState({
      changeNameDialogVisible: true,
      clickedAutomatizationIndex: automatizationIndex,
      nameToChange: automatizations.get(automatizationIndex).name,
    });
  };

  onRestChangeHanler = (value) => {
    this.setState({
      restToChange: value ? Number(value) : null,
    });
  };

  handleChangeRestOk = (key) => {
    let { automatizations } = this.state;
    if (this.state.clickedAutomatizationIndex >= 0) {
      this.setState({ changeRestTimeDialogVisible: false });
      let automatizationToChange = automatizations.get(this.state.clickedAutomatizationIndex);

      automatizationToChange = automatizationToChange.set("rest", this.state.restToChange);
      mqttClient.sendCondition(automatizationToChange, 2);
    }
  };

  handleChangeNameOK = (key) => {
    let { automatizations } = this.state;
    if (this.state.clickedAutomatizationIndex >= 0) {
      this.setState({ changeNameDialogVisible: false });
      let automatizationToRename = automatizations.get(this.state.clickedAutomatizationIndex);

      automatizationToRename = automatizationToRename.set("name", this.state.name);
      mqttClient.sendCondition(automatizationToRename, 2);
    }
  };

  handleAddDialogClose = () => {
    this.setState({
      addDialogVisible: false,
      newName: "",
    });
  };
  handleAddDialogOK = () => {
    let newAutomatization = Map({
      id: -1,
      active: 1,
      state: 0,
      type: 2,
      lunched: 0,
      name: this.state.newName,
      sub_conditions: List([]),
      actions: List([]),
    });
    let automatizations = null;
    if (this.state.automatizations) {
      automatizations = this.state.automatizations.push(newAutomatization);
    }
    this.setState(
      {
        addDialogVisible: false,
        automatizations,
      },
      () => {
        this.props.navigation.navigate("AutomatizationDetails", {
          title: this.state.newName,
          automatization: newAutomatization,
          automatizations: automatizations,
        });
      }
    );
  };

  onChangeNameHandler = (value) => {
    this.setState({
      newName: value,
    });
  };

  onNameChangeHandler = (name) => {
    this.setState({ name });
  };

  componentWillUnmount() {
    this.props.automatizationsReceived(null);
  }
  render() {
    const { theme } = this.context;
    const { automatizations, loading, addDialogVisible, changeNameDialogVisible, name, nameToChange, changeRestTimeDialogVisible, restToChange } =
      this.state;

    const { devices, navigation } = this.props;

    return (
      <View style={{ flex: 1 }}>
        {loading ? (
          <ScreenViewWithoutScroll
            style={{
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="small" />
          </ScreenViewWithoutScroll>
        ) : automatizations && automatizations.size ? (
          <ScreenView>
            {automatizations.map((automatization, automatizationIndex) => {
              return (
                <SingleAutomatization
                  key={`${automatization.get("name")}${automatization.get("id")}`}
                  automatization={automatization}
                  automatizationIndex={automatizationIndex}
                  removeItem={this.removeItem}
                  renameItem={this.renameItem}
                  changeRestTime={this.changeRestTime}
                  automatizations={automatizations}
                  navigation={navigation}
                />
              );
            })}
          </ScreenView>
        ) : (
          <NoDataScreen />
        )}
        <ModalSimple
          isVisible={this.state.addDialogVisible}
          title={"conditions:AUTOMATIZATION_NAME"}
          placeholder={"conditions:ENTER_AUTOMATIZATION_NAME"}
          onInputChange={this.onChangeNameHandler}
          onClose={this.handleAddDialogClose}
          onOK={this.handleAddDialogOK}
        />
        {changeNameDialogVisible && (
          <ModalSimple
            isVisible={changeNameDialogVisible}
            onClose={() => {
              this.setState({ changeNameDialogVisible: false });
            }}
            onOK={this.handleChangeNameOK}
            title={"conditions:AUTOMATIZATION_NAME"}
            placeholder={"conditions:ENTER_AUTOMATIZATION_NAME"}
            onInputChange={this.onNameChangeHandler}
            value={nameToChange}
          />
        )}

        {changeRestTimeDialogVisible && (
          <ModalSimple
            isVisible={changeRestTimeDialogVisible}
            onClose={() => {
              this.setState({ changeRestTimeDialogVisible: false });
            }}
            onOK={this.handleChangeRestOk}
            title={"conditions:AUTOMATIZATION_REST_TIME_IN_S"}
            placeholder={"conditions:ENTER_REST_TIME"}
            onInputChange={this.onRestChangeHanler}
            value={this.state.restToChange ? this.state.restToChange.toString() : undefined}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    automatizations: state.statesData.get("automatizations"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    automatizationsReceived: (automatizations) => dispatch(automatizationsReceived(automatizations)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatizationsScreen);
