import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";
import { Map } from "immutable";

import { PrimaryColorText, StyledIcon } from "../../UI/styledComponents";
import DefaultIcon from "../../../screens/Dashboard/weather/defaultIcon";

// let deviceState = Map({
// 	state: "0",
// 	temp: 5,
// 	lux: 6,
// 	//
// 	pressure: 0,

// 	humidity: 20,
// 	wind: 3,
// 	weatherRain: 5,
// });
const WaetherStationComponent = ({
	deviceState,
	params_device,
	device,
	header,
	deviceFromCustom,
}) => {
	return (
		<View>
			{header}

			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-evenly",
					width: "90%",
					alignSelf: "center",
				}}
			>
				<View style={{ alignItems: "center" }}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							paddingBottom: 10,
						}}
					>
						<DefaultIcon name="thermometer" library="FontAwesome" />
						<PrimaryColorText>
							{deviceState.get("temp")} &deg;C
						</PrimaryColorText>
					</View>
					<StyledIcon
						name={deviceState.get("weatherRain") ? "cloud-rain" : "cloud"}
						size={22}
						color={"primary"}
					/>
				</View>

				<View style={{ alignItems: "center" }}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							paddingBottom: 10,
						}}
					>
						<DefaultIcon
							name="weather-windy"
							library="MaterialCommunityIcons"
						/>
						<PrimaryColorText style={{ padding: 5 }}>
							{deviceState.get("wind")} km/h
						</PrimaryColorText>
					</View>

					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<DefaultIcon name="speedometer" library="MaterialCommunityIcons" />
						<PrimaryColorText>
							{deviceState.get("pressure")} hPa
						</PrimaryColorText>
					</View>
				</View>

				<View style={{ alignItems: "center" }}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							paddingBottom: 10,
						}}
					>
						<DefaultIcon name="water" library="MaterialCommunityIcons" />
						<PrimaryColorText>{deviceState.get("humidity")} %</PrimaryColorText>
					</View>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						<DefaultIcon
							name="lightbulb-outline"
							library="MaterialCommunityIcons"
						/>
						<PrimaryColorText>{deviceState.get("lux")} lux</PrimaryColorText>
					</View>
				</View>
			</View>
		</View>
	);
};

export default WaetherStationComponent;
