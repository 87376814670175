import React, { Component } from "react";
import { connect } from "react-redux";
import { View, Text, TouchableOpacity, Image } from "react-native";

import {
  CameraComponent,
  InputComponent,
  LedComponent,
  LedWWComponent,
  RegulatorComponent,
  RelayComponent,
  SatelOutputComponent,
  EventComponent,
  RGBComponent,
  RollerComponent,
  SatelComponent,
  UnknownTypeComponent,
  LinearInputComponent,
  TextApiComponent,
  RadioComponent,
  WebViewComponent,
  ChartComponent,
  IpRadioComponent,
  IRComponent,
  CameraUniversalComponent,
} from "../../components/smartHomeComponents/index";
import { ComponentContainer } from "../../components/UI/styledComponents";
import HeaderWithDotsDevices from "../HeaderWithDotsDevices";
import ImageForLinearInput from "../ImageForLinearInput";
import GroupComponent from "../GroupComponent";

import ImageFromIkona0_0 from "../ImageFromIkona0_0";

import Modal from "react-native-modal";
import AdjustableTextToFitSketch from "../AdjustableTextToFitSketch";
import JanusVideoRoomScreen from "../../screens/AudioVideo/JanusVideoRoomScreen";

class DeviceSketchHandler extends Component {
  state = {
    showAdditional: false,
  };

  toggleValue = () => {
    this.setState((prevState) => ({
      showAdditional: !prevState.showAdditional,
    }));
  };

  changeHexToPercent = (hexValue) => {
    let percentValue = (hexValue * 100) / 255;
    if (percentValue < 1) {
      percentValue = Math.floor(percentValue * 10) / 10;
    } else {
      percentValue = Math.round(percentValue);
    }

    return `${percentValue}%`;
  };

  componentType = (device) => {
    switch (device.get("typ_komponentu")) {
      case "kamera_mjpeg":
        return CameraComponent;
      case "kamera_rtsp":
        return CameraUniversalComponent;

      case "flaga":
      case "flaga_l":
      case "flaga_p":
      case "przekaznik":
        return RelayComponent;

      case "satel_wyj":
        return SatelOutputComponent;

      case "event":
      case "ircode":
        return EventComponent;

      case "led":
      case "flaga_liniowa":
      case "flaga_liniowa16":
        return LedComponent;

      case "ledww":
        return LedWWComponent;

      case "reg":
        return RegulatorComponent;

      case "radio":
        return RadioComponent;

      case "ip_radio":
        return IpRadioComponent;

      case "ir":
        return IRComponent;

      case "rgb":
      case "rgbw":
        return RGBComponent;

      case "roleta":
      case "roleta_procenty":
      case "roleta_lamelki":
        return RollerComponent;

      case "alarm":
      case "satel":
      case "satel_alarm":
        return SatelComponent;

      case "satel_wej":
      case "wej":
      case "wej_d":
      case "wej_g":
      case "motion":
      case "presence":
        return InputComponent;

      case "lin_wej":
      case "bit8":
      case "bit16":
      case "sbit16":
      case "bit32":
      case "moc_ciagla":
      case "moc_przyr":
      case "temp":
      case "tekst_can":
      case "sensor":
        return LinearInputComponent;

      case "tekst_api":
        return TextApiComponent;

      case "wekta":
        return JanusVideoRoomScreen;
      case "webview": {
        return WebViewComponent;
      }
      case "wykres": {
        return ChartComponent;
      }
      default:
        return UnknownTypeComponent;
    }
  };

  render() {
    const { device, deviceState, params_device, styleToPass, width, height, stretchSketch, currentGroup, group_params, customBg, currentConnection } =
      this.props;

    let relayArray = [
      "flaga",
      "flaga_l",
      "flaga_p",
      "przekaznik",
      "satel_wyj",
      "event",
      "ircode",
      "satel_wej",
      "wej",
      "wej_d",
      "wej_g",
      "wykres",
      "motion",
      "presence",
    ];

    let linearInputArray = ["lin_wej", "bit8", "bit16", "sbit16", "bit32", "moc_ciagla", "moc_przyr", "temp", "tekst_can", "sensor"];

    let statelessObjects = ["event", "ircode", "roleta", "wykres", "ir", "webview", "kamera_rtsp", "kamera_mjpeg"];

    let element;
    let element2;
    let readOnly = params_device && (params_device.get("params") & 0x40) > 0 ? true : false;

    let blockObject = false;
    if ((currentConnection === "local" || currentConnection === "local2") && params_device && (params_device.get("params") & 0x80) > 0) {
      blockObject = true;
    }
    if ((currentConnection === "cloud" || currentConnection === "new_cloud") && params_device && (params_device.get("params") & 0x100) > 0) {
      blockObject = true;
    }
    if (blockObject) return null;
    let ComponentName = this.componentType(device);
    let showLedValue = device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x200) == 0 ? true : false;
    let deviceNameToShow = (group_params & 0x02) > 0 ? device.get("opis_rozwiniety") : device.get("opis_menu");

    let header = (
      <View style={{ height: 30 }}>
        <HeaderWithDotsDevices device={device} params_device={params_device} deviceNameToShow={deviceNameToShow} />
      </View>
    );

    if (device.get("typ_komponentu") == "grupa") {
      let showGroupDesc = params_device && (params_device.get("params") & 0x200) == 0 ? true : false;
      element = (
        <GroupComponent
          iconId={device.get("ikona0")}
          groupId={device.get("funkcja")}
          viewWidth={styleToPass.width}
          styleToPass={styleToPass}
          showGroupDesc={showGroupDesc}
          stretchSketch={stretchSketch}
        />
      );
    } else if (
      styleToPass.width > 100 &&
      styleToPass.height > 100 &&
      (device.get("typ_komponentu") == "kamera_rtsp" || device.get("typ_komponentu") == "kamera_mjpeg" || device.get("typ_komponentu") == "webview")
    ) {
      element = (
        <View style={[styleToPass]}>
          <ComponentName
            device={device}
            deviceState={deviceState}
            params_device={params_device}
            header={header}
            readOnly={readOnly}
            deviceFromSketch={true}
            stretchSketch={stretchSketch}
            onSketchWidth={styleToPass.width}
            onSketchHeight={styleToPass.height}
          />
        </View>
      );
    } else if ((deviceState && deviceState.get("state")) || statelessObjects.includes(device.get("typ_komponentu"))) {
      if (relayArray.includes(device.get("typ_komponentu"))) {
        element = (
          <View style={[styleToPass]}>
            <ComponentName
              device={device}
              deviceState={deviceState}
              params_device={params_device}
              header={header}
              readOnly={readOnly}
              deviceFromSketch={true}
              stretchSketch={stretchSketch}
              onSketchWidth={styleToPass.width}
              onSketchHeight={styleToPass.height}
            />
          </View>
        );
      } else if (linearInputArray.includes(device.get("typ_komponentu"))) {
        if (deviceState.get("res")) {
          let imageAndDesc = params_device && (params_device.get("params") & 0x40000) > 0;
          let imageAndValue = params_device && (params_device.get("params") & 0x80000) > 0;
          let url = params_device && params_device.get("url") ? params_device.get("url") : "%";

          let heighrMultiple = 1;
          if (imageAndDesc) heighrMultiple = heighrMultiple + 0.3;
          if (imageAndValue) heighrMultiple = heighrMultiple + 0.3;
          element = (
            <View
              style={[
                styleToPass,
                {
                  height: styleToPass.height * heighrMultiple,
                  borderWidth: 1,
                },
              ]}
            >
              <ImageForLinearInput
                deviceFromSketch={true}
                resizeMode={stretchSketch ? "stretch" : "contain"}
                style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                id={deviceState.get("res")}
              />
              {imageAndDesc && deviceState.get("desc") && (
                <AdjustableTextToFitSketch styleToPass={styleToPass} addStyle={{ top: 0, left: 0, position: "relative" }}>
                  {deviceState.get("desc")}
                </AdjustableTextToFitSketch>
              )}
              {imageAndValue && (
                <AdjustableTextToFitSketch styleToPass={styleToPass} addStyle={{ top: 0, left: 0, position: "relative" }}>
                  {deviceState && deviceState.get("state")}
                  {device.get("typ_komponentu") === "temp" ? "\u00b0C" : url}
                </AdjustableTextToFitSketch>
              )}
            </View>
          );
        } else if (deviceState.get("desc")) {
          element = <AdjustableTextToFitSketch styleToPass={styleToPass}>{deviceState.get("desc")}</AdjustableTextToFitSketch>;
        } else {
          let url = params_device && params_device.get("url") ? params_device.get("url") : "%";
          element = (
            <AdjustableTextToFitSketch styleToPass={styleToPass}>
              {deviceState && deviceState.get("state")}
              {device.get("typ_komponentu") === "temp" ? "\u00b0C" : url}
            </AdjustableTextToFitSketch>
          );
        }
      } else if (device.get("typ_komponentu") == "tekst_api") {
        element = (
          <View style={[styleToPass]}>
            <ComponentName
              device={device}
              deviceState={deviceState}
              params_device={params_device}
              header={header}
              readOnly={readOnly}
              deviceFromSketch={true}
              stretchSketch={stretchSketch}
            />
          </View>
        );
      } else if (device.get("typ_komponentu") == "ir") {
        element = (
          <View style={[styleToPass]}>
            <ComponentName
              device={device}
              deviceState={deviceState}
              params_device={params_device}
              header={header}
              readOnly={readOnly}
              deviceFromSketch={true}
              stretchSketch={stretchSketch}
            />
          </View>
        );
      } else {
        element = (
          <TouchableOpacity
            onPress={this.toggleValue}
            disabled={readOnly}
            style={[
              styleToPass,
              {
                height: showLedValue ? styleToPass.height * 1.3 : styleToPass.height,
              },
            ]}
          >
            {(device.get("ikona_0") && device.get("ikona_1")) || (device.get("ikona0") && device.get("ikona1")) ? (
              <ImageFromIkona0_0
                deviceFromSketch={true}
                device={device}
                value={deviceState && deviceState.get("state")}
                resizeMode={stretchSketch ? "stretch" : "contain"}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "contain",
                }}
              />
            ) : null}

            {showLedValue && (
              <AdjustableTextToFitSketch styleToPass={styleToPass} addStyle={{ top: 0, left: 0, position: "relative" }}>
                {this.changeHexToPercent(deviceState && deviceState.get("state"))}
              </AdjustableTextToFitSketch>
            )}
          </TouchableOpacity>
        );
      }

      element2 = (
        <ComponentContainer>
          <ComponentName
            device={device}
            deviceFromSketch={true}
            readOnly={readOnly}
            deviceState={deviceState}
            params_device={params_device}
            header={header}
            customBg={customBg}
            onBackdropPress={() => this.setState({ showAdditional: false })}
          />
        </ComponentContainer>
      );
    }

    return (
      <View style={{ width: "100%" }}>
        {element}
        {(this.state.showAdditional && device.get("typ_komponentu") == "kamera_rtsp") ||
        device.get("typ_komponentu") == "kamera_mjpeg" ||
        device.get("typ_komponentu") == "webview" ? (
          element2
        ) : this.state.showAdditional ? (
          <Modal
            isVisible={this.state.showAdditional}
            style={{ alignItems: "center", justifyContent: "center" }}
            onBackdropPress={() => this.setState({ showAdditional: false })}
          >
            <View style={{ width: "90%" }}>{element2}</View>
          </Modal>
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceState: state.statesData.get("states").get(ownProps.device.get("id")),
    currentConnection: state.statesData.get("currentConnection"),
    params_device: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices").get(ownProps.device.get("id")),
  };
};

export default connect(mapStateToProps)(DeviceSketchHandler);
