import React, { useState, useContext } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { t } from "../../services/i18n";

import { Feather } from "@expo/vector-icons";
import { useTheme } from "../../../ThemeContext";
import moment from "moment";
import { TextInputStyled, ButtonPrimary } from "../UI/styledComponents";
import ModalWithContent from "../UI/ModalWithContent";

const DateTimePicker = ({ value, onChange, type }) => {
  let mapType = type;
  if (type == "datetime") {
    mapType = "datetime-local";
  }

  return React.createElement("input", {
    type: mapType,
    value: value,
    onInput: onChange,
  });
};

const DateTimePickerX = (props) => {
  const onChange = (e) => {
    if (props.mode === "time") {
      let time = moment(e.target.value, "hh:mm");
      props.onConfirm(time);
    } else if (props.mode == "datetime") {
      let time = moment(e.target.value, "YYYY-MM-DDThh:mm");
      props.onConfirm(time);
    } else {
      props.onConfirm(e.target.value);
    }
  };
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <DateTimePicker onChange={(value) => onChange(value)} value={props.value} inputStyle={props.style} type={props.mode} />
    </View>
  );
};
export default DateTimePickerX;
