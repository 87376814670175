import React, { Component, useContext, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { t } from "../../services/i18n";
import { useTheme, ThemeContext } from "../../../ThemeContext";
import { Feather } from "@expo/vector-icons";

import { connect } from "react-redux";

import ModalWithContent from "../../components/UI/ModalWithContent";
import {
  StyledIcon,
  ButtonPrimary,
} from "../../components/UI/styledComponents";

class MultiplePickerSelectObjects extends Component {
  static contextType = ThemeContext;
  state = {
    deviceArray: this.props.devicesInGroup,
    modalVisible: this.props.modalVisible,
  };

  componentDidUpdate(prevProps) {
    if (this.props.selectedGroup != prevProps.selectedGroup) {
      this.setState({ deviceArray: this.props.devicesInGroup });
    }
    if (this.props.modalVisible != prevProps.modalVisible) {
      this.setState({ modalVisible: this.props.modalVisible });
    }
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  onClose = () => {
    this.setState({ modalVisible: false });
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  itemSelected = (el) => {
    let newArray = this.state.deviceArray;

    if (el == "ALL") {
      let item = newArray[newArray.length - 1];
      item.chosen = !item.chosen;

      newArray.map((el) => {
        el.chosen = item.chosen;
        return el;
      });
    } else {
      let indexToChange = newArray.findIndex((item) => {
        return item.value == el;
      });
      newArray[indexToChange].chosen = !newArray[indexToChange].chosen;
    }
    this.setState({
      deviceArray: newArray,
    });
  };

  onAdd = () => {
    let deviceArray = this.state.deviceArray.filter((el) => {
      return el.chosen == true;
    });

    this.setState({ modalVisible: false }, () => {
      this.props.onValueChange(deviceArray);
    });
  };

  render() {
    const { theme } = this.context;
    // let [deviceArray, setDeviceArray] = useState(items);
    const textColor = theme.TEXT_COLOR_PRIMARY;
    const textColorSecondary = theme.TEXT_COLOR_SECONDARY;
    const inputBackgroundColor = theme.APP_BACKGROUND_COLOR;
    const borderColor = theme.PLACEHOLDER_BACKGROUND_COLOR;

    let { deviceArray, modalVisible } = this.state;
    if (this.props.modalVisible) modalVisible = this.props.modalVisible;

    let placeholder = {
      label: t("scenes:SELECT_DEVICES"),
      value: null,
    };

    if (this.props.placeholder) {
      placeholder.label = this.props.placeholder;
    }

    let {
      selectedDevices,
      containerStyle,
      onValueChange,
      additionalTextStyle,
      disabled,
      hideText,
      additionalGroup,
      devicesInGroup,
      groups,
      type,
      additionalZ,
    } = this.props;

    let content = (
      <ScrollView style={{ width: "100%" }}>
        {deviceArray.map((item, index) => {
          return (
            <TouchableOpacity
              style={{
                padding: 10,
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                // justifyContent: "space-between",
              }}
              key={`${item.value}${index}`}
              onPress={() => this.itemSelected(item.value)}
            >
              <Feather
                name={item.chosen ? "check-circle" : "circle"}
                color={item.chosen ? "rgb(50, 200, 80)" : "#ccc"}
                size={22}
              />
              <Text style={{ color: textColor, marginLeft: 10 }}>
                {item.label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    );

    let styles = StyleSheet.create({
      input: {
        paddingLeft: 8,
        height: 26,
        borderWidth: 1,
        borderColor: borderColor,
        borderRadius: 4,
        width: "100%",
        backgroundColor: inputBackgroundColor,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
    });

    let inputText = "";
    if (selectedDevices && selectedDevices.length) {
      if (selectedDevices[selectedDevices.length - 1].value == "ALL") {
        inputText = t("scenes:ALL");
      } else {
        selectedDevices.map((el, index) => {
          inputText += el.label;
          if (index != selectedDevices.length - 1) {
            inputText += ", ";
          }
        });
      }
    } else {
      inputText = placeholder.label;
    }

    let modalContent = modalVisible && (
      <ModalWithContent
        isVisible={modalVisible}
        title={placeholder.label}
        onClose={this.onClose}
      >
        {content}
        <ButtonPrimary
          disabled={!this.state.deviceArray.some((el) => el.chosen == true)}
          onPress={this.onAdd}
        >
          {this.props.addText ? this.props.addText : t("ADD")}
        </ButtonPrimary>
      </ModalWithContent>
    );
    let pikcerModal = (
      <View
        style={{
          width: "100%",
          alignItems: "center",
        }}
      >
        <TouchableOpacity style={styles.input} onPress={this.showModal}>
          <Text
            numberOfLines={1}
            style={[
              additionalTextStyle,
              {
                color:
                  selectedDevices && selectedDevices.length
                    ? textColor
                    : textColorSecondary,
                paddingRight: 15,
              },
            ]}
          >
            {inputText}
          </Text>
          <View style={{ position: "absolute", right: 0 }}>
            <StyledIcon name="chevron-down" size={18} color="secondary" />
          </View>
        </TouchableOpacity>
      </View>
    );

    return !disabled ? (
      <View style={containerStyle}>
        {hideText ? null : pikcerModal}
        {modalContent}
      </View>
    ) : (
      <View>
        <Text
          style={{
            fontSize: 12,
            paddingVertical: 4,
            paddingHorizontal: 8,
            height: 26,
            borderWidth: 1,
            borderColor: borderColor,
            borderRadius: 4,
            color: textColor,
            paddingRight: 25,
            backgroundColor: inputBackgroundColor,
          }}
        >
          {inputText}
        </Text>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("groups"),
  };
};

export default connect(mapStateToProps)(MultiplePickerSelectObjects);
