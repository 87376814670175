let versions = [
  {
    name: "1.7.6",
    desc_pl: `   - znaczna poprawa wydajności
    - poprawino podlewanie ogrodu ( po usunięciu i dodaniu harmonogramu )
    - dodano obiekt energia ( dla instalacji fotowoltaicznych )
    - wyszukiwanie profili w spotlight (iOS)
    - powiadomienia krytyczne
    - nowy komponent do obsługi kamer ( z obsługą pełnego ekranu)
    - obsługa uruchamiana obiektów z powiadomienia PUSH
    - poprawino widok statystyk
    - osługa widoku kamer dla rzutach
    - poprawino początkowy widok
    - obsługa importu exportu kontroli dostępu
    - zaszyfrowane hasła znajdują się w exporcie profili
    - dodano przycisk Anuluj w trakcie połączenia
    - dodano feedback podczas sterowania urządzeniami
    - wsparcie zdalne przez e-mail instalatora`,

    desc_en: `   - significant performance improvement
    - fixed problem with garden irrigation ( after delete and add new schedule )
    - added energy object ( for photovoltaic installations )
    - searching profiles in spotlight (iOS)
    - critical notifications
    - new component for camera handling ( with full screen support)
    - support for open objects from PUSH notification
    - fixed statistics view
    - support for camera view in floorplan
    - fixed initial view
    - support for import export of access control
    - encrypted passwords are in profile export
    - added Cancel button during connection
    - added heptic feedback during device control
    - remote support by installer e-mail`,
    date: "03.07.2023",
  },
  {
    name: "1.7.0",
    desc_pl: `   - obsługa krytycznych powiadomień
    - poprawki w ponownym łączeniu
    - nowy layout menu głównego
    - nowy tryb na wykresach
    - poprawione obsługa rolet w automatyzacjach
    - przesuwane za długie teksty w nazwach obiektów
    - nowe czcionki
    - obsługa nowych obiektów na rzucie
    - poprawa wydajności
    - inne drobne poprawki`,

    desc_en: `   - support for critical notifications
    - fixes in reconnnect
    - new layout of main menu
    - now chart mode
    - fix with rollers in automatisations
    - sliding text in object names
    - new font
    - stability improvments
    - other minors fixes`,
    date: "28.02.2023",
  },
  {
    name: "1.6.0",
    desc_pl: `   - obsługa połączenia SIP 
    - obsługa kamer RTSP
    - obsługa dodatkowych obiektów w automatyzacjach
    - obsługa zdjęć z domofonu w wiadomościach
    - obsługa obiektów typu ip_radio (Sonos,Heos,Musiccast,Chromecast,Acrylic)
    - biblioteka audio
    - obsługa zaproszeń z chmury z poziomu aplikacji
    - poprawa wydajności
    - inne drobne poprawki`,

    desc_en: `   - support for SIP connection
    - support for RTSP cameras
    - support for new objects in automatizations
    - support for images in message center
    - support fir ip_radio objects (Sonos,Heos,Musiccast,Chromecast,Acrylic)
    - audio library
    - support for cloud invitations from app
    - stability improvments
    - other minors fixes`,
    date: "14.09.2022",
  },
  {
    name: "1.5.1",
    desc_pl: `   - obsługa zmiany stylu w bocznym menu w widoku profili
    - dodatkowa opcja trybu automatycznego logowania ( do wybranego profilu )
    - obsługa powiązywania instalacji dla innych użytkoników niż admin
    - przyspieszona obsługa wczytywania wiadomości
    - poprawiony problem z szerokością w aktywnych obiektach na tablecie`,

    desc_en: `- option to change style in menu on profile list
    - new option for automatic login ( to choosen profile )
    - support for bonding instalation for users other than admin
    - performance improvments on messages screen
    - active view on tablet fix`,
    date: "17.03.2022",
  },
  {
    name: "1.5.0",
    desc_pl: `    - obsługa wyboru ekranu początkowego 
    - obsługa warunków lub W automatyzacjach
    - Satus połączenia na pulpicie
    - Obiekty podzielone na grupy w aktywnych urządzeniach
    - Obsługa powrotu do połączenia lokalnego ( po powrocie do WiFi)
    - Naprawione automatyzacje od urządzenia MSENS
    - Uruchamianie obiektów na czas w automatyTzacji
    - Opcja wyłączenia wszystkich sekcji w podlewaniu
    - Naprawione problemy z obiektami satel
    - Auto logowanie do poprzedniego profilu
    - Nowy silnik aplikacji
    - Nowy widok listy profili`,
    desc_en: `- support for choose welcome screen
    - support for OR Condittions in Automatizations
    - Connection status on Dashboard
    - Groups in active devices screen
    - Support for switching back to local connection after cloud
    - Conditions from MSENS values fix
    - Support for time in objects automatization
    - Option to disable all sections in garden
    - Fix problem with Satel alarm and outputs
    - Auto login to last selected profile
    - New app engine
    - New profile list sceen`,
    date: "03.03.2022",
  },
  {
    name: "1.4.0",
    desc_pl: `    - bugfixy
    - dodana obsługa nowych języków
    - drobne poprawki
    - obsługa obiektu ledww
    - obsługa zakresu czasu w automatyzacja
    - opcja usuwania wszystkich wiadomości
    - nowy silnik aplikacji
    - obsługa rolety typu karnisz`,
    desc_en: `- support for new languages
    - bugfixes
    - support for ledww object
    - support for time range in automatization
    - option to remove all messages
    - new app engine
    - new type of roller support,`,
    date: "29.05.2021",
  },
  {
    name: "1.3.4",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "06.04.2021",
  },
  {
    name: "1.3.3",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "12.03.2021",
  },
  {
    name: "1.3.2",
    desc_pl: `- centrum wiadomości dostępne z pulpitu,
- automatyzacje - możiwość włączania urządzeń na czas, wybór wysyłania powiadomień do danego użytkownika lub do wszystkich (przy odpowiednich uprawnieniach), 
- pogoda - wschód i zachód słońca,
- obsługa parametrów: ukryj loader dla włączników czasowych, ukryj 3 kropki w tytule,
- możliwość ustawienie lamelek co 1%,
- obsługa czasu odświeżania kamery ustawionego z SHMu
- możliwość przywrócenia kolejności obiektów do orygianlnej,
- ukrywanie wielu zakładek w Koncie na podstawie uprawnień użytkownika`,
    desc_en: `- message center available from Dashboard,
- automatization - turn on devices for specific time, choice of sending push notifications to all users / current user,
- weather - sunrise & sunset,
- handling SHM parameters - hide loader, hide 3 dots in title, time for camera refresh,
- possibility of setting lammels at every 1%,
- reset of order of devices to original,
- hiding tabs in Account based on user permissions`,
    date: "10.03.2021",
  },
  {
    name: "1.3.1",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "21.01.2021",
  },
  {
    name: "1.3.0",
    desc_pl: ` -  nowa funkcjonalność: centrum wiadomości (od wersji serwera 489, beta) + opcja wysyłania wiadomości w automatyzacjach,
- uaktualniony silnik aplikacji,
- bugfixy`,
    desc_en: `- new functionality: message centre (from server version 489, beta) + option of sending messages from automatization,
- app engine update,
- bugfixes `,
    date: "08.01.2021",
  },
  {
    name: "1.2.3",
    desc_pl: `- komponent dzwonek do drzwi,
- możliwość ustawiania kolejności scen,
- bugfixy`,
    desc_en: `- door bell component,
- possibility of setting up scenes order,
- bugfixes `,
    date: "04.12.2020",
  },
  {
    name: "1.2.2",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "18.11.2020",
  },
  {
    name: "1.2.1",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "09.11.2020",
  },
  {
    name: "1.2.0",
    desc_pl: `- nowa funkcjonalność: automatyzacja (od wersji serwera 470, beta),
- obsługa własnych ikon dla animacji rolet,
- obsługa wyświetlania danych pogodowych na podstawie zadeklarowanych czujników,
- uaktualniony silnik aplikacji,
- drobne poprawki i usprawnienia`,
    desc_en: `- new functionality: automatization (from server version 470, beta),
- custom icons for rollers animation,
- weather data in app also from decalred sensors, not only form api,
- app engine update,
- small bugfixes and improvements`,
    date: "04.11.2020",
  },
  {
    name: "1.1.17",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "28.08.2020",
  },
  {
    name: "1.1.16",
    desc_pl: `- bugfix w podlewaniu ogrodu`,
    desc_en: `- bugfixes in garden irrigation,`,
    date: "30.07.2020",
  },
  {
    name: "1.1.15",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "28.07.2020",
  },
  {
    name: "1.1.14",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "24.07.2020",
  },
  {
    name: "1.1.13",
    desc_pl: `- podlewanie: warunki czasowe dla czujnika deszczu,
- uaktualnianie wersji mqtt core z aplikacji, zakładka diagnostyka`,
    desc_en: `- irrigation: time conditions for rain sensor,
- update of mqtt core from app, diagnostic tab`,
    date: "23.07.2020",
  },
  {
    name: "1.1.12",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes,`,
    date: "16.07.2020",
  },
  {
    name: "1.1.11",
    desc_pl: `- możliwość deklaracji więcej niż jednego czasu w nawadnianiu,
- czujnik wilgotności w nawadnianiu,
- inforamcje o wersjach i aktualizacjach w osobnej zakładce,
- bugfixy`,
    desc_en: `- possible declaration of more than one time in irrigation,
- humidity sensor in irrigation,
- information about versions and updates in separate tab,
- bugfixes`,
    date: "24.06.2020",
  },
  {
    name: "1.1.10",
    desc_pl: `- uruchamianie podlewanie względem wschodów i zachodów,
- opcja ręcznej dezaktywacji nawadniania,
- obsługa stacji Meteo Ampio,
- obsługa własnych ikon dla obiektu z krańcówkami,
- automatyczna informacja o aktualizacjach aplikacji`,
    desc_en: `- option to run irrigation at sunset/sunrise,
- temporary desactivating of irrigation,
- new object: station Meteo Ampio,
- custom icons for  object with end switches,
- automatic information about app updates`,
    date: "19.06.2020",
  },
  {
    name: "1.1.9",
    desc_pl: `- obsługa: bass, mid i treble dla radia, 
- możliwość zmiany hasła użytkownika z poziomu aplikacji,
- pokazywanie czy scena jest aktywna`,
    desc_en: `- set bass, mid & treble for radio,
- change password for the user directly from the app,
- show if scene is active`,
    date: "05.06.2020",
  },
  {
    name: "1.1.8",
    desc_pl: `- nowa funkcjonalność: podlewanie ogródka (testowe, od wersji serwera 399),
- większa personalizacja dla menu bocznego,
- więcej detali w statystykach,
- obsługa powiadomień push wewnątrz aplikacji,
- bugfixy`,
    desc_en: `- new functionality: garden irrigation (in test, from server version 399),
- greater porsonalization for side menu,
- more details in statistics,
- handling of push notification when app in foreground,
- bugfixes`,
    date: "29.05.2020",
  },
  {
    name: "1.1.7",
    desc_pl: `- większa personalizacja widoku tabletowego (wybór czy wyświetalać kafelki, dla wszystkich obiektów razem i dla każdego obiektu indywidualnie),
- wsparcie dla języka niemieckiego i francuskiego,
- ustalanie kolejnośći wyświetlanie dla Ulubionych`,
    desc_en: `- better customization of the tablet view(choice if show the tiles, for all devices together or individually for each device),
- support for french and german,
- set of order also for Favourites`,
    date: "22.05.2020",
  },
  {
    name: "1.1.6",
    desc_pl: `- bugfix: sceny`,
    desc_en: `- bugfix: scenes`,
    date: "15.05.2020",
  },
  {
    name: "1.1.5",
    desc_pl: `- obsługa komponentu stacja pogodowa,
- obsługa własnego koloru tekstu na rzutach`,
    desc_en: `- component: weather station,
- custom color of text on sketches`,
    date: "13.05.2020",
  },
  {
    name: "1.1.4",
    desc_pl: `- bugfixy (Konto -> Personalizacja -> Różne: wymuś orientację poziomą dla tableta)`,
    desc_en: `- bugfixes`,
    date: "12.05.2020",
  },
  {
    name: "1.1.3",
    desc_pl: `- bugfixy`,
    desc_en: `- bugfixes`,
    date: "11.05.2020",
  },
  {
    name: "1.1.2",
    desc_pl: `- możliwość wyboru ogólnej kolejnosci wyświetlania scen, grup i urządzeń,
- bugfixy (języki, uaktualnianie pogody)`,
    desc_en: `- choose the general order of display: scenes & groups & devices,
- bugfixes (languages, weather updates)`,
    date: "08.05.2020",
  },
  {
    name: "1.1.1",
    desc_pl: ` - statystki: logbook i sterowanie, filtrowanie po konkretnej dacie, urządzeniu, zdarzeniach + funkcja odświeżania i paginacja,
- ustawianie kolejności scen w grupach (Konto -> Personalizacja -> Organizacja grup i urządzeń)
- bugfixy (orientacja)`,
    desc_en: ` - statistics: logbook and action log, filtering by specific date, device, event + pagination and refreshing,
- order of scenes in groups (Account -> Personalization -> Organization of groups and devices),
- bugfixes (screen orientation)`,
    date: "06.05.2020",
  },
  {
    name: "1.1.0",
    desc_pl: `- widoki dostosowane do tableta,
- możliwość włączenia "ekranu powitalnego" na dashboardzie dla tabletów,
- wielokrotny wybór urządzeń podczas tworzenia sceny,
- opcja aktualizacji serwera do wersji testowej,
- obsługa krańcówek i komponentu tekst_api,
- kody IR w scenach`,
    desc_en: `- views adjusted to the tablet screen,
- option of 'greeting screen' in Dashboard,
- multiple choice of devices during scene creation,
- option of update of the server to test version,
- handle of state of end switches,
- IR codes in scenes`,
    date: "23.04.2020",
  },
  {
    name: "1.0.4",
    desc_pl: `- obsługa ikon w obiekcie zdarzenie,
- nowy typ obiektu: flaga liniowa,
- bugfixy (ir i regulator)`,
    desc_en: `- custom icons in event object,
- new object type : linear flag,
- bugfixes (ir & heating)`,
    date: "20.04.2020",
  },
  {
    name: "1.0.3",
    desc_pl: `- możliwość ukrycia górnego paska nawigacji na dashboardzie,
- opisy stanu rolet w logbooku,
- bugfixy`,
    desc_en: `- option of hiding the top bar in Dashboard,
- description of roller states in logbook,
- bugfixes`,
    date: "06.04.2020",
  },
  {
    name: "1.0.2",
    desc_pl: `- obsługa sbit16,
- komponent IR: wyszukiwanie, dodawanie, obsługa,
- opcja przełączania profilu bezpośrednio z zakładki Konto,
- bugfixy`,
    desc_en: `- sbit16,
- IR component: searching, adding and running,
- added option: switch to different profile directly from Account Tab,
- bugfixes`,
    date: "02.04.2020",
  },
  {
    name: "1.0.1",
    desc_pl: `- bugfixes: rolety w scenach,
- dodany changlog`,
    desc_en: `- bugfixes: rollers in scenes,
- added changlog`,
    date: "27.03.2020",
  },
  {
    name: "1.0.0",
    desc_pl: "Oficjalna wersja aplikacji Ampio UNI",
    desc_en: "Official release of Ampio UNI app",
    date: "24.03.2020",
  },
];

export default versions;
