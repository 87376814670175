import base64 from "base-64";
import { Platform } from "react-native";
import { mainHost } from "../../CloudConstants";
import * as Linking from "expo-linking";

export const calculateCameraDimmensions = (columnWidth, width, proportions, gretingViewHeight, fullScreen) => {
  let webViewWidth = gretingViewHeight ? width / 2 - 20 : columnWidth - 20;
  if (fullScreen) {
    webViewWidth = width;
  }
  let webViewHeight = webViewWidth / proportions;
  let maxHeight = gretingViewHeight ? gretingViewHeight / 2 - 60 : webViewHeight;

  return { webViewWidth, webViewHeight };
};

const encodeGetParams = (p) => {
  if (p && Object.entries(p).length > 0) {
    return (
      "?" +
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&") +
      "&"
    );
  } else {
    return "?";
  }
};

export const getCloudUri = (device, profile, authData, clientData, currentConnection, params_device, customUrl) => {
  try {
    let condition = currentConnection && currentConnection == "cloud" && params_device && (params_device.get("params") & 0x2000) > 0;
    let url = customUrl ? customUrl : params_device ? params_device.get("url") : "";

    let internetAccess = params_device ? (params_device.get("params") & 0x4000000) > 0 : 0;

    //let legacyMode = (params_device.get("params") & 0x2000000) > 0;
    let legacyMode = false;
    let uri = condition || legacyMode ? device.get("uwagi") : url;

    if (
      currentConnection == "new_cloud" &&
      profile.get("loginData").get("device_id") &&
      authData &&
      authData.get("access_token") &&
      !internetAccess &&
      !legacyMode
    ) {
      if (Platform.OS !== "web") {
        if (!profile.get("loginData").get("login") && clientData && clientData.get("local_token")) {
          uri = `https://${profile.get("loginData").get("device_id")}-${device.get("id")}.${mainHost}:6214/${
            Linking.parse(url).path
          }${encodeGetParams(Linking.parse(url).queryParams)}_cloud_access_token=${authData.get("access_token")}&_cloud_local_token=${clientData.get(
            "local_token"
          )}`;
        } else {
          uri = `https://${profile.get("loginData").get("login")}:${profile.get("loginData").get("password")}@${profile
            .get("loginData")
            .get("device_id")}-${device.get("id")}.${mainHost}:6214/${Linking.parse(url).path}${encodeGetParams(
            Linking.parse(url).queryParams
          )}_cloud_access_token=${authData.get("access_token")}`;
        }
      } else {
        if (!profile.get("loginData").get("login") && clientData && clientData.get("local_token")) {
          uri = `https://${profile.get("loginData").get("device_id")}-${device.get("id")}.${mainHost}:6214/${
            Linking.parse(url).path
          }${encodeGetParams(Linking.parse(url).queryParams)}_cloud_access_token=${authData.get("access_token")}&_cloud_local_token=${clientData.get(
            "local_token"
          )}`;
        } else {
          uri = `https://${profile.get("loginData").get("device_id")}-${device.get("id")}.${mainHost}:6214/${
            Linking.parse(url).path
          }${encodeGetParams(Linking.parse(url).queryParams)}_cloud_access_token=${authData.get("access_token")}&_shm_credentials=${base64.encode(
            `${profile.get("loginData").get("login")}:${profile.get("loginData").get("password")}`
          )}`;
        }
      }
      0;
    }
    if (device.get("typ_komponentu") == "wekta" && !condition) {
      uri = device.get("uwagi");
    }
    return uri;
  } catch (e) {}
  return "";
  //console.log(uri);
};
