import Janus from "./Janus";
import { store } from "../../store/configureStore";
import { mainHost } from "../../../CloudConstants";
import { fetchIceServers } from "../../screens/Initial/CloudTools";
import moment from "moment";

import { changeJanus } from "../../store/actions";

class JanusClient {
  static instance = null;
  static createInstance() {
    var object = new JanusClient();
    return object;
  }

  static getInstance() {
    if (!JanusClient.instance) {
      JanusClient.instance = JanusClient.createInstance();
    }
    return JanusClient.instance;
  }

  end = (reconnect) => {
    if (this.janus) {
      this.janus.destroy(reconnect);
    }
    store.dispatch(changeJanus(null));
  };

  get = async () => {
    if (this.janus == null) {
      await this.create();
    }
    return this.janus;
  };

  async create() {
    await this.init(
      store.getState().statesData.get("clientData").toJS(),
      store.getState().cloudData,
      store.getState().statesData.get("currentConnection")
    );
  }

  isURL = (str) => {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  };

  async init(profile, cloudData, currentConnection) {
    let host = null;
    let iceServers = null;
    if (currentConnection === "new_cloud" && cloudData && cloudData.get("authData")) {
      host = `wss://${profile.device_id}-0.${mainHost}:6214/janus?_cloud_access_token=${cloudData.get("authData").get("access_token")}`;
    } else if (window.location.protocol == "http:") {
      if (profile.ip) {
        host = "ws://" + profile.ip + ":8188/janus";
      } else if (profile.ip2) {
        host = "ws://" + profile.ip2 + ":8188/janus";
      }
    }
    if (host && this.isURL(host)) {
      if (cloudData && cloudData.get("authData")) {
        if (cloudData.get("iceServers")) {
          let currentTime = moment().unix();
          let timeToCheck = cloudData.get("iceServers").get("exp");
          if (timeToCheck - currentTime < 60 * 60 * 6) {
            iceServers = await fetchIceServers(cloudData.get("authData").get("access_token"));
          }
        } else {
          iceServers = await fetchIceServers(cloudData.get("authData").get("access_token"));
        }
      }

      if (!iceServers && cloudData.get("iceServers")) {
        iceServers = cloudData.get("iceServers").toJS();
      }

      this.janus = new Janus(host);
      let janusIce = [
        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
            "stun:stun3.l.google.com:19302",
            "stun:stun4.l.google.com:19302",
          ],
        },
        {
          urls: ["stun:cloud.ampio.com:3478"],
        },
      ];
      if (iceServers) {
        janusIce.push({
          urls: iceServers.uris,
          username: iceServers.username,
          credential: iceServers.password,
        });
      }
      this.janus.setIceServers(janusIce);

      if (profile.local_token && !profile.password) {
        this.janus.socket.setLocalToken(profile.local_token);
      } else {
        this.janus.socket.setCredentials(profile.login, profile.password);
      }
      await this.janus.init();
    }
  }
}

let janusClient = JanusClient.getInstance();
export default janusClient;
