import React, { Component, useEffect, useLayoutEffect } from "react";
import { View, I18nManager, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  ComponentImage,
  ComponentHeaderText,
  ComponentContainerTouchable,
  StyledIcon,
} from "../../components/UI/styledComponents";

import IconBadge from "../../utils/IconBadge";

const PlaceGroupComponent = ({
  group,
  globalSettings,
  showSketchFirst,
  profileSettings,
  overWriteName,
  fromDevices,
  dimensions,
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const numberOfColumns = dimensions.get("numberOfColumns");

  const showBGForGroupIcons = globalSettings.get("showBGForGroupIcons");

  const theme = globalSettings.get("primaryTheme");
  const iconBGColor =
    globalSettings.get("iconBGColors") &&
    globalSettings.get("iconBGColors").get(theme);

  var base64Icon = group
    ? `data:image/png;base64,${group.get("ikona_menu")}`
    : null;
  const originalColor = {
    ampio: "rgb(255,255,255)",
    night: "rgb(128,128,128)",
    oled: "rgb(17,17,17)",
  };

  let bgColor = showBGForGroupIcons ? iconBGColor : originalColor[theme];
  let isBig = numberOfColumns == 1 ? false : true;

  useEffect(() => {
    let startingGroup = profileSettings.get("startingPlace");
    if (
      group &&
      group.get("id") == startingGroup &&
      window.app.getGoToGroup()
    ) {
      navigation.push("Devices_Places", {
        group,
        auto: window.app.getGoToGroup(),
      });
    }
  });

  return (
    <ComponentContainerTouchable
      onPress={() => {
        navigation.push("Devices_Places", { group });
      }}
      containerStyle={fromDevices && { width: "100%" }}
      style={{ alignItems: "center" }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View
          style={{
            backgroundColor: bgColor,
            height: isBig ? 60 : 50,
            width: isBig ? 60 : 50,
            borderRadius: isBig ? 120 : 100,
            justifyContent: "center",
            alignItems: "center",
            margin: -5,
          }}
        >
          <IconBadge group={group} isBig={isBig}>
            <ComponentImage isBig={isBig} source={{ uri: base64Icon }} />
          </IconBadge>
        </View>

        <ComponentHeaderText style={{ paddingLeft: 20 }}>
          {overWriteName ? overWriteName : group.get("opis_menu")}
        </ComponentHeaderText>
      </View>
      <StyledIcon
        name={I18nManager.isRTL ? "chevron-left" : "chevron-right"}
        color={"secondary"}
        size={isBig ? 40 : 24}
      />
    </ComponentContainerTouchable>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    globalSettings: state.globalSettings,
    dimensions: state.statesData.get("dimensions"),
    profileSettings: state.profilesSettings.get(
      state.profilesSettings.get("currentProfile")
    ),
    showSketchFirst: state.globalSettings.get("showSketchFirst"),
    group: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("groups")
      .get(ownProps.groupId),
  };
};

export default connect(mapStateToProps)(PlaceGroupComponent);
