import React from "react";
import { View, FlatList } from "react-native";
import DeviceListHandler from "./smartHomeHandlers/DeviceListHandler";
import { connect } from "react-redux";

const DevicesToShowByColumns = ({
  listOfDevices,
  arrayOfIds,
  sceneListOfDevices,
  activeSceneControl,
  changeSceneFromDevice,
  removeDeviceFromScene,
  devices,
  source,
  customBg,
  dimensions,
}) => {
  let data = [];

  const numberOfColumns = dimensions.get("numberOfColumns");
  if (listOfDevices) {
    listOfDevices.map((group_device) => {
      if (devices.get(group_device.get("id_obiektu"))) {
        data.push({
          customWidth: group_device.get("customWidth"),
          props: {
            key: group_device.get("id_obiektu"),
            group_params: group_device.get("param"),
            device: devices.get(group_device.get("id_obiektu")),
            customWidth: group_device.get("customWidth"),
            customBg: customBg,
          },
        });
      }
    });
  } else if (arrayOfIds) {
    arrayOfIds.map((deviceID) => {
      if (devices && devices.get(deviceID)) {
        data.push({
          customWidth: null,
          props: {
            key: deviceID,
            device: devices.get(deviceID),
            source: source,
          },
        });
      }
    });
  } else if (sceneListOfDevices) {
    sceneListOfDevices.map((deviceInfo) => {
      data.push({
        customWidth: null,
        props: {
          key: `${deviceInfo.get("id")}${deviceInfo.get("temporary_id")}`,
          device: devices.get(deviceInfo.get("id")),
          deviceInScene: deviceInfo,
          activeSceneControl: activeSceneControl,
          changeSceneFromDevice: changeSceneFromDevice,
          removeDeviceFromScene: removeDeviceFromScene,
        },
      });
    });
  }

  let columns = [];
  let columnIndex = 0;
  let columnNumbers = 0;

  data.map((el, index) => {
    columnIndex = columnNumbers + (index % numberOfColumns);
    if (el.customWidth) {
      if (index != 0) {
        columnNumbers = columnNumbers + numberOfColumns;
      }

      columns[columnNumbers] = {
        width: "100%",
        elements: [],
      };
      columns[columnNumbers].elements.push(el);
      columnNumbers = columnNumbers + 1;
    } else {
      if (columns[columnIndex] == undefined) {
        columns[columnIndex] = {
          width: `${100 / numberOfColumns}%`,
          // width: `100%`,
          elements: [],
        };
      }

      columns[columnIndex].elements.push(el);
    }
  });

  //musi być tak bo inaczej się układją w rzędzie a że są różne wysokości to brzysko wygląda
  //dzięki temu to sie układają po kolumnach

  return (
    <View
      style={{
        flexDirection: "row",
        width: `100%`,
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      {columns.map((el, index) => {
        return (
          <FlatList
            key={index}
            data={el.elements}
            nestedScrollEnabled={true}
            scrollEnabled={false}
            initialNumToRender={el.elements ? el.elements.length : 0}
            renderItem={({ item, index, separators }) => <DeviceListHandler {...item.props} />}
            keyExtractor={(item) => item.props.key}
            style={{
              width: el.width ? el.width : `${100 / numberOfColumns}%`,
            }}
          />

          // <View
          //   key={index}
          //   style={{
          //     width: el.width ? el.width : `${100 / numberOfColumns}%`,
          //   }}
          // >

          //   {el.elements.map((el) => {
          //     return <DeviceListHandler {...el.props} />;
          //   })}
          // </View>
        );
      })}
    </View>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dimensions: state.statesData.get("dimensions"),
  };
};

export default connect(mapStateToProps)(DevicesToShowByColumns);
