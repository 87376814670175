import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet, ActivityIndicator, Platform } from "react-native";
import { connect } from "react-redux";

import { t } from "../../services/i18n";

import { PrimaryColorText, ButtonPrimary } from "../../components/UI/styledComponents";
// import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
// import MultiplePickerSelectObjects from "./MultiplePickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";
import PickerSelect from "../../components/UI/PickerSelect";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import { devicesForGroups } from "../../utils";
import * as Location from "expo-location";

// COMMENT IN WEB
//import MapView, { PROVIDER_GOOGLE } from "react-native-maps";

import { mainHost } from "../../../CloudConstants";

const conditionTypes = {
  DAYS: 1,
  TIME: 2,
  OBJECT: 0,
  EVENT: 8,
  LOCATION_ENTER: 12,
  LOCATION_LEAVE: 13,
  OBJECT_PARAM2: 15,
};
const locationRadius = {
  KM01: 100,
  KM05: 500,
  KM1: 1000,
  KM2: 2000,
  KM5: 5000,
};

const styles = StyleSheet.create({
  map: {
    ...StyleSheet.absoluteFillObject,
  },
});

class AddConditionModal extends Component {
  types = Object.entries(conditionTypes).map(([key, value]) => {
    if (value !== conditionTypes.OBJECT_PARAM2) {
      let element = { label: t(`${t("conditions:" + key)}`), value: value };
      return element;
    }
    return null;
  });

  state = {
    selectedRegion: null,
    selectedType: conditionTypes.DAYS,
    selectedGroup: 0,
    selectedDevice: null,
    selectedEvent: null,
    selectedRadius: 100,
    buttonAddDisabled: false,
  };

  onAddHandler = () => {
    const { selectedType, selectedEvent, selectedDevice, selectedRegion, selectedRadius } = this.state;

    let object_id = null;

    if (conditionTypes.EVENT === selectedType) {
      object_id = selectedEvent;
    } else if (conditionTypes.OBJECT === selectedType || conditionTypes.OBJECT_PARAM2 === selectedType) {
      object_id = selectedDevice;
    } else if (selectedType == conditionTypes.LOCATION_LEAVE || selectedType == conditionTypes.LOCATION_ENTER) {
      object_id = { radius: selectedRadius, ...selectedRegion };
    }
    if ((selectedType == conditionTypes.LOCATION_LEAVE || selectedType == conditionTypes.LOCATION_ENTER) && !this.props.profile.get("cloudIp")) {
      window.app.showToast("Enable cloud access in security tab");
    } else {
      this.props.onAdd(this.state.selectedType, object_id);
    }
  };

  onSelectedTypeChange = (selectedType) => {
    let buttonDisabled = this.state.buttonAddDisabled;
    if (conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType) {
      if (!this.state.selectedRegion) buttonDisabled = true;
      this.getLocationPerrmisions();
    }

    this.setState({
      selectedType,
      buttonAddDisabled: buttonDisabled,
    });
  };
  onGroupValueChange = (value) => {
    this.setState({
      selectedGroup: value,
      selectedDevice: null,

      buttonAddDisabled: true,
    });
  };

  onDeviceValueChange = (value) => {
    if (value) {
      this.setState({ selectedDevice: value, buttonAddDisabled: false });
    }
  };

  onEventValueChange = (value) => {
    if (value) {
      this.setState({ selectedEvent: value, buttonAddDisabled: false });
    }
  };

  onRadiusValueChange = (value) => {
    if (value) {
      this.setState({ selectedRadius: value, buttonAddDisabled: false });
    }
  };

  onRegionChangeComplete = (value) => {
    if (value) {
      this.setState({ selectedRegion: value, buttonAddDisabled: false });
    }
  };

  setDevices = () => {
    const { devices, group_devices, logging, params_devices } = this.props;

    let devicesToSend = [];

    let listOfDevicesForGroup = devicesForGroups(group_devices, this.state.selectedGroup);

    listOfDevicesForGroup.map((deviceForGroup) => {
      let deviceID = deviceForGroup.get("id_obiektu");
      if (devices.get(deviceID)) {
        let device = devices.get(deviceID);
        let typKomponentu = device.get("typ_komponentu");
        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID) ? params_devices.get(deviceID).get("powiazane") : "";
          let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];

          powiazaneArrayWithPossibleCustom.map((el, index) => {
            el = el.split(":").reverse();
            let deviceFromCustom = devices.get(el[0]);
            if (
              deviceFromCustom &&
              deviceFromCustom.get("typ_komponentu") != "custom" &&
              logging.find((el) => el.get("id_obiektu") == deviceFromCustom.get("id"))
            ) {
              devicesToSend.push({
                label: deviceFromCustom.get("opis_menu"),
                value: deviceFromCustom.get("id"),
              });
            }
          });
        } else {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      }
    });
    return devicesToSend;
  };

  setEvents = () => {
    const { events } = this.props;

    let eventsToSend = [];

    events &&
      events.map((event) => {
        if (event) {
          eventsToSend.push({
            label: event.get("opis"),
            value: event.get("wartoscN"),
          });
        }
      });

    return eventsToSend;
  };

  getLocationPerrmisions = async () => {
    try {
      let { status } = await Location.requestForegroundPermissionsAsync();

      if (status === "granted") {
        let { status } = await Location.requestBackgroundPermissionsAsync();
        if (status !== "granted") {
          return false;
        } else {
          let position = await Location.getCurrentPositionAsync({});

          let location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          };
          this.onRegionChangeComplete(location);
          return true;
        }
      } else {
        return false;
      }
    } catch (ex) {
      console.log(ex.message);
      window.app.showToast(ex.message);
      return false;
    }
  };

  render() {
    let events = this.setEvents();
    let devicesInGroup = this.setDevices();
    let radius = Object.entries(locationRadius).map(([key, value]) => {
      let element = { label: t("conditions:" + `${key}`), value: value };
      return element;
    });

    const { isVisible, onClose, eventAlreadyExist } = this.props;
    const { selectedType, selectedDevice, selectedGroup, selectedEvent, selectedRadius, buttonAddDisabled, selectedRegion } = this.state;

    return (
      <ModalWithContent isVisible={isVisible} title={t("conditions:ADD_CONDITION")} onClose={onClose}>
        <View style={{ paddingBottom: 16 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>{t("conditions:TYPE")}</PrimaryColorText>
          <PickerSelect
            placeholder={{
              label: t("conditions:CHOOSE_TYPE"),
              value: null,
            }}
            items={this.types}
            onValueChange={this.onSelectedTypeChange}
            value={selectedType}
          />
        </View>

        {conditionTypes.OBJECT == selectedType || conditionTypes.OBJECT_PARAM2 == selectedType ? (
          <View>
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_PLACE")}</PrimaryColorText>
              <PickerSelectObjects onValueChange={this.onGroupValueChange} value={selectedGroup} type={"groups"} />
            </View>

            <View style={{ paddingBottom: 20 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_NAME")}</PrimaryColorText>
              <PickerSelectObjects type={"devices"} items={devicesInGroup} onValueChange={this.onDeviceValueChange} value={selectedDevice} />
            </View>
          </View>
        ) : null}
        {conditionTypes.EVENT == selectedType ? (
          eventAlreadyExist ? (
            <PrimaryColorText style={{ textAlign: "center", paddingBottom: 10 }}>{t("conditions:CAN_ADD_ONLY_1_EVENT")}</PrimaryColorText>
          ) : (
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("EVENT_NAME")}</PrimaryColorText>

              <PickerSelect
                placeholder={{
                  label: t("SELECT_EVENT_NAME"),
                  value: null,
                }}
                items={events}
                onValueChange={this.onEventValueChange}
                value={selectedEvent}
              />
            </View>
          )
        ) : null}
        {selectedRegion && (conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType) ? (
          <View>
            <PickerSelect
              placeholder={{
                label: t("RADIUS"),
                value: null,
              }}
              items={radius}
              onValueChange={this.onRadiusValueChange}
              value={selectedRadius}
            />
            {Platform.OS !== "web" && (
              <View style={{ height: 200, marginBottom: 5, marginTop: 5 }}>
                <MapView
                  style={styles.map}
                  provider={PROVIDER_GOOGLE}
                  followUserLocation={true}
                  showsUserLocation={true}
                  onRegionChangeComplete={this.onRegionChangeComplete}
                  initialRegion={selectedRegion}
                  region={selectedRegion}
                >
                  <MapView.Circle
                    center={selectedRegion}
                    radius={selectedRadius}
                    strokeColor="#80bfff"
                    strokeWidth={0}
                    fillColor="rgba(0,0,0,0.4)"
                    accessibilityIgnoresInvertColors={false}
                  />
                  <MapView.Marker coordinate={selectedRegion} />
                </MapView>
              </View>
            )}
          </View>
        ) : null}
        {!selectedRegion && (conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType) ? (
          <View style={{ height: 50, marginBottom: 5, marginTop: 5 }}>
            <ActivityIndicator size="large" color="#ed695c" />
          </View>
        ) : null}
        <ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
          {t("ADD")}
        </ButtonPrimary>
      </ModalWithContent>
    );
  }
}
const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
    logging: state.smartHomeData.get(currentProfile).get("logging"),
    events: state.smartHomeData.get(currentProfile).get("events"),
    profile: state.profilesSettings.get(currentProfile).get("loginData"),
  };
};

export default connect(mapStateToProps)(AddConditionModal);
