import React, { useContext } from "react";
import { View } from "react-native";

import {
  FontAwesome,
  MaterialCommunityIcons,
  Feather,
} from "@expo/vector-icons";
import { useTheme } from "../../../../ThemeContext";

const DefaultIcon = (props) => {
  const { theme } = useTheme();

  let { library } = props;

  let content = null;
  switch (library) {
    case "MaterialCommunityIcons":
      return (content = (
        <MaterialCommunityIcons
          name={props.name}
          size={props.size || 22}
          color={theme.ICONS_COLOR_PRIMARY}
          style={[{ padding: 5 }, props.style]}
        />
      ));
    case "FontAwesome":
      return (content = (
        <FontAwesome
          name={props.name}
          size={props.size || 22}
          color={theme.ICONS_COLOR_PRIMARY}
          style={[{ padding: 5 }, props.style]}
        />
      ));

    case "Feather":
      return (content = (
        <Feather
          name={props.name}
          size={props.size || 22}
          color={theme.ICONS_COLOR_PRIMARY}
          style={[{ padding: 5 }, props.style]}
        />
      ));

    default:
      break;
  }

  return <View>{content}</View>;
};

export default DefaultIcon;
