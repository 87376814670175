import React, { Component } from "react";
import { View, TouchableOpacity, Keyboard, Platform } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import mqttClient from "../../../services/mqtt";

import { StyledIcon } from "../../UI/styledComponents";
import ModalSimple from "../../UI/ModalSimple";
import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import * as LocalAuthentication from "expo-local-authentication";
class EventComponent extends Component {
  state = {
    pinDialogVisible: false,
    wrongPinDialogVisible: false,
    pin: "",
  };

  onPinInputHandler = (value) => {
    this.setState({
      pin: value,
    });
  };

  handleClose = () => {
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false });
  };

  handleOK = () => {
    const { device } = this.props;
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false, pin: "" });
    if (this.state.pin == device.get("haslo")) {
      this.handleSend();
    } else {
      setTimeout(() => {
        this.setState({
          wrongPinDialogVisible: true,
        });
      }, 500);
    }
  };
  handleOKwrongPin = () => {
    this.setState({ wrongPinDialogVisible: false });
  };

  handleBiometrics = async () => {
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false });
    const result = await LocalAuthentication.authenticateAsync({ requireConfirmation: false });
    if (result && result.success) {
      this.handleSend();
    }
  };

  playEvent = () => {
    const { device, deviceInScene, changeScene } = this.props;
    if (device.get("bezpieczne")) {
      this.setState({ pinDialogVisible: true });
    } else {
      this.handleSend();
    }
  };

  handleSend = () => {
    const { device, deviceInScene, changeScene, activeSceneControl } = this.props;

    let message = `/api/set/${device.get("id")}/setValue/255`;

    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  render() {
    const { device, header, readOnly, deviceFromCustom, deviceFromSketch, group_params, stretchSketch } = this.props;
    const { value, pinDialogVisible, wrongPinDialogVisible } = this.state;

    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!deviceFromCustom && !deviceFromSketch && header}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {device.get("bezpieczne") ? <StyledIcon style={{ marginRight: 4 }} name={"lock"} size={20} /> : null}
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={this.playEvent}
              style={{
                width: deviceFromSketch ? "100%" : null,
                height: deviceFromSketch ? "100%" : null,
              }}
              disabled={readOnly}
            >
              {((device.has("ikona0") && device.get("ikona0")) || (device.has("ikona_0") && device.get("ikona_0"))) &&
              (deviceFromSketch || (!deviceFromSketch && (group_params & 0x04) == 0)) ? (
                <ImageFromIkona0_0
                  deviceFromSketch={deviceFromSketch}
                  deviceFromCustom={deviceFromCustom}
                  device={device}
                  value={value}
                  resizeMode={stretchSketch ? "stretch" : "contain"}
                  style={{
                    width: deviceFromSketch ? "100%" : 36,
                    height: deviceFromSketch ? "100%" : 36,
                  }}
                />
              ) : (
                <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
              )}
            </TouchableOpacity>
          </View>
        </View>
        {pinDialogVisible && (
          <ModalSimple
            isVisible={pinDialogVisible}
            title={"AUTH"}
            description={"PASS_PIN"}
            placeholder={"PASS_PIN"}
            secureTextEntry={true}
            onInputChange={this.onPinInputHandler}
            keyboardType={"numeric"}
            onClose={this.handleClose}
            onOK={this.handleOK}
            showBiometrics={device.get("bezpieczne") == 2 && Platform.OS !== "web"}
            onThirdOption={this.handleBiometrics}
          />
        )}
        {wrongPinDialogVisible && (
          <ModalSimple isVisible={wrongPinDialogVisible} title={"AUTH"} description={"PIN_NOT"} onOK={this.handleOKwrongPin} />
        )}
      </View>
    );
  }
}

export default EventComponent;
