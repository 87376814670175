import "./global";
import { t } from "../i18n";
import md5 from "md5";
var mqtt = require("mqtt");
import { Platform } from "react-native";
import { store } from "../../store/configureStore";
import CLOUD_CONSTANTS, { mainHost } from "../../../CloudConstants";
import ipaddr from "ipaddr.js";
import Constants from "expo-constants";
import * as WebBrowser from "expo-web-browser";
import * as Network from "expo-network";
// import NetInfo from "@react-native-community/netinfo";
import * as Updates from "expo-updates";
import jwt_decode from "jwt-decode";
import janusClient from "../janus";
import {
  checkLocalTokenNeedRefresh,
  refreshAccessToken,
  fetchLocalToken,
  checkTokenNeedRefresh,
  checkIfDeviceInAssociactions,
} from "../../screens/Initial/CloudTools";
//import ExpoFileSystemStorage from "redux-persist-expo-filesystem";
import ExpoFileSystemStorage from "redux-persist/lib/storage";

import {
  changeAppLogout,
  changeDontAsk,
  changeClientId,
  changeClientData,
  groupsReceived,
  devicesReceived,
  group_devicesReceived,
  params_devicesReceived,
  loggingReceived,
  scenesReceived,
  resourcesReceived,
  iconsReceived,
  md5DataReceived,
  statesReceived,
  statesActiveReceived,
  heatingScheduleReceived,
  irDataReceived,
  ir_statusReceived,
  accessControlReceived,
  chartDataReceived,
  barChartDataReceived,
  showInfoBar,
  changeConnectionStatus,
  serverInfoReceived,
  mqttVersionReceived,
  diagnosticStatsReceived,
  initialLoadingStatusReceived,
  serverUpdateInfoReceived,
  mqttUpdateInfoReceived,
  logbookReceived,
  actions_logReceived,
  irrigationsReceived,
  automatizationsReceived,
  passwordChangedInfoReceived,
  changeCurrentProfile,
  singleStateReceived,
  singleActiveStateReceived,
  radioFavouritesReceived,
  cameraStateReceived,
  receivedWeatherAmpio,
  messagesReceived,
  messagesCountReceived,
  serverOwnerReceived,
  authDataReceived,
  showAlertScreen,
  showCriticalNoConnection,
  changeCurrentConnectionPlace,
  changeConsentsStatus,
} from "../../store/actions";

const MINIMUM_SUPPORTED_VERSION = 290;
const MINIMUM_PROTOCOL_VERSION = 0;

let waitingForAllRequiredSmartHomeDataInterval = 0;
let waitingForAllmd5Interval = 0;
let checkedTheVersion = false;
let triedWithLocalhost = false;
let waitingForStatesInterval = 0;
let internetConnectionType = null;
let profile = {};
let serverVersion = 0;
let janusApplicable = 0;

let previousMD5Data = {};
let newMD5Data = {
  devices: "",
  group_devices: "",
  groups: "",
  params_devices: "",
  logging: "",
  scenes: "",
  resources: "",
  icons: "",
};

let alarmToShow = ["satel_alarm", "alarm"];

let typeToShow = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "led",
  "ledww",
  "flaga_liniowa",
  "flaga_liniowa16",
  "przekaznik",
  "radio",
  "ip_radio",
  "rgb",
  "rgbw",
  "reg",
  "wej",
];

//the same length as md5 object
let dataToFetch = 0;
let stepsToTake = 0;
let waitingToConnect = false;

let smartDataToUpdateArray = [];
let initialStates = null;

//later states
let sendingStateChangesInterval = 0;
let statesArrayIntervallySend = [];

let location = "auth";
let curClient = null;
let newClient = null;
let randomSession = -1;
let randomDevice = -1;

let connectionTimeout = 0;

let sendStatusInfo = (message, numberToReduce = 0, dataFetched = 0) => {
  dataToFetch += dataFetched;
  stepsToTake += numberToReduce;
  //md5 object length: 8 + md5 + serverInfo + states;
  let totalNumberOfSteps = 11;

  let percentage = (stepsToTake / totalNumberOfSteps) * 100;
  if (percentage > 100) percentage = 100;
  const limitedDataToFetch = dataToFetch > 9 ? 9 : dataToFetch;

  let messageToSend = message == "" ? `${t("progress:DATA")} ${limitedDataToFetch} / 9` : t(`progress:${message}`);
  store.dispatch(initialLoadingStatusReceived(messageToSend, `${percentage}%`));
};

let clearAllIntervals = () => {
  waitingForAllmd5Interval && clearInterval(waitingForAllmd5Interval);
  waitingForAllRequiredSmartHomeDataInterval && clearInterval(waitingForAllRequiredSmartHomeDataInterval);
  waitingForStatesInterval && clearInterval(waitingForStatesInterval);
  sendingStateChangesInterval && clearInterval(sendingStateChangesInterval);

  waitingForAllRequiredSmartHomeDataInterval = 0;
  waitingForAllmd5Interval = 0;
  waitingForStatesInterval = 0;
  sendingStateChangesInterval = 0;
};

let clearAllData = (loc) => {
  checkedTheVersion = false;
  store.dispatch(changeClientId(null));
  curClient = null;
  newClient = null;
  profile = {};

  previousMD5Data = {};
  newMD5Data = {
    devices: "",
    group_devices: "",
    groups: "",
    params_devices: "",
    logging: "",
    scenes: "",
    resources: "",
    icons: "",
  };

  smartDataToUpdateArray = [];
  initialStates = null;

  dataToFetch = 0;
  stepsToTake = 0;

  //later states
  statesArrayIntervallySend = [];
  triedWithLocalhost = false;
  location = loc;
  internetConnectionType = null;
};

//local or cloud
let connectionType = "local";

class MQTTClient {
  static instance = null;
  static createInstance() {
    var object = new MQTTClient();
    if (store.getState().statesData.get("appLocation")) {
      object.location = store.getState().statesData.get("appLocation");
    }
    return object;
  }

  static getInstance() {
    if (!MQTTClient.instance) {
      MQTTClient.instance = MQTTClient.createInstance();
    }
    return MQTTClient.instance;
  }

  mqttInit = async (data, connectionTypeReceived) => {
    if (!data || Object.keys(data).length == 0) {
      console.log("Data cleared, fetching clientData from store state in mqtt init");
      if (store.getState().statesData.get("clientData")) {
        profile = store.getState().statesData.get("clientData").toJS();
      }
      if (store.getState().statesData.get("appLocation")) {
        location = store.getState().statesData.get("appLocation");
      }
    } else {
      profile = data;
      store.dispatch(changeClientData(data));
    }
    location == "auth" && sendStatusInfo("WAITING_FOR_CONNECTION");
    store.dispatch(changeCurrentProfile(profile.name));
    store.dispatch(changeConnectionStatus(0));
    if (connectionType !== "new_cloud") store.dispatch(changeConsentsStatus(0));
    connectionType = connectionTypeReceived;
    if (connectionTypeReceived === "demo") {
      connectionType = "local";
    } else {
      await this.getPossibleConnectionType();
    }

    // this.unsubscribe = NetInfo.addEventListener((state) => {
    //   if (!this.internetConnectionType) {
    //     this.internetConnectionType = state.type;
    //   }
    //   if (this.internetConnectionType != state.type) {
    //     setTimeout(() => {
    //       this.sendPing();
    //     }, 1000);
    //   }

    //   this.internetConnectionType = state.type;
    // });

    this.doConnect(true);
  };

  handleLogOut = (fromSwitchProfile, data, connectionType) => {
    curClient &&
      curClient.end(true, null, () => {
        if (fromSwitchProfile == true) {
          this.mqttInit(data, connectionType);
        }
      });
    if (store.getState().statesData.get("janus")) {
      janusClient.end(false);
    }
    location = "auth";
    window.app.setGoToGroup(true);
    clearAllIntervals();
    clearAllData("auth");
    store.dispatch(showInfoBar(false));
    waitingToConnect = false;
    store.dispatch(showCriticalNoConnection(null));
  };

  appGoesToBackground = (param) => {
    if (store.getState().statesData.get("janus")) {
      janusClient.end(false);
    }
    curClient && curClient.end(true);
    waitingToConnect = true;
    store.dispatch(changeCurrentConnectionPlace("loader"));
    //store.dispatch(showInfoBar(true));
    clearAllIntervals();
    curClient = null;

    previousMD5Data = {};
    newMD5Data = {
      devices: "",
      group_devices: "",
      groups: "",
      params_devices: "",
      logging: "",
      scenes: "",
      resources: "",
      icons: "",
    };
    smartDataToUpdateArray = [];
    initialStates = null;

    //later states
    statesArrayIntervallySend = [];
    if (param == "reconnectWithNewToken" && connectionType === "new_cloud") {
      this.doConnect(true);
    }
  };

  insideLocalNetwork = (ip) => {
    try {
      const addr = ipaddr.parse(ip);
      if (addr.range() == "private") {
        return true;
      } else if (addr.range() == "linkLocal") {
        return true;
      }
    } catch (ex) {
      return false;
    }
  };

  getPossibleConnectionType = async () => {
    //let networkInfo = await this.hasNetwork();
    let networkState = null;
    try {
      networkState = await Network.getNetworkStateAsync();
    } catch (e) {}
    if (networkState && networkState.type != Network.NetworkStateType.CELLULAR) {
      // sieć lokalna
      triedWithLocalhost = false;
      connectionType = "local";
      if (connectionType == "local" && !profile.ip) {
        connectionType = "local2";
      }
      if (
        connectionType == "local2" &&
        !profile.ip2 &&
        store.getState().cloudData.get("authData") &&
        profile.device_id &&
        (await checkIfDeviceInAssociactions(
          store.getState().cloudData.get("authData"),
          store.getState().cloudData.get("associationsData"),
          profile.device_id
        ))
      ) {
        connectionType = "new_cloud";
      } else if (connectionType == "local2" && !profile.ip2 && profile.cloudIp) {
        connectionType = "cloud";
      }
    } else {
      // siec komrkowa
      if (connectionType == "local" && (!profile.ip || this.insideLocalNetwork(profile.ip))) {
        connectionType = "local2";
      }
      if (
        connectionType == "local2" &&
        store.getState().cloudData.get("authData") &&
        profile.device_id &&
        !profile.ip2 &&
        (await checkIfDeviceInAssociactions(
          store.getState().cloudData.get("authData"),
          store.getState().cloudData.get("associationsData"),
          profile.device_id
        ))
      ) {
        connectionType = "new_cloud";
      } else if (connectionType == "local2" && !profile.ip2 && profile.cloudIp) {
        connectionType = "cloud";
      }
      // sieć komórkowa
    }
  };

  appComesToForeground = async () => {
    if (!profile || Object.keys(profile).length == 0) {
      console.log("Data cleared, fetching clientData from store state");
      if (store.getState().statesData.get("clientData")) {
        profile = store.getState().statesData.get("clientData").toJS();
      }
      if (store.getState().statesData.get("appLocation")) {
        location = store.getState().statesData.get("appLocation");
      }
    }
    await this.getPossibleConnectionType();

    if (profile && Object.keys(profile).length > 0) {
      this.doConnect(true);
    } else {
      this.handleLogOut();
      store.dispatch(showCriticalNoConnection("INVALID_STREAM"));
    }
  };

  getMessageFromReason = (reason) => {
    let message = "UNKNOWN";
    switch (reason) {
      case "error":
        message = "CHECK_METADATA";
        break;
      case "offline":
        message = "CHECK_CONNECTION";
        break;
      case "oldProtocol":
        message = "OLD_PROTOCOL";
        break;
      case "deviceIdsNotMatch":
        message = "DEVICE_IDS_NOT_MATCH";
        break;
      case "refreshtoken":
        message = "INVALID_REFRESH_TOKEN";
        break;
      case "nopermission":
        message = "NO_PERMISSION_TO_DEVICE";
        break;
      case "servernocloudconnection":
        message = "DEVICE_NO_CLOUD_CONNECTION";
        break;
      case "connectionlimit":
        message = "CONNECTIONS_LIMIT_REACHED";
        break;
      case "errorcloud":
        message = "CLOUD_CONNECTION_ERROR";
        break;
      case "invalidstream":
        message = "INVALID_STREAM";
        break;
      default:
        message = "CHECK_CONNECTION";
    }
    return message;
  };

  dealWithNoConnection = async (reason, critical, client) => {
    clearAllIntervals();

    const clientId = client && client.options ? client.options.clientId : "";
    //console.log("Dealing with no connection", reason, critical, clientId);
    let message = this.getMessageFromReason(reason);
    if (location == "auth") {
      if (client) {
        window.authScreen.showNoConnectionModal(message);

        checkedTheVersion = false;

        client.end(true);
        clearTimeout(connectionTimeout);
        clearAllData("auth");
      }
    } else if (location == "app") {
      //sprawdz czy nie trzeba odpalac nowego klineta

      setTimeout(() => {
        if (waitingToConnect && clientId === store.getState().statesData.get("clientId")) {
          store.dispatch(showInfoBar(true));
        }
      }, 5000);
      waitingToConnect = true;

      let oldConnectionType = connectionType;

      await this.getPossibleConnectionType();
      if (oldConnectionType != connectionType && !critical) {
        connectionType = oldConnectionType;
        this.handleClientEnd(reason, client);
      } else if (critical) {
        checkedTheVersion = false;
        client.end(true);
        clearTimeout(connectionTimeout);
        waitingToConnect = false;
      }

      if (critical && !store.getState().statesData.get("noConnectionCriticalError")) {
        setTimeout(() => {
          if (clientId === store.getState().statesData.get("clientId") || store.getState().statesData.get("clientId") === null) {
            store.dispatch(showCriticalNoConnection(message));
          }
          clearAllData("app");
        }, 100);
      }
    }
  };

  handleClientEnd = async (reason, client) => {
    if (connectionType == "local") {
      if (profile.ip2) {
        connectionType = "local2";
        this.doConnect(true);
      } else if (
        store.getState().cloudData.get("authData") &&
        profile.device_id &&
        (await checkIfDeviceInAssociactions(
          store.getState().cloudData.get("authData"),
          store.getState().cloudData.get("associationsData"),
          profile.device_id
        ))
      ) {
        connectionType = "new_cloud";
        this.doConnect(true);
      } else if (profile.cloudIp) {
        connectionType = "cloud";
        this.doConnect(true);
      } else {
        this.dealWithNoConnection(reason, true, client);
      }
    } else if (connectionType == "local2") {
      if (
        store.getState().cloudData.get("authData") &&
        profile.device_id &&
        (await checkIfDeviceInAssociactions(
          store.getState().cloudData.get("authData"),
          store.getState().cloudData.get("associationsData"),
          profile.device_id
        ))
      ) {
        connectionType = "new_cloud";
        this.doConnect(true);
      } else if (profile.cloudIp) {
        connectionType = "cloud";
        this.doConnect(true);
      } else if (profile.ip && !triedWithLocalhost) {
        connectionType = "local";
        this.doConnect(true);
      } else {
        this.dealWithNoConnection(reason, true, client);
      }
    } else if (connectionType == "new_cloud") {
      if (profile.cloudIp) {
        connectionType = "cloud";
        this.doConnect(true);
      } else if (profile.ip && !triedWithLocalhost) {
        connectionType = "local";
        this.doConnect(true);
      } else {
        this.dealWithNoConnection(reason, true, client);
      }
    } else {
      this.dealWithNoConnection(reason, true, client);
    }
  };

  connectionChaining = (client) => {
    let setNewTimout = (client) => {
      connectionTimeout = setTimeout(
        () => {
          if (client.options.clientId == store.getState().statesData.get("clientId") || store.getState().statesData.get("clientId") == null) {
            possibleConnection("timeout", client);
          }
        },
        connectionType == "local" ? 2000 : 10000
      );
    };
    clearTimeout(connectionTimeout);
    setNewTimout(client);

    let possibleConnection = (reason, client) => {
      clearTimeout(connectionTimeout);
      connectionTimeout = 0;
      if (client) {
        try {
          client.end(true, null, async () => {
            await store.dispatch(changeClientId(null));
            this.handleClientEnd(reason, client);
          });
        } catch (ex) {
          store.dispatch(changeClientId(null));
          this.handleClientEnd(reason, client);
        }
      }
    };

    client &&
      client.on("connect", () => {
        if (client.options.clientId === store.getState().statesData.get("clientId")) {
          if (connectionTimeout) {
            clearTimeout(connectionTimeout);
            connectionTimeout = 0;
          }

          store.dispatch(changeCurrentConnectionPlace(connectionType));
          if (newClient) {
            curClient = newClient;
            newClient = null;
          }
          //jak połączone to usuń timeOut
          waitingToConnect = false;
          location == "auth" && sendStatusInfo("CLIENT_CONNECTED");
          if (location == "app") {
            client && this.goFurther(client);
          }
          client && client.subscribe(`ampio/fromDB/${profile.login}/data/#`, () => {});
        }
      });

    client &&
      client.on("reconnect", () => {
        if (client.options.clientId == store.getState().statesData.get("clientId")) {
          if (location == "auth") {
            client && possibleConnection("reconnect", client);
          } else {
            client && this.dealWithNoConnection("reconnect", false, client);
          }
        } else {
          client.end(true);
        }
      });

    client &&
      client.on("close", () => {
        if (client.options.clientId == store.getState().statesData.get("clientId")) {
          store.dispatch(changeConnectionStatus(0));
          store.dispatch(changeCurrentConnectionPlace("loader"));
        }
      });

    client &&
      client.on("error", (error) => {
        if (client.options.clientId == store.getState().statesData.get("clientId")) {
          if (location == "auth") {
            client && possibleConnection("error", client);
          } else {
            client && this.dealWithNoConnection("error", true, client);
          }
        }
      });

    client &&
      client.on("offline", () => {
        if (client.options.clientId == store.getState().statesData.get("clientId")) {
          if (location == "auth") {
            client && possibleConnection("offline", client);
          }
          // else {
          //   client && this.dealWithNoConnection("offline", false, client);
          // }
        }
      });
  };

  handleAssociation = async (url) => {
    let result = await WebBrowser.openBrowserAsync(url);
  };

  getCurrentProfile = () => {
    return profile;
  };

  doConnect = async (createNewClient) => {
    //console.error("Doing connect", connectionType);
    let brokerUrl = "ws://0.0.0.2/";
    curClient = null;
    if (Platform.OS == "web") {
      switch (connectionType) {
        case "local":
          triedWithLocalhost = true;
          let port = ":9001";
          if (profile.ip && profile.ip.lastIndexOf(":") > 0) {
            port = "";
          }
          if (profile.ip && (profile.ip.startsWith("ws://") || profile.ip.startsWith("wss://"))) {
            brokerUrl = `${profile.ip}${port}`;
          } else {
            brokerUrl = `ws://${profile.ip}${port}`;
          }
          break;
        case "local2":
          let port2 = ":443";
          if (profile.ip2 && profile.ip2.lastIndexOf(":") > 0) {
            port2 = "";
          }
          if (profile.ip2 && (profile.ip2.startsWith("ws://") || profile.ip2.startsWith("wss://"))) {
            brokerUrl = `${profile.ip2}${port2}`;
          } else {
            brokerUrl = `wss://${profile.ip2}${port2}`;
          }
          break;
        case "cloud":
          brokerUrl = `wss://${profile.cloudIp}:443`;
          break;
        case "new_cloud":
          if (store.getState().cloudData.get("authData")) {
            let access_token = store.getState().cloudData.get("authData").get("access_token");
            if (checkTokenNeedRefresh(store.getState().cloudData.get("authData"))) {
              let authData = await refreshAccessToken(store.getState().cloudData.get("authData"));
              if (authData && authData.access_token) {
                access_token = authData.access_token;
              }
            }

            if (access_token) {
              brokerUrl = `wss://${profile.device_id}-0.${mainHost}:6214/?_cloud_access_token=${access_token}`;
            }
          }
          break;
      }
    } else {
      switch (connectionType) {
        case "local":
          triedWithLocalhost = true;
          let port = ":9001";
          if (profile.ip && profile.ip.lastIndexOf(":") > 0) {
            port = "";
          }
          if (profile.ip && (profile.ip.startsWith("ws://") || profile.ip.startsWith("wss://"))) {
            brokerUrl = `${profile.ip}${port}`;
          } else {
            brokerUrl = `ws://${profile.ip}${port}`;
          }
          break;
        case "local2":
          let port2 = ":9001";
          if (profile.ip2 && profile.ip2.lastIndexOf(":") > 0) {
            port2 = "";
          }
          if (profile.ip2 && (profile.ip2.startsWith("ws://") || profile.ip2.startsWith("wss://"))) {
            brokerUrl = `${profile.ip2}${port2}`;
          } else {
            brokerUrl = `ws://${profile.ip2}${port2}`;
          }
          break;
        case "cloud":
          brokerUrl = `wss://${profile.cloudIp}:443`;
          break;
        case "new_cloud":
          if (store.getState().cloudData.get("authData")) {
            let access_token = store.getState().cloudData.get("authData").get("access_token");
            if (checkTokenNeedRefresh(store.getState().cloudData.get("authData"))) {
              let authData = await refreshAccessToken(store.getState().cloudData.get("authData"));
              if (authData && authData.access_token) {
                access_token = authData.access_token;
              }
            }

            if (access_token) {
              brokerUrl = `wss://${profile.device_id}-0.${mainHost}:6214/?_cloud_access_token=${access_token}`;
            }
          }
          break;
      }
    }

    if (
      (profile.local_tokenExp || !profile.login || !profile.password) &&
      (checkLocalTokenNeedRefresh(profile.local_tokenExp) ||
        (!profile.local_tokenExp && !profile.login && !profile.password && connectionType == "new_cloud")) &&
      !profile.password &&
      store.getState().cloudData.get("authData")
    ) {
      let access_token = store.getState().cloudData.get("authData").get("access_token");
      if (access_token) {
        try {
          let localToken = await fetchLocalToken(access_token, profile.device_id);
          profile.local_token = localToken.local_token;
          profile.local_tokenExp = localToken.exp;
          if (!profile.login && localToken.association && localToken.association.local_user && connectionType == "new_cloud")
            profile.login = localToken.association.local_user.user_name;
          store.dispatch(changeClientData(profile));
        } catch (ex) {}
      }
    }

    let checkRefreshToken = false;
    var client;
    var setupStream = mqtt.MqttClient.prototype._setupStream;
    var dealWithNoConnection = this.dealWithNoConnection;
    var appGoesToBackground = this.appGoesToBackground;
    mqtt.MqttClient.prototype._setupStream = function () {
      try {
        setupStream.call(this);
        if (this.stream && this.stream.socket) {
          var oryginal_onclose = this.stream.socket.onclose;
          this.stream.socket.onclose = function (er) {
            if (oryginal_onclose) oryginal_onclose();
            //dealWithNoConnection("nopermission", true);
            const code = er ? er.code : 0;
            // console.log(
            //   "On close",
            //   code,
            //   er,
            //   checkRefreshToken,
            //   client.options.clientId,
            //   store.getState().statesData.get("clientId")
            // );
            if (client && client.options.clientId !== store.getState().statesData.get("clientId")) {
              client.end(true);
              if (this && this.stream && this.stream.socket) {
                this.stream.socket.onclose = null;
              }
            }

            if (er && connectionType == "new_cloud" && client && client.options.clientId == store.getState().statesData.get("clientId")) {
              // sprawdzic czy to cloud tez
              if (er.code == 4470) {
                dealWithNoConnection("invalidstream", true, client);
              } else if (er.code == 4471) {
                if (store.getState().cloudData.get("authData") && !checkRefreshToken) {
                  checkRefreshToken = true;
                  const data = refreshAccessToken(store.getState().cloudData.get("authData"));
                  if (data) {
                    appGoesToBackground("reconnectWithNewToken");
                  } else {
                    dealWithNoConnection("refreshtoken", true, client);
                    //store.dispatch(authDataReceived(null));
                    if (checkRefreshToken) {
                      console.log(er);
                      alert(JSON.stringify(er));
                    }
                  }
                }
              } else if (er.code == 4473) {
                dealWithNoConnection("nopermission", true, client);
              } else if (er.code == 4474) {
                dealWithNoConnection("servernocloudconnection", true, client);
              } else if (er.code == 4479) {
                dealWithNoConnection("connectionlimit", true, client);
              } else if (er.code == 1001) {
              } else {
                //dealWithNoConnection("errorcloud", false, client);
              }
            }
          };
        }
      } catch (ex) {
        console.log("Catch on setup mqtt stream", console.log(ex));
      }
    };

    let mqttClientId = "ampiouni_" + store.getState().globalSettings.get("deviceUUID");
    let epoch = Date.now();
    await store.dispatch(changeClientId(mqttClientId + "&" + epoch));
    client = mqtt.connect(brokerUrl, {
      username: profile.loginAsAdmin ? "admin" : profile.local_token && !profile.password ? "" : `${profile.login}`,
      password: profile.local_token && !profile.password ? profile.local_token : `${profile.password}`,
      clientId: `${mqttClientId}&${epoch}`,
      clean: true,
      reconnectPeriod: 2000,
      keepalive: 180,
      will: {
        topic: `ampio/control/${profile.login}/status/${mqttClientId}`,
        payload: `{"status":"0","app":"ampiouni","source":"${connectionType}"}`,
        retain: true,
        qos: 1,
      },
    });

    if (createNewClient) {
      newClient = client;
    } else {
      curClient = client;
    }
    this.connectionChaining(client);
    client &&
      client.on("message", (topic, message, packet) => {
        try {
          this.handleMessage(topic, message, packet, client);
        } catch (e) {
          console.log(e);
          //alert(JSON.stringify(e));
        }

        if (topic.endsWith("sheep/v1/from") && message && message.toString()) {
          let asocciationData = JSON.parse(message.toString());

          if (
            asocciationData.id == randomDevice &&
            asocciationData.result &&
            asocciationData.result.meta &&
            asocciationData.result.meta.device &&
            asocciationData.result.meta.device.association
          ) {
            randomDevice = 0;
            const profileSettings = store.getState().profilesSettings.get(store.getState().profilesSettings.get("currentProfile"));

            let currentOwner = profileSettings.get("cloud_owner");
            if (currentOwner !== asocciationData.result.meta.device.association.owner) {
              store.dispatch(serverOwnerReceived(asocciationData.result.meta.device.association.owner));
            }
          } else if (
            asocciationData.result &&
            asocciationData.id == randomSession &&
            asocciationData.result.token &&
            asocciationData.result.nonce &&
            asocciationData.result.device_id &&
            store.getState().cloudData &&
            store.getState().cloudData.get("authData")
          ) {
            randomSession = 0;
            let url = `${CLOUD_CONSTANTS.deviceAssociationInit}?nonce=${asocciationData.result.nonce}&token=${
              asocciationData.result.token
            }&device_id=${asocciationData.result.device_id}&access_token=${store
              .getState()
              .cloudData.get("authData")
              .get("access_token")}&client_id=${CLOUD_CONSTANTS.client_id}&local_user_hint=${profile.login}&redirect_uri=${
              CLOUD_CONSTANTS.redirect_uri
            }associate`;
            this.handleAssociation(url);
          }
        } else if (topic == `ampio/fromDB/${profile.login}/data/info` && location == "auth") {
          let Results = JSON.parse(message.toString()).Results;
          serverVersion = Results.serverVersion;
          let protocol = Results.protocol;

          //  CLOUD_CODE

          // let newDeviceId = Results.deviceId;
          // if (connectionType === 'new_cloud) {
          // 	if (newDeviceId !== "123") {
          // 		window.authScreen.showNoConnectionModal("deviceIdsNotMatch");
          // 	}
          // }
          if (MINIMUM_PROTOCOL_VERSION < protocol) {
            window.authScreen.showNoConnectionModal(this.getMessageFromReason("oldProtocol"));
          } else {
            if (!checkedTheVersion && Number(serverVersion) < MINIMUM_SUPPORTED_VERSION) {
              sendStatusInfo("SERVER_UPDATE");
              window.authScreen.requireUpdate();
            } else {
              this.goFurther(client);
            }
          }
        }
      });
  };

  handleMessage = (topic, message, packet, client) => {
    if (client == curClient) {
      if (message && message.toString()) {
        if (topic == `ampio/fromDB/${profile.login}/data/info`) {
          store.dispatch(serverInfoReceived(JSON.parse(message.toString()).Results));
        } else if (topic == "ampio/from/info/version") {
          store.dispatch(mqttVersionReceived(message.toString()));
        } else if (topic == `ampio/fromDB/${profile.login}/network_stats`) {
          store.dispatch(diagnosticStatsReceived(JSON.parse(message.toString())));
        } else if (topic == `ampio/fromDB/${profile.login}/weather`) {
          store.dispatch(receivedWeatherAmpio(JSON.parse(message.toString())));
        } else if (topic == `ampio/fromDB/${profile.login}/messages`) {
          store.dispatch(messagesReceived(JSON.parse(message.toString()).List));
        } else if (topic == `ampio/fromDB/${profile.login}/pins`) {
          let response = JSON.parse(message.toString());
          if (response && response.status == 2) {
            window.app.showToast("Too many pins");
          } else if (response && response.status == 1) {
            window.app.showToast("Error when saving pins");
          }
        } else if (topic == `ampio/fromDB/${profile.login}/messages_count`) {
          store.dispatch(messagesCountReceived(message.toString()));
        } else if (topic == `ampio/fromDB/${profile.login}/conditions`) {
          //pozostałość po starszej wersji, kiedyś coditions były używane tylko do irrigation, bez typu
          store.dispatch(irrigationsReceived(JSON.parse(message.toString()).List));
        } else if (topic.startsWith(`ampio/fromDB/${profile.login}/conditions/`)) {
          let type = topic.slice(topic.lastIndexOf("/") + 1);
          if (type == 1) {
            store.dispatch(irrigationsReceived(JSON.parse(message.toString()).List));
          } else if (type == 2) {
            store.dispatch(automatizationsReceived(JSON.parse(message.toString()).List));
          }
        } else if (topic == `ampio/fromDB/${profile.login}/control/pass_change`) {
          let toastMessage = "";
          if (message.toString() == "ok") {
            toastMessage = "Hasło zostało zmienione";
          } else {
            toastMessage = "Wystąpił błąd podczas zmiany hasła";
          }
          window.app.showToast(toastMessage);
          store.dispatch(passwordChangedInfoReceived(message.toString()));
        } else if (topic.startsWith(`ampio/fromDB/${profile.login}/ob/`)) {
          let deviceID = topic.substring(`ampio/fromDB/${profile.login}/ob/`.length, topic.lastIndexOf("/"));
          let subject = topic.slice(topic.lastIndexOf("/") + 1);
          let messageToDispatch = "";
          let messageReceived = "";
          let toastMessage = "";

          // if (deviceID == 189)
          switch (subject) {
            case "heat":
              messageToDispatch = JSON.parse(message.toString());
              store.dispatch(heatingScheduleReceived(deviceID, messageToDispatch));
              break;
            case "irs":
              messageToDispatch = JSON.parse(message.toString());
              store.dispatch(irDataReceived(deviceID, messageToDispatch));
              break;
            case "ir_status":
              messageToDispatch = JSON.parse(message.toString());
              store.dispatch(ir_statusReceived(deviceID, messageToDispatch));
              break;
            case "accesscontrol":
              messageToDispatch = JSON.parse(message.toString());
              store.dispatch(accessControlReceived(deviceID, messageToDispatch));
              break;
            case "charts":
              messageToDispatch = JSON.parse(message);
              store.dispatch(chartDataReceived(deviceID, messageToDispatch));
              break;
            case "barCharts":
              messageToDispatch = JSON.parse(message);
              store.dispatch(barChartDataReceived(deviceID, messageToDispatch));
              break;
            case "heats":
              messageReceived = message.toString();

              if (messageReceived == "ok") {
                toastMessage = "Harmonogrom zapisany poprawnie";
              } else if (messageReceived == "error") {
                //toastMessage = "Wystąpił błąd podczas zapisywania harmonogramu";
              }

              window.app.showToast(toastMessage);
              break;

            case "alarm":
              messageReceived = message.toString();

              if (messageReceived == "arming") {
                toastMessage = "Pin ok. Arming";
              } else if (messageReceived == "disarming") {
                toastMessage = "Pin ok. Disarming";
              } else if (messageReceived == "wrong_pin") {
                toastMessage = "Unable to control Satel";
              } else if (messageReceived == "change_ok") {
                toastMessage = "Alarm pin change ok!";
              } else if (messageReceived == "change_err") {
                toastMessage = "Alarm pin change error!";
              }
              window.app.showToast(toastMessage);
              break;
            case "pins":
              messageReceived = message.toString();

              if (messageReceived == "0") {
                toastMessage = "Access control saved ok";
              } else if (messageReceived == "1") {
                toastMessage = "Access control save error";
              } else if (messageReceived == "2") {
                toastMessage = "Access control save error, to much data";
              }
              window.app.showToast(toastMessage);
              break;

            case "save":
              messageReceived = message.toString();

              if (messageReceived == "ok") {
                toastMessage = "Saved sucesfully";
              } else if (messageReceived == "err") {
                toastMessage = "Saved failed";
              }
              window.app.showToast(toastMessage);
              break;
            case "radio":
              messageToDispatch = JSON.parse(message.toString());
              store.dispatch(radioFavouritesReceived(deviceID, messageToDispatch));
              break;

            case "upTime":
              let upTime = JSON.parse(message.toString());
              store.dispatch(singleStateReceived(deviceID, upTime));
            case "state":
              messageToDispatch = JSON.parse(message.toString());
              store.dispatch(singleStateReceived(deviceID, messageToDispatch));
              try {
                if (
                  alarmToShow.includes(store.getState().smartHomeData.get(profile.name).get("devices").get(deviceID).get("typ_komponentu")) &&
                  messageToDispatch &&
                  messageToDispatch.state == 2
                ) {
                  store.dispatch(showAlertScreen(true));
                }
              } catch {}
              try {
                if (typeToShow.includes(store.getState().smartHomeData.get(profile.name).get("devices").get(deviceID).get("typ_komponentu"))) {
                  store.dispatch(singleActiveStateReceived(deviceID, messageToDispatch));
                }
              } catch {}
              break;
            case "cam":
              messageToDispatch = message.toString();
              store.dispatch(cameraStateReceived(deviceID, messageToDispatch));
              break;
          }
        } else if (topic.startsWith(`ampio/fromDB/${profile.login}/md5/`)) {
          let key = topic.split("/").pop();
          newMD5Data[key] = message.toString();

          if (location == "app") {
            const state = store.getState();
            if (state.md5HashData.get("currentProfile")) {
              const profileMd5 = state.md5HashData.get(state.md5HashData.get("currentProfile"));
              if (profileMd5 && profileMd5.get(key) !== newMD5Data[key]) {
                this.md5DataStepHandler(client);
              }
            }
          }
        } else {
          switch (topic) {
            case `ampio/fromDB/${profile.login}/data/info`:
              store.dispatch(serverInfoReceived(JSON.parse(message.toString()).Results));
              break;
            case `ampio/fromDB/${profile.login}/data/update`:
              store.dispatch(serverUpdateInfoReceived(JSON.parse(message.toString()).Response));
              break;
            case `ampio/fromDB/${profile.login}/data/mqtt_update`:
              store.dispatch(mqttUpdateInfoReceived(JSON.parse(message.toString()).Response));
              break;
            case `ampio/fromDB/${profile.login}/data/states`:
              store.dispatch(changeConnectionStatus(1));
              let initialStatesJson = JSON.parse(message.toString());
              if (initialStatesJson.List) {
                initialStates = initialStatesJson.List;
              } else {
                if (initialStatesJson.Response) {
                  window.app.showToast(initialStatesJson.Response);
                }
                initialStates = {};
              }
              break;
            case `ampio/fromDB/${profile.login}/data/scenes`:
              store.dispatch(scenesReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_scenes", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/resources`:
              store.dispatch(resourcesReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_resources", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/icons`:
              store.dispatch(iconsReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_icons", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/devices`:
              store.dispatch(devicesReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_devices", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/groups`:
              store.dispatch(groupsReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_groups", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/group_devices`:
              store.dispatch(group_devicesReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_group_devices", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/params_devices`:
              store.dispatch(params_devicesReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_params_devices", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/logging`:
              store.dispatch(loggingReceived(JSON.parse(message.toString()).List));
              ExpoFileSystemStorage.setItem(profile.name + "_logging", message.toString());
              break;
            case `ampio/fromDB/${profile.login}/data/logbook`:
              store.dispatch(logbookReceived(JSON.parse(message.toString()).List));
              break;
            case `ampio/fromDB/${profile.login}/data/actions_log`:
              store.dispatch(actions_logReceived(JSON.parse(message.toString()).List));
              break;

            default:
              break;
          }

          smartDataToUpdateArray = smartDataToUpdateArray.filter((item) => {
            if (location == "auth" && topic == `ampio/fromDB/${profile.login}/data/${item}`) {
              sendStatusInfo("", 1, 1);
            }
            return topic !== `ampio/fromDB/${profile.login}/data/${item}`;
          });
        }
      }
    }
  };

  goFurther = (client) => {
    // CLOUD_CODE
    // this.get_association_session();
    client && this.md5DataStepHandler(client);

    let topicsToSubscribe = [
      "/sheep/v1/from",
      `ampio/control/${profile.login}/api`,
      `ampio/control/${profile.login}/cam`,
      `ampio/control/${profile.login}/update`,
      `ampio/control/${profile.login}/logbook`,
      `ampio/control/${profile.login}/actions_log`,
      `ampio/fromDB/${profile.login}/md5/#`,
      `ampio/fromDB/${profile.login}/network_stats`,
      `ampio/fromDB/${profile.login}/messages`,
      `ampio/fromDB/${profile.login}/messages_count`,
      `ampio/fromDB/${profile.login}/pins`,
      `ampio/fromDB/${profile.login}/weather`,
      `ampio/fromDB/${profile.login}/conditions`,
      `ampio/fromDB/${profile.login}/conditions/#`,
      `ampio/fromDB/${profile.login}/control/pass_change`,
      `ampio/fromDB/${profile.login}/sheep/v1/from`,
      "ampio/from/info/version",
    ];
    client && client.subscribe(topicsToSubscribe);
    try {
      if (connectionType !== "cloud" && store.getState().statesData.get("server").get("serverInfo").get("janusStatus") > 0) {
        janusClient.end(false);
        janusClient.init(profile, store.getState().cloudData, connectionType);
      }
    } catch (ex) {
      console.error("Error on init janus", ex);
    }
  };

  getProfile = () => {
    return profile;
  };

  md5DataStepHandler = async (client) => {
    location == "auth" && sendStatusInfo("", 1, 1);
    let profileMd5 = store.getState().md5HashData.get(profile.name);

    previousMD5Data = {
      devices: "",
      group_devices: "",
      groups: "",
      params_devices: "",
      logging: "",
      scenes: "",
      resources: "",
      icons: "",
    };

    if (profileMd5) {
      for (let key in previousMD5Data) {
        let data = null;
        try {
          data = await ExpoFileSystemStorage.getItem(`${profile.name}_${key}`);

          if (data) {
            if (key == "resources") {
              store.dispatch(resourcesReceived(JSON.parse(data).List));
            } else if (key == "devices") {
              store.dispatch(devicesReceived(JSON.parse(data).List));
            } else if (key == "group_devices") {
              store.dispatch(group_devicesReceived(JSON.parse(data).List));
            } else if (key == "groups") {
              store.dispatch(groupsReceived(JSON.parse(data).List));
            } else if (key == "params_devices") {
              store.dispatch(params_devicesReceived(JSON.parse(data).List));
            } else if (key == "logging") {
              store.dispatch(loggingReceived(JSON.parse(data).List));
            } else if (key == "scenes") {
              store.dispatch(scenesReceived(JSON.parse(data).List));
            } else if (key == "icons") {
              store.dispatch(iconsReceived(JSON.parse(data).List));
            }
            previousMD5Data[key] = profileMd5.get(`${key}`);
          }
        } catch (ex) {}
      }
    }

    if (client && !waitingForAllmd5Interval) {
      waitingForAllmd5Interval = setInterval(async () => {
        const isAllmd5DataReceived = Object.values(newMD5Data).every((el) => {
          return el;
        });

        if (waitingForAllmd5Interval && isAllmd5DataReceived) {
          // client && client.unsubscribe(`ampio/fromDB/${profile.login}/md5/#`);
          for (let key of Object.keys(newMD5Data)) {
            if (!previousMD5Data[key] || newMD5Data[key] !== previousMD5Data[key]) {
              client && client.publish(`ampio/control/${profile.login}/data`, `${key}`);
              smartDataToUpdateArray.push(key);
            }
          }

          let numberToFetch = 8 - smartDataToUpdateArray.length;
          location == "auth" && sendStatusInfo("", numberToFetch, numberToFetch);
          clearInterval(waitingForAllmd5Interval);
          waitingForAllmd5Interval = 0;
          smartDataToUpdateArray.length > 0 ? this.smartHomeDataHandler(client) : this.initStatesHandling(client);
        }
      }, 100);
    }
  };

  smartHomeDataHandler = (client) => {
    if (client && !waitingForAllRequiredSmartHomeDataInterval) {
      waitingForAllRequiredSmartHomeDataInterval = setInterval(() => {
        if (waitingForAllRequiredSmartHomeDataInterval && smartDataToUpdateArray.length === 0) {
          clearInterval(waitingForAllRequiredSmartHomeDataInterval);
          waitingForAllRequiredSmartHomeDataInterval = 0;
          this.initStatesHandling(client);
        }
      }, 100);
    }
  };

  initStatesHandling = (client) => {
    location == "auth" && sendStatusInfo("STATES", 1);
    client &&
      client.subscribe(`ampio/control/${profile.login}/states`, (err) => {
        if (!err) {
          client.publish(`ampio/control/${profile.login}/states`, "api/json/states");
          client.publish(`ampio/control/${profile.login}/status/${client.options.clientId.split("&")[0]}`, '{"status":"1","app":"ampiouni"}', {
            qos: 1,
            retain: true,
          });
        }
      });
    //todo
    if (serverVersion > 635) {
      this.getMessagesCount();
    } else if (serverVersion > 480) {
      this.getMessages();
    }

    const profileSettings = store.getState().profilesSettings.get(store.getState().profilesSettings.get("currentProfile"));

    let currentOwner = profileSettings.get("cloud_owner");
    let dontAsk = profileSettings.get("dontAsk");
    let onProfileList = false;
    if (store.getState().cloudData && store.getState().cloudData.get("associationsData")) {
      const cloudDevices = store.getState().cloudData.get("associationsData").toJS();

      if (Array.isArray(cloudDevices)) {
        cloudDevices.forEach((singleProfile, num) => {
          if (singleProfile.device_id == profile.device_id) {
            onProfileList = true;
          }
        });
      }
    }
    if (serverVersion > 480 && !dontAsk && !onProfileList && !currentOwner) {
      this.get_device_representation();
    }
    store.dispatch(md5DataReceived(newMD5Data, profile.name));
    client && this.statesDataHandler(client);
  };

  statesDataHandler = (client) => {
    if (client && !waitingForStatesInterval) {
      waitingForStatesInterval = setInterval(() => {
        if (client && waitingForStatesInterval && initialStates) {
          store.dispatch(showInfoBar(false));
          store.dispatch(showCriticalNoConnection(null));
          location == "auth" && sendStatusInfo("VIEW", 1);
          client && client.subscribe(`ampio/fromDB/${profile.login}/ob/#`);
          if (profile.name && store.getState().smartHomeData.get(profile.name) && store.getState().smartHomeData.get(profile.name).get("devices")) {
            store.dispatch(statesReceived(initialStates, store.getState().smartHomeData.get(profile.name).get("devices")));
          } else {
            store.dispatch(statesReceived(initialStates));
          }
          store.dispatch(statesActiveReceived(initialStates));

          location == "auth" && window.authScreen.navigateToApp(connectionType);

          clearInterval(waitingForStatesInterval);
          waitingForStatesInterval = 0;

          location = "app";
          newMD5Data = {
            devices: "",
            group_devices: "",
            groups: "",
            params_devices: "",
            logging: "",
            scenes: "",
            resources: "",
            icons: "",
          };
          sendingStateChangesInterval = setInterval(() => {
            this.sendMessagess();
          }, 50);
        }
      }, 100);
    }
  };

  //below state changes, info feyching
  stateChangeToSend = (message, id) => {
    let objIndex = statesArrayIntervallySend.findIndex((obj) => obj.id === id);
    if (objIndex >= 0) {
      statesArrayIntervallySend[objIndex].message = message;
    } else {
      statesArrayIntervallySend.push({ id: id, message: message });
    }
  };

  sendMessagess = () => {
    statesArrayIntervallySend.forEach((el) => {
      curClient && curClient.publish(`ampio/control/${profile.login}/api`, el.message);
    });
    statesArrayIntervallySend = [];
  };

  changePassword = (password) => {
    let md5Password = md5(password);
    curClient && curClient.publish(`ampio/control/${profile.login}/pass_change`, md5Password);
  };

  die = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/die`);
  };

  askForWeatherState = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/weather`);
  };

  askForRadioFavorite = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/getRadioFavStations`);
  };

  irQuiries = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, message);
  };

  saveIR = (data, deviceID) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/irs/${deviceID}`, JSON.stringify(data));
  };

  askForHeatingSchedule = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/getHeatingSchedule`);
  };

  askForAccessControl = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/getPinList`);
  };

  askForConditions = (type) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/get_conditions/${type}`, ``);
  };

  changeStateOfConditions = (state, type) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/change_conditions_state/${type}/${state}`, ``);
  };

  sendCondition = (object, type) => {
    //console.log(object);
    curClient && curClient.publish(`ampio/control/${profile.login}/save_condition/${type}`, JSON.stringify(object));
  };

  sendPing = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/get_conditions/ping`, ``);
  };

  runCondition = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/run/condition/${id}`);
  };

  stopCondition = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/off/condition/${id}`);
  };

  askForLogbook = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/logbook`, message);
  };

  askForActionsLog = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/actions_log`, message);
  };

  askForCharts = (message, id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/charts/${id}`, message);
  };

  askForUptime = (message, id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/upTime/${id}`, message);
  };

  askForBarCharts = (message, id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/barCharts/${id}`, message);
  };

  removeDeviceFromFluxSimulation = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/deactiveFluxSimulation`);
  };

  addDeviceToFluxSimulation = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/activeFluxSimulation`);
  };

  removeDeviceFromActive = (id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/deactive`);
  };

  addDeviceToActive = (id) => {
    curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/active`);
  };

  changeName = (id, newName, type) => {
    let message = `/api/json/${type}/${id}/changeName/${newName}`;
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, message);
  };

  updatePin = (id, oldPin, newPin) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/pin_change/${id}/${oldPin}/${newPin}`, ``);
  };

  updateParam = (id, value) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/json/device/${id}/updateParam/${value}`);
  };

  controlScene = (id, option) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `/api/${option}/scene/${id}`);
  };

  saveFavColor = (deviceID, colorPosition, color) => {
    let message = `/api/set/${deviceID}/setFavColor/${colorPosition}/${color}`;
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, message);
  };

  sendSingleScene = (scene) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/scenesSingle`, JSON.stringify(scene));
  };

  removeSingleScene = (sceneId) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/scenesSingle`, JSON.stringify(sceneId));
  };

  sendScenes = (scenes) => {
    let scenesToSend = scenes.map((el) => {
      if (el) {
        return el.delete("id").set("method", "addScene");
      }
    });
    curClient && curClient.publish(`ampio/control/${profile.login}/scenes`, JSON.stringify(scenesToSend));
  };

  sendToken = (token) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/token`, token);
  };

  askForCamera = (id, reset) => {
    let topic = reset ? `ampio/control/${profile.login}/cam/reset` : `ampio/control/${profile.login}/cam`;
    curClient && curClient.publish(topic, `${id}`);
  };

  askForServerUpdate = (type) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/update`, `${type}`);
  };

  allowCloudConnection = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/cloud`, message);
  };

  addFavouriteStationRadio = (object, id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/radio/${id}`, JSON.stringify(object));
  };

  setHeatingType = (deviceID, type) => {
    let message = `/api/set/${deviceID}/setHeatingType/${type}`;
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, message);
  };

  sendNewHeating = (object, id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/heat/${id}`, JSON.stringify(object));
  };

  sendNewAccessControl = (object, id) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/pins/${id}`, JSON.stringify(object));
  };

  customerSupport = (value, pin, type) => {
    if (type == 0) {
      curClient && curClient.publish(`ampio/control/${profile.login}/support/${value}`, `${pin}`);
    } else if (type == 1) {
      curClient && curClient.publish(`ampio/control/${profile.login}/long_support/${value}`, `${pin}`);
    }
  };

  getMessagesCount = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/get_messages_count`);
  };

  getMessages = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/get_messages/-1`);
  };

  getMessagesPaginate = (size, offset) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/get_messages/-1/${size}/${offset}`);
  };

  readMessage = (index) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/read_message/${index}`);
  };

  readAllMessages = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/read_all_messages`);
  };

  deleteAllMessages = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/delete_all_messages`);
  };

  getDiagnostic = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/get_network_stats`, "diagnostic");
    curClient && curClient.publish("ampio/to/info/version");
  };

  askForReboot = () => {
    curClient && curClient.publish(`ampio/control/${profile.login}/die`, "die");
  };

  editUserVariables = (value, resID) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/update_resource/${resID}`, `${value}`);
  };

  editserverLocation = (payload) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/location`, JSON.stringify(payload));
  };

  changeSimulationActivity = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `${message}`);
  };

  sendPush = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `${message}`);
  };
  sendMessage = (message) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/api`, `${message}`);
  };

  sendSimulationData = (payload) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/simulation`, JSON.stringify(payload));
  };

  sendDetectionData = (payload) => {
    curClient && curClient.publish(`ampio/control/${profile.login}/detection`, JSON.stringify(payload));
  };

  get_device_representation = () => {
    randomDevice = Math.floor(Math.random() * 10000000 + 10000000);
    let data = {
      jsonrpc: "2.0",
      method: "get_device_representation",
      params: [],
      id: randomDevice,
    };
    curClient && curClient.publish(`/sheep/v1/to`, JSON.stringify(data));
    curClient && curClient.publish(`ampio/control/${profile.login}/sheep/v1/to`, JSON.stringify(data));
  };

  get_association_session = () => {
    randomSession = Math.floor(Math.random() * 10000000);
    let data = {
      jsonrpc: "2.0",
      method: "get_association_session",
      params: [],
      id: randomSession,
    };
    curClient && curClient.publish(`/sheep/v1/to`, JSON.stringify(data));
    curClient && curClient.publish(`ampio/control/${profile.login}/sheep/v1/to`, JSON.stringify(data));

    setTimeout(() => {
      if (randomSession <= 0) {
        window.app.showToast(t("NO_DATA"));
      }
    }, 2000);
  };
}

let mqttClient = MQTTClient.getInstance();
export default mqttClient;
