import React from "react";
import { Text, Image, View } from "react-native";
import { connect } from "react-redux";

const ImageFromIkona0_0 = (props) => {
	const {
		icons,
		resizeMode,
		device,
		value,
		globalSettings,
		deviceFromSketch,
		deviceFromCustom,
	} = props;
	const showBGForDeviceIcons = globalSettings.get("showBGForDeviceIcons");

	const theme = globalSettings.get("primaryTheme");
	const iconBGColor =
		globalSettings.get("iconBGColors") &&
		globalSettings.get("iconBGColors").get(theme);

	let uri = "data:image/png;base64,";
	let restUri = "";
	if (device.has("ikona_0")) {
		restUri = value != "0" ? device.get("ikona_1") : device.get("ikona_0");
	} else if (device.has("ikona0")) {
		let icon =
			value != "0"
				? icons.get(device.get("ikona1"))
				: icons.get(device.get("ikona0"));

		restUri = icon && icon.get("wartoscB");
	}
	uri += restUri;
	let contentImage = (
		<Image
			source={{ uri }}
			resizeMode={resizeMode ? resizeMode : "contain"}
			style={[{ width: 36, height: 36 }, props.style]}
		/>
	);

	return !deviceFromSketch ? (
		<View
			style={{
				backgroundColor: showBGForDeviceIcons ? iconBGColor : null,
				height: showBGForDeviceIcons ? 45 : null,
				width: showBGForDeviceIcons ? 45 : null,
				marginTop: deviceFromCustom ? (showBGForDeviceIcons ? -2 : -2) : -5,
				marginBottom: deviceFromCustom ? -10 : -5,
				borderRadius: 100,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{contentImage}
		</View>
	) : (
		contentImage
	);
};

const mapStateToProps = (state) => {
	return {
		icons: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("icons"),
		globalSettings: state.globalSettings,
	};
};

export default connect(mapStateToProps)(ImageFromIkona0_0);
