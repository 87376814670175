import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import { TabView, TabBar } from "react-native-tab-view";

import { ThemeContext } from "../../../ThemeContext";
import Conditions_IrrigationScreen from "./Conditions_IrrigationScreen";
import Actions_IrrigationScreen from "./Actions_IrrigationScreen";
import { t } from "../../services/i18n";
import { StyledIcon, ScreenView } from "../../components/UI/styledComponents";
import AddConditionModal from "./AddConditionModal";
import AddActionModal from "./AddActionModal";
import mqttClient from "../../services/mqtt";
import { ForceTouchGestureHandler } from "react-native-gesture-handler";

//całe consitons są na nie immutable
class IrrigationDetailsScreen extends Component {
  static contextType = ThemeContext;

  state = {
    index: 0,
    routes: [
      { key: "conditions", title: t("conditions:CONDITIONS") },
      { key: "actions", title: t("conditions:ACTIONS") },
    ],
    conditionModalVisible: false,
    actionModalVisible: false,
    conditions: null,
    actions: null,
    active: 1,
  };

  componentDidMount() {
    let { irrigation } = this.props.route.params;
    if (irrigation) {
      let irrigationJS = irrigation.toJS();
      this.setState({
        conditions: irrigationJS.sub_conditions,
        actions: irrigationJS.actions,
        active: irrigationJS.active,
      });
    }

    this.props.navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            this.onPressHeaderButton();
          }}
        >
          <StyledIcon name={"plus"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
      headerLeft: () => (
        <TouchableOpacity
          style={{ padding: 10, flexDirection: "row" }}
          onPress={() => {
            this.onPressBackButton();
          }}
        >
          <StyledIcon name={"arrow-left"} color={"tabBar"} size={24} style={{ paddingRight: 10 }} />
          <StyledIcon name={"save"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }

  onPressHeaderButton = () => {
    if (this.state.index == 0) {
      this.setState({ conditionModalVisible: true });
    } else if (this.state.index == 1) {
      this.setState({ actionModalVisible: true });
    }
  };

  onPressBackButton = () => {
    let { conditions, actions, active } = this.state;
    let delay = 0;
    actions = actions.map((action) => {
      action.delay = delay;
      delay = delay + action.time;
      return action;
    });
    let { irrigation } = this.props.route.params;

    irrigation = irrigation.set("active", active).set("actions", actions).set("sub_conditions", conditions);
    mqttClient.sendCondition(irrigation, 1);

    this.props.navigation.goBack();
  };
  onIndexChange = (index) => {
    this.setState({ index });
  };

  onCloseHandler = (key) => {
    if (key == "condition") {
      this.setState({ conditionModalVisible: false });
    } else if (key == "action") {
      this.setState({ actionModalVisible: false });
    }
  };

  onAddCondition = (type, deviceID) => {
    let condition = {
      active: 1,
      object_id: deviceID,
      type: 0,
      value: 900,
      operator: 1,
      state: 0,
      value2: 0,
      counter: 0,
      date_of_change: 0,
      description: type,
    };

    if (type == "DAYS") {
      condition.type = 1;
      condition.object_id = 0;
      condition.value = 127;
      condition.description = type;
      condition.operator = 11;
    } else if (type == "TIME") {
      condition.type = 2;
      condition.object_id = 0;
      condition.value = 900;
      condition.description = type;
      condition.operator = 1;
    } else if (type == "RAIN") {
      //value 0-100
      condition.value = 80;
      condition.operator = 3;
    } else if (type == "RAIN_TIME") {
      condition.type = 7;
      condition.value = 6;
      condition.value2 = 2;
      condition.operator = 3;
    } else if (type == "HUM") {
      condition.value = 30;
      condition.operator = 3;
    } else if (type == "TEMP") {
      //3 st * 10
      condition.value = 30;
      condition.operator = 2;
    } 

    let conditions = [...this.state.conditions];

    conditions.push(condition);
    this.setState({ conditions, conditionModalVisible: false });
  };

  onAddAction = (devicesArray) => {
    // 1min - 6000 ms
    // 30 min = 30* 600
    let actions = [...this.state.actions];

    devicesArray.map((deviceID) => {
      let newAction = {
        active: 1,
        description: "",
        action: `/api/set/0/setValue/255/180000`,
        type: 0,
        delay: 0,
        //dla mnie
        //30*600
        time: 180000,
        object_id: 0,
        value: 255,
        value2: 0,
      };
      newAction.action = `/api/set/${deviceID}/setValue/255/180000`;
      newAction.object_id = deviceID;
      actions.push(newAction);
    });

    this.setState({ actions, actionModalVisible: false });
  };

  onRemoveAction = (index) => {
    let actions = [...this.state.actions];

    actions.splice(index, 1);
    this.setState({ actions });
  };
  onRemoveCondition = (index) => {
    let conditions = [...this.state.conditions];

    conditions.splice(index, 1);
    this.setState({ conditions });
  };

  modifyAction = (value, type, index) => {
    let actions = [...this.state.actions];

    if (type == "time") {
      let deviceID = actions[index].object_id;
      actions[index].time = value;
      actions[index].action = `/api/set/${deviceID}/setValue/255/${value}`;
    }

    this.setState({ actions });
  };

  modifyCondition = (condition, index) => {
    let conditions = [...this.state.conditions];
    conditions[index] = condition;
    this.setState({ conditions });
  };

  changeActive = (newValue) => {
    this.setState({ active: newValue });
  };

  render() {
    const { theme } = this.context;
    const { routes, index, conditionModalVisible, actionModalVisible, active } = this.state;
    const renderScene = ({ route }) => {
      const { actions, conditions } = this.state;
      switch (route.key) {
        case "conditions":
          return (
            <Conditions_IrrigationScreen
              conditions={conditions}
              onRemoveCondition={this.onRemoveCondition}
              modifyCondition={this.modifyCondition}
              active={active}
              changeActive={this.changeActive}
            />
          );
        case "actions":
          return <Actions_IrrigationScreen actions={actions} onRemoveAction={this.onRemoveAction} modifyAction={this.modifyAction} />;
        default:
          return null;
      }
    };
    return (
      <View style={{ flex: 1 }}>
        <TabView
          renderTabBar={(props) => (
            <TabBar
              {...props}
              indicatorStyle={{ backgroundColor: "white" }}
              style={{
                backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
              }}
            />
          )}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={this.onIndexChange}
        />

        {conditionModalVisible && (
          <AddConditionModal isVisible={conditionModalVisible} onClose={() => this.onCloseHandler("condition")} onAdd={this.onAddCondition} />
        )}
        {actionModalVisible && (
          <AddActionModal isVisible={actionModalVisible} onClose={() => this.onCloseHandler("action")} onAdd={this.onAddAction} />
        )}
      </View>
    );
  }
}

export default IrrigationDetailsScreen;
