import React, { useState, useEffect } from "react";
import { Text, TouchableOpacity, Platform, View } from "react-native";
import { connect } from "react-redux";

import { DevicesToShowByColumns } from "../../utils";
import { ScreenView } from "../../components/UI/screenViewX";
import { StyledIcon, NoDataScreen } from "../../components/UI/styledComponents";
import AddFavouritesModal from "./AddFavouritesModal";
import {
  addDeviceToFavourites,
  removeDeviceFromFavourites,
} from "../../store/actions";

const FavouritesScreen = ({
  favouritesDevices,
  navigation,
  onAddDeviceToFavourites,
  devices,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => showDialog()}>
          <StyledIcon name={"plus"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }, []);

  const showDialog = () => {
    setDialogVisible(true);
  };

  const onAdd = (selectedDevice) => {
    onAddDeviceToFavourites(selectedDevice);
    setDialogVisible(false);
  };

  let daneDoWyswitelenie = favouritesDevices ? (
    <DevicesToShowByColumns
      arrayOfIds={favouritesDevices}
      devices={devices}
      source="fav"
    />
  ) : null;

  return (
    <View style={{ flex: 1 }}>
      {favouritesDevices && favouritesDevices.size ? (
        <ScreenView>{daneDoWyswitelenie}</ScreenView>
      ) : (
        <NoDataScreen />
      )}

      {dialogVisible && (
        <AddFavouritesModal
          isVisible={dialogVisible}
          onClose={() => {
            setDialogVisible(false);
          }}
          onAdd={onAdd}
        />
      )}
    </View>
  );
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    favouritesDevices: state.profilesSettings
      .get(currentProfile)
      .get("favouritesDevices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeviceToFavourites: (deviceID) =>
      dispatch(addDeviceToFavourites(deviceID)),
    onRemoveDeviceFromFavourites: (deviceID) =>
      dispatch(removeDeviceFromFavourites(deviceID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesScreen);
