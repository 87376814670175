import React, { Component } from "react";
import { View, Platform, Text } from "react-native";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";

import { ComponentHeaderText, PrimaryColorText } from "../../UI/styledComponents";

import ImageForLinearInput from "../../../utils/ImageForLinearInput";
import RollerButtonsBar from "./RollerButtonsBar";
import RollerAnimationView from "./RollerAnimationView";
import PickerSelect from "../../UI/PickerSelect";
import EndSwitchIcons from "../../../utils/EndSwitchIcons";
import * as Haptics from "expo-haptics";
class RollerComponent extends Component {
  state = {
    block: 0,
    blindsLevel: 0,
    lammelsLevel: 0,
    blindsLevelToSend: 0,
    lammelsLevelToSend: 0,
    inChange: false,
    percent: [],
    percentLammels: [],
  };

  round5 = (value) => {
    if (value > 0) {
      let number = Number(value);
      return Math.round(number / 5) * 5;
    } else {
      return value;
    }
  };

  componentDidMount() {
    const { deviceState, device, deviceInScene, params_device, deviceFromAutomatization } = this.props;
    const percent = [];
    const percentLammels = [];
    let lammelsStep = 5;

    if ((deviceFromAutomatization || deviceInScene) && device.get("typ_komponentu") == "roleta_lamelki") {
      percent.push({
        label: t("NO_ACTION"),
        value: -1,
      });
      percentLammels.push({
        label: t("NO_ACTION"),
        value: -1,
      });
    }

    if (params_device && (params_device.get("params") & 0x8000) > 0) {
      lammelsStep = 1;
    }
    for (let index = 0; index <= 100; index = index + 5) {
      let element = {
        label: "" + index + " %",
        value: index,
      };
      if (index == 0) {
        if (params_device && (params_device.get("params") & 0x2000000) > 0) {
          // MARKIZA
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("RIGHT");
          } else {
            element.label = "" + index + " %   " + t("LEFT");
          }
        } else {
          // NORMALNE
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("UP");
          } else {
            element.label = "" + index + " %   " + t("DOWN");
          }
        }
      } else if (index == 100) {
        if (params_device && (params_device.get("params") & 0x2000000) > 0) {
          // MARKIZA
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("LEFT");
          } else {
            element.label = "" + index + " %   " + t("RIGHT");
          }
        } else {
          // NORMALNE
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("DOWN");
          } else {
            element.label = "" + index + " %   " + t("UP");
          }
        }
      }
      percent.push(element);
    }

    for (let index = 0; index <= 100; index = index + lammelsStep) {
      let lammelsElement = {
        label: "" + index + " %",
        value: index,
      };

      percentLammels.push(lammelsElement);
    }

    if (!deviceInScene && !deviceFromAutomatization) {
      if (device.get("typ_komponentu") != "roleta") {
        this.setState({
          blindsLevel: this.round5(deviceState.get("state")),
          block: deviceState.get("block") ? Number(deviceState.get("block")) : 0,
        });
        if (device.get("typ_komponentu") == "roleta_lamelki") {
          this.setState({
            lammelsLevel:
              params_device && (params_device.get("params") & 0x8000) > 0
                ? Number(deviceState.get("lammel"))
                : this.round5(deviceState.get("lammel")),
          });
        }
      }
    } else {
      this.setState({
        blindsLevel: deviceInScene.get("param1"),
        lammelsLevel: deviceInScene.get("param2"),
      });
    }

    this.setState({ percent, percentLammels });
  }
  onPickerOpen = () => {
    this.setState({ inChange: true });
  };

  onPickerValueChange = (value, key) => {
    if (key == "blinds") {
      this.setState(
        {
          blindsLevelToSend: value,
          lammelsLevelToSend: this.state.lammelsLevel,
        },
        () => {
          if (Platform.OS !== "ios") {
            this.pickerCallback();
          }
        }
      );
    } else if (key == "lammels") {
      this.setState(
        {
          lammelsLevelToSend: value,
          blindsLevelToSend: this.state.blindsLevel,
        },
        () => {
          if (Platform.OS !== "ios") {
            this.pickerCallback();
          }
        }
      );
    }
  };

  onPickerClose = () => {
    if (Platform.OS === "ios") {
      this.setState({ inChange: false });

      this.pickerCallback();
    }
  };

  pickerCallback = () => {
    const { device, deviceInScene, params_device } = this.props;

    let { blindsLevelToSend, lammelsLevelToSend } = this.state;

    let deviceID = device.get("id");
    let message =
      device.get("typ_komponentu") == "roleta_lamelki"
        ? `/api/set/${deviceID}/setRollerPos/${blindsLevelToSend}/${lammelsLevelToSend}`
        : `/api/set/${deviceID}/setRollerPos/${blindsLevelToSend}/-1`;
    if (deviceInScene && device.get("typ_komponentu") == "roleta") {
      message = `/api/set/${deviceID}/${blindsLevelToSend ? "open" : "close"}`;
    }

    this.sendMessageHandler(message);

    if (deviceInScene) {
      this.setState({
        blindsLevel: this.round5(blindsLevelToSend),
        lammelsLevel: params_device && (params_device.get("params") & 0x8000) > 0 ? Number(lammelsLevelToSend) : this.round5(lammelsLevelToSend),
      });
      let infoToUpdate = deviceInScene;
      infoToUpdate = infoToUpdate.set("param1", blindsLevelToSend);
      infoToUpdate = infoToUpdate.set("param2", lammelsLevelToSend);

      this.props.changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }
  };

  onActionFromButtons = (action) => {
    const { device } = this.props;
    let message = "";
    switch (action) {
      case "close":
        message = `/api/set/${device.get("id")}/close`;
        break;
      case "open":
        message = `/api/set/${device.get("id")}/open`;
        break;
      case "stop":
        message = `/api/set/${device.get("id")}/stop`;
        break;
      default:
        break;
    }

    this.sendMessageHandler(message);
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  };

  sendMessageHandler = (message) => {
    const { device, activeSceneControl } = this.props;
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deviceInScene, deviceState, device, params_device } = this.props;
    if (!deviceInScene && device.get("typ_komponentu") != "roleta") {
      let lammelsLevelReceived =
        device.get("typ_komponentu") == "roleta_lamelki"
          ? params_device && (params_device.get("params") & 0x8000) > 0
            ? Number(nextProps.deviceState.get("lammel"))
            : this.round5(nextProps.deviceState.get("lammel"))
          : 0;
      this.setState({
        blindsLevel: this.round5(nextProps.deviceState.get("state")),
        lammelsLevel: lammelsLevelReceived,
        block: nextProps.deviceState.get("block") ? Number(nextProps.deviceState.get("block")) : 0,
      });
    }
  }

  render() {
    const { device, deviceInScene, deviceFromAutomatization, header, readOnly, deviceState, deviceFromCustom, params_device } = this.props;
    const { percent, percentLammels, lammelsLevel, lammelsLevelToSend, block } = this.state;

    let rollerMarquize = false;
    let invertPercent = false;
    if (params_device && (params_device.get("params") & 0x2000000) > 0) {
      rollerMarquize = true;
    }
    if (params_device && (params_device.get("params") & 0x200000) > 0) {
      invertPercent = true;
    }
    const rollerView =
      deviceState && deviceState.get("res") ? (
        <ImageForLinearInput id={deviceState.get("res")} />
      ) : (
        <RollerAnimationView blindsLevel={this.state.blindsLevel} rollerMarquize={rollerMarquize} invertPercent={invertPercent} />
      );
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 10,
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {!deviceFromCustom ? <View style={{ flex: 1 }}>{header}</View> : null}
          {device.get("typ_komponentu") == "roleta" && deviceState && deviceState.get("conn") && <EndSwitchIcons value={deviceState.get("conn")} />}
          {device.get("typ_komponentu") != "roleta" ? (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                flex: deviceFromCustom && 1,
              }}
            >
              <PickerSelect
                placeholder={{}}
                items={percent}
                // containerWidthAndroid={80}
                onValueChange={(value) => this.onPickerValueChange(value, "blinds")}
                onOpen={this.onPickerOpen}
                value={this.state.inChange ? this.state.blindsLevelToSend : this.state.blindsLevel}
                containerStyle={{ width: 120 }}
                onClose={this.onPickerClose}
                disabled={readOnly}
              />
              {!deviceInScene && rollerView}
            </View>
          ) : null}
          {(deviceInScene || deviceFromAutomatization) && device.get("typ_komponentu") == "roleta" && (
            <PickerSelect
              // containerWidthAndroid={80}
              placeholder={{}}
              items={[
                {
                  label: t("DOWN"),
                  value: 0,
                },
                {
                  label: t("UP"),
                  value: 100,
                },
              ]}
              onOpen={this.onPickerOpen}
              onValueChange={(value) => this.onPickerValueChange(value, "blinds")}
              value={this.state.inChange ? this.state.blindsLevelToSend : this.state.blindsLevel}
              containerStyle={{ width: 80 }}
              onClose={this.onPickerClose}
            />
          )}
        </View>
        {!deviceInScene && !readOnly && block !== 3 && (
          <RollerButtonsBar onActionFromButtons={this.onActionFromButtons} rollerMarquize={rollerMarquize} invertPercent={invertPercent} />
        )}
        {block === 1 && (
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "red",
              paddingTop: 5,
            }}
          >
            {t("ROLLER_CLOSE_LOCK")}
          </PrimaryColorText>
        )}
        {block === 2 && (
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "red",
              paddingTop: 5,
            }}
          >
            {t("ROLLER_OPEN_LOCK")}
          </PrimaryColorText>
        )}
        {block === 3 && (
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "red",
            }}
          >
            {t("ROLLER_LOCK")}
          </PrimaryColorText>
        )}
        {device.get("typ_komponentu") == "roleta_lamelki" ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <ComponentHeaderText style={{ marginLeft: 6 }}>{t("LAMMELS")}</ComponentHeaderText>

            <PickerSelect
              // containerWidthAndroid={80}
              disabled={readOnly}
              placeholder={{
                label: t("SELECT_VALUE"),
                value: null,
              }}
              items={percentLammels}
              onOpen={this.onPickerOpen}
              onValueChange={(value) => this.onPickerValueChange(value, "lammels")}
              value={this.state.inChange ? this.state.lammelsLevelToSend : this.state.lammelsLevel}
              containerStyle={{ width: 80 }}
              onClose={this.onPickerClose}
            />
          </View>
        ) : null}
      </View>
    );
  }
}

export default RollerComponent;
