import * as Notifications from "expo-notifications";
import mqttClient from "../mqtt";
import { PlatformColor, Vibration, Alert } from "react-native";
import * as Device from "expo-device";
import { Platform } from "react-native";

const registerForPushNotificationsAsync = async () => {
  let token;

  if (Device.isDevice) {
    try {
      if (Platform.OS === "android") {
        await Notifications.setNotificationChannelAsync("default", {
          name: "default",
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: "#FF231F7C",
        });
      }

      const existing = await Notifications.getPermissionsAsync();
      let finalStatus = existing.status;

      if (existing.status !== "granted") {
        const response = await Notifications.requestPermissionsAsync({
          ios: {
            allowAlert: true,
            allowBadge: true,
            allowSound: true,
            allowAnnouncements: true,
            allowCriticalAlerts: true,
          },
        });
        finalStatus = response.status;
      } else {
        if (Platform.OS === "ios" && !existing.ios.allowsCriticalAlerts) {
          await Notifications.requestPermissionsAsync({
            ios: {
              allowCriticalAlerts: true,
            },
          });
        }
      }

      if (finalStatus !== "granted") {
        return;
      }

      token = (
        await Notifications.getExpoPushTokenAsync({
          experienceId: "@justynak/Ampio-UNI",
        })
      ).data;
      mqttClient.sendToken(token);
    } catch (ex) {
      console.log("Problem with getting notification", JSON.stringify(ex));
    }
    /*
    const category = await Notifications.setNotificationCategoryAsync(
      "ampioBasic",
      [
        {
          buttonTitle: "Tak na prezent od Michołaja",
          identifier: "first-button",
          options: {
            opensAppToForeground: true,
          },
        },
        {
          buttonTitle: "Nie",
          identifier: "secoond-button",
          options: {
            opensAppToForeground: true,
          },
        },
      ]
    );
    */
  }
};

export default registerForPushNotificationsAsync;
