import React, { Component } from "react";
import { Text, View, TouchableOpacity, Dimensions } from "react-native";
import { t } from "../../../../services/i18n";

import { PrimaryColorText } from "../../../UI/styledComponents";

class FavouritesColors extends Component {
	removeColor = (index) => {
		let { deviceID } = this.props;
		let colorPosition = index;
		this.props.onRemoveFavouriteColor(colorPosition);
	};

	addColor = (index) => {
		let colorPosition = index;

		this.props.onAddFavouriteColor(colorPosition);
	};

	chooseColorFromFav = (index) => {
		let { favouritesColors } = this.props;
		let color = favouritesColors.get(`${index}`).get("color");
		this.props.setColorInComponent({
			r: color.get("r"),
			g: color.get("g"),
			b: color.get("b"),
			w: color.get("w"),
		});
	};

	render() {
		let { favouritesColors, deviceName, modalVisible } = this.props;

		let array = [null, null, null, null];
		let arrayColors =
			favouritesColors && favouritesColors.size > 0
				? array.map((el, index) => {
						if (favouritesColors.get(index.toString())) {
							let color = favouritesColors.get(index.toString()).get("color");

							return `rgb(${color.get("r")}, ${color.get("g")}, ${color.get(
								"b"
							)})`;
						} else {
							return null;
						}
				  })
				: array;

		const favouritesColorsRow = (
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{arrayColors.map((el, index) => {
					if (el == null) {
						return (
							<TouchableOpacity
								key={index}
								onPressIn={() => this.addColor(index)}
							>
								<View
									style={[
										styles.colorContainer,
										styles.empty,
										{ backgroundColor: "#ddd" },
									]}
								>
									<Text style={{ fontSize: 20 }}>+</Text>
								</View>
							</TouchableOpacity>
						);
					}
					return (
						<TouchableOpacity
							key={index}
							onPress={() => this.chooseColorFromFav(index)}
							onLongPress={() => this.removeColor(index)}
						>
							<View style={[styles.colorContainer, { backgroundColor: el }]} />
						</TouchableOpacity>
					);
				})}
			</View>
		);

		return (
			<View style={{ marginVertical: 10 }}>
				<PrimaryColorText
					style={{
						fontSize: 16,
						// marginLeft: 10,
						paddingBottom: 10,
					}}
				>
					{t("FAVOURITE_COLORS")}
				</PrimaryColorText>

				{favouritesColorsRow}
			</View>
		);
	}
}

const styles = {
	colorContainer: {
		display: "flex",
		height: 50,
		width: 50,
		borderRadius: 50,
		borderColor: "gray",
		borderWidth: 1,
	},
	empty: {
		backgroundColor: "white",
		alignItems: "center",
		justifyContent: "center",
	},
	withColor: {
		backgroundColor: "red",
	},
};

export default FavouritesColors;
