import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, ActivityIndicator, Text } from "react-native";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import HighChartView from "../../../utils/HighChartView";
import { chartDataReceived } from "../../../store/actions";

import {
  SecondaryColorText,
  PrimaryColorText,
  StyledIcon,
} from "../../UI/styledComponents";

const ChartComponent = (props) => {
  const {
    device,
    header,
    deviceFromCustom,
    chartDevice,
    chartDeviceState,
    deviceID,
    connectionStatus,
    gretingViewHeight,
    customWidth,
    deviceFromSketch,
    onSketchWidth,
    onSketchHeight,
    params_device,
    chartDeviceParam,
    dimensions,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (chartDevice && connectionStatus == 1) {
      askForCharts();
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (
      chartDeviceState &&
      chartDeviceState.get("chartData") &&
      chartDeviceState.get("chartData").get("List")
    ) {
      setIsLoading(false);
      setShowText(
        chartDeviceState.get("chartData").get("List").size ? false : true
      );
    } else {
      setIsLoading(true);
    }
  }, [chartDeviceState]);

  const askForCharts = () => {
    let message = `/api/json/device/${device.get(
      "funkcja"
    )}/charts/${device.get("interpretacja")}`;

    setIsLoading(true);
    props.chartDataReceived(deviceID, null);
    mqttClient.askForCharts(message, device.get("id"));
  };

  const refreshCharts = () => {
    setIsLoading(true);
    askForCharts();
  };

  let columnWidth = dimensions.get("columnWidth");
  let width = dimensions.get("width");

  if (customWidth) {
    columnWidth = width;
  }

  let webViewWidth = gretingViewHeight ? width / 2 - 20 : columnWidth - 20;
  let webViewHeight = gretingViewHeight
    ? gretingViewHeight / 2 - 20
    : webViewWidth * 1.15;
  if (deviceFromSketch) {
    webViewWidth = onSketchWidth;
    webViewHeight = onSketchHeight;
  }
  return !chartDevice ? (
    <View>
      {!deviceFromCustom && !deviceFromSketch && header}
      <SecondaryColorText
        style={{
          paddingLeft: deviceFromCustom ? 0 : 10,
          textAlign: deviceFromCustom ? "center" : null,
        }}
      >
        {deviceFromCustom ? "?" : t("NO_DEVICE_OF_GIVEN_ID")}
      </SecondaryColorText>
    </View>
  ) : (
    <View
      style={{
        width: "100%",
        height: gretingViewHeight ? "100%" : webViewHeight,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 5,
          zIndex: 500,
        }}
      >
        {!deviceFromCustom && !deviceFromSketch && header}
        {!deviceFromSketch ? (
          <TouchableOpacity onPress={refreshCharts}>
            <SecondaryColorText style={{ fontStyle: "italic" }}>
              {t("REFRESH")}
            </SecondaryColorText>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={refreshCharts}
            style={{ paddingTop: -100, position: "relative" }}
          >
            <StyledIcon
              name={"refresh-ccw"}
              color={"primary"}
              size={22}
              style={{ position: "absolute", top: 15, left: 10 }}
            />
          </TouchableOpacity>
        )}
      </View>

      {isLoading ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      ) : showText ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrimaryColorText>{t("NO_DATA")}</PrimaryColorText>
        </View>
      ) : (
        <HighChartView
          dimensions={{
            width: webViewWidth,
            height: webViewHeight,
          }}
          params_device={chartDeviceParam}
          timeRange={device.get("interpretacja")}
          chartData={
            chartDeviceState ? chartDeviceState.get("chartData") : null
          }
          deviceType={chartDevice.get("typ_komponentu")}
        />
      )}
    </View>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    chartDeviceParam: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("params_devices")
      .get(ownProps.device.get("funkcja")),

    chartDeviceState: state.statesData
      .get("states")
      .get(ownProps.device.get("id")),
    chartDevice: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("devices")
      .get(ownProps.device.get("funkcja")),
    connectionStatus: state.statesData.get("connectionStatus"),
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    dimensions: state.statesData.get("dimensions"),
    noConnectionCriticalError: state.statesData.get(
      "noConnectionCriticalError"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chartDataReceived: (deviceID, reset) =>
      dispatch(chartDataReceived(deviceID, reset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);
