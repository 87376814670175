import React, { Component } from "react";
import { Text, View } from "react-native";

import { ComponentHeaderText } from "../../UI/styledComponents";
import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";

const InputComponent = ({
	device,
	group_params,
	header,
	deviceFromCustom,
	deviceFromSketch,
	deviceState,
}) => {
	const valueToDisplay = (value) => {
		if (
			((device.get("ikona_0") != undefined &&
				device.get("ikona_0") !== null &&
				device.get("ikona_1") !== null) ||
				(device.get("ikona0") != undefined &&
					device.get("ikona0") !== null &&
					device.get("ikona1") !== null)) &&
			(deviceFromSketch || (!deviceFromSketch && (group_params & 0x04) == 0))
		) {
			return (
				<ImageFromIkona0_0
					style={{
						width: deviceFromSketch ? "100%" : 36,
						height: deviceFromSketch ? "100%" : 36,
					}}
					deviceFromSketch={deviceFromSketch}
					deviceFromCustom={deviceFromCustom}
					device={device}
					value={value}
				/>
			);
		} else {
			return (
				<ComponentHeaderText>{value != "0" ? "ON" : "OFF"}</ComponentHeaderText>
			);
		}
	};

	return (
		<View
			style={{
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			{!deviceFromCustom && !deviceFromSketch && header}

			<View
				style={{
					width: deviceFromSketch ? "100%" : null,
					height: deviceFromSketch ? "100%" : null,
				}}
			>
				{valueToDisplay(deviceState.get("state"))}
			</View>
		</View>
	);
};

export default InputComponent;
