import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Platform,
} from "react-native";

import { fromJS } from "immutable";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";

import {
  ComponentHeaderText,
  StyledIcon,
  SliderStyled,
  PrimaryColorText,
} from "../../UI/styledComponents";
import FavouriteStationsModal from "./FavouriteStationsModal";
import AddFavouriteStationModal from "./AddFavouriteStationModal";
import RadioButtonsBar from "./RadioButtonsBar";
import PickerSelect from "../../UI/PickerSelect";
import ModalWithContent from "../../UI/ModalWithContent";

const modes = [
  {
    label: "Input 1",
    value: 0,
  },
  {
    label: "Input 2",
    value: 1,
  },
  {
    label: "Radio FM",
    value: 2,
  },
];

const placeholder = {
  label: "Select input...",
  value: null,
};

let changeVolumeToPercent = (volumeValue) => {
  let percentValue = Math.round((volumeValue * 100) / 56);
  return `${percentValue} %`;
};

class RadioComponent extends Component {
  constructor(props) {
    super(props);
    this.timeOut = null;
    this.state = {
      favouritesListDialogVisible: false,
      addFavouriteStationDialogVisible: false,
      blockProps: false,
      isLoading: true,
      favouriteStations: null,
      volume: 0,
      bass: 0,
      mid: 0,
      treble: 0,
      mode: -1,
      selectedStationInScene: -1,
      settingsDialogVisible: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        volume: Number(nextProps.deviceState.get("state")),
        bass: Number(nextProps.deviceState.get("bass")),
        mid: Number(nextProps.deviceState.get("mid")),
        treble: Number(nextProps.deviceState.get("treble")),
        mode: Number(nextProps.deviceState.get("mode")),
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        volume: Number(nextProps.deviceInScene.get("value")),
        mode: Number(nextProps.deviceInScene.get("param1")),
        selectedStationInScene: Number(nextProps.deviceInScene.get("param2")),
      };
    }

    return null;
  }

  componentDidMount() {
    const { deviceState } = this.props;

    if (deviceState.get("favouriteStations")) {
      this.setState({
        favouriteStations: deviceState.get("favouriteStations"),
        isLoading: false,
      });
    } else {
      mqttClient.askForRadioFavorite(this.props.device.get("id"));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.noConnectionBarVisible == true &&
      this.props.noConnectionBarVisible == false
    ) {
      mqttClient.askForRadioFavorite(this.props.device.get("id"));
    }
    const { deviceState, noConnectionBarVisible, noConnectionCriticalError } =
      this.props;
    if (
      deviceState.get("favouriteStations") !=
      prevProps.deviceState.get("favouriteStations")
    ) {
      this.setState({
        favouriteStations: deviceState.get("favouriteStations"),
        isLoading: false,
      });
    }
    //this is because new states are overriding fav radios
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);

    this.setState({
      favouritesListDialogVisible: false,
      addFavouriteStationDialogVisible: false,
    });
  }

  showFavouritesListDialog = () => {
    this.setState({ favouritesListDialogVisible: true });
  };

  showAddFavouriteStationDialog = () => {
    this.setState({ addFavouriteStationDialogVisible: true });
  };

  onAddFavouriteStation = (name, num) => {
    const { device, deviceState } = this.props;

    let object = { name: name, freq: deviceState.get("freq"), num: num };
    let favouriteStations = this.state.favouriteStations;

    if (favouriteStations) {
      if (favouriteStations.some((el) => el.get("num") == num)) {
        let index = favouriteStations.findIndex((x) => x.get("num") == num);
        favouriteStations = favouriteStations.set(index, fromJS(object));
      } else {
        favouriteStations = favouriteStations.push(fromJS(object));
      }

      mqttClient.addFavouriteStationRadio(object, device.get("id"));

      this.setState({
        addFavouriteStationDialogVisible: false,
        favouriteStations,
      });
    }
  };

  increaseOne = () => {
    if (this.state.volume < 55) {
      this.setState(
        (prevState) => ({
          volume: prevState.volume + 1,
          blockProps: true,
        }),
        () => this.sendMessage(this.state.volume, "volume")
      );
    }
  };

  decreaseOne = () => {
    if (this.state.volume > 0) {
      this.setState(
        (prevState) => ({
          volume: prevState.volume - 1,
          blockProps: true,
        }),
        () => this.sendMessage(this.state.volume, "volume")
      );
    }
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (volume) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    this.sendMessage(volume, "volume", true);
  };

  onSlidingCompleteHandler = (volume) => {
    this.setState({ volume: Number(volume) }, () =>
      this.sendMessage(volume, "volume")
    );
  };

  onModeChange = (mode) => {
    this.setState({ mode, blockProps: true }, () => {
      if (Platform.OS !== "ios") {
        this.sendMessage(this.state.mode, "mode");
      }
    });
  };

  onModeClose = () => {
    if (Platform.OS === "ios") {
      this.sendMessage(this.state.mode, "mode");
    }
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        volume: Number(deviceState.get("state")),
        mode: Number(deviceState.get("mode")),
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, type, fromSliderMoving) => {
    const { device, deviceInScene, activeSceneControl } = this.props;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    let message = "";
    if (type == "volume") {
      message = `/api/set/${device.get("id")}/setVolume/${value}`;
    } else if (type == "mode") {
      message = `/api/set/${device.get("id")}/setInput/${value}`;
    } else if (type == "seek") {
      message = `/api/set/${device.get("id")}/setSeek/${value}`;
    } else if (type == "playFav") {
      message = `/api/set/${device.get("id")}/setStation/${value}`;
    } else if (type == "removeFav") {
      message = `/api/set/${device.get("id")}/removeStation/${value}`;
    } else if (type == "bass") {
      message = `/api/set/${device.get("id")}/setBass/${value}`;
    } else if (type == "mid") {
      message = `/api/set/${device.get("id")}/setMid/${value}`;
    } else if (type == "treble") {
      message = `/api/set/${device.get("id")}/setTreble/${value}`;
    }
    if (type != "setFavInScenes") {
      activeSceneControl != false &&
        mqttClient.stateChangeToSend(message, device.get("id"));
    }
    if (deviceInScene) {
      let infoToUpdate = deviceInScene;
      infoToUpdate = infoToUpdate.set("value", this.state.volume);
      infoToUpdate = infoToUpdate.set("param1", this.state.mode);
      infoToUpdate = infoToUpdate.set(
        "param2",
        this.state.selectedStationInScene
      );

      this.props.changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }
  };

  onActionFromButtons = (action) => {
    if (action == "showFavouritesListDialog") {
      this.showFavouritesListDialog();
    } else if (action == "showAddFavouriteStationDialog") {
      this.showAddFavouriteStationDialog();
    } else {
      this.sendMessage(action, "seek");
    }
  };

  onActionFromStationList = (num, action) => {
    this.sendMessage(num, action);
    if (action == "playFav") {
      this.setState({ favouritesListDialogVisible: false });
    } else if (action == "removeFav") {
      let favouriteStations = this.state.favouriteStations;
      let index = favouriteStations.findIndex((x) => x.get("num") == num);
      favouriteStations = favouriteStations.delete(index);
      this.setState({ favouriteStations });
    }
  };

  onStationForSceneChange = (value) => {
    this.setState({ selectedStationInScene: value }, () => {
      this.sendMessage(this.state.selectedStationInScene, "setFavInScenes");
    });
  };

  openRadioSettings = () => {
    this.setState({ settingsDialogVisible: true });
  };
  closeRadioSettings = () => {
    this.setState({ settingsDialogVisible: false });
  };

  onSettingsChangeHandler = (value, el) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }

    this.sendMessage(value, el, true);

    // if (el == "bass") {
    // 	this.sendMessage(value, "bass", true);
    // } else if (el == "mid") {
    // 	this.sendMessage(value, "mid", true);
    // } else if (el == "treble") {
    // 	this.sendMessage(value, "treble", true);
    // }
  };

  onSettingsCompleteHandler = (value, el) => {
    if (el == "bass") {
      this.setState({ bass: value }, () => this.sendMessage(value, "bass"));
    } else if (el == "mid") {
      this.setState({ mid: value }, () => this.sendMessage(value, "mid"));
    } else if (el == "treble") {
      this.setState({ treble: value }, () => this.sendMessage(value, "treble"));
    }
  };

  render() {
    const { device, deviceInScene, header, readOnly, deviceState } = this.props;
    const {
      favouriteStations,
      selectedStationInScene,
      isLoading,
      favouritesListDialogVisible,
      addFavouriteStationDialogVisible,
      mode,
      settingsDialogVisible,
      volume,
      bass,
      mid,
      treble,
    } = this.state;

    let favouriteStationsList = [];
    if (favouriteStations) {
      let element = {};
      for (let i = 0; i < 16; i++) {
        favouriteStations.some((el) => {
          return el.get("num") == i;
        })
          ? (element = {
              label:
                `${i + 1}. ` +
                favouriteStations.find((x) => x.get("num") == i).get("name"),
              value: i,
            })
          : (element = { label: `${i + 1}. Empty`, value: i });

        favouriteStationsList.push(element);
      }
    }

    let settingsArray = [
      { label: "treble", value: treble },
      { label: "mid", value: mid },
      { label: "bass", value: bass },
    ];

    return (
      <View>
        <FavouriteStationsModal
          isVisible={favouritesListDialogVisible}
          favouriteStations={favouriteStations}
          isLoading={isLoading}
          onActionFromStationList={this.onActionFromStationList}
          onClose={() => {
            this.setState({ favouritesListDialogVisible: false });
          }}
        />

        <AddFavouriteStationModal
          isVisible={addFavouriteStationDialogVisible}
          favouriteStationsList={favouriteStationsList}
          isLoading={isLoading}
          onClose={() => {
            this.setState({ addFavouriteStationDialogVisible: false });
          }}
          onAdd={this.onAddFavouriteStation}
        />

        {settingsDialogVisible ? (
          <ModalWithContent
            isVisible={settingsDialogVisible}
            title={t("RADIO_SETTINGS")}
            onClose={this.closeRadioSettings}
            modalHeight={250}
          >
            {settingsArray.map((el) => {
              return (
                <View key={el.label}>
                  <View
                    style={{
                      // width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <PrimaryColorText
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {el.label}
                    </PrimaryColorText>
                    <PrimaryColorText style={{ textTransform: "capitalize" }}>
                      {el.value - 100}
                    </PrimaryColorText>
                  </View>
                  <View
                    style={{
                      // width: "100%",
                      flexDirection: "row",
                      paddingBottom: 10,
                    }}
                  >
                    <SliderStyled
                      disabled={readOnly}
                      value={el.value}
                      minimumValue={93}
                      maximumValue={107}
                      step={1}
                      // onSlidingStart={this.onSlidingStartHandler}
                      onValueChange={(value) =>
                        this.onSettingsChangeHandler(value, el.label)
                      }
                      onSlidingComplete={(value) =>
                        this.onSettingsCompleteHandler(value, el.label)
                      }
                    />
                  </View>
                </View>
              );
            })}
          </ModalWithContent>
        ) : null}

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {deviceInScene ? (
            <View style={{ flex: 1 }}>{header}</View>
          ) : (
            <TouchableOpacity
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
              onPress={this.openRadioSettings}
            >
              {header}

              <StyledIcon
                style={{ paddingRight: 5 }}
                name={"settings"}
                color={"primary"}
                size={20}
              />
            </TouchableOpacity>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ComponentHeaderText style={{ marginRight: 10 }}>
              {changeVolumeToPercent(volume)}
            </ComponentHeaderText>

            <PickerSelect
              placeholder={placeholder}
              items={modes}
              disabled={readOnly}
              containerStyle={{ width: 90 }}
              onValueChange={this.onModeChange}
              onClose={this.onModeClose}
              value={mode}
            />
          </View>
        </View>

        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <TouchableOpacity
              style={styles.iconButton}
              onPress={this.decreaseOne}
            >
              <StyledIcon name={"volume-1"} color={"primary"} size={24} />
            </TouchableOpacity>

            <SliderStyled
              disabled={readOnly}
              value={volume}
              maximumValue={56}
              // onSlidingStart={this.onSlidingStartHandler}
              onValueChange={this.onSlidingChangeHandler}
              onSlidingComplete={this.onSlidingCompleteHandler}
            />
            <TouchableOpacity
              disabled={readOnly}
              style={styles.iconButton}
              onPress={this.increaseOne}
            >
              <StyledIcon name={"volume-2"} color={"primary"} size={24} />
            </TouchableOpacity>
          </View>
        </View>
        {!deviceInScene ? (
          mode == 2 ? (
            <RadioButtonsBar
              onActionFromButtons={this.onActionFromButtons}
              rds={deviceState.get("rds")}
              freq={deviceState.get("freq")}
              readOnly={readOnly}
            />
          ) : null
        ) : mode == 2 ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PrimaryColorText style={{ paddingRight: 5 }}>
              {t("FAVOURITE_STATIONS")}
            </PrimaryColorText>
            {isLoading ? (
              <ActivityIndicator size="small" />
            ) : (
              <PickerSelect
                placeholder={{
                  label: "Select favourite station",
                  value: null,
                }}
                items={favouriteStationsList}
                onValueChange={this.onStationForSceneChange}
                value={selectedStationInScene}
              />
            )}
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    noConnectionCriticalError: state.statesData.get(
      "noConnectionCriticalError"
    ),
  };
};

export default connect(mapStateToProps, null)(RadioComponent);
