import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  FlatList,
  Platform,
  I18nManager,
  ScrollView,
  TouchableWithoutFeedback,
  Keyboard,
  AppState,
} from "react-native";
import * as Updates from "expo-updates";
import * as SplashScreen from "expo-splash-screen";
import { Feather } from "@expo/vector-icons";

import { connect } from "react-redux";
import { t } from "../../services/i18n";
import * as ScreenOrientation from "expo-screen-orientation";
import { persistor } from "../../store/configureStore";
import RNRestart from "react-native-restart";
import { ThemeContext } from "../../../ThemeContext";

import ModalWithContent from "../../components/UI/ModalWithContent";
import { clearStatesData, changeAppLocation, changeFromSwitchProfile, changeCurrentProfile, changeAppLogout } from "../../store/actions";
import { ScreenView } from "../../components/UI/screenViewX";
import { ComponentContainerTouchable, PrimaryColorText, StyledIcon, ButtonPrimary, ComponentHeaderText } from "../../components/UI/styledComponents";

import mqttClient from "../../services/mqtt";

const Tile = ({ element, onPress, updateAvailable, messages, messagesCount }) => {
  let numberOfColumns = 2;

  let isBig = numberOfColumns > 1 ? true : false;
  let messagesNumber = 0;
  if (messagesCount) {
    messagesNumber = Number(messagesCount);
  } else if (messages) {
    messages.map((message) => {
      if (message.get("state") === 0) {
        messagesNumber++;
      }
    });
  }

  let messagesIndicator =
    messagesNumber !== 0 ? (
      <View
        style={{
          position: "absolute",
          top: isBig ? -10 : -5,
          right: isBig ? -15 : -8,
          // marginLeft: 10,
          minWidth: isBig ? 20 : 15,
          height: isBig ? 20 : 15,
          borderRadius: isBig ? 20 : 15,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#4ED164",
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: isBig ? 13 : 10,
          }}
        >
          {messagesNumber}
        </Text>
      </View>
    ) : null;
  return (
    <ComponentContainerTouchable onPress={onPress} style={{ alignItems: "center" }} numberOfColumns={2}>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: 75,
        }}
      >
        <View>
          <StyledIcon
            name={element.icon}
            size={isBig ? 36 : 22}
            color={"secondary"}
            style={{
              padding: isBig ? 5 : 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          />

          {element.title === "Message_Center" ? messagesIndicator : null}
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <ComponentHeaderText style={{ alignItems: "center" }}>{t(`account:${element.title.toUpperCase()}`)}</ComponentHeaderText>
          {updateAvailable ? <Feather name="refresh-ccw" size={18} color={"rgb(50, 200, 80)"} style={{ paddingLeft: 10 }} /> : null}
        </View>
      </View>
    </ComponentContainerTouchable>
  );
};
class AccountScreen extends Component {
  static contextType = ThemeContext;
  state = {
    switchProfileDialogVisible: false,
    profilesList: null,
    appUpdateAvailable: false,
    appState: AppState.currentState,
  };

  checkForUpdates = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      this.setState({ appUpdateAvailable: update.isAvailable });
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
      }
    } catch (e) {
      // console.log(e, "err in update");
    }
  };
  componentDidMount() {
    this.appStateSubscription = AppState.addEventListener("change", this._handleAppStateChange);

    const { profilesSettings } = this.props;
    if (!__DEV__) {
      this.checkForUpdates();
    }
    let newArray = [];
    profilesSettings.forEach((v, k) => {
      if (k && !k.includes("currentProfile") && k != "demo") {
        newArray.push(v.toJS());
      }
    });

    this.setState({ profilesList: newArray });
  }

  componentWillUnmount() {
    this.appStateSubscription.remove();
    //AppState.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    if (this.state.appState === "background" && nextAppState === "active") {
      this.checkForUpdates();

      this.setState({ appState: nextAppState });
    } else if (this.state.appState === "active" && nextAppState == "background") {
      this.setState({ appState: nextAppState });
    }
  };

  handleLogoutOK = async () => {
    async function unlockLandscapeOrientation() {
      try {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock["DEFAULT"]);
      } catch (error) {
        console.error(error, "error in 	ScreenOrientation.OrientationLock");
      }
    }

    !this.props.globalSettings.get("forceLandscapeOrientation") &&
      !this.props.globalSettings.get("forceInvertedLandscapeOrientation") &&
      unlockLandscapeOrientation();

    this.setState({ switchProfileDialogVisible: false });
    await this.props.changeAppLogout(true);
    mqttClient.handleLogOut();
    this.props.onChangeAppLocation("auth");
    await this.props.clearStatesData();
  };

  showSwitchProfileDialog = () => {
    this.setState({ switchProfileDialogVisible: true });
  };

  handleSwitchProfileClose = () => {
    this.setState({ switchProfileDialogVisible: false });
  };

  switchToProfile = (name) => {
    let connectionType = "local";

    let loginDataProps = this.props.profilesSettings.get(`${name}`).get("loginData");

    let loginData = {
      name: loginDataProps.get("name"),
      login: loginDataProps.get("login"),
      password: loginDataProps.get("password"),
      ip: loginDataProps.get("ip"),
      ip2: loginDataProps.get("ip2"),
      cloudIp: loginDataProps.get("cloudIp"),
      device_id: loginDataProps.get("device_id"),
      cloudType: loginDataProps.get("cloudType"),
    };

    this.props.onChangeFromSwitchProfile(true);
    this.setState({ switchProfileDialogVisible: false }, () => {
      mqttClient.handleLogOut(true, loginData, connectionType);
      this.props.onChangeAppLocation("auth");
    });
  };

  render() {
    const { serverInfo, profileName } = this.props;
    const { switchProfileDialogVisible, profilesList, appUpdateAvailable } = this.state;
    let permission = serverInfo.get("perm");
    let serverVersion = serverInfo.get("serverVersion");
    let permissionForSupport = (permission & 0x10) == 0;
    let permissionForAutomatization = (permission & 0x100) == 0;
    let permissionForParameters = (permission & 0x200) == 0;
    let permissionForStatistics = (permission & 0x400) == 0;
    let permissionForGarden = (permission & 0x800) == 0;
    let permissionForMessageCenter = (permission & 0x1000) == 0;
    let permissionForDetection = (permission & 0x4000) == 0;
    let permissionForSimulation = (permission & 0x8000) == 0;

    let updateAvailable = false;

    if (
      // (serverInfo.get("update") && serverInfo.get("update") != "0") ||
      (serverInfo.get("update") && (serverInfo.get("update") & 0x01) > 0) ||
      appUpdateAvailable
    ) {
      updateAvailable = true;
    }

    let elements = [
      {
        title: "Scenes",
        icon: "eye",
      },
    ];

    serverVersion >= 399 &&
      permissionForGarden &&
      elements.push({
        title: "Irrigation",
        icon: "droplet",
      });
    serverVersion >= 470 &&
      permissionForAutomatization &&
      elements.push({
        title: "Automatization",
        icon: "settings",
      });
    serverVersion >= 489 &&
      permissionForMessageCenter &&
      elements.push({
        title: "Message_Center",
        icon: "message-circle",
      });

    permissionForSimulation &&
      elements.push({
        title: "Simulation",
        icon: "shield",
      });

    permissionForDetection &&
      elements.push({
        title: "Detection",
        icon: "search",
      });

    permissionForParameters &&
      elements.push({
        title: "Parameters",
        icon: "sliders",
      });

    elements.push({
      title: "Versions",
      icon: "hard-drive",
    });
    permissionForStatistics &&
      elements.push({
        title: "Statistics",
        icon: "book",
      });

    permissionForSupport &&
      elements.push({
        title: "Support",
        icon: "headphones",
      });

    (this.props.profileLogin == "admin" || (this.props.clientData && this.props.clientData.get("login") == "admin")) &&
      elements.push({
        title: "Diagnostic",
        icon: "activity",
      });

    elements.push({
      title: "Personalization",
      icon: "user",
    });

    elements.push({
      title: "Security",
      icon: "lock",
    });

    elements.push({
      title: "About",
      icon: "info",
    });

    let content =
      profilesList &&
      profilesList.map((el, key) => {
        let profileData = el.loginData;

        return (
          <View key={key + profileData.name}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryColorText
                style={{
                  fontSize: 16,
                  fontWeight: "600",
                  // paddingVertical: 5
                }}
              >
                {key + 1}. {profileData.name}
              </PrimaryColorText>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity style={{ padding: 10 }} onPress={() => this.switchToProfile(profileData.name)}>
                  <StyledIcon name="log-in" size={20} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        );
      });

    return (
      <ScreenView>
        {elements.map((element) => {
          return (
            <Tile
              key={element.title}
              element={element}
              updateAvailable={element.title == "Versions" && updateAvailable}
              messages={element.title === "Message_Center" && this.props.messages}
              messagesCount={this.props.messagesCount}
              onPress={() => {
                this.props.navigation.navigate(`${element.title}_Account`, {
                  title: t(`account:${element.title.toUpperCase()}`),
                });
              }}
            />
          );
        })}

        <Tile
          element={{
            title: "EXIT_INSTALATION",
            icon: "log-out",
          }}
          onPress={this.handleLogoutOK}
        />
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serverInfo: state.statesData.get("server").get("serverInfo"),
    messages: state.statesData.get("messages"),
    messagesCount: state.statesData.get("messages_count"),
    clientData: state.statesData.get("clientData"),
    profileLogin: state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("loginData").get("login"),
    profileName: state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("loginData").get("name"),
    profilesSettings: state.profilesSettings,
    globalSettings: state.globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeAppLocation: (appLocation) => dispatch(changeAppLocation(appLocation)),
    onChangeFromSwitchProfile: (value) => dispatch(changeFromSwitchProfile(value)),
    changeAppLogout: (value) => dispatch(changeAppLogout(value)),
    clearStatesData: () => dispatch(clearStatesData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountScreen);
