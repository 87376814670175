import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { t } from "../../services/i18n";

import PickerSelect from "../../components/UI/PickerSelect";
import {
	StyledIcon,
	PrimaryColorText,
} from "../../components/UI/styledComponents";

class HeatingHarmonogramSingle extends Component {
	state = {
		daysArray: [
			{
				value: 1,
				label: t("weekdays:MON"),
				active: false,
			},
			{
				value: 2,
				label: t("weekdays:TUE"),
				active: false,
			},
			{
				value: 3,
				label: t("weekdays:WED"),
				active: false,
			},
			{
				value: 4,
				label: t("weekdays:THU"),
				active: false,
			},
			{
				value: 5,
				label: t("weekdays:FRI"),
				active: false,
			},
			{
				value: 6,
				label: t("weekdays:SAT"),
				active: false,
			},
			{
				value: 0,
				label: t("weekdays:SUN"),
				active: false,
			},
		],
		dayMask: this.props.dayMask,
	};

	componentDidMount() {
		let daysArray = this.state.daysArray.map((day, index) => {
			day.active = this.props.dayMask & (1 << day.value) ? true : false;
			return day;
		});

		this.setState({ daysArray });
	}

	onScheduleChange = (sendValue) => {
		let daysArray = this.state.daysArray;

		let indexToChange = this.state.daysArray.findIndex(
			(el) => el.value == sendValue
		);

		daysArray[indexToChange].active = !daysArray[indexToChange].active;

		let dayMask = 0;

		daysArray.forEach((el) => {
			if (el.active) {
				dayMask |= 1 << el.value;
			}
		});

		this.setState({ dayMask, daysArray }, () => {
			this.props.onScheduleChange, "dayMask";
		});
	};

	render() {
		let { schedule, daysArray } = this.state;
		let days = daysArray.map((day, index) => {
			return (
				<TouchableOpacity
					onPress={() => this.onScheduleChange(day.value)}
					key={index}
					style={{
						flex: 1,
						alignItems: "center",
					}}
				>
					<PrimaryColorText
						style={{
							paddingBottom: 2,
						}}
					>
						{day.label}
					</PrimaryColorText>
					{day.active ? (
						<StyledIcon name={"check"} color={"primary"} size={20} />
					) : (
						<StyledIcon name={"x"} color={"secondary"} size={20} />
					)}
				</TouchableOpacity>
			);
		});
		return (
			<View style={styles.row}>
				<PrimaryColorText
					style={{
						fontWeight: "bold",
						marginRight: 10,
						width: 50,
					}}
				>
					{t("DAY")}:
				</PrimaryColorText>
				<View style={styles.days}>{days}</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	// row: {
	// 	flexDirection: "row",
	// 	alignItems: "center",
	// 	justifyContent: "space-between",
	// 	margin: 5,
	// 	borderWidth: 0.3,
	// 	borderColor: "gray",
	// 	padding: 5
	// },
	days: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		flex: 1,
	},
	row: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 10,
	},
	row2: {
		// flex: 1,
		flexDirection: "row",
		alignItems: "center",
		// justifyContent: "center"
	},
	hourText: {
		fontWeight: "bold",
		fontSize: 16,
		margin: 6,
	},
	dayText: {
		fontSize: 12,
		color: "white",
	},
	dayCircle: {
		width: 26,
		height: 26,
		borderRadius: 13,
		margin: 2,
		// borderWidth: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
});
export default HeatingHarmonogramSingle;
