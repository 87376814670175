import React, { Component, useEffect, useState } from "react";
import { Text, View, FlatList, Button, Image, TouchableOpacity, StyleSheet, ScrollView, Keyboard, Platform } from "react-native";
import moment from "moment";
import { createSelector } from "reselect";
import Slider from "@react-native-community/slider";
import { changeSelectedSoruces } from "../../store/actions";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";
import { SliderStyled } from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import SourceChooseScreen from "./SourceChooseScreen";
import { StyledIcon, TitleWithLines, PrimaryColorText } from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";
import { useTheme } from "../../../ThemeContext";

// import TrackPlayer, { usePlaybackState, useTrackPlayerProgress, useTrackPlayerEvents } from "react-native-track-player";

const NowPlaying = () => {
  const playbackState = usePlaybackState();
  const [track, setTrack] = useState({});

  //const { position, duration } = useTrackPlayerProgress();

  useEffect(() => {
    const getTrackData = async () => {
      // const trackObject = await TrackPlayer.getActiveTrack();
      // if (trackObject) {
      //   setTrack(trackObject);
      // }
    };
    getTrackData();
  }, []);

  const playbackStatus = () => {
    if (playbackState) {
      return playbackState.state;
    } else {
      return "none";
    }
  };

  const handlePreviousTrack = async () => {
    const currentTrack = await TrackPlayer.getActiveTrack();
    // if (currentTrack !== null) {
    //   await TrackPlayer.skipToPrevious();
    // }
  };

  const handleNextTrack = async () => {
    const currentTrack = await TrackPlayer.getActiveTrack();
    // if (currentTrack !== null) {
    //   await TrackPlayer.skipToNext();
    // }
  };

  const handleSliderChange = (value) => {
    //TrackPlayer.seekTo(value);
  };

  // useTrackPlayerEvents(["playback-track-changed"], async (event) => {
  //   const trackObject = await TrackPlayer.getTrack(event.track);
  //   if (trackObject) {
  //     setTrack(trackObject);
  //   }
  // });

  return playbackState !== "none" ? (
    <View style={{ alignItems: "center", justifyContent: "center", width: "100%" }}>
      {track.artwork && <Image source={{ uri: track.artwork }} style={{ width: 200, height: 200 }} />}
      <Text>{track.title}</Text>
      <Text>{track.artist}</Text>

      <Text>{playbackStatus()}</Text>
      <Slider
        style={{ width: "100%" }}
        minimumValue={0}
        minimumTrackTintColor="#1f6ee8"
        maximumTrackTintColor="#c7c7c7"
        onSlidingComplete={handleSliderChange}
      />
    </View>
  ) : null;
};

const CurrentMusicScreen = ({ navigation, changeSelectedSoruces }) => {
  const _closeScreen = (clean) => {
    if (clean) {
      changeSelectedSoruces([]);
    }
    Keyboard.dismiss();
    navigation.goBack(null);
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => _closeScreen()}>
          <StyledIcon name={"x"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }, []);

  return (
    <ScreenView>
      <NowPlaying></NowPlaying>
      <SourceChooseScreen navigation={navigation} embedded={true}></SourceChooseScreen>
    </ScreenView>
  );
};

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
});

const selectDevices = (state) => state;

const devices = createSelector(selectDevices, (smartHomeData) => {
  const filteredItems = smartHomeData.filter((device) => {
    if (device) {
      return device.get("typ_komponentu") === "ip_radio";
    } else {
      return false;
    }
  });
  return filteredItems;
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  let possibleDevices = devices(state.smartHomeData.get(currentProfile).get("devices"));

  return {
    selectedSoruces: state.statesData.get("selectedSoruces"),
    devicesArray: possibleDevices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedSoruces: (status) => dispatch(changeSelectedSoruces(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentMusicScreen);
