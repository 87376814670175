import React, { Component } from "react";
import { t } from "../../../services/i18n";

import {
	ComponentContainer,
	SecondaryColorText,
} from "../../UI/styledComponents";

const NoStateComponent = ({ header, deviceFromCustom }) => {
	return (
		<ComponentContainer containerStyle={{ width: "100%" }}>
			{!deviceFromCustom && header}

			<SecondaryColorText
				style={{
					paddingLeft: deviceFromCustom ? 0 : 10,
					textAlign: deviceFromCustom ? "center" : null,
				}}
			>
				{deviceFromCustom ? "?" : t("STATE_NOT_RECEIVED")}
			</SecondaryColorText>
		</ComponentContainer>
	);
};

export default NoStateComponent;
