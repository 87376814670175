import * as Actions from "../actionTypes";
import { Map, fromJS, List } from "immutable";

const initialState = fromJS({
  currentProfile: "",
  demo: {
    loginData: {
      name: "demo",
      login: "demo",
      password: "demo",
      ip: "demo.ampio.pl",
      ip2: "demo.ampio.pl",
      cloudIp: "",
      device_id: "",
      cloudType: "",
    },
    dontAsk: false,
    favouritesColors: Map(),
    camerasConnectionType: null,
    favouritesDevices: List(),
    cityForWeather: "Szczecin",
    automaticLogin: false,
    order: Map(),
    greetingViewObjects: List(),
    startingPlace: -4,
  },
});

const profilesSettingsReducer = (state = initialState, action) => {
  let currentProfile = state.get("currentProfile");
  switch (action.type) {
    case Actions.CHANGE_CURRENT_PROFILE:
      return state.set("currentProfile", action.payload);

    case Actions.CHANGE_STARTING_PLACE:
      return state.setIn([state.get("currentProfile"), "startingPlace"], fromJS(action.payload));

    case Actions.CHANGE_BACK_TO_START_TIMEOUT:
      return state.setIn([state.get("currentProfile"), "backToStartTimeout"], fromJS(action.payload));

    case Actions.DELETE_PROFILE:
      let newState = state.delete(action.payload);
      if (action.payload == state.get("currentProfile")) {
        return newState.set("currentProfile", "");
      }
      return newState;
    case Actions.CHANGE_PASSWORD:
      return state.setIn([state.get("currentProfile"), "loginData", "password"], action.payload);

    case Actions.SAVE_PROFILE:
      let { oldName, loginData, automaticLogin } = action.payload;

      if (oldName == "" || oldName != loginData.name) {
        let newProfileData = fromJS({
          loginData: {
            name: loginData.name,
            login: loginData.login,
            password: loginData.password,
            ip: loginData.ip,
            ip2: loginData.ip2,
            cloudIp: loginData.cloudIp,
            device_id: loginData.device_id,
            cloudType: loginData.cloudType,
          },
          favouritesColors: Map(),
          favouritesDevices: List(),
          cityForWeather: "",
          dontAsk: false,
          automaticLogin: automaticLogin,
        });
        return state.set(loginData.name, newProfileData);
      } else if (oldName == loginData.name) {
        return state.setIn([oldName, "loginData"], fromJS(loginData)).setIn([oldName, "automaticLogin"], fromJS(automaticLogin));
      }
    // else if (oldName != loginData.name) {
    // 	let newState = state.setIn([oldName, "loginData"], fromJS(loginData));
    // 	let newState2 = state
    // 		.set(loginData.name, newState.get(oldName))
    // 		.delete(oldName);
    // 	if (oldName == state.get("currentProfile")) {
    // 		return newState2.set("currentProfile", loginData.name);
    // 	}
    // 	return newState2;
    // }
    case Actions.SET_WEATHER_CITY_FOR_SETTINGS:
      return state.setIn([state.get("currentProfile"), "cityForWeather"], fromJS(action.payload));
    case Actions.SET_DONT_ASK:
      return state.setIn([state.get("currentProfile"), "dontAsk"], fromJS(action.payload));
    case Actions.CHANGE_PROFILE_AUTOMATIC_LOGIN:
      return state.setIn([state.get("currentProfile"), "automaticLogin"], fromJS(action.payload));
    case Actions.SAVE_FAVOURITES:
      return state.setIn([currentProfile, "favouritesDevices"], fromJS(action.payload));

    case Actions.ADD_DEVICE_TO_FAVOURITES:
      if (state.get(currentProfile).get("favouritesDevices"))
        if (!state.get(currentProfile).get("favouritesDevices").includes(action.payload)) {
          return state.updateIn([currentProfile, "favouritesDevices"], (favouriteList) => favouriteList.push(action.payload));
        } else {
          return state;
        }
      else {
        return state.setIn([currentProfile, "favouritesDevices"], List([action.payload]));
      }

    case Actions.REMOVE_DEVICE_FROM_FAVOURITES:
      return state.setIn(
        [currentProfile, "favouritesDevices"],
        state
          .get(currentProfile)
          .get("favouritesDevices")
          .filter((o) => o != action.payload)
      );

    case Actions.CHANGE_CAMERA_CONNECTION_TYPE:
      const state2 = state.get(currentProfile).get("camerasConnectionType") ? state : state.setIn([currentProfile, "camerasConnectionType"], Map({}));

      return state2.setIn(
        [currentProfile, "camerasConnectionType", `${action.payload.deviceID}`],
        fromJS({
          type: action.payload.type,
        })
      );

    case Actions.ADD_FAVOURITE_COLOR_RGB_MODAL:
      let newstate = state.setIn(
        [currentProfile, "favouritesColors", `${action.payload.deviceID}`, `${action.payload.colorPosition}`],
        fromJS({
          index: action.payload.colorPosition,
          color: action.payload.color,
        })
      );

      return newstate;
    case Actions.REMOVE_FAVOURITE_COLOR_RGB_MODAL:
      return state.deleteIn([currentProfile, "favouritesColors", `${action.payload.deviceID}`, `${action.payload.colorPosition}`]);

    // case Actions.CHANGE_CLOUD_CONNECTION_ALLOWANCE:
    // 	return state.setIn(
    // 		[currentProfile, "cloudConnectionAllowance"],
    // 		fromJS(action.payload)
    // 	);

    case Actions.SERVER_OWNER_RECEIVED:
      return state.setIn([state.get("currentProfile"), "cloud_owner"], fromJS(action.payload));

    case Actions.SERVER_INFO_RECEIVED:
      return state
        .setIn([currentProfile, "loginData", "cloudIp"], fromJS(action.payload.cloudInfo))
        .setIn([currentProfile, "loginData", "device_id"], fromJS(action.payload.device_id));
    case Actions.SAVE_GEOFENCES:
      return state.setIn([state.get("currentProfile"), "geofences"], fromJS(action.payload));

    case Actions.SAVE_ORDER:
      return state.setIn([currentProfile, "order", action.payload.groupId], fromJS(action.payload.orderArray));
    case Actions.RESET_ORDER:
      return state.setIn([currentProfile, "order"], Map());
    case Actions.SAVE_GREETING_VIEW_OBJECTS:
      return state.setIn([currentProfile, "greetingViewObjects"], fromJS(action.payload));
    default:
      return state;
  }
};

export default profilesSettingsReducer;
