import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { useTheme } from "../../../../ThemeContext";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";
import AppleMusic from "@raw2dd4/react-native-apple-music";
import { changeSoundStatus } from "../../../store/actions";
import mqttClient from "../../../services/mqtt";

// import TrackPlayer, {
//   usePlaybackState,
//   State,
// } from "react-native-track-player";

const BarMusicPlayer = ({ song, soundStatus, changeSoundStatus, selectedSoruces }) => {
  const navigation = useNavigation();

  // const playbackState = usePlaybackState();
  const { theme } = useTheme();

  const [pause, setPause] = React.useState(false);

  React.useEffect(() => {
    let interval = null;
    if (pause) {
      interval = setInterval(() => {
        if (pause) {
          changeSoundStatus(null);
        }
      }, 5000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [pause]);

  React.useEffect(() => {
    if (soundStatus) {
      if (soundStatus.get("isPlaying")) {
        setPause(false);
      } else {
        setPause(true);
      }
    }
  }, [soundStatus]);

  const _onClick = () => {
    let controlledDevice = false;
    if (selectedSoruces && Object.keys(selectedSoruces).length > 0) {
      Object.keys(selectedSoruces).map((key) => {
        if (selectedSoruces[key]) {
          let message = `/api/set/${key}/setIpRadioButton/pause`;
          mqttClient.stateChangeToSend(message, key);
          controlledDevice = true;
        }
      });
      if (controlledDevice) {
        setPause(true);
      }
    }

    // if (soundStatus && soundStatus.get("type") === "LOCAL_APPLE") {
    //   if (soundStatus.get("isPlaying")) {
    //     AppleMusic.pause();
    //     setPause(true);
    //   } else {
    //     AppleMusic.play();
    //   }
    // } else if (playbackState === State.Playing) {
    //   //TrackPlayer.pause();
    //   setPause(true);
    // } else if (playbackState === State.Paused) {
    //   if (!controlledDevice) {
    //     //TrackPlayer.play();
    //   }
    //   setPause(false);
    // }
  };

  return soundStatus ? (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        navigation.navigate(`CurrentMusicScreen`);
      }}
      style={[
        styles.container,
        {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          borderBottomColor: theme.TAB_BAR_BACKGROUND_COLOR,
        },
      ]}
    >
      {soundStatus && soundStatus.get("image") ? (
        <Image
          source={{ uri: soundStatus.get("image") }}
          resizeMode="stretch"
          style={{ width: 30, height: 30, marginLeft: 5 }}
          width={30}
          height={30}
        />
      ) : null}
      {soundStatus && (
        <View style={{ flex: 1 }}>
          <View style={[styles.containerSong, {}]}>
            <Text style={[styles.artist, { color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }]}>{soundStatus ? soundStatus.get("title") : null}</Text>
          </View>
          <View
            style={[
              {
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 4,
              },
            ]}
          >
            <Text style={[styles.device, { color: theme.TAB_BAR_TEXT_COLOR }]}>{soundStatus ? soundStatus.get("subtitle") : null}</Text>
          </View>
        </View>
      )}

      <TouchableOpacity
        hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
        onPress={() => {
          _onClick();
        }}
        style={[styles.containerIcon]}
      >
        <FontAwesome color={theme.TAB_BAR_TEXT_COLOR} name={!pause ? "pause-circle" : "play-circle"} size={28} />
      </TouchableOpacity>
    </TouchableOpacity>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    width: "100%",
  },
  containerIcon: {
    alignItems: "center",
    justifyContent: "center",
    width: 50,
  },
  containerSong: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    overflow: "hidden",
  },
  title: {
    fontSize: 12,
  },
  artist: {
    fontSize: 12,
  },
  device: {
    fontSize: 10,
    marginLeft: 4,
    textTransform: "uppercase",
  },
});
const mapStateToProps = (state) => {
  return {
    soundStatus: state.statesData.get("soundStatus"),
    selectedSoruces: state.statesData.get("selectedSoruces"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSoundStatus: (status) => dispatch(changeSoundStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarMusicPlayer);
