import React, { useState } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
//ERROR IN WEB FROM BELOW
//import RNActionSheet from "react-native-actionsheet";
import ModalWithContent from "../components/UI/ModalWithContent";

import { t } from "../services/i18n";
import { PrimaryColorText } from "../components/UI/styledComponents";

const ActionSheet = (props) => {
  return Platform.OS != "web" ? null : (
    <ModalWithContent
      isVisible={props.showWebActionSheet}
      title={t("CHOOSE_OPTION")}
      onClose={props.onWebActionSheetClose}
    >
      {props.options.map((el, index) => {
        //nie pokazuj ostatniej opcji CLOSE
        if (index != props.options.length - 1) {
          return (
            <TouchableOpacity key={el} onPress={() => props.onPress(index)}>
              <PrimaryColorText style={{ padding: 10, fontSize: 16 }}>
                {el}
              </PrimaryColorText>
            </TouchableOpacity>
          );
        }
      })}
    </ModalWithContent>
  );
};
export default ActionSheet;
