import React, { Component } from "react";
import {
  Text,
  View,
  FlatList,
  Button,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Animated,
  Platform,
} from "react-native";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";

import { removeOutdatedHeatingSchedule } from "../../store/actions";
import HeatingHarmonogramSingle from "./HeatingHarmonogramSingle";
import HeatingHarmonogramFixed from "./HeatingHarmonogramFixed";
import TemperatureRow from "./TemperatureRow";
import TemperatureRowFixed from "./TemperatureRowFixed";
import {
  ComponentContainer,
  ScreenViewWithoutScroll,
  TitleWithLines,
  PrimaryColorText,
  StyledIcon,
  ButtonPrimary,
  SliderStyled,
} from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";
import PickerSelect from "../../components/UI/PickerSelect";
import mqttClient from "../../services/mqtt";
import { ThemeContext } from "../../../ThemeContext";

class HeatingScreen extends Component {
  static contextType = ThemeContext;

  state = {
    isLoading: true,
    heatingSchedule: null,
    temp: [],
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  componentDidMount() {
    const { route, navigation } = this.props;
    const { theme } = this.context;
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ padding: 10, flexDirection: "row", alignItems: "center" }}
          onPress={() => {
            this.onPressSaveButton();
          }}
        >
          <StyledIcon name={"save"} color={"tabBar"} size={24} />
          <PrimaryColorText
            style={{
              paddingLeft: 10,
              color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
              fontWeight: "500",
            }}
          >
            {t("SAVE")}
          </PrimaryColorText>
        </TouchableOpacity>
      ),
      headerLeft: () => (
        <TouchableOpacity
          style={{ padding: 10, flexDirection: "row" }}
          onPress={() => {
            this.onPressCancelButton();
          }}
        >
          <PrimaryColorText
            style={{
              paddingLeft: 10,
              color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
              fontWeight: "500",
            }}
          >
            {t("CANCEL")}
          </PrimaryColorText>
        </TouchableOpacity>
      ),
    });

    const { device } = route.params;

    mqttClient.askForHeatingSchedule(device.get("id"));
    let step = device.get("param1") ? Number(device.get("param1")) : 1;

    const temp = [];
    for (
      let index = Number(device.get("min"));
      index <= Number(device.get("max"));
      index += step
    ) {
      let value = index.toFixed(1);
      let element = {
        label: `${value} \u00b0C`,
        value: Number(value),
      };
      temp.push(element);
    }

    this.setState({ temp });
  }

  onPressSaveButton = () => {
    const { device } = this.props.route.params;
    this.props.onRemoveOutdatedHeatingSchedule(device.get("id"));
    if (this.props.deviceState.get("heatingSchedule")) {
      if (
        JSON.stringify(this.props.deviceState.get("heatingSchedule").toJS()) !==
        JSON.stringify(this.state.heatingSchedule)
      ) {
        mqttClient.sendNewHeating(this.state.heatingSchedule, device.get("id"));
      }
    }
    this.props.navigation.goBack();
  };
  onPressCancelButton = () => {
    this.props.navigation.goBack();
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const heatingSchedule =
      nextProps.deviceState && nextProps.deviceState.get("heatingSchedule");
    if (heatingSchedule) {
      this.setState({
        isLoading: false,
        heatingSchedule: heatingSchedule.toJS(),
      });
    }
  }

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
    }).start();
  };

  onTempValueChange = (value, key) => {
    let heatingSchedule = Object.assign({}, this.state.heatingSchedule);
    heatingSchedule[key] = value;
    this.setState({ heatingSchedule });
  };

  onScheduleChange = (newSchedule, scheduleIndex) => {
    let heatingSchedule = Object.assign({}, this.state.heatingSchedule);
    heatingSchedule.schedule[scheduleIndex] = newSchedule;
    this.setState({ heatingSchedule });
  };

  onAddNewSchedule = () => {
    let heatingSchedule = Object.assign({}, this.state.heatingSchedule);

    let newSchedule = {};
    if (heatingSchedule.type == 0) {
      newSchedule = { dayMask: 62, s: 7, e: 15 };
    } else if (heatingSchedule.type == 1) {
      newSchedule = { dayMask: 62, s: 700, temp: 20 };
    }
    heatingSchedule.schedule.push(newSchedule);
    this.setState({ heatingSchedule });
  };

  onRemoveSchedule = (scheduleIndex) => {
    let heatingSchedule = Object.assign({}, this.state.heatingSchedule);

    heatingSchedule.schedule.splice(scheduleIndex, 1);
    this.setState({ heatingSchedule });
  };

  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  // componentWillUnmount() {
  // 	const { device } = this.props.route.params;
  // 	this.props.onRemoveOutdatedHeatingSchedule(device.get("id"));
  // 	if (this.props.deviceState.get("heatingSchedule")) {
  // 		if (
  // 			JSON.stringify(this.props.deviceState.get("heatingSchedule").toJS()) !==
  // 			JSON.stringify(this.state.heatingSchedule)
  // 		) {
  // 			mqttClient.sendNewHeating(this.state.heatingSchedule, device.get("id"));
  // 		}
  // 	}
  // }

  render() {
    const { route } = this.props;

    const { device } = route.params;
    const { temp, heatingSchedule, editMode } = this.state;

    const harmograms =
      heatingSchedule &&
      heatingSchedule.schedule.map((schedule, index) => {
        let { dayMask, e, s, temp } = schedule;
        return (
          <View
            key={`${index}${dayMask}${s}${e}`}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
              <TouchableOpacity onPress={() => this.onRemoveSchedule(index)}>
                <View style={[styles.removeButton]}>
                  <Text
                    style={{
                      color: "white",
                      marginLeft: 1,
                      lineHeight: 16,
                      fontWeight: "bold",
                      fontSize: 10,
                    }}
                  >
                    —
                  </Text>
                </View>
              </TouchableOpacity>
            </Animated.View>

            <Animated.View
              style={{
                marginLeft: this.state.animationMargin,
                width: "100%",
              }}
            >
              {heatingSchedule.type == 0 ? (
                <HeatingHarmonogramSingle
                  index={index}
                  schedule={schedule}
                  onScheduleChange={this.onScheduleChange}
                />
              ) : (
                <HeatingHarmonogramFixed
                  device={device}
                  index={index}
                  schedule={schedule}
                  onScheduleChange={this.onScheduleChange}
                />
              )}
            </Animated.View>
          </View>
        );
      });

    return this.state.isLoading ? (
      <ScreenViewWithoutScroll
        style={{
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" />
      </ScreenViewWithoutScroll>
    ) : (
      <ScreenView>
        {heatingSchedule.type == 0 ? (
          <TemperatureRow
            heatingSchedule={heatingSchedule}
            onTempValueChange={this.onTempValueChange}
            temp={temp}
          />
        ) : (
          <TemperatureRowFixed
            heatingSchedule={heatingSchedule}
            onTempValueChange={this.onTempValueChange}
            temp={temp}
          />
        )}

        <TitleWithLines textInside={t("heating:SCHEDULE")} fromHeating />

        <View style={{ width: "100%" }}>{harmograms}</View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              marginRight: 30,
              width: 100,
            }}
            onPress={this.onAddNewSchedule}
          >
            {t("ADD_NEW")}
          </ButtonPrimary>
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              // paddingHorizontal: 20,
              width: 100,
              backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
            }}
            onPress={this.toggleOnPressMode}
          >
            {editMode ? t("DONE") : t("DELETING")}
          </ButtonPrimary>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    deviceState: state.statesData
      .get("states")
      .get(ownProps.route.params.device.get("id")),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveOutdatedHeatingSchedule: (deviceID) =>
      dispatch(removeOutdatedHeatingSchedule(deviceID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeatingScreen);
