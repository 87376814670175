import React, { useEffect, useState, useRef } from "react";
import { ActivityIndicator, View, AppState, Modal, Platform, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback } from "react-native";

import base64 from "base-64";

import { calculateCameraDimmensions, getCloudUri } from "../../../utils/cameraTools";

import DefaultIcon from "../../../screens/Dashboard/weather/defaultIcon";
import { t } from "../../../services/i18n";
//TU I NA DOLE ZMIEŃ DLA WEB
//import { WebView as RNWebView } from "react-native-webview";
import { WebView } from "WebView";

import { connect } from "react-redux";
import * as Linking from "expo-linking";
import { PrimaryColorText, SecondaryColorText } from "../../UI/styledComponents";
import CLOUD_CONSTANTS, { mainHost } from "../../../../CloudConstants";
import JanusVideoRoomScreen from "../../../screens/AudioVideo/JanusVideoRoomScreen";
import { TextInput } from "react-native-gesture-handler";
import { changeBlockDimensions } from "../../../store/actions";
import * as ScreenOrientation from "expo-screen-orientation";
const WebViewComponent = ({
  device,
  header,
  readOnly,
  params_device,
  currentConnection,
  gretingViewHeight,
  customWidth,
  authData,
  clientData,
  server,
  profile,
  deviceState,
  customUrl,
  dimensions,
  changeBlockDimensions,
}) => {
  const [proportions, setProportions] = useState(device.get("param1"));
  const [error, setError] = useState("");

  const [fullScreen, setFullScreen] = useState(false);
  const [currentOrientation, setCurrentOrientation] = useState(null);
  const [appState, setAppState] = useState(AppState.currentState);
  const [key, setKey] = useState(AppState.currentState);
  const [errorCount, setErrorCount] = useState(0);
  let columnWidth = dimensions.get("columnWidth");
  let width = dimensions.get("width");
  let height = dimensions.get("height");

  const ref = useRef();
  let backCount = 0;
  if (ref && isNaN(ref.current)) {
    ref.current = 0;
  }
  useEffect(() => {
    //to znaczy,  ze ktos juz to ustawial w shmie i nie biore default 1,33
    if (device.get("max") === 30 && device.get("min") === 10 && device.get("param1") === 1) {
      setProportions(1.33);
    }
  }, []);

  const handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active" || nextAppState === "background") {
      setError("");
      setAppState(nextAppState);
    }
    if (nextAppState == "active") {
      let randomNum = Math.random();
      setKey(`${nextAppState}${randomNum}`);
    }
  };

  useEffect(() => {
    const subscription = AppState.addEventListener("change", handleAppStateChange);
    return () => {
      subscription.remove();
    };
  });

  const ActivityIndicatorLoadingView = () => {
    return <ActivityIndicator color="#009688" size="large" style={styles.ActivityIndicatorStyle} />;
  };
  if (fullScreen) {
    let tmp = height;
    height = width;
    width = tmp;
    width -= 120;
    height -= 120 * proportions;
  }

  const { webViewWidth, webViewHeight } = calculateCameraDimmensions(columnWidth, width, proportions, gretingViewHeight, fullScreen);

  let headerHidden = params_device && (params_device.get("params") & 0x4000) > 0;

  const isURL = (str) => {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  };

  const refreshWebview = () => {
    let randomNum = Math.random();
    setError("");
    setKey(`${randomNum}`);
    //webViewRef.current.reload();
  };

  let uri = getCloudUri(device, profile, authData, clientData, currentConnection, params_device, customUrl);

  let errorRender = null;
  if (!isURL(uri)) {
    errorRender = "NOT A URL: " + uri;
  }
  if (customWidth) {
    columnWidth = width;
  }

  const getRNWebView = () => {
    //return null;
    // return (
    //   <RNWebView
    //     source={{ uri }}
    //     key={`${key}`}
    //     scrollEnabled={true}
    //     allowsInlineMediaPlayback={true}
    //     renderLoading={ActivityIndicatorLoadingView()}
    //     androidHardwareAccelerationDisabled={true}
    //     // originWhitelist={["*"]}
    //     style={
    //       !fullScreen
    //         ? {
    //             width: webViewWidth,
    //             borderWidth: 0.4,
    //             borderColor: "#ccc",
    //             height: webViewHeight,
    //             justifyContent: "center",
    //             alignItems: "center",
    //             opacity: 0.99,
    //             // flex: 1,
    //           }
    //         : {
    //             width: webViewWidth,
    //             borderWidth: 0.4,
    //             borderColor: "#ccc",
    //             backgroundColor: "transparent",
    //             height: webViewHeight,
    //             justifyContent: "center",
    //             alignItems: "center",
    //             opacity: 0.99,
    //           }
    //     }
    //     onError={({ nativeEvent }) => {
    //       setError(
    //         `Error: ${nativeEvent.description}  Code: ${nativeEvent.code}`
    //       );
    //     }}
    //     onLoadProgress={({ nativeEvent }) => {
    //       ref.current++;
    //     }}
    //     onHttpError={({ nativeEvent }) => {
    //       if (errorCount > 3 && ref.current < errorCount * 2) {
    //         setError(`Http Error: ${nativeEvent.statusCode}`);
    //       } else {
    //         let randomNum = Math.random();
    //         setKey(`${randomNum}`);
    //       }
    //       setErrorCount(errorCount + 1);
    //     }}
    //   />
    // );

    //console.log(uri);

    return (
      <WebView
        source={{ uri }}
        key={`${key}`}
        scrollEnabled={true}
        allowsInlineMediaPlayback={true}
        renderLoading={ActivityIndicatorLoadingView()}
        androidHardwareAccelerationDisabled={true}
        // originWhitelist={["*"]}
        style={
          !fullScreen
            ? {
                width: webViewWidth,
                borderWidth: 0.4,
                borderColor: "#ccc",
                height: webViewHeight,
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.99,
                // flex: 1,
              }
            : {
                width: webViewWidth,
                borderWidth: 0.4,
                borderColor: "#ccc",
                backgroundColor: "transparent",
                height: webViewHeight,
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.99,
              }
        }
        onError={({ nativeEvent }) => {
          setError(`Error: ${nativeEvent.description}  Code: ${nativeEvent.code}`);
        }}
        onLoadProgress={({ nativeEvent }) => {
          ref.current++;
        }}
        onHttpError={({ nativeEvent }) => {
          if (errorCount > 3 && ref.current < errorCount * 2) {
            setError(`Http Error: ${nativeEvent.statusCode}`);
          } else {
            let randomNum = Math.random();
            setKey(`${randomNum}`);
          }
          setErrorCount(errorCount + 1);
        }}
      />
    );
  };

  const enableFullScreen = async () => {
    if (Platform.OS === "android") {
      changeBlockDimensions(true);
      setCurrentOrientation(await ScreenOrientation.getOrientationAsync());
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
    }

    setFullScreen(true);
  };

  const disableFullScreen = async () => {
    if (Platform.OS === "android") {
      if (currentOrientation === ScreenOrientation.Orientation.PORTRAIT_UP) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      } else if (currentOrientation === ScreenOrientation.Orientation.PORTRAIT_DOWN) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_DOWN);
      } else if (currentOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
      } else if (currentOrientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
      }
      setTimeout(async () => {
        changeBlockDimensions(false);
      }, 500);
    }
    setFullScreen(false);
  };

  const getWebview = () => {
    let view;
    if (device.get("typ_komponentu") == "webview") {
      view = errorRender ? (
        <PrimaryColorText style={fullScreen ? { paddingTop: 100 } : { padding: 10 }}>{errorRender}</PrimaryColorText>
      ) : (
        <View
          pointerEvents={readOnly ? "none" : "auto"}
          style={
            !fullScreen
              ? {
                  height: webViewHeight,
                  maxHeight: gretingViewHeight ? gretingViewHeight / 2 - 40 : webViewHeight,
                }
              : {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  height: webViewHeight,
                  maxHeight: gretingViewHeight ? gretingViewHeight / 2 : webViewHeight,
                }
          }
        >
          {getRNWebView()}
        </View>
      );
    } else if (device.get("typ_komponentu") == "wekta") {
      view = (
        <JanusVideoRoomScreen
          uri={uri}
          deviceState={deviceState}
          style={{
            width: "100%",
            borderWidth: 0.4,
            borderColor: "#ccc",
            // flex: 1,
          }}
          onErr
        ></JanusVideoRoomScreen>
      );
    }

    if (appState == "active") {
      return view;
    } else {
      return null;
    }
  };

  if (customUrl) {
    return getRNWebView();
  }

  if (fullScreen && !errorRender) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Modal visible={true} supportedOrientations={["landscape"]}>
          <TouchableOpacity
            onPress={({}) => {
              disableFullScreen();
            }}
            style={{ alignSelf: "center", right: 5, position: "absolute", top: 5, zIndex: 1000 }}
          >
            <DefaultIcon name="minimize-2" library="Feather" size={24} />
          </TouchableOpacity>

          <View style={[styles.modal, { flexDirection: "row", alignItems: "center" }]}>{getWebview()}</View>
        </Modal>
      </View>
    );
  } else {
    return (
      <View
        style={{
          height: gretingViewHeight ? "100%" : headerHidden ? webViewHeight : webViewHeight + 30,
        }}
      >
        {!headerHidden && !customUrl ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 0,
              height: 30,
            }}
          >
            {header}

            <TouchableOpacity onPress={refreshWebview}>
              <SecondaryColorText style={{ fontStyle: "italic" }}>{t("REFRESH")}</SecondaryColorText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={({}) => {
                enableFullScreen();
              }}
            >
              <DefaultIcon name="resize" library="MaterialCommunityIcons" />
            </TouchableOpacity>
          </View>
        ) : null}

        <View>{getWebview()}</View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "99%",
    height: "99%",
  },
  ActivityIndicatorStyle: {
    flex: 1,
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    currentConnection: state.statesData.get("currentConnection"),
    clientData: state.statesData.get("clientData"),
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    server: state.statesData.get("server"),
    profile: state.profilesSettings.get(currentProfile),
    dimensions: state.statesData.get("dimensions"),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeBlockDimensions: (block) => dispatch(changeBlockDimensions(block)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebViewComponent);
