import React, { PureComponent } from "react";
import { View } from "react-native";
import * as Localization from "expo-localization";
import "moment/min/locales";
import {
  ComponentContainer,
  SecondaryColorText,
  PrimaryColorText,
} from "../../../components/UI/styledComponents";
import nameDays from "./nameDays";
import moment from "moment";

class CalendarComponent extends PureComponent {
  state = {
    time: new Date(),
    fontSize: 50,
  };

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000 * 60);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  onLayout = ({ nativeEvent }) => {
    let { width, height } = nativeEvent.layout;
    let fontSize = width / 6 < 80 ? width / 6 : 80;
    this.setState({ fontSize });
  };

  render() {
    const { time, fontSize } = this.state;
    let lang = Localization.locale.split("-")[0];
    var date = "";
    try {
      date = moment(time).locale(lang).format("D MMMM YYYY, dddd");
    } catch (ex) {
      date = moment(time).format("D MMMM YYYY, dddd");
    }

    let clockTime = moment(time).format("HH : mm");
    let dateForNameDay = moment(time).format("MM-DD");
    let namesElement = nameDays.find((el) => {
      return el.includes(dateForNameDay);
    });

    let names = namesElement
      ? namesElement.slice(namesElement.indexOf("|") + 2)
      : "";

    return (
      <ComponentContainer
        containerStyle={[
          { width: this.props.middleWidth ? "50%" : "100%" },
          this.props.containerStyle,
        ]}
        style={[
          {
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
          },
        ]}
        onLayout={this.onLayout}
      >
        <SecondaryColorText style={{ fontSize: fontSize / 3 }}>
          {date}
        </SecondaryColorText>
        <PrimaryColorText style={{ fontSize: fontSize, fontWeight: "bold" }}>
          {clockTime}
        </PrimaryColorText>
        {lang.startsWith("pl") ? (
          <SecondaryColorText>{names}</SecondaryColorText>
        ) : null}
      </ComponentContainer>
    );
  }
}

export default CalendarComponent;
