import React, { Component } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  Platform,
  I18nManager,
} from "react-native";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";
import * as WebBrowser from "expo-web-browser";
import { ScreenView } from "../../../components/UI/screenViewX";
import CLOUD_CONSTANTS from "../../../../CloudConstants";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import CryptoES from "crypto-es";
import {
  SwitchStyled,
  PrimaryColorText,
  ComponentContainerTouchable,
  ButtonPrimary,
  StyledIcon,
} from "../../../components/UI/styledComponents";
import mqttClient from "../../../services/mqtt";
import PickerSelect from "../../../components/UI/PickerSelect";

import { ListItemContainer } from "../styled";

import {
  changeAutoLogin,
  changeAutoLoginType,
  changeCloudConnectionAllowance,
  changeProfileAutomaticLogin,
  saveProfile,
} from "../../../store/actions";

class Drawer_AccountScreen extends Component {
  state = {
    autoLoginType: this.props.automaticLoginType
      ? this.props.automaticLoginType
      : this.props.automaticLogin,
    automaticLogin: this.props.automaticLogin,
  };

  onConnect = (source, loginDataForNewCloud) => {
    //console.log("On connect from secuirty");
  };
  addAssociation = () => {
    //console.log("Adding association");
    //<CloudIntegration onConnect={this.onConnect} />;
  };

  changeCloudConnectionAllowanceHandler = (value) => {
    this.setState({ allowCloud: value });
    // this.props.changeCloudConnectionAllowance(value);
    let message = value ? "1" : "0";
    mqttClient.allowCloudConnection(message);
  };

  profileAutomaticLoginChangeHandler = (value) => {
    this.props.onChangeProfileAutomaticLogin(value);
  };

  onAutologinChange = (value) => {
    let autoLog = true;
    if (value === false) {
      this.props.onChangeProfileAutomaticLogin(false);
      autoLog = false;
      this.props.onChangeProfileAutomaticLoginType(null);
    } else if (value === true) {
      this.props.onChangeProfileAutomaticLogin(true);
      this.props.onChangeProfileAutomaticLoginType(null);
    } else if (value) {
      this.props.onChangeProfileAutomaticLogin(true);
      this.props.onChangeProfileAutomaticLoginType(value);
    }

    this.setState({ autoLoginType: value, automaticLogin: autoLog });
  };

  render() {
    const { autoLoginType, automaticLogin } = this.state;
    const { profilesSettings } = this.props;
    let modes = [
      { label: t("LAST2"), value: true },
      { label: t("conditions:UNACTIVE"), value: false },
    ];

    profilesSettings.forEach((v, k) => {
      if (
        v &&
        k != "demo" &&
        k != "currentProfile" &&
        v.get("loginData") &&
        v.get("loginData").get("name")
      ) {
        modes.push({
          label: v.get("loginData").get("name"),
          value: v.get("loginData").get("name"),
        });
      }
    });

    var xhr = new XMLHttpRequest();
    async function urlToBlob(url) {
      return new Promise((resolve, reject) => {
        xhr.onerror = reject;
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            resolve(xhr.response);
          }
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    }

    var reader = new FileReader();
    async function readAsTextAsync(file) {
      return new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
          if (typeof reader.result === "string") {
            return resolve(reader.result);
          }
          return reject(
            `Unable to get result of file due to bad type, waiting string and getting ${typeof reader.result}.`
          );
        };
        reader.onerror = (e) => {
          console.warn("File reader error: " + e);
        };
        reader.onloadstart = (e) => {
          console.log("Starting Load");
        };
        reader.onprogress = (e) => {
          console.log("File reader prog: " + e.loaded);
          //NEVER CALLED!
        };
        reader.readAsBinaryString(file);
      });
    }

    return (
      <ScreenView>
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("LOGIN_AUTOMATICALLY")}
          </PrimaryColorText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <PickerSelect
              placeholder={{
                label: t("CHOOSE_OPTION"),
              }}
              containerStyle={{
                width: 120,
              }}
              items={modes}
              onValueChange={this.onAutologinChange}
              value={autoLoginType ? autoLoginType : automaticLogin}
            />
          </View>
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("account:IMPORT_PROFILES")}
          </PrimaryColorText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              style={{
                padding: 10,
              }}
              onPress={async () => {
                try {
                  let result;
                  if (Platform.OS === "ios") {
                    result = await DocumentPicker.getDocumentAsync();
                  } else {
                    result = await DocumentPicker.getDocumentAsync({
                      copyToCacheDirectory: false,
                    });
                  }

                  if (result && result.type === "success") {
                    let profilesData;
                    if (Platform.OS === "web") {
                      let blob = await urlToBlob(result.uri);
                      profilesData = await readAsTextAsync(blob);
                    } else if (Platform.OS === "ios") {
                      profilesData = await FileSystem.readAsStringAsync(
                        result.uri
                      );
                    } else {
                      let result2 = await FileSystem.copyAsync({
                        from: result.uri,
                        to: FileSystem.documentDirectory + result.name,
                      });
                      profilesData = await FileSystem.readAsStringAsync(
                        FileSystem.documentDirectory + result.name
                      );
                    }
                    let profilesJson = JSON.parse(profilesData);

                    Object.entries(profilesJson).forEach(([key, v]) => {
                      if (v && v.loginData && v.loginData.name) {
                        if (v.loginData.passwordEnc) {
                          v.loginData.password = CryptoES.AES.decrypt(
                            v.loginData.passwordEnc,
                            "QOji9fRDlc26FaKr"
                          ).toString(CryptoES.enc.Utf8);
                        }
                        this.props.onSaveProfile(
                          v.loginData.name,
                          v.loginData,
                          v.automaticLogin
                        );
                      }
                    });
                  }
                } catch (ex) {
                  console.log(ex);
                  alert(ex.message);
                }
              }}
            >
              <StyledIcon name={"download"} size={24} />
            </TouchableOpacity>
          </View>
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("account:EXPORT_PROFILES")}
          </PrimaryColorText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              style={{
                padding: 10,
              }}
              onPress={async () => {
                try {
                  let newArray = [];
                  profilesSettings.forEach((v, k) => {
                    try {
                      if (
                        v &&
                        v.get("loginData") &&
                        v.get("loginData").get("name") &&
                        v.get("loginData").get("name") !== "demo"
                      ) {
                        let profileToAdd = v.toJS();
                        profileToAdd.loginData.passwordEnc =
                          CryptoES.AES.encrypt(
                            profileToAdd.loginData.password,
                            "QOji9fRDlc26FaKr"
                          ).toString();
                        profileToAdd.loginData.password = "";
                        newArray.push(profileToAdd);
                      }
                    } catch (ex) {}
                  });
                  if (Platform.OS === "web") {
                    const bytes = new TextEncoder().encode(
                      JSON.stringify(newArray)
                    );
                    const blob = new Blob([bytes], {
                      type: "application/json;charset=utf-8",
                    });
                    let link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = "profiles.json";
                    document.body.appendChild(link);
                    link.dispatchEvent(
                      new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window,
                      })
                    );
                    link.remove();
                    URL.revokeObjectURL(link.href);
                  } else {
                    await FileSystem.writeAsStringAsync(
                      FileSystem.cacheDirectory + "profiles.json",
                      JSON.stringify(newArray)
                    );
                    Sharing.shareAsync(
                      FileSystem.cacheDirectory + "profiles.json"
                    );
                  }
                } catch (ex) {
                  console.log(ex);
                  alert(ex.message);
                }
              }}
            >
              <StyledIcon name={"upload"} size={24} />
            </TouchableOpacity>
          </View>
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("cloud:DELETE_ACCOUNT")}
          </PrimaryColorText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              style={{
                padding: 10,
              }}
              onPress={async () => {
                await WebBrowser.openBrowserAsync(
                  CLOUD_CONSTANTS.deleteAccountUrl
                );
              }}
            >
              <StyledIcon name={"trash"} size={24} />
            </TouchableOpacity>
          </View>
        </ListItemContainer>
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    automaticLogin: state.globalSettings.get("autoLogin"),
    automaticLoginType: state.globalSettings.get("autoLoginType"),
    profilesSettings: state.profilesSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeProfileAutomaticLogin: (automaticLogin) =>
      dispatch(changeAutoLogin(automaticLogin)),
    onChangeProfileAutomaticLoginType: (automaticLogin) =>
      dispatch(changeAutoLoginType(automaticLogin)),
    onSaveProfile: (oldName, loginData, automaticLogin) =>
      dispatch(saveProfile(oldName, loginData, automaticLogin)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drawer_AccountScreen);
