import React, { Component } from "react";
import { ActivityIndicator, KeyboardAvoidingView, View, TouchableOpacity, Text, TextInput, StyleSheet, ScrollView, Switch } from "react-native";
import { t } from "../../../services/i18n";
import * as Network from "expo-network";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";
import { SafeAreaView } from "react-native-safe-area-context";
import { store } from "../../../store/configureStore";
import io from "socket.io-client";
import { ThemeContext } from "../../../../ThemeContext";
import { useTheme } from "../../../../ThemeContext";
import { revokeAssociation, fetchAssociations } from "../CloudTools";
import moment from "moment";
const mqtt = require("mqtt");
import { Platform } from "react-native";
import * as LocalAuthentication from "expo-local-authentication";
import { saveProfile, deleteProfile, changeProfileAutomaticLogin } from "../../../store/actions";
import { ButtonPrimary, SwitchStyled } from "../../../components/UI/styledComponents";
import ModalSimple from "../../../components/UI/ModalSimple";

class ProfileDetailsScreen extends Component {
  static contextType = ThemeContext;

  state = {
    oldProfileName: "",
    profileData: {
      name: "",
      login: "",
      password: "",
      ip: "",
      ip2: "",
      cloudIp: "",
      cloudType: "",
      device_id: "",
      biometics: false,
    },
    cloudData: null,
    deleteDialogVisible: false,
    deleteingCloudOwner: false,
    findServerButtonVisible: true,
    findServerResults: "SEARCHING",
    automaticLogin: false,
  };

  componentDidMount() {
    let { navigation, route } = this.props;
    let { profileAndCloudData } = route.params;
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ paddingHorizontal: 10 }} onPress={() => this.saveProfile()}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Feather name={"save"} color={"#fff"} size={20} />
            <Text style={{ color: "#fff", paddingHorizontal: 5 }}>OK</Text>
          </View>
        </TouchableOpacity>
      ),
    });
    if (profileAndCloudData.profileData) {
      this.setState({
        profileData: profileAndCloudData.profileData,
        oldProfileName: profileAndCloudData.profileData.name,
        automaticLogin: profileAndCloudData.automaticLogin,
        cloudData: profileAndCloudData.cloudData,
      });
    }
  }
  saveProfile = () => {
    let { profileData, oldProfileName } = this.state;
    let { route } = this.props;
    let { name, login, password, ip, ip2 } = profileData;
    let { profileAndCloudData } = route.params;
    if (
      !(
        name &&
        ((login && password) ||
          (profileAndCloudData &&
            profileAndCloudData.cloudData &&
            profileAndCloudData.cloudData.local_user &&
            profileAndCloudData.cloudData.local_user.present)) &&
        (ip || profileAndCloudData.cloudData)
      )
    ) {
      alert(t("MISSING_DATA"));
    } else if (name != oldProfileName && this.props.profilesSettings.has(name)) {
      alert(t("PROFILE_NAME_EXIST"));
    } else {
      if (oldProfileName != name) {
        this.props.onDeleteProfile(oldProfileName);
        this.props.onSaveProfile(this.state.oldProfileName, this.state.profileData, this.state.automaticLogin);
      } else {
        this.props.onSaveProfile(this.state.oldProfileName, this.state.profileData, this.state.automaticLogin);
      }
      this.props.navigation.pop();
      //this.props.navigation.navigate("ProfilesList");
    }
  };

  onBiometricsChange = async (value) => {
    const result = await LocalAuthentication.authenticateAsync({ requireConfirmation: false });
    if (result && result.success) {
      this.setState((prevState) => ({
        profileData: {
          ...prevState.profileData,
          biometics: value,
        },
      }));
    }
  };

  onInputChange = (value, box) => {
    this.setState((prevState) => ({
      profileData: {
        ...prevState.profileData,
        [box]: value,
      },
    }));
  };

  someThingConnected = false;
  checkConnectionTimout = 0;

  serverSearch = async (ip) => {
    let nextStep = (connected) => {
      this.someThingConnected = true;
      connected;
      this.setState((prevState) => ({
        findServerResults: "SERVER_FOUND",
        profileData: {
          ...prevState.profileData,
          ip: ip,
        },
      }));
    };

    try {
      const response = await fetch(`http://${ip}:8060/`);
      if (response.headers.get("www-authenticate").includes("Ampio")) {
        if (this.checkConnectionTimout) {
          clearTimeout(this.checkConnectionTimout);
        }
        nextStep(true);
      }
    } catch (error) {}
  };
  checkCon = () => {
    this.setState({ findServerButtonVisible: false }, () => {
      this.networkSearch();
    });
  };

  handleDeleteProfileClose = () => {
    this.setState({ deleteDialogVisible: false, deleteingCloudOwner: false });
  };

  handleDeleteProfileOK = (type) => {
    let notOwner = false;
    const { authData } = this.props;
    if (type == 2) {
      if (this.state.cloudData && this.state.cloudData.association_id && this.state.cloudData.type !== "owner") {
        notOwner = true;
      } else if (this.state.cloudData && this.state.cloudData.type === "owner") {
        this.setState({
          deleteDialogVisible: false,
          deleteingCloudOwner: true,
        });
      }
    }
    if (type == 3 || notOwner) {
      if (this.state.cloudData && this.state.cloudData.association_id && authData) {
        revokeAssociation(authData.toJS(), this.state.cloudData.association_id);
        fetchAssociations(authData.toJS());
      }
    }
    if (type == 1 || type == 3 || notOwner) {
      this.setState({ deleteDialogVisible: false, deleteingCloudOwner: false }, () => {
        this.props.onDeleteProfile(this.state.oldProfileName);
        this.props.navigation.pop();
      });
    }
  };

  showDeleteProfileDialog = () => {
    this.setState({ deleteDialogVisible: true });
  };

  networkSearch = async () => {
    let afterPromise = (ip) => {
      if (ip) {
        let string = ip.substring(0, ip.lastIndexOf(".") + 1);
        let ipAdress;

        for (let i = 1; i <= 255; i++) {
          if (!this.someThingCOnnected) {
            ipAdress = string + i;
            this.serverSearch(ipAdress);
          }
        }

        this.checkConnectionTimout = setTimeout(() => {
          this.setState((prevState) => ({
            findServerResults: "SERVER_NOT_FOUND",
            profileData: {
              ...prevState.profileData,
              ip: "",
            },
          }));
        }, 20000);
      }
    };

    try {
      const ip = await Network.getIpAddressAsync();
      if (ip == "0.0.0.0") {
        this.setState((prevState) => ({
          findServerResults: "NOT_IN_LOCAL_NETWORK",
          findServerButtonVisible: true,
        }));
      } else {
        afterPromise(ip);
      }
    } catch (e) {
      console.log(e, "error in catch network");
    }

    // Network.getIpAddressAsync().then((ip) => {
    // 	afterPromise(ip);
    // });
  };

  profileAutomaticLoginChangeHandler = (value) => {
    this.setState({ automaticLogin: value });
    // this.props.onChangeProfileAutomaticLogin(value);
  };

  render() {
    let { profileData, oldProfileName, findServerButtonVisible, findServerResults, deleteDialogVisible, deleteingCloudOwner, cloudData } = this.state;
    const { theme } = this.context;
    const styles = StyleSheet.create({
      header: {
        paddingTop: 10,
        color: theme.TEXT_COLOR_SECONDARY,
        textTransform: "uppercase",
      },
      textInput: {
        width: "60%",
        textAlign: "right",
        color: theme.TEXT_COLOR_PRIMARY,
      },
      textSingle: {
        color: theme.TEXT_COLOR_PRIMARY,
      },
      singleLine: {
        color: theme.TEXT_COLOR_PRIMARY,
        borderBottomWidth: 0.5,
        borderBottomColor: "#ccc",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: 10,
      },
    });

    return (
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: -50,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        }}
      >
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }}>
          <ScrollView style={{ padding: 10 }}>
            <View style={[styles.singleLine]}>
              <Text style={[styles.header]}>{t("GENERAL_INFORMATION")}</Text>
            </View>
            <View style={[styles.singleLine]}>
              <Text style={styles.textSingle}>{t("LOGIN")}</Text>
              <TextInput
                style={styles.textInput}
                autoCapitalize={"none"}
                placeholder={t("LOGIN")}
                placeholderTextColor={theme.TEXT_COLOR_SECONDARY}
                value={profileData.login}
                onChangeText={(value) => this.onInputChange(value, "login")}
              />
            </View>
            <View style={[styles.singleLine]}>
              <Text style={styles.textSingle}>{t("PASSWORD")}</Text>
              <TextInput
                style={styles.textInput}
                placeholder={t("PASSWORD")}
                placeholderTextColor={theme.TEXT_COLOR_SECONDARY}
                secureTextEntry={true}
                value={profileData.password}
                onChangeText={(value) => this.onInputChange(value, "password")}
                autoCapitalize={"none"}
              />
            </View>
            <View style={[styles.singleLine]}>
              <Text
                style={[
                  styles.textSingle,
                  {
                    height: 30,
                    textAlignVertical: "center",
                    ...Platform.select({
                      ios: {
                        lineHeight: 30, // as same as height
                      },
                      android: {},
                    }),
                  },
                ]}
              >
                {t("REQUIRE_BIOMETRICS_TO_LOGIN")}
              </Text>
              <SwitchStyled value={profileData.biometics ? profileData.biometics : false} onValueChange={(value) => this.onBiometricsChange(value)} />
            </View>
            {profileData.device_id ? (
              <View style={[styles.singleLine]}>
                <Text style={styles.textSingle}>{t("SERVER_ID")}</Text>
                <Text style={styles.textSingle}>{profileData.device_id}</Text>
              </View>
            ) : null}
            <View style={[styles.singleLine]}>
              <Text style={[styles.header]}>{t("SERVER_AMPIO")}</Text>
            </View>
            <View style={[styles.singleLine]}>
              <Text style={styles.textSingle}>{t("NAME")}</Text>
              <TextInput
                style={styles.textInput}
                autoCapitalize={"none"}
                placeholder={t("NAME")}
                placeholderTextColor={theme.TEXT_COLOR_SECONDARY}
                value={profileData.name}
                onChangeText={(value) => this.onInputChange(value, "name")}
              />
            </View>

            <View style={[styles.singleLine]}>
              <Text style={styles.textSingle}>{t("HOST")}</Text>
              <TextInput
                style={styles.textInput}
                keyboardType={"numbers-and-punctuation"}
                placeholder={"IP"}
                placeholderTextColor={theme.TEXT_COLOR_SECONDARY}
                value={profileData.ip}
                onChangeText={(value) => this.onInputChange(value, "ip")}
              />
            </View>

            <View style={[styles.singleLine]}>
              <Text style={styles.textSingle}>{t("HOST_2")}</Text>
              <TextInput
                style={styles.textInput}
                keyboardType={"numbers-and-punctuation"}
                placeholder={"IP"}
                placeholderTextColor={theme.TEXT_COLOR_SECONDARY}
                value={profileData.ip2}
                onChangeText={(value) => this.onInputChange(value, "ip2")}
              />
            </View>
            {cloudData ? (
              <View>
                <View style={[styles.singleLine]}>
                  <Text style={[styles.header]}>{t("AMPIO_CLOUD_CONNECTION")}</Text>
                </View>
                <View style={[styles.singleLine]}>
                  <Text style={styles.textSingle}>{t("NAME")}</Text>
                  <Text style={styles.textSingle}>{cloudData.device.name ? cloudData.device.name : cloudData.alias}</Text>
                </View>
                <View style={[styles.singleLine]}>
                  <Text style={styles.textSingle}>{t("RELATION_TYPE")}</Text>
                  <Text style={styles.textSingle}>{cloudData.type}</Text>
                </View>
                <View style={[styles.singleLine]}>
                  <Text style={styles.textSingle}>{t("CREATION_TIME")}</Text>
                  <Text style={styles.textSingle}>{moment(cloudData.device.created).format("DD MMM YYYY, HH:mm:ss")}</Text>
                </View>
                <View style={[styles.singleLine]}>
                  <Text style={styles.textSingle}>{t("EXPIRATION_TIME")}</Text>
                  <Text style={styles.textSingle}>{cloudData.not_after ? cloudData.not_after : t("Indefinitely")}</Text>
                </View>
              </View>
            ) : null}
            <View
              style={{
                margin: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></View>
            <View style={{ alignItems: "center" }}>
              {findServerButtonVisible ? (
                <ButtonPrimary
                  style={{
                    paddingVertical: 5,
                    width: 200,
                  }}
                  onPress={() => this.checkCon()}
                >
                  {t("SEARCH_FOR_SERVER")}
                </ButtonPrimary>
              ) : null}
              {!findServerButtonVisible && findServerResults === "SEARCHING" ? (
                <View style={{ alignItems: "center" }}>
                  <Text style={styles.textSingle}>{t(`${findServerResults}`)}</Text>
                  <ActivityIndicator size="small" />
                </View>
              ) : null}
              {!findServerButtonVisible && findServerResults === "SERVER_FOUND" ? (
                <Text style={styles.textSingle}>{t(`${findServerResults}`)}</Text>
              ) : null}
              {!findServerButtonVisible && findServerResults === "SERVER_NOT_FOUND" ? (
                <Text style={styles.textSingle}>{t(`${findServerResults}`)}</Text>
              ) : null}
              {findServerResults === "NOT_IN_LOCAL_NETWORK" ? <Text style={styles.textSingle}>{t(`${findServerResults}`)}</Text> : null}
            </View>
            {oldProfileName ? (
              <View style={{ margin: 20, alignItems: "center" }}>
                <ButtonPrimary
                  style={{
                    paddingVertical: 5,
                    width: 200,
                    backgroundColor: "rgb(200,00,0)",
                  }}
                  onPress={this.showDeleteProfileDialog}
                >
                  {t("DELETE_PROFILE")}
                </ButtonPrimary>
              </View>
            ) : null}
            {deleteDialogVisible && (
              <ModalSimple
                isVisible={deleteDialogVisible}
                title={"DELETE_PROFILE_SURE"}
                onThirdOption={this.state.cloudData ? () => this.handleDeleteProfileOK(1) : null}
                thirdOptionText={this.state.cloudData ? t("ONLY_LOCAL") : null}
                onClose={this.handleDeleteProfileClose}
                onOK={this.state.cloudData ? () => this.handleDeleteProfileOK(2) : () => this.handleDeleteProfileOK(1)}
              />
            )}
            {deleteingCloudOwner && (
              <ModalSimple
                isVisible={deleteingCloudOwner}
                title={"INFO"}
                description={"DELETE_CLOUD_OWNER"}
                onClose={this.handleDeleteProfileClose}
                onOK={() => this.handleDeleteProfileOK(3)}
              />
            )}
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profilesSettings: state.profilesSettings,
    authData: state.cloudData ? state.cloudData.get("authData") : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveProfile: (oldName, profileData, automaticLogin) => dispatch(saveProfile(oldName, profileData, automaticLogin)),
    onDeleteProfile: (profileName) => dispatch(deleteProfile(profileName)),
    onChangeProfileAutomaticLogin: (automaticLogin) => dispatch(changeProfileAutomaticLogin(automaticLogin)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailsScreen);
