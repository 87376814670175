import * as Actions from "../actionTypes";
import { Map, fromJS, List } from "immutable";

const initialState = fromJS({
  primaryTheme: "ampio",
  themeBySchedule: "night",
  themeByScheduleEnabled: false,
  schedule: {
    start: "22:00",
    end: "10:00",
  },

  iconBGColors: {
    ampio: "rgb(255,255,255)",
    night: "rgb(128,128,128)",
    oled: "rgb(17,17,17)",
  },
  questionnaire: null,
  autoLogin: true,
  autoLoginType: null,
  appLogout: false,
  showBGForGroupIcons: false,
  showBGForDeviceIcons: false,
  showByColumns: true,
  weatherShown: true,
  calendarShown: true,
  simulationDashboardShown: false,
  deviceIdShown: false,
  forceLandscapeOrientation: false,
  forceInvertedLandscapeOrientation: false,
  dashboardTopBarShown: true,
  messagesIndicatorShown: true,
  keepAwakeOn: false,
  consentsStatus: 0,
  stretchSketch: false,
  disableClickVibrations: false,
  // showSketchFirst: false,
  sideMenuColor: "rgb(	51, 51, 51)",
  sideMenuSize: "short",
  sideMenuShown: false,
  sideMenuSetByUser: false,
  onlyMainGroupsShown: false,
  placesButtonWithSideMenuShown: false,
});

const globalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CHANGE_AUTO_LOGIN:
      return state.set("autoLogin", fromJS(action.payload));

    case Actions.SET_QUESTIONNAIRE_RESPONSE:
      return state.set("questionnaire", fromJS(action.payload));

    case Actions.CHANGE_AUTO_LOGIN_TYPE:
      return state.set("autoLoginType", fromJS(action.payload));

    case Actions.CHANGE_APP_LOGOUT:
      return state.set("appLogout", fromJS(action.payload));

    case Actions.CHANGE_USER_THEME:
      return state.set("primaryTheme", fromJS(action.payload));

    case Actions.CHANGE_THEME_BY_SCHEDULE:
      return state.set("themeBySchedule", fromJS(action.payload));

    case Actions.CHANGE_NIGHT_THEME_TURN_BY_SCHEDULE:
      return state.set("themeByScheduleEnabled", fromJS(action.payload));

    case Actions.CHANGE_GROUP_ICONS_BG_VISIBILITY:
      return state.set("showBGForGroupIcons", fromJS(action.payload));

    case Actions.CHANGE_DEVICE_ICONS_BG_VISIBILITY:
      return state.set("showBGForDeviceIcons", fromJS(action.payload));

    case Actions.SET_NIGHT_THEME_SCHEDULE:
      return state.set("schedule", fromJS(action.payload));

    case Actions.SAVE_ICON_BG_COLORS:
      return state.set("iconBGColors", fromJS(action.payload));

    case Actions.SAVE_SIDE_MENU_COLOR:
      return state.set("sideMenuColor", fromJS(action.payload));

    case Actions.SHOW_BY_COLUMNS:
      return state.set("showByColumns", fromJS(action.payload));

    case Actions.CHANGE_WEATHER_VISIBILITY:
      return state.set("weatherShown", fromJS(action.payload));

    case Actions.CHANGE_CALENDAR_VISIBILITY:
      return state.set("calendarShown", fromJS(action.payload));

    case Actions.CHANGE_GREETINIG_VIEW_VISIBILITY:
      return state.set("greetingViewShown", fromJS(action.payload));

    case Actions.CHANGE_SIDE_MENU_VISIBILITY:
      return state.set("sideMenuShown", fromJS(action.payload));

    case Actions.CHANGE_ONLY_MAIN_GROUPS_VISIBILITY:
      return state.set("onlyMainGroupsShown", fromJS(action.payload));

    case Actions.PLACES_BUTTON_WITH_SIDE_MENU_VISIBILITY:
      return state.set("placesButtonWithSideMenuShown", fromJS(action.payload));

    case Actions.SIDE_MENU_SET_BY_USER:
      return state.set("sideMenuSetByUser", fromJS(action.payload));

    case Actions.CHANGE_SIDE_MENU_SIZE:
      return state.set("sideMenuSize", fromJS(action.payload));

    case Actions.CHANGE_DEVICE_ID_VISIBILITY:
      return state.set("deviceIdShown", fromJS(action.payload));

    case Actions.CHANGE_FORCE_LANDSCAPE_ORIENTATION:
      return state.set("forceLandscapeOrientation", fromJS(action.payload));

    case Actions.CHANGE_INVERTED_FORCE_LANDSCAPE_ORIENTATION:
      return state.set(
        "forceInvertedLandscapeOrientation",
        fromJS(action.payload)
      );

    case Actions.CHANGE_DISABLE_CLICK_VIBRATIONS:
      return state.set("disableClickVibrations", fromJS(action.payload));

    case Actions.CHANGE_DASHBOARD_TOPBAR_VISIBILITY:
      return state.set("dashboardTopBarShown", fromJS(action.payload));

    case Actions.CHANGE_MESSAGES_INDICATOR_VISIBILITY:
      return state.set("messagesIndicatorShown", fromJS(action.payload));

    case Actions.CHANGE_SIMULATION_DASHBOARD_VISIBILITY:
      return state.set("simulationDashboardShown", fromJS(action.payload));

    case Actions.CHANGE_KEEP_AWAKE_SETTINGS:
      return state.set("keepAwakeOn", fromJS(action.payload));

    case Actions.CHANGE_STRETCH_SKETCH:
      return state.set("stretchSketch", fromJS(action.payload));

    case Actions.CHANGE_SHOW_SKETCH_FIRST:
      return state.set("showSketchFirst", fromJS(action.payload));
    case Actions.CHANGE_DEVICE_UUID:
      return state.set("deviceUUID", fromJS(action.payload));
    case Actions.CHANGE_CONSENTS_TIME:
      return state.set("consentsTime", fromJS(action.payload));
    case Actions.CHANGE_CONSENTS_STATUS:
      return state.set("consentsStatus", fromJS(action.payload));

    default:
      return state;
  }
};

export default globalSettingsReducer;
