import { Platform } from "react-native";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { store, persistor } from "../../store/configureStore";
import { associationsDataReceived, iceServersReceived, authDataReceived, metaDataReceived } from "../../store/actions";
import CLOUD_CONSTANTS from "../../../CloudConstants";

export const queryStringToObject = (url) => {
  // Extract the query string from the URL, limiting the split to 2 parts
  let queryString = url.split("?", 2)[1];
  if (!queryString) {
    return {};
  }

  // Rest of the function remains the same
  let pairs = queryString.split("&");
  let result = {};
  pairs.forEach((pair) => {
    let [key, value] = pair.split("=");
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return result;
};

export const fetchAccessToken = async (code) => {
  var params = {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `client_id=${CLOUD_CONSTANTS.client_id}&code=${code}&grant_type=authorization_code&redirect_uri=${CLOUD_CONSTANTS.redirect_uri}redirect`,
  };
  //return null;

  const response = await fetch(CLOUD_CONSTANTS.tokenUrl, params);
  const data = await response.json();
  if (data.error) throw data;
  let { access_token } = data;
  let decoded = jwt_decode(access_token);
  let authData = { ...data, exp: decoded.exp };
  await store.dispatch(authDataReceived(authData));
  await persistor.flush();

  return authData;
};

export const checkLocalTokenNeedRefresh = (timeToCheck) => {
  let currentTime = moment().unix();
  if (timeToCheck - currentTime < 60 * 5) {
    return true;
  }
  return false;
};

export const checkTokenNeedRefresh = (authData) => {
  if (authData && authData.get("access_token")) {
    let timeToCheck = authData.get("exp");
    let currentTime = moment().unix();
    //console.log("Token valid for " + (timeToCheck - currentTime) / 60 + " minutes");
    if (timeToCheck - currentTime < 60 * 5) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const refreshAccessToken = async (authData) => {
  let authDataFromFetch = null;
  let refreshToken = authData ? authData.get("refresh_token") : null;
  if (refreshToken) {
    let params = {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `client_id=${CLOUD_CONSTANTS.client_id}&grant_type=refresh_token&refresh_token=${refreshToken}`,
    };
    try {
      const response = await fetch(CLOUD_CONSTANTS.tokenUrl, params);
      const data = await response.json();
      if (data.error) throw data;
      let { access_token } = data;
      let decoded = jwt_decode(access_token);
      authDataFromFetch = { ...data, exp: decoded.exp };
    } catch (err) {
      if (err && (err.error === "invalid_token" || (err.error === "invalid_grant" && err.error_reason === "refresh_token_not_found"))) {
        store.dispatch(authDataReceived(null));
        store.dispatch(associationsDataReceived(null));
        store.dispatch(metaDataReceived(null));
        await persistor.flush();
      }
      if (err) {
        console.error("Error in revoke token CI " + err.error + " " + JSON.stringify(err) + " with " + refreshToken); // forbidden unauthorized
      } else {
        console.log("Error in revoke token CI " + err + " with " + refreshToken); // forbidden unauthorized
      }
    }
  }
  if (authDataFromFetch) {
    await store.dispatch(authDataReceived(authDataFromFetch));
    await persistor.flush();
  }

  return authDataFromFetch;
};

export const checkIfDeviceInAssociactions = async (authData, associationsData, device_id) => {
  let foundInAssociations = false;
  if (!associationsData && authData) {
    associationsData = await fetchAssociations(authData.toJS());
    if (associationsData && associationsData.associations) {
      associationsData = associationsData.associations;
    }
  } else if (associationsData) {
    associationsData = associationsData.toJS();
  }
  if (associationsData && Array.isArray(associationsData)) {
    associationsData.forEach((singleProfile, num) => {
      if (singleProfile.device_id == device_id) {
        foundInAssociations = true;
      }
    });
  } else {
    return false;
  }
  return foundInAssociations;
};

export const checkIfDeviceInAssociactionsNoDownload = (associationsData, device_id) => {
  let foundInAssociations = false;
  if (associationsData) {
    associationsData = associationsData.toJS();
  }
  if (associationsData && Array.isArray(associationsData)) {
    associationsData.forEach((singleProfile, num) => {
      if (singleProfile.device_id == device_id) {
        foundInAssociations = true;
      }
    });
  } else {
    return false;
  }
  return foundInAssociations;
};

export const remoteSupport = async (accessData, email, device_id) => {
  let not_after = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString();

  let params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessData.access_token}`,
    },
    body: JSON.stringify({
      association: {
        user_email: email,
        device_id: device_id,
        type: "support",
        not_after: not_after,
        local_user: {
          user_name: "admin",
        },
      },
    }),
  };
  try {
    const response = await fetch(`${CLOUD_CONSTANTS.assosiationUrl}`, params);
    const data = await response.json();
    return data;
  } catch (ex) {}
  return null;
};

export const updateAssociations = async (accessData, associationId, consent, alias) => {
  let params = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessData.access_token}`,
    },
    body: alias
      ? JSON.stringify({
          association: {
            consent: consent,
            alias: alias,
          },
        })
      : JSON.stringify({
          association: {
            consent: consent,
          },
        }),
  };
  try {
    const response = await fetch(`${CLOUD_CONSTANTS.assosiationUrl}/${associationId}`, params);
    const data = await response.json();
    return data;
  } catch (ex) {}
  return null;
};

export const revokeAssociation = async (accessData, associationId) => {
  let params = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessData.access_token}`,
    },
    body: JSON.stringify({
      association: {
        revoked: true,
      },
    }),
  };
  try {
    const response = await fetch(`${CLOUD_CONSTANTS.assosiationUrl}/${associationId}`, params);
    const data = await response.json();
    return data;
  } catch (ex) {}
  return null;
};

export const fetchIceServers = async (access_token) => {
  var params = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
  const response = await fetch(`${CLOUD_CONSTANTS.exchangeUrl}`, params);
  let data = await response.json();
  if (data.error) throw data;

  if (data) {
    data = { ...data, exp: moment().unix() + data.ttl };
    store.dispatch(iceServersReceived(data));
  }

  return data;
};

export const fetchLocalToken = async (access_token, device_id) => {
  var params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
  const response = await fetch(`${CLOUD_CONSTANTS.localTokenUrl}?with_token=true&device_id=${device_id}`, params);
  let data = await response.json();
  if (data.error) throw data;

  let decoded = jwt_decode(data.local_token);
  data = { ...data, exp: decoded.exp };

  return data;
};

export const fetchAssociations = async (accessData) => {
  let params = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessData.access_token}`,
    },
  };
  try {
    const response = await fetch(
      `${CLOUD_CONSTANTS.assosiationUrl}?applicable=true&pending=false&with_devices=true&user_id=${accessData.userId}`,
      params
    );
    const data = await response.json();

    const responseInvitation = await fetch(`${CLOUD_CONSTANTS.assosiationUrl}?pending=true&with_devices=true&user_id=${accessData.userId}`, params);
    const dataInvitations = await responseInvitation.json();
    if (data && data.associations) {
      if (dataInvitations && dataInvitations.associations) {
        store.dispatch(associationsDataReceived(data.associations.concat(dataInvitations.associations)));
      } else {
        store.dispatch(associationsDataReceived(data.associations));
      }
    }

    return data;
  } catch (ex) {}
  return null;
};

export const fetchMetaData = async (accessData) => {
  let params = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessData.access_token}`,
    },
  };
  try {
    const response = await fetch(`${CLOUD_CONSTANTS.metaDataUrl}`, params);
    const data = await response.json();
    if (data && data.user) {
      store.dispatch(metaDataReceived(data.user));
    }
    return data;
  } catch (ex) {}
  return null;
};
