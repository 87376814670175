const ampioColor = "#ED695C";
const ampioColorBlade = "rgba(237, 105, 92, 0.5)";
// const ampioColor = "#0946B8";
// const ampioColorBlade = "rgba(9, 70, 184, 0.5)";

const themesFile = {
  ampio: {
    TAB_BAR_TEXT_COLOR: "rgba(255, 255, 255, 0.5)",
    TAB_BAR_TEXT_COLOR_HIGHLITED: "#fff",
    TAB_BAR_BACKGROUND_COLOR: ampioColor,

    APP_BACKGROUND_COLOR: "#f4f4f4",
    COMPONENT_BACKGROUND_COLOR: "#fff",
    COMPONENT_BACKGROUND_COLOR_SECONDARY: "#fff",
    PLACEHOLDER_BACKGROUND_COLOR: "#f4f4f4",

    BUTTON_BACKGROUND_COLOR_PRIMARY: ampioColor,
    BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED: ampioColorBlade,
    BUTTON_BACKGROUND_COLOR_SECONDARY: "#FFF",

    TEXT_COLOR_PRIMARY: "#333333",
    TEXT_COLOR_SECONDARY: "#808080",
    AMPIO_COLOR: ampioColor,

    SLIDER_MIN: ampioColor,
    SLIDER_MAX: "#e4e4e4",
    SLICER_CIRCLE: "#FFFFFF",
    SLIDER_CIRCLE_BORDER: "#C1C1C1",

    SWITCH_OFF_BACKGROUND: "#DDD",

    ICONS_COLOR_PRIMARY: ampioColor,
    ICONS_COLOR_SECONDARY: "#808080",

    CHART_VALUE_MIN: "rgba(250, 85, 85, 0.5)",
    CHART_VALUE_MAX: "rgba(50, 200, 80, 0.5)",
    FONT_BOLD: "Montserrat_700Bold",
    FONT: "Montserrat_300Light",
  },
  night: {
    TAB_BAR_TEXT_COLOR: "rgba(255, 255, 255, 0.5)",
    TAB_BAR_TEXT_COLOR_HIGHLITED: "#fff",
    TAB_BAR_BACKGROUND_COLOR: "#484848",

    APP_BACKGROUND_COLOR: "#333",
    COMPONENT_BACKGROUND_COLOR: "#808080",
    COMPONENT_BACKGROUND_COLOR_SECONDARY: "#747474",
    PLACEHOLDER_BACKGROUND_COLOR: "#606060",

    BUTTON_BACKGROUND_COLOR_PRIMARY: ampioColor,
    BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED: ampioColorBlade,
    BUTTON_BACKGROUND_COLOR_SECONDARY: "#FFF",

    TEXT_COLOR_PRIMARY: "#FFF",
    TEXT_COLOR_SECONDARY: "#ccc",
    AMPIO_COLOR: "#484848",

    SLIDER_MIN: "#333333",
    SLIDER_MAX: "#606060",
    SLICER_CIRCLE: "#808080",
    SLIDER_CIRCLE_BORDER: "#E4E4E4",

    SWITCH_OFF_BACKGROUND: "#DDD",

    ICONS_COLOR_PRIMARY: "#FFF",
    ICONS_COLOR_SECONDARY: "#fff",

    CHART_VALUE_MIN: "#adadad",
    CHART_VALUE_MAX: "#151515",
    FONT_BOLD: "Montserrat_700Bold",
    FONT: "Montserrat_300Light",
  },
  oled: {
    TAB_BAR_TEXT_COLOR: "rgba(255, 255, 255, 0.5)",
    TAB_BAR_TEXT_COLOR_HIGHLITED: "#f1f1f1",
    TAB_BAR_BACKGROUND_COLOR: "#050505",

    APP_BACKGROUND_COLOR: "#050505",
    COMPONENT_BACKGROUND_COLOR: "#111",
    COMPONENT_BACKGROUND_COLOR_SECONDARY: "#333333",
    PLACEHOLDER_BACKGROUND_COLOR: "#606060",

    BUTTON_BACKGROUND_COLOR_PRIMARY: ampioColor,
    BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED: ampioColorBlade,
    BUTTON_BACKGROUND_COLOR_SECONDARY: "#FFF",

    TEXT_COLOR_PRIMARY: "#F1f1f1",
    TEXT_COLOR_SECONDARY: "#ccc",
    AMPIO_COLOR: "#484848",

    SLIDER_MIN: "#333333",
    SLIDER_MAX: "#606060",
    SLICER_CIRCLE: "#808080",
    SLIDER_CIRCLE_BORDER: "#E4E4E4",

    SWITCH_OFF_BACKGROUND: "#777",

    ICONS_COLOR_PRIMARY: ampioColor,
    ICONS_COLOR_SECONDARY: "#f1f1f1",

    CHART_VALUE_MIN: "#adadad",
    CHART_VALUE_MAX: "#f1f1f1",
    FONT_BOLD: "Montserrat_700Bold",
    FONT: "Montserrat_300Light",
  },
};

export default themesFile;
