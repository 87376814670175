import React, { useContext, useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import CLOUD_CONSTANTS, { mainHost, updateHost } from "../../CloudConstants";
import { View, Text, TouchableOpacity, Alert } from "react-native";
import { connect } from "react-redux";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { t } from "../services/i18n";
import jwt_decode from "jwt-decode";
import { About_AccountScreen, Drawer_AccountScreen } from "../screens";
import CloudIntegration from "../screens/Initial/CloudIntegration";
import { Platform } from "react-native";
import PickerSelect from "../components/UI/PickerSelect";
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

import * as SplashScreen from "expo-splash-screen";
import { useTheme } from "../../ThemeContext";
import { StyledIcon } from "../components/UI/styledComponents";

import { AppStackScreen, InitialStackScreen } from "./stacks";
import {
  associationsDataReceived,
  authDataReceived,
  saveProfile,
  changeUserTheme,
  changeAutoLogin,
  changeAutoLoginType,
  metaDataReceived,
} from "../store/actions";

function CustomDrawerContent(props) {
  const { theme, onThemeChange } = useTheme();

  const onCloseMode = (value) => {};

  const modes = [
    { label: "Ampio", value: "ampio" },
    { label: t("account:NIGHT"), value: "night" },
    { label: "Oled", value: "oled" },
  ];

  let {
    navigation,
    progress,
    logoutFunction,
    clearCloudProfiles,
    globalSettings,
    changeUserTheme,
    onChangeProfileAutomaticLogin,
    onChangeProfileAutomaticLoginType,
    authData,
    metaData,
    profiles,
    onSaveProfile,
    ...rest
  } = props;
  let userName;
  let email;

  let loginModes = [
    { label: t("LAST2"), value: true },
    { label: t("conditions:UNACTIVE"), value: false },
  ];

  profiles.forEach((v, k) => {
    if (
      v &&
      k != "demo" &&
      k != "currentProfile" &&
      v.get("loginData") &&
      v.get("loginData").get("name")
    ) {
      loginModes.push({
        label: v.get("loginData").get("name"),
        value: v.get("loginData").get("name"),
      });
    }
  });
  if (authData && metaData && metaData.get("fullName")) {
    userName = metaData.get("fullName");
    email = metaData.get("email");
  } else if (authData && metaData && metaData.get("firstName")) {
    userName = metaData.get("firstName");
    email = metaData.get("email");
    if (metaData && metaData.get("lastName")) {
      userName = userName + " " + metaData.get("lastName");
    }
  } else if (authData && authData.get("access_token")) {
    let decoded = jwt_decode(authData.get("access_token"));
    if (decoded) {
      userName = decoded.email;
    }
  }

  const [currentTheme, setCurrentTheme] = useState(
    globalSettings.get("primaryTheme")
  );

  const onModeValueChange = (value) => {
    if (value) {
      onThemeChange(value);
      setCurrentTheme(value);
      changeUserTheme(value);
    }
  };

  const changeNewCloudHost = () => {
    if (mainHost == "cloud.ampio.com") {
      updateHost("staging.ampio.com");
    } else {
      updateHost("cloud.ampio.com");
    }
    window.app.showToast("New cloud host: " + mainHost);
  };

  let allowCloud = true;
  if (Platform.OS === "web") {
    if (window.location.protocol == "http:") allowCloud = false;
  }

  return (
    <DrawerContentScrollView {...rest}>
      {userName ? (
        <View
          style={{
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
            marginTop: -500,
            paddingTop: 500,
            paddingBottom: 20,
            paddingLeft: 20,
          }}
        >
          <Text style={{ color: "white", fontSize: 16 }}>
            {t("cloud:LOGGED_AS")}
          </Text>
          <Text style={{ color: "white", fontSize: 20, fontWeight: "bold" }}>
            {userName}
          </Text>
          {email ? (
            <Text style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>
              {email}
            </Text>
          ) : null}
        </View>
      ) : (
        allowCloud && <CloudIntegration source="init" />
      )}
      <DrawerItemList {...props} />

      <DrawerItem
        label={"Demo"}
        onPress={() => {
          navigation.closeDrawer();
          if (window && window.profileScreen) {
            window.profileScreen.connectWithDemo();
          }
        }}
        icon={({ focused, size }) => (
          <MaterialCommunityIcons
            name="star"
            size={size}
            color={focused ? "blue" : "red"}
          />
        )}
      />
      <TouchableOpacity
        style={{ flex: 1 }}
        onLongPress={() => {
          changeNewCloudHost();
          navigation.closeDrawer();
        }}
      >
        <DrawerItem
          label={t("account:SUPPORT")}
          onPress={async () => {
            navigation.closeDrawer();

            if (window && window.profileScreen) {
              window.profileScreen.chooseRemoteSupport(1);
            }
          }}
          icon={({ focused, size }) => (
            <MaterialCommunityIcons
              name="headphones"
              size={size}
              color={focused ? "blue" : "red"}
            />
          )}
        />
      </TouchableOpacity>

      <View
        style={{
          marginLeft: 18,
          flexDirection: "row",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <MaterialCommunityIcons name="invert-colors" size={25} color={"red"} />
        <View style={{ flexDirection: "row", marginLeft: 25 }}>
          <PickerSelect
            placeholder={{
              label: t("CHOOSE_OPTION"),
            }}
            items={modes}
            onValueChange={onModeValueChange}
            additionalTextStyle={{ color: "#444" }}
            containerStyle={{
              width: 80,
              color: "#444",
            }}
            onClose={onCloseMode}
            inputStyle={{
              borderWidth: 0,
              backgroundColor: null,
              color: "#444",
            }}
            value={currentTheme}
          />
        </View>
      </View>

      {userName ? (
        <View>
          <DrawerItem
            label={t("cloud:LOGOUT")}
            onPress={() => {
              navigation.closeDrawer();
              logoutFunction(null);
              clearCloudProfiles(null);
              //navigation.goBack();
            }}
            icon={({ focused, size }) => (
              <MaterialCommunityIcons
                name="logout"
                size={size}
                color={focused ? "blue" : "red"}
              />
            )}
          />
        </View>
      ) : null}
    </DrawerContentScrollView>
  );
}

const Root = ({
  authData,
  metaData,
  logoutFunction,
  clearCloudProfiles,
  profiles,
  onSaveProfile,
  changeUserTheme,
  onChangeProfileAutomaticLogin,
  onChangeProfileAutomaticLoginType,
  globalSettings,
}) => {
  let auth = authData;
  let meta = metaData;

  const { theme } = useTheme();

  return (
    <Drawer.Navigator
      initialRouteName={InitialStackScreen}
      screenOptions={{
        headerShown: false,
        activeBackgroundColor: "#eee",
        drawerPosition: "right",
        labelStyle: { color: "#444" },
        drawerStyle: { backgroundColor: "#ccc" },
      }}
      drawerContent={(props) => (
        <CustomDrawerContent
          {...props}
          authData={auth}
          metaData={meta}
          logoutFunction={logoutFunction}
          clearCloudProfiles={clearCloudProfiles}
          globalSettings={globalSettings}
          profiles={profiles}
          onSaveProfile={onSaveProfile}
          changeUserTheme={changeUserTheme}
          onChangeProfileAutomaticLogin={onChangeProfileAutomaticLogin}
          onChangeProfileAutomaticLoginType={onChangeProfileAutomaticLoginType}
        />
      )}
    >
      <Drawer.Screen name={t("CLOSE")} component={InitialStackScreen} />
      <Drawer.Screen
        options={({ navigation, route }) => ({
          drawerIcon: ({ focused, size }) => (
            <MaterialCommunityIcons
              name="account"
              size={size}
              color={focused ? "blue" : "red"}
            />
          ),
          headerShown: true,
          headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
          headerTitleAlign: "center",
          headerStatusBarHeight: 50,
          headerLeft: () => null,
          headerStyle: {
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          },
          headerRight: () => {
            return (
              <TouchableOpacity
                style={{
                  padding: 10,
                }}
                onPress={() => navigation.goBack(null)}
              >
                <StyledIcon name={"x"} color={"tabBar"} size={24} />
              </TouchableOpacity>
            );
          },
        })}
        name={t("ACCOUNT")}
        component={Drawer_AccountScreen}
      />
      <Drawer.Screen
        options={({ navigation, route }) => ({
          drawerIcon: ({ focused, size }) => (
            <MaterialCommunityIcons
              name="information-outline"
              size={size}
              color={focused ? "blue" : "red"}
            />
          ),
          headerShown: true,
          headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
          headerTitleAlign: "center",
          headerStatusBarHeight: 50,
          headerLeft: () => null,
          headerStyle: {
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          },
          headerRight: () => {
            return (
              <TouchableOpacity
                style={{
                  padding: 10,
                }}
                onPress={() => navigation.goBack(null)}
              >
                <StyledIcon name={"x"} color={"tabBar"} size={24} />
              </TouchableOpacity>
            );
          },
        })}
        name={t("account:ABOUT")}
        component={About_AccountScreen}
      />
    </Drawer.Navigator>
  );
};

const AppContainer = ({
  appLocation,
  authData,
  metaData,
  logoutFunction,
  clearCloudProfiles,
  profiles,
  onSaveProfile,
  changeUserTheme,
  onChangeProfileAutomaticLogin,
  onChangeProfileAutomaticLoginType,
  globalSettings,
}) => {
  useEffect(() => {
    setTimeout(async () => {
      try {
        await SplashScreen.hideAsync();
      } catch (error) {}
    }, 500);
  }, [appLocation]);

  return (
    <SafeAreaProvider>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{ headerShown: false }}
          key={appLocation}
        >
          {appLocation == "auth" ? (
            <Stack.Screen name="Initial">
              {(props) => (
                <Root
                  {...props}
                  authData={authData}
                  metaData={metaData}
                  logoutFunction={logoutFunction}
                  clearCloudProfiles={clearCloudProfiles}
                  profiles={profiles}
                  onSaveProfile={onSaveProfile}
                  globalSettings={globalSettings}
                  changeUserTheme={changeUserTheme}
                  onChangeProfileAutomaticLogin={onChangeProfileAutomaticLogin}
                  onChangeProfileAutomaticLoginType={
                    onChangeProfileAutomaticLoginType
                  }
                />
              )}
            </Stack.Screen>
          ) : (
            <Stack.Screen name="App" component={AppStackScreen} />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    globalSettings: state.globalSettings,
    appLocation: state.statesData.get("appLocation"),
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    metaData: state.cloudData ? state.cloudData.get("metaData") : null,
    profiles: state.profilesSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutFunction: (authData) => dispatch(authDataReceived(authData)),
    clearCloudProfiles: (cloudProfiles) => {
      dispatch(associationsDataReceived(cloudProfiles));
      dispatch(metaDataReceived(null));
    },
    onSaveProfile: (oldName, loginData, automaticLogin) =>
      dispatch(saveProfile(oldName, loginData, automaticLogin)),
    changeUserTheme: (theme) => dispatch(changeUserTheme(theme)),
    onChangeProfileAutomaticLogin: (automaticLogin) =>
      dispatch(changeAutoLogin(automaticLogin)),
    onChangeProfileAutomaticLoginType: (automaticLogin) =>
      dispatch(changeAutoLoginType(automaticLogin)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
