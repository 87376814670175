import React, { Component, useEffect, useState } from "react";
import { Text, View, FlatList, Button, TouchableOpacity, StyleSheet, ScrollView, Keyboard, Platform } from "react-native";

import { createSelector } from "reselect";
import { changeSoundStatus, changeSelectedSoruces } from "../../store/actions";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";
import { SliderStyled } from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import { store } from "../../store/configureStore";
import { StyledIcon, TitleWithLines, PrimaryColorText } from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";
import { useTheme } from "../../../ThemeContext";

// import GoogleCast, { useDevices, useCastDevice } from "react-native-google-cast";

// import TrackPlayer, { AppKilledPlaybackBehavior, Capability, RepeatMode, Event, State } from "react-native-track-player";

const SourceChooseScreen = ({ navigation, devicesArray, changeSelectedSoruces, selectedSoruces, embedded }) => {
  const { theme } = useTheme();

  const [selected, setSelected] = useState({});

  const _closeScreen = (clean) => {
    if (clean) {
      changeSelectedSoruces({});
    } else {
      changeSelectedSoruces(selected);
    }
    const status = store.getState().statesData.get("soundStatus");
    if (status) {
      let playedOnDevice = false;
      Object.keys(selected).map((key) => {
        if (selected[key] === true) {
          let message = `/api/set/${key}/setIpRadioUri/${status.get("uri")}`;
          playedOnDevice = true;
          mqttClient.stateChangeToSend(message, key);
        } else if (selected[key] === false) {
          let message = `/api/set/${key}/setIpRadioButton/pause`;
          mqttClient.stateChangeToSend(message, key);
        }
      });
      if (playedOnDevice) {
        //TrackPlayer.pause();
      }
    }

    Keyboard.dismiss();
    navigation.goBack(null);
  };

  useEffect(() => {
    setSelected(selectedSoruces);
  }, []);

  const castDevice = useCastDevice();
  const castDevices = useDevices();
  const sessionManager = GoogleCast.getSessionManager();

  const googleCastContent = castDevices.map((device) => {
    const active = device.deviceId === castDevice?.deviceId;

    return (
      <TouchableOpacity
        style={{
          padding: 10,
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          // justifyContent: "space-between",
        }}
        key={device.deviceId}
        onPress={() => {
          active ? sessionManager.endCurrentSession() : sessionManager.startSession(device.deviceId);
          _closeScreen(!active);
        }}
      >
        <StyledIcon name={"tv"} size={24} />
        <PrimaryColorText style={{ marginLeft: 10, fontSize: 18 }}>{device.friendlyName}</PrimaryColorText>
      </TouchableOpacity>
    );
  });

  const itemSelected = (item, index) => {
    let copy = { ...selected };
    if (copy[item.get("id")]) {
      copy[item.get("id")] = !copy[item.get("id")];
    } else {
      copy[item.get("id")] = true;
    }
    setSelected(copy);
  };

  const onSlidingCompleteHandler = (volume, device) => {
    let message = `/api/set/${device.get("id")}/setVolume/${volume}`;
    mqttClient.stateChangeToSend(message, device.get("id"));
  };

  let content = (
    <ScrollView style={{ width: "100%", flex: 1 }}>
      {devicesArray.map((item, index) => {
        let state = 0;
        if (item.get("id") && store.getState().statesData.get("states").get(item.get("id"))) {
          state = Number(store.getState().statesData.get("states").get(item.get("id")).get("state"));
        }

        return (
          <View
            key={item.get("id")}
            style={{
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TouchableOpacity
              style={{
                padding: 10,
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                // justifyContent: "space-between",
              }}
              onPress={() => itemSelected(item, index)}
            >
              <Feather
                name={selected[item.get("id")] ? "check-circle" : "circle"}
                color={selected[item.get("id")] ? "rgb(50, 200, 80)" : "#ccc"}
                size={22}
              />
              <PrimaryColorText style={{ marginLeft: 10, fontSize: 18 }}>{item.get("opis_menu")}</PrimaryColorText>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                marginHorizontal: 10,
              }}
            >
              <SliderStyled
                value={state}
                maximumValue={item && item.get("param1") == 3 ? 60 : 100}
                onSlidingComplete={(value) => onSlidingCompleteHandler(value, item)}
              />
            </View>
          </View>
        );
      })}
    </ScrollView>
  );

  const fullView = null;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            _closeScreen();
          }}
        >
          <StyledIcon name={"x"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }, [selected]);

  return embedded ? fullView : <ScreenView>{fullView}</ScreenView>;
};

const selectDevices = (state) => state;

const devices = createSelector(selectDevices, (smartHomeData) => {
  const filteredItems = smartHomeData.filter((device) => {
    if (device) {
      return device.get("typ_komponentu") === "ip_radio";
    } else {
      return false;
    }
  });
  return filteredItems;
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  let possibleDevices = devices(state.smartHomeData.get(currentProfile).get("devices"));

  return {
    expoSound: state.statesData.get("expoSound"),
    selectedSoruces: state.statesData.get("selectedSoruces"),
    devicesArray: possibleDevices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSoundStatus: (status) => dispatch(changeSoundStatus(status)),
    changeSelectedSoruces: (status) => dispatch(changeSelectedSoruces(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceChooseScreen);
