import React, { Component } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Keyboard } from "react-native";
import { Feather } from "@expo/vector-icons";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";
import mqttClient from "../../../services/mqtt";

import { showAlertScreen } from "../../../store/actions";

import ModalSimple from "../../UI/ModalSimple";
import { PrimaryColorText } from "../../UI/styledComponents";

class SatelComponent extends Component {
  key = new Uint8Array([0x00, 0x01, 0x02, 0x03, 0x04, 0x05, 0x06, 0x07, 0x08, 0x09, 0x0a, 0x0b, 0x0c, 0x0d, 0x0e, 0x0f]);

  state = {
    pin: "",
    dialogVisible: false,
    action: "",
  };

  _evaluateState = (state) => {
    switch (state) {
      case "0":
        return "DISARMED"; //uzbroj
      case "1":
        return "ARMED"; //rozbroj
      case "2":
        //this.props.onShowAlertScreen(true);
        return "ALARM"; //rozbrij
      case "3":
        return "ALARM_DETECTION"; //dowole
      case "4":
        return "ARMING"; //dowolne
      default:
        return "UNKNOWN";
    }
  };

  _allowedAction = (state) => {
    switch (state) {
      case "0":
        return "ARM"; //uzbroj
      case "1":
        return "DISARM"; //rozbroj
      case "2":
        return "DISARM"; //rozbrij
      case "3":
        return "BOTH"; //dowole
      case "4":
        return "BOTH"; //dowolne
      default:
        return "UNKNOWN";
    }
  };

  armingHandler = (action) => {
    const { device } = this.props;
    if (device.get("haslo")) {
      this.handleAction(action, device.get("haslo"));
    } else {
      this.setState({ dialogVisible: true, action });
    }
  };

  onPinInputHandler = (value) => {
    this.setState({
      pin: value,
    });
  };
  showDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    Keyboard.dismiss();
    this.setState({ dialogVisible: false });
  };

  handleAction = (action, pin) => {
    const { device } = this.props;
    Keyboard.dismiss();
    let message = `/api/set/${device.get("id")}/${action}/${pin}`;
    mqttClient.stateChangeToSend(message, device.get("id"));
    this.setState({ dialogVisible: false });
  };

  handleOK = () => {
    this.handleAction(this.state.action, this.state.pin);
  };

  render() {
    const { deviceState, header, readOnly } = this.props;

    let stanDescription = this._evaluateState(deviceState.get("state"));
    let stanAction = this._allowedAction(deviceState.get("state"));

    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <ModalSimple
          isVisible={this.state.dialogVisible}
          title={"AUTH"}
          description={"PASS_PIN"}
          placeholder={"PASS_PIN"}
          secureTextEntry={true}
          onInputChange={this.onPinInputHandler}
          keyboardType={"phone-pad"}
          onClose={this.handleClose}
          onOK={this.handleOK}
        />
        <View style={{ flex: 1 }}>
          {header}
          <PrimaryColorText style={{ paddingLeft: 8 }}>({t(`satel:${stanDescription}`)})</PrimaryColorText>
        </View>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <TouchableOpacity
            disabled={readOnly || (stanAction === "ARM" || stanAction == "BOTH" ? false : true)}
            onPress={() => this.armingHandler("arm")}
          >
            <View
              style={[
                styles.button,
                {
                  backgroundColor: stanAction === "ARM" || stanAction == "BOTH" ? "rgb(50, 200, 80)" : "rgba(50, 200, 80, 0.2)",
                },
              ]}
            >
              <Feather name={"lock"} size={16} color={"white"} />
              <Text style={styles.text}>{t("satel:ARM")}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={readOnly || (stanAction == "DISARM" || stanAction == "BOTH" ? false : true)}
            onPress={() => this.armingHandler("disarm")}
          >
            <View
              style={[
                styles.button,
                {
                  marginRight: 0,
                  backgroundColor: stanAction == "DISARM" || stanAction == "BOTH" ? "rgb(250, 85, 85)" : "rgba(250, 85, 85, 0.2)",
                },
              ]}
            >
              <Feather name={"unlock"} size={16} color={"white"} />
              <Text style={styles.text}>{t("satel:DISARM")}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    margin: 5,
    width: 80,
    height: 30,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 3,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    onShowAlertScreen: (value) => dispatch(showAlertScreen(value)),
  };
};

export default connect(null, mapDispatchToProps)(SatelComponent);
