import React, { Component } from "react";
import { useTheme } from "../../../ThemeContext";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";

import {
  InitialScreen,
  ProfilesListScreen,
  ProfileDetailsScreen,
} from "../../screens";

import { t } from "../../services/i18n";

const InitialStack = createStackNavigator();

const InitialStackScreen = ({ navigation }) => {
  const { theme } = useTheme();

  return (
    <InitialStack.Navigator
      initialRouteName={InitialScreen}
      screenOptions={{
        ...TransitionPresets.SlideFromRightIOS,
        headerTitleAlign: "center",
        headerMode: "float",
        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          // backgroundColor: "#0946B8",
        },
        headerTintColor: "#fff",
      }}
    >
      <InitialStack.Screen
        name="Initial"
        component={InitialScreen}
        options={{ title: "Main", headerShown: false }}
      />
      <InitialStack.Screen
        name="ProfilesList"
        component={ProfilesListScreen}
        options={{
          title: t("PROFILES"),
          headerLeft: null,
          animationEnabled: false,
        }}
      />
      <InitialStack.Screen
        name="ProfileDetails"
        component={ProfileDetailsScreen}
        options={({ route }) => ({
          title: route.params.profileName
            ? route.params.profileName
            : t("PROFILE"),
        })}
      />
    </InitialStack.Navigator>
  );
};

export default InitialStackScreen;
