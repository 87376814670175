import { mediaDevices, MediaStream, RTCIceCandidate, RTCPeerConnection, RTCSessionDescription, RTCView } from "react-native-webrtc-web-shim";
import React from "react";
import { connect } from "react-redux";
import { Audio } from "expo-av";
import { ActivityIndicator, AppState, View, ImageBackground, Platform } from "react-native";
import { store } from "../../store/configureStore";
import Janus from "../../services/janus/Janus";
import JanusPlugin from "../../services/janus/utils/JanusPlugin";
import InCallManager from "react-native-incall-manager";
import mqttClient from "../../services/mqtt";
import { CameraUniversalComponent } from "../../components/smartHomeComponents";
import { t } from "../../services/i18n";
import { ButtonPrimary, PrimaryColorText } from "../../components/UI/styledComponents";
import { ThemeContext } from "../../../ThemeContext";
import { min } from "moment";

Janus.setDependencies({
  RTCPeerConnection,
  RTCSessionDescription,
  RTCIceCandidate,
  MediaStream,
});

class JanusSipComponent extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.janus = null;
    this.sipPlugin = null;
    this.mediaStream = null;
    this.dtmfSender = null;

    try {
      if (this.props.params_device.get("url")) {
        this.sipData = JSON.parse(this.props.params_device.get("url").trim());
        if (this.sipData) {
          if (!this.sipData.username.startsWith("sip:")) this.sipData.username = "sip:" + this.sipData.username;

          if (!this.sipData.callee.startsWith("sip:")) this.sipData.callee = "sip:" + this.sipData.callee;
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.state = {
      stream: null,
      videoStream: null,
      isCalling: false,
      appState: AppState.currentState,
      sipConnected: false,
      noPermissions: null,
      audioPerm: null,
    };
  }

  getMediaStream = async (AudioPerm) => {
    if (AudioPerm && AudioPerm.status === "granted") {
      let stream = await mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      this.mediaStream = stream;
      await this.initJanus(stream);
    } else {
      this.setState({
        noPermissions: true,
      });
    }
  };

  open = async () => {
    let message = `/api/set/${this.sipData.obj}/setValue/255/${this.sipData.objTime}`;
    mqttClient.stateChangeToSend(message, this.sipData.obj);

    this.sipPlugin.pc;
  };

  hangUp = async () => {
    const startResponse = await this.sipPlugin.sendAsync({
      request: "hangup",
    });
  };

  speaker = async () => {
    if (Platform.OS != "web") {
      InCallManager.setSpeakerphoneOn(true);
    }
  };

  makeCall = async () => {
    let offer = await this.sipPlugin.pc.createOffer({
      media: {
        audioSend: true,
        audioRecv: true,
        videoSend: true,
        videoRecv: true,
      },
    });
    if (offer && offer.sdp && offer.type) {
      await this.sipPlugin.pc.setLocalDescription(offer);

      const startResponse = await this.sipPlugin.sendAsyncWithJsep(
        {
          request: "call",
          uri: this.sipData.callee,
        },
        { sdp: offer.sdp, type: offer.type }
      );
    }
  };

  initJanus = async (stream) => {
    this.setState((state) => ({
      sipConnected: false,
    }));

    try {
      this.janus = this.props.janus;
      if (this.janus && this.sipData && this.sipData.username && this.sipData.password) {
        this.sipPlugin = new JanusPlugin("janus.plugin.sip", this.janus);
        this.sipPlugin.onMessage = async (message) => {
          if (
            message &&
            message.plugindata &&
            message.plugindata.data &&
            message.plugindata.data.result &&
            message.plugindata.data.result.event == "hangup"
          ) {
            if (message.plugindata.data.result.code == 503 || message.plugindata.data.result.code == 486) {
              this.setState({ isBusy: true });
              setTimeout(() => {
                this.setState({ isBusy: false });
              }, 5000);
            } else if (this.state.isCalling) {
              InCallManager.stop();
              this.deinitJanus(this.state.appState, true);
              await this.sipPlugin.createPeer();
              await this.sipPlugin.connect();
              await this.sipPlugin.sendAsync({
                request: "register",
                username: this.sipData.username,
                secret: this.sipData.password,
              });
            }
          } else if (
            message &&
            message.plugindata &&
            message.plugindata.data &&
            message.plugindata.data.result &&
            message.plugindata.data.result.event == "ringing"
          ) {
            this.setState((state) => ({
              isCalling: true,
            }));
          } else if (
            message &&
            message.plugindata &&
            message.plugindata.data &&
            message.plugindata.data.result &&
            message.plugindata.data.result.event == "registered"
          ) {
            this.setState((state) => ({
              sipConnected: true,
            }));
            console.log("Register to SIP");
            this.sipPlugin.pc.addStream(stream);
          } else if (
            message &&
            message.plugindata &&
            message.plugindata.data &&
            message.plugindata.data.result &&
            message.plugindata.data.result.event == "incomingcall"
          ) {
            console.log("Incoming call");
            // const timer = setTimeout(async () => {
            //   await this.sipPlugin.pc.setRemoteDescription(
            //     new Janus.RTCSessionDescription({
            //       sdp: message.jsep.sdp,
            //       type: message.jsep.type,
            //     })
            //   );

            //   let answer = await this.sipPlugin.pc.createAnswer({
            //     media: {
            //       audio: true,
            //       video: true,
            //     },
            //   });

            //   if (answer && answer.sdp && answer.type) {
            //     await this.sipPlugin.pc.setLocalDescription(answer);

            //     const startResponse = await this.sipPlugin.sendAsyncWithJsep(
            //       {
            //         request: "accept",
            //       },
            //       { sdp: answer.sdp, type: answer.type }
            //     );
            //   }
            // }, 1);
          } else if (
            message &&
            message.jsep &&
            message.plugindata &&
            message.plugindata.data &&
            message.plugindata.data.result &&
            (message.plugindata.data.result.event == "progress" || message.plugindata.data.result.event == "accepted")
          ) {
            this.setState((state) => ({
              isCalling: true,
            }));
            await this.sipPlugin.pc.setRemoteDescription(
              new Janus.RTCSessionDescription({
                sdp: message.jsep.sdp,
                type: message.jsep.type,
              })
            );
          }
        };
        this.sipPlugin.setOnStreamListener((stream) => {
          if (Platform.OS != "web") {
            InCallManager.start({ media: "audio" });
            InCallManager.setForceSpeakerphoneOn(true);

            setTimeout(() => {
              InCallManager.setSpeakerphoneOn(true);
            }, 100);
          }

          this.setState((state) => ({
            stream: stream,
          }));
        });

        this.sipPlugin.setOnWebRTCUpListener(async () => {
          console.log("RTCUp Listener");
        });

        await this.sipPlugin.createPeer();
        await this.sipPlugin.connect();

        const createStreamingResponse = await this.sipPlugin.sendAsync({
          request: "register",
          username: this.sipData.username,
          secret: this.sipData.password,
        });
      }
    } catch (e) {
      console.error("main", JSON.stringify(e));
    }
  };

  async componentDidMount() {
    this.appStateSubscription = AppState.addEventListener("change", this._handleAppStateChange);
    const AudioPerm = await Audio.requestPermissionsAsync();

    this.setState({ audioPerm: AudioPerm });
    await this.getMediaStream(AudioPerm);
  }

  componentWillUnmount = async () => {
    this.appStateSubscription.remove();
    this.deinitJanus("background");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.janus == null && nextProps.janus) {
      this.getMediaStream(this.state.audioPerm);
    }
  }

  deinitJanus = async (nextAppState, onlySip) => {
    if (this.janus && this.janus.connectionID === store.getState().statesData.get("janusId")) {
      if (this.mediaStream) {
        //this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      if (this.state.stream) {
        this.state.stream.getTracks().forEach((track) => track.stop());
      }
      if (this.state.videoStream && !onlySip) {
        this.state.videoStream.getTracks().forEach((track) => track.stop());
      }

      if (this.sipPlugin && this.sipPlugin.pc) {
        this.sipPlugin.pc.close();
      }

      this.setState({
        appState: nextAppState,
        stream: null,
        sipConnected: false,
        isCalling: false,
        isBusy: false,
      });
    }
  };

  _handleAppStateChange = async (nextAppState) => {
    if (this.state.appState === "background" && nextAppState === "active") {
      this.setState({ appState: nextAppState });
      await this.getMediaStream(this.state.audioPerm);
    } else if (this.state.appState === "active" && nextAppState == "background") {
      await this.deinitJanus(nextAppState);
    }
  };

  renderView() {}

  render() {
    const { device, header, deviceState, currentConnection, currentTheme } = this.props;
    const { theme } = this.context;
    const { noPermissions } = this.state;

    return AppState.currentState === "active" ? (
      <View>
        {this.sipData && this.sipData.rtsp ? <CameraUniversalComponent {...this.props} /> : header}
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <View
            style={[
              {
                backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                flex: 1,
              },
              this.props.style,
            ]}
          >
            {this.state.stream ? (
              <RTCView
                style={{
                  flex: 1,
                  marginTop: this.sipData.onlyAudio ? 0 : 10,
                  height: this.sipData.onlyAudio ? 1 : 200,
                }}
                objectFit={"fill"}
                stream={Platform.OS === "web" ? this.state.stream : null}
                streamURL={this.state.stream && Platform.OS !== "web" ? this.state.stream.toURL() : null}
              />
            ) : null}

            {this.state.videoStream ? (
              <ImageBackground
                // source={require("../../../assets/activity.gif")}
                style={{
                  backgroundColor: "transparent",
                  flex: 1,
                }}
                imageStyle={{
                  resizeMode: "contain",
                }}
              >
                <View
                  style={[
                    {
                      backgroundColor: "transparent",
                      flexDirection: "row",
                      height: 200,
                    },
                    this.props.style,
                  ]}
                >
                  <RTCView
                    style={{
                      flex: 1,
                      backgroundColor: "transparent",
                    }}
                    objectFit={"fill"}
                    stream={Platform.OS === "web" ? this.state.videoStream : null}
                    streamURL={this.state.videoStream && Platform.OS !== "web" ? this.state.videoStream.toURL() : null}
                  />
                </View>
              </ImageBackground>
            ) : null}

            {this.state.sipConnected ? (
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                    width: "100%",
                  },
                ]}
              >
                {!this.state.stream ? (
                  this.state.isCalling ? (
                    <ButtonPrimary onPress={this.hangUp}>{t("CALLING")}</ButtonPrimary>
                  ) : (
                    <ButtonPrimary onPress={this.makeCall}>{this.state.isBusy ? t("BUSY") : t("CALL")}</ButtonPrimary>
                  )
                ) : null}
                {this.state.stream ? (
                  <View>
                    <ButtonPrimary onPress={this.hangUp}>{t("HANGUP")}</ButtonPrimary>
                  </View>
                ) : null}

                {this.sipData.objTime && this.sipData.obj ? (
                  <View
                    style={[
                      {
                        marginLeft: 10,
                      },
                    ]}
                  >
                    <ButtonPrimary onPress={this.open}>{t("OPEN")}</ButtonPrimary>
                  </View>
                ) : null}
              </View>
            ) : (
              <View>
                {noPermissions || currentConnection === "cloud" ? (
                  <PrimaryColorText style={{ paddingBottom: 4 }}>
                    {noPermissions ? t("NO_PERMISSIONS_TO_MICROPHONE") : t("ONLY_WORKS_USING_CLOUD_AMPIO_COM")}
                  </PrimaryColorText>
                ) : (
                  <ActivityIndicator
                    style={[
                      {
                        marginTop: 10,
                      },
                    ]}
                    size="large"
                  />
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    currentConnection: state.statesData.get("currentConnection"),
    janus: state.statesData.get("janus"),
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    server: state.statesData.get("server"),
    profile: state.profilesSettings.get(currentProfile),
    currentTheme: state.globalSettings.get("primaryTheme"),
  };
};

export default connect(mapStateToProps, null)(JanusSipComponent);
