import React, { Component } from "react";
import { useNavigation } from "@react-navigation/native";
import { connect } from "react-redux";
import AdjustableTextToFitSketch from "./AdjustableTextToFitSketch";

import { View, Text, TouchableOpacity, Image } from "react-native";

const GroupComponent = ({
	group,
	showSketchFirst,
	viewWidth,
	styleToPass,
	showGroupDesc,
	stretchSketch,
	icon
}) => {
	const navigation = useNavigation();
	return (
		<TouchableOpacity
			onPress={() => {
				navigation.push("Devices_Places", {
					group,
					fromGroupComponent: true
				});
			}}
			key={group.get("id")}
			style={[styleToPass]}
		>
			{icon ? (
				<Image
					source={{ uri: `data:image/png;base64,${icon.get("wartoscB")}` }}
					resizeMode={stretchSketch ? "stretch" : "contain"}
					style={{ width: "100%", height: "100%" }}
				/>
			) : null}
			{showGroupDesc ? (
				<AdjustableTextToFitSketch
					styleToPass={styleToPass}
					addStyle={{
						position: "absolute",
						left: 0,
						top: styleToPass.height / 2
					}}
				>
					{group.get("opis_menu")}
				</AdjustableTextToFitSketch>
			) : null}
		</TouchableOpacity>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		group: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("groups")
			.get(ownProps.groupId + 1),
		icon: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("icons")
			.get(ownProps.iconId),
		showSketchFirst: state.globalSettings.get("showSketchFirst")
	};
};

export default connect(mapStateToProps)(GroupComponent);
