import React, { useEffect } from "react";
import { View, Animated, StyleSheet, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import DefaultIcon from "../../../screens/Dashboard/weather/defaultIcon";
import { PrimaryColorText } from "../../UI/styledComponents";
import { useTheme } from "../../../../ThemeContext";

const EnergyComponent = ({ deviceState, header }) => {
  const animatedArrowPosition = new Animated.Value(0);
  const { theme } = useTheme();
  const arrowAnimation = Animated.loop(
    Animated.sequence([
      Animated.timing(animatedArrowPosition, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: false,
      }),
    ])
  );

  useEffect(() => {
    arrowAnimation.start();
  }, [deviceState]);

  return (
    <View style={{ height: "100%" }}>
      <View>{header}</View>
      <View style={styles.container}>
        <View style={styles.triangleContainer}>
          <View style={styles.triangleRow}>
            <View style={[styles.iconContainer, { marginBottom: 50 }]}>
              <DefaultIcon name="solar-power" library="MaterialCommunityIcons" size={48} />
              <PrimaryColorText style={styles.valueText}>{(deviceState.get("pv") / 1000).toFixed(1) + " kW"}</PrimaryColorText>
            </View>
          </View>
          <View style={styles.triangleRow}>
            <View style={[styles.iconContainer, { marginRight: 75 }]}>
              <DefaultIcon name="flash" library="MaterialCommunityIcons" size={48} />
              <PrimaryColorText style={styles.valueText}>{((deviceState.get("pg") * -1) / 1000).toFixed(1) + " kW"}</PrimaryColorText>
            </View>
            <View style={[styles.iconContainer, { marginLeft: 75 }]}>
              <DefaultIcon name="home-lightning-bolt" library="MaterialCommunityIcons" size={48} />
              <PrimaryColorText style={styles.valueText}>{(deviceState.get("ht") / 1000).toFixed(1) + " kW"}</PrimaryColorText>
            </View>
          </View>
          <View style={styles.lineContainerRight}>
            <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
            <View style={styles.arrowContainer}>
              <Animated.View
                style={[
                  styles.arrow,
                  {
                    transform: [
                      {
                        translateX: animatedArrowPosition.interpolate({
                          inputRange: [0, 1],
                          outputRange: [-120, -20],
                        }),
                      },
                    ],
                  },
                ]}
              >
                {deviceState.get("pv") > 0 && <Ionicons name="chevron-forward-outline" size={24} color={theme.ICONS_COLOR_PRIMARY} />}
              </Animated.View>
            </View>
          </View>

          <View style={styles.lineContainerLeft}>
            <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
            <View style={styles.arrowContainer}>
              <Animated.View
                style={[
                  styles.arrow,
                  {
                    transform: [
                      {
                        translateX: animatedArrowPosition.interpolate({
                          inputRange: [0, 1],
                          outputRange: [-120, -20],
                        }),
                      },
                    ],
                  },
                ]}
              >
                {deviceState.get("tg") > 0 && deviceState.get("pv") > 0 && (
                  <Ionicons name="chevron-forward-outline" size={24} color={theme.ICONS_COLOR_PRIMARY} />
                )}
              </Animated.View>
            </View>
          </View>

          <View style={styles.lineContainerBottom}>
            <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
            <View style={styles.arrowContainer}>
              <Animated.View
                style={[
                  styles.arrow,
                  {
                    transform: [
                      {
                        translateX: animatedArrowPosition.interpolate({
                          inputRange: [0, 1],
                          outputRange: [-120, -20],
                        }),
                      },
                    ],
                  },
                ]}
              >
                {deviceState.get("fg") > 0 && <Ionicons name="chevron-forward-outline" size={24} color={theme.ICONS_COLOR_PRIMARY} />}
              </Animated.View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  triangleContainer: {
    alignItems: "center",
    marginBottom: 20,
  },
  triangleRow: {
    flexDirection: "row",
  },
  iconContainer: {
    alignItems: "center",
    marginHorizontal: 10,
  },
  valueText: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: "bold",
  },
  lineContainerLeft: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 50,
    left: 20,
    width: 130,
    height: 100,
    transform: [{ rotate: "125deg" }],
  },
  lineContainerRight: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 50,
    left: 150,
    width: 130,
    height: 100,
    transform: [{ rotate: "55deg" }],
  },
  lineContainerBottom: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 50,
    left: 95,
    width: 130,
    height: 230,
  },
  line: {
    flex: 1,
    height: 1,
  },
  arrowContainer: {
    position: "relative",
    width: 24,
    height: 24,
  },
  arrow: {
    position: "absolute",
  },
});

export default EnergyComponent;
