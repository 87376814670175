import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import ActionSheet from "../../../utils/ActionSheet";
import * as Updates from "expo-updates";

import { ListItemContainer } from "../styled";
import { ScreenView } from "../../../components/UI/screenViewX";
import { PrimaryColorText, SecondaryColorText, StyledIcon, TitleWithLines } from "../../../components/UI/styledComponents";

import mqttClient from "../../../services/mqtt";

class Versions_AccountScreen extends Component {
  state = {
    serverUpdateStatus: `${t("UPDATE_AVAILABLE")}. ${t("account:CLICK_TO_START")}`,
    showWebActionSheet: false,
    serverUpdateAvailable: false,
    appUpdateAvailable: "checking",
  };

  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  handleServerUpdate = () => {
    mqttClient.askForServerUpdate("query");
    this.setState({ serverUpdateStatus: t("account:WAITING") });
  };

  checkForAppUpdates = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      this.setState({ appUpdateAvailable: update.isAvailable });
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
      }
    } catch (e) {
      this.setState({ appUpdateAvailable: false });
      console.log(e, "err in update");
    }
  };

  handleAppUpdate = async () => {
    await Updates.reloadAsync();
  };

  componentDidMount() {
    if (!__DEV__) {
      this.checkForAppUpdates();
    }
    const { server } = this.props;
    let serverInfo = server.get("serverInfo");
    if (serverInfo.get("update") && (serverInfo.get("update") & 0x01) > 0) {
      this.setState({
        serverUpdateAvailable: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.server.get("serverUpdateInfo") && nextProps.server.get("serverUpdateInfo") != this.state.serverUpdateStatus) {
      this.setState({
        serverUpdateAvailable: true,
      });
      this.handleUpdateInfo(nextProps.server.get("serverUpdateInfo"));
    }
  };

  doAction = (index) => {
    if (index == 0) {
      mqttClient.askForServerUpdate("query");
    } else if (index == 1) {
      mqttClient.askForServerUpdate("test");
    }
  };

  handleOnPressAction = (index) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false }, () => {
        this.doAction(index);
      });
    } else {
      this.doAction(index);
    }
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
  };

  handleUpdateInfo = (serverUpdateInfo) => {
    let message = "";
    switch (serverUpdateInfo) {
      case "START":
        message = t("account:START");
        break;
      case "UPDATE_OK":
        message = t("account:UPDATE_OK");
        break;
      case "ERROR_RENAME":
        message = t("account:ERROR_RENAME");
        break;
      case "INVALID_MD5":
        message = t("account:INVALID_MD5");
        break;
      case "NO_UPDATE":
        message = t("account:NO_UPDATE");
        break;
      default:
        message = `${serverUpdateInfo}%`;
    }
    this.setState({
      serverUpdateStatus: message,
    });
  };

  render() {
    const { server } = this.props;
    let serverInfo = server.get("serverInfo");

    const { serverUpdateStatus, appUpdateAvailable, serverUpdateAvailable } = this.state;
    let serverVersion = serverInfo.get("serverVersion") ? serverInfo.get("serverVersion") : "0";

    let permissionForServerUpdates = (serverInfo.get("perm") & 0x08) == 0;
    return (
      <ScreenView>
        <TitleWithLines textInside={t("account:SERVER")} />
        <ListItemContainer>
          <PrimaryColorText
            style={{
              fontStyle: "italic",
            }}
          >
            {t("account:CURRENT_SERVER_VERSION")}
          </PrimaryColorText>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
            }}
          >
            {serverVersion}
          </PrimaryColorText>
        </ListItemContainer>

        {permissionForServerUpdates ? (
          <ListItemContainer style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <ActionSheet
              actionRef={(o) => (this.ActionSheet = o)}
              options={[t("UPDATE_TO_STABLE_VERSION"), t("UPDATE_TO_TEST_VERSION"), t("CLOSE")]}
              cancelButtonIndex={2}
              onPress={(index) => {
                this.handleOnPressAction(index);
              }}
              onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
              showWebActionSheet={this.state.showWebActionSheet}
            />

            <TouchableOpacity
              onPress={this.showActionSheet}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: -5,
                paddingBottom: 5,
              }}
            >
              <StyledIcon color="secondary" size={14} name="more-vertical" />

              <PrimaryColorText
                style={{
                  fontStyle: "italic",
                }}
              >
                {t("account:UPDATE_OF_SERVER")}
              </PrimaryColorText>
            </TouchableOpacity>

            {serverUpdateAvailable ? (
              <TouchableOpacity onPress={this.handleServerUpdate}>
                <SecondaryColorText
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  {serverUpdateStatus}
                </SecondaryColorText>
              </TouchableOpacity>
            ) : (
              <SecondaryColorText
                style={{
                  fontStyle: "italic",
                }}
              >
                {t("account:EVERYTHING_UP_TO_DATE")}
              </SecondaryColorText>
            )}
          </ListItemContainer>
        ) : null}
        <TitleWithLines textInside={t("account:MOBILE_APP")} />
        <ListItemContainer>
          <PrimaryColorText
            style={{
              fontStyle: "italic",
            }}
          >
            {t("account:CURRENT_APP_VERSION")}
          </PrimaryColorText>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
            }}
          >
            Ver. 1.7.7
          </PrimaryColorText>
        </ListItemContainer>
        <ListItemContainer style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <PrimaryColorText
            style={{
              fontStyle: "italic",
            }}
          >
            {t("account:UPDATE_OF_APP")}
          </PrimaryColorText>

          {appUpdateAvailable === true ? (
            <TouchableOpacity onPress={this.handleAppUpdate}>
              <SecondaryColorText
                style={{
                  fontStyle: "italic",
                }}
              >
                {t("UPDATE_AVAILABLE")}. {t("account:CLICK_TO_START")}
              </SecondaryColorText>
            </TouchableOpacity>
          ) : (
            <SecondaryColorText
              style={{
                fontStyle: "italic",
              }}
            >
              {appUpdateAvailable == "checking" ? t("account:CHECKING_VERSION") : t("account:EVERYTHING_UP_TO_DATE")}
            </SecondaryColorText>
          )}
        </ListItemContainer>
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    server: state.statesData.get("server"),
  };
};
export default connect(mapStateToProps, null)(Versions_AccountScreen);
