import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  TextInput,
  Platform,
  Button,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import RGBPicker from "./RGBPicker";

import moment from "moment";
import PickerSelect from "../../../components/UI/PickerSelect";
import {
  SwitchStyled,
  TitleWithLines,
  SecondaryColorText,
  PrimaryColorText,
} from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";

import DateTimePicker from "../../../components/UI/dateTimePicker";

import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from "react-native-simple-radio-button";

import { ThemeContext } from "../../../../ThemeContext";
import {
  changeUserTheme,
  changeThemeBySchedule,
  changeThemeTurnByScheduleSwitch,
  changeGroupIconsBGVisibility,
  changeDeviceIconsBGVisibility,
  setNightThemeSchedule,
  saveIconBGColors,
} from "../../../store/actions";

import { ListItemContainer } from "../styled";

const originalColor = {
  ampio: "rgb(255,255,255)",
  night: "rgb(128,128,128)",
  oled: "rgb(17,17,17)",
};

class Colors_PersonalizationScreen extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.radio_props2 = [
      { label: "Ampio", value: "ampio" },
      { label: t("account:NIGHT"), value: "night" },
      { label: "Oled", value: "oled" },
    ];
    this.state = {
      modalVisible: false,
      currentTheme: this.props.globalSettings.get("primaryTheme"),
      themeBySchedule: this.props.globalSettings.get("themeBySchedule")
        ? this.props.globalSettings.get("themeBySchedule")
        : "night",
      themeByScheduleSwitch: this.props.globalSettings.get(
        "themeByScheduleEnabled"
      ),
      schedule: {
        start: this.props.globalSettings.get("schedule").get("start"),
        end: this.props.globalSettings.get("schedule").get("end"),
      },
      colorForPicker: "",
      themeForPicker: "",
      iconBGColors: originalColor,
    };
  }

  componentDidMount() {
    const { globalSettings } = this.props;
    let iconBGColors = {};
    if (globalSettings.get("iconBGColors")) {
      let iconBGColorsFromGlobal = globalSettings.get("iconBGColors");
      iconBGColors = {
        ampio: iconBGColorsFromGlobal.get("ampio"),
        night: iconBGColorsFromGlobal.get("night"),
        oled: iconBGColorsFromGlobal.get("oled"),
      };
    } else {
      iconBGColors = originalColor;
    }

    let schedule = {
      start: this.props.globalSettings.get("schedule").get("start"),
      end: this.props.globalSettings.get("schedule").get("end"),
    };
    if (
      typeof globalSettings.get("schedule").get("end") == "string" ||
      typeof globalSettings.get("schedule").get("start") == "string"
    ) {
      schedule.start = moment().subtract(5, "hours").toDate();
      schedule.end = moment().toDate();
      this.props.onSetNightThemeSchedule(schedule);
    }

    this.setState({ iconBGColors, schedule });
  }

  //100 ok
  onChoseCurrentThemeHandler = (value) => {
    this.setState({ currentTheme: value, themeByScheduleSwitch: false }, () => {
      this.props.onChangeUserTheme(value);
      this.context.onThemeChange(value);
      this.props.onChangeThemeTurnByScheduleSwitch(false);
    });
  };

  onTimeChange = (value, key) => {
    let schedule = Object.assign({}, this.state.schedule);

    schedule[key] = value;

    this.setState({ schedule }, () => {
      this.props.onSetNightThemeSchedule(this.state.schedule);
      let theme = this.checkTime();
      this.props.onChangeUserTheme(theme);
      this.context.onThemeChange(theme);
    });
  };

  themeByScheduleSwitchHandler = (value) => {
    this.setState({ themeByScheduleSwitch: value }, () => {
      this.props.onChangeThemeTurnByScheduleSwitch(value);
      if (this.state.themeByScheduleSwitch) {
        let theme = this.checkTime();
        this.props.onChangeUserTheme(theme);
        this.context.onThemeChange(theme);
      } else {
        this.props.onChangeUserTheme(this.state.currentTheme);
        this.context.onThemeChange(this.state.currentTheme);
      }
    });
  };

  onThemeByScheduleChange = (value) => {
    this.setState({ themeBySchedule: value }, () => {
      if (Platform.OS !== "ios") {
        this.props.onChangeThemeBySchedule(value);
        if (this.state.themeByScheduleSwitch) {
          let theme = this.checkTime();
          this.props.onChangeUserTheme(theme);
          this.context.onThemeChange(theme);
        }
      }
    });
  };

  onThemeByScheduleClose = () => {
    if (Platform.OS === "ios") {
      this.props.onChangeThemeBySchedule(this.state.themeBySchedule);
      if (this.state.themeByScheduleSwitch) {
        let theme = this.checkTime();
        this.props.onChangeUserTheme(theme);
        this.context.onThemeChange(theme);
      }
    }
  };

  changeIconsBGVisibilityHandler = (value, target) => {
    if (target == "group") {
      this.props.onChangeGroupIconsBGVisibility(value);
    } else if (target == "device") {
      this.props.onChangeDeviceIconsBGVisibility(value);
    }
    const { globalSettings } = this.props;

    if (!globalSettings.get("iconBGColors")) {
      this.props.onSaveIconBGColors(this.state.iconBGColors);
    }
  };

  checkTime = () => {
    let { schedule } = this.state;
    let { start, end } = schedule;
    start = moment(start).format("HH:mm");
    end = moment(end).format("HH:mm");
    let theme = "";

    var currentTime = moment().format("HH:mm");
    if (start < end) {
      if (currentTime > start && currentTime < end) {
        //wybierz z themeByChadeley
        theme = this.state.themeBySchedule;
      } else {
        theme = this.state.currentTheme;
      }
    } else {
      if (currentTime > end && currentTime < start) {
        theme = this.state.currentTheme;
      } else {
        theme = this.state.themeBySchedule;
      }
    }

    return theme;
  };

  setModalVisible = (theme, color) => {
    this.setState(
      {
        colorForPicker: color,
        themeForPicker: theme,
      },
      () => {
        this.setState({ modalVisible: true });
      }
    );
  };

  onCloseModal = (mode, color) => {
    if (mode == "save") {
      let iconBGColors = Object.assign({}, this.state.iconBGColors);
      iconBGColors[this.state.themeForPicker] = color;

      this.props.onSaveIconBGColors(iconBGColors);

      this.setState({
        colorForPicker: "",
        themeForPicker: "",
        modalVisible: false,
        iconBGColors,
      });
    } else if (mode == "close") {
      this.setState({
        colorForPicker: "",
        themeForPicker: "",
        modalVisible: false,
      });
    }
  };

  onResetColor = (theme) => {
    let iconBGColors = Object.assign({}, this.state.iconBGColors);
    iconBGColors[theme] = originalColor[theme];

    this.props.onSaveIconBGColors(iconBGColors);

    this.setState({ iconBGColors });
  };

  render() {
    const {
      schedule,
      currentTheme,
      themeByScheduleSwitch,
      themeBySchedule,
      modalVisible,
      colorForPicker,
    } = this.state;
    const { theme } = this.context;
    const { start, end } = schedule;
    const { globalSettings } = this.props;
    const showBGForGroupIcons = globalSettings.get("showBGForGroupIcons");
    const showBGForDeviceIcons = globalSettings.get("showBGForDeviceIcons");

    const bgColorsForIconsContent = this.radio_props2.map((el) => {
      let color = this.state.iconBGColors[el.value];
      return (
        <ListItemContainer key={el.value}>
          <PrimaryColorText>{el.label}</PrimaryColorText>
          <TouchableOpacity onPress={() => this.onResetColor(el.value)}>
            <SecondaryColorText
              style={{
                fontSize: 12,
                fontStyle: "italic",
                textAlign: "center",
                paddingRight: 10,
              }}
            >
              {t("account:RESET_TO_TRANSPARENT")}
            </SecondaryColorText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.setModalVisible(el.value, color)}
            style={{
              backgroundColor: color,
              height: 24,
              width: 24,
              borderRadius: 24,
              borderWidth: 1,
              borderColor: "#ED695C",
              paddingRight: 5,
            }}
          />
        </ListItemContainer>
      );
    });

    return (
      <ScreenView>
        {modalVisible && (
          <RGBPicker
            title={t("account:BG_FOR_ICONS")}
            modalVisible={modalVisible}
            onCloseModal={this.onCloseModal}
            colorRGB={colorForPicker}
          />
        )}
        <TitleWithLines textInside={t("account:STYLE")} />

        <RadioForm
          style={{
            width: "100%",
          }}
        >
          {this.radio_props2.map((obj, i) => {
            return (
              <RadioButton
                key={i}
                wrapStyle={{
                  padding: 10,
                  width: "100%",
                  display: "flex",
                  marginTop: 5,
                  backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,

                  paddingHorizontal: 10,
                  paddingVertical: 5,
                }}
              >
                <RadioButtonLabel
                  obj={obj}
                  index={i}
                  onPress={this.onChoseCurrentThemeHandler}
                  labelStyle={{
                    fontSize: 16,
                    color: theme.TEXT_COLOR_PRIMARY,
                  }}
                  labelWrapStyle={{ flex: 1 }}
                />
                <RadioButtonInput
                  obj={obj}
                  index={i}
                  isSelected={currentTheme === obj.value}
                  onPress={this.onChoseCurrentThemeHandler}
                  borderWidth={1}
                  buttonInnerColor={
                    currentTheme == obj.value
                      ? theme.TEXT_COLOR_PRIMARY
                      : theme.TEXT_COLOR_SECONDARY
                  }
                  buttonOuterColor={
                    currentTheme == obj.value
                      ? theme.TEXT_COLOR_PRIMARY
                      : theme.TEXT_COLOR_SECONDARY
                  }
                  buttonSize={12}
                  buttonOuterSize={24}
                  buttonStyle={{}}
                  buttonWrapStyle={{ marginRight: 5 }}
                />
              </RadioButton>
            );
          })}
        </RadioForm>
        <ListItemContainer>
          <PrimaryColorText>
            {t("account:SWITCH_TO_THEME_BY_SCHEDULE")}
          </PrimaryColorText>
          <SwitchStyled
            value={themeByScheduleSwitch}
            onValueChange={this.themeByScheduleSwitchHandler}
          />
        </ListItemContainer>
        {themeByScheduleSwitch ? (
          <ListItemContainer style={{ flexDirection: "column" }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <PrimaryColorText style={{ marginRight: 15 }}>
                {t("account:SWITCH_TO_THEME")}:
              </PrimaryColorText>
              <PickerSelect
                placeholder={{
                  label: t("SELECT_MODE"),
                  value: null,
                }}
                containerStyle={{ width: 100 }}
                items={this.radio_props2}
                onValueChange={this.onThemeByScheduleChange}
                onClose={this.onThemeByScheduleClose}
                value={themeBySchedule}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <DateTimePicker
                value={moment(start).format("HH:mm")}
                date={start}
                onConfirm={(time) => this.onTimeChange(time, "start")}
                mode="time"
                style={{ flex: 1 }}
              />
              <View style={{ marginRight: 20 }} />
              <DateTimePicker
                value={moment(end).format("HH:mm")}
                date={end}
                onConfirm={(time) => this.onTimeChange(time, "end")}
                mode="time"
                style={{ flex: 1 }}
              />
            </View>
          </ListItemContainer>
        ) : null}
        <TitleWithLines textInside={t("account:BG_FOR_ICONS")} />
        <ListItemContainer>
          <PrimaryColorText>{t("account:BG_FOR_GROUP")}</PrimaryColorText>
          <SwitchStyled
            value={showBGForGroupIcons}
            onValueChange={(value) =>
              this.changeIconsBGVisibilityHandler(value, "group")
            }
          />
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText>{t("account:BG_FOR_DEVICE")}</PrimaryColorText>
          <SwitchStyled
            value={showBGForDeviceIcons}
            onValueChange={(value) =>
              this.changeIconsBGVisibilityHandler(value, "device")
            }
          />
        </ListItemContainer>
        {bgColorsForIconsContent}
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalSettings: state.globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeUserTheme: (theme) => dispatch(changeUserTheme(theme)),
    onChangeThemeBySchedule: (theme) => dispatch(changeThemeBySchedule(theme)),
    onChangeThemeTurnByScheduleSwitch: (bool) =>
      dispatch(changeThemeTurnByScheduleSwitch(bool)),
    onChangeGroupIconsBGVisibility: (bool) =>
      dispatch(changeGroupIconsBGVisibility(bool)),
    onChangeDeviceIconsBGVisibility: (bool) =>
      dispatch(changeDeviceIconsBGVisibility(bool)),
    onSetNightThemeSchedule: (schedule) =>
      dispatch(setNightThemeSchedule(schedule)),
    onSaveIconBGColors: (colorsObject) =>
      dispatch(saveIconBGColors(colorsObject)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Colors_PersonalizationScreen);
