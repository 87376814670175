const scenesForScenes = (data, order) => {
	let filteredArray = null;
	if (data) {
		filteredArray = data;

		let scenesGeneralOrder = order && order.get("scenes");
		if (scenesGeneralOrder) {
			filteredArray = filteredArray.map((scene) => {
				let inOrderListIndex = scenesGeneralOrder.findIndex((el) => {
					return el == scene.get("id");
				});
				if (inOrderListIndex == -1) {
					inOrderListIndex = undefined;
				}
				scene = scene.set("orderNumber", inOrderListIndex);
				return scene;
			});
			filteredArray = filteredArray.sort((x, y) => {
				return x.get("orderNumber") - y.get("orderNumber");
			});
		}
	}

	return filteredArray;
};

export default scenesForScenes;
