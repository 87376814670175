import React, { Component, useEffect, useState } from "react";
import { FlatList, StyleSheet, View, I18nManager, Image, TouchableOpacity } from "react-native";
import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from "expo-av";
import { connect } from "react-redux";
import { Platform } from "react-native";
import { t } from "../../../services/i18n";
import ModalWithContent from "../../../components/UI/ModalWithContent";
import { PrimaryColorText } from "../../../components/UI/styledComponents";
import MultiplePickerSelectObjects from "../../Scenes/MultiplePickerSelectObjects";
import { ButtonPrimary } from "../../../components/UI/styledComponents";
import mqttClient from "../../../services/mqtt";
import { ComponentContainerTouchable, StyledIcon, ComponentHeaderText } from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";

import ActionSheet from "../../../utils/ActionSheet";

import { changeExpoSound, changeSoundStatus } from "../../../store/actions";

// components

const AudioLibrary_AccountScreen = ({ expoSound, changeExpoSound, changeSoundStatus, navigation, route, devices }) => {
  // const isAirplayConnected = Platform.OS === "ios" ? useAirplayConnectivity() : null;
  // const isExternalPlaybackAvailable = Platform.OS !== "web" ? useExternalPlaybackAvailability() : null;
  // const client = Platform.OS !== "web" ? useRemoteMediaClient() : null;

  // WEB ZAKOMENTUJ
  //const castState = useCastState();
  const [state, setState] = useState(null);
  const [sound, setSound] = useState(expoSound);
  const [isLoading, setIsLoading] = useState(false);
  const [presetId, setPresetId] = useState(null);
  const [actionSheet, setActionSheet] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);

  async function playSound(radio, device) {
    if (!isLoading) {
      setIsLoading(true);
      setPresetId(radio.preset_id);
      fetch(radio.URL)
        .then((response) => response.text())
        .then(async (textResponse) => {
          const source = {
            uri: textResponse.split("\n")[0].replace(/(\r\n|\n|\r)/gm, ""),
          };
          console.log("Loading Sound", source.uri);
          if (!device) {
            const radioInfo = {
              title: radio.text,
              subtitle: radio.subtext,
              image: radio.image,
            };

            if (client) {
              await client.loadMedia({
                mediaInfo: {
                  contentUrl: source.uri,
                  metadata: {
                    images: [
                      {
                        url: radio.image,
                      },
                    ],
                    title: radio.text,
                    subtitle: radio.subtext,
                  },
                  streamType: "live",
                },
              });
            } else {
              await Audio.setAudioModeAsync({
                allowsRecordingIOS: false,
                staysActiveInBackground: true,
                interruptionModeIOS: InterruptionModeIOS.DoNotMix,
                playsInSilentModeIOS: true,
                shouldDuckAndroid: true,
                interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
              });
              if (sound) {
                sound.unloadAsync();
              }

              const { sound } = await Audio.Sound.createAsync(source, null, (status) => {
                changeSoundStatus({ ...radioInfo, ...status });
              });

              setSound(sound);
              changeExpoSound(sound);
              await sound.playAsync();
            }
          } else {
            let message = `/api/set/${device.value}/setIpRadioUri/${source.uri}`;
            //console.log(message, mqttClient);
            mqttClient.stateChangeToSend(message, device.value);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }

  React.useEffect(() => {
    return sound
      ? () => {
          //sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  const handleOnPressAction = (index) => {
    if (index == 0) {
      setModalVisible(true);
    }
  };

  const getRadios = () => {
    let devicesArray = [];
    devices.map((device, index) => {
      if (device) {
        let typKomponentu = device.get("typ_komponentu");
        if (typKomponentu == "ip_radio") {
          devicesArray.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      }
    });
    return devicesArray;
  };

  const showActionSheet = (item) => {
    setSelectedRadio(item);
    if (Platform.OS != "web") {
      actionSheet.show();
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const onDeviceStreamValueChange = (device) => {
    setModalVisible(false);
    device.map((el, key) => {
      if (el && typeof el === "object") {
        playSound(selectedRadio, el);
      }
    });
    setSelectedRadio(null);
  };

  const getItemView = (item) => {
    return (
      <ComponentContainerTouchable
        key={item.URL}
        onLongPress={() => {}}
        onPress={() => {
          if (item.type === "station" || item.type === "audio" || item.type === "topic") {
            if (item.preset_id == presetId) {
              setPresetId(null);
              if (sound) {
                sound.unloadAsync();
              }
              if (client) {
                try {
                  client.pause();
                } catch (ex) {}
              }
            } else {
              if (sound) {
                sound.unloadAsync();
              }
              if (client) {
                try {
                  client.pause();
                } catch (ex) {}
              }
              playSound(item);
            }
          } else if (item.URL || item.children) {
            navigation.push("AudioLibrary_Account", {
              url: item.URL + "&render=json",
              children: item.children,
            });
          }
        }}
        style={{ alignItems: "center" }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {item.type === "station" || item.type === "audio" || item.type === "topic" ? (
            <TouchableOpacity
              onPress={() => {
                if (item) {
                  showActionSheet(item);
                }
              }}
              style={{ width: 20, height: 30, marginTop: 5 }}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <StyledIcon color="secondary" size={20} name="more-vertical" />
            </TouchableOpacity>
          ) : null}
          <View>
            {(item.type === "station" || item.type === "audio" || item.type === "topic") && item.image ? (
              <View>
                <Image source={{ uri: item.image }} resizeMode="stretch" style={{ width: 36, height: 36 }} width={36} height={36} />
              </View>
            ) : (
              <StyledIcon name="refresh-ccw" size={36} color={"secondary"} />
            )}
          </View>
          {item.preset_id === presetId ? (
            <Image
              source={require("../../../../assets/playing.gif")}
              resizeMode="stretch"
              style={{ width: 30, height: 30, paddingLeft: 5 }}
              width={30}
              height={30}
            />
          ) : null}
          <ComponentHeaderText style={{ paddingLeft: 5 }}>{item ? item.text : "N/A"}</ComponentHeaderText>
        </View>
        {item.type === "station" || item.type === "audio" || item.type === "topic" || item.type === "text" ? null : (
          <StyledIcon name={I18nManager.isRTL ? "chevron-left" : "chevron-right"} size={36} color={"secondary"} />
        )}
      </ComponentContainerTouchable>
    );
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View
          style={{
            flexDirection: "row",
          }}
        ></View>
      ),
    });

    if (route && route.params && route.params.children) {
      setState(route.params.children);
    } else {
      let fetchUrl = route.params.url ? route.params.url : "https://opml.radiotime.com/Browse.ashx?render=json";

      fetch(fetchUrl)
        .then((response) => response.json())
        .then((jsonResponse) => {
          //console.log(jsonResponse.body);
          setState(jsonResponse.body);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <ScreenView>
      <ActionSheet
        actionRef={(o) => {
          setActionSheet(o);
        }}
        options={[t("STREAM_TO_OBJECT"), t("CLOSE")]}
        cancelButtonIndex={2}
        onPress={(index) => handleOnPressAction(index)}
      />
      <MultiplePickerSelectObjects
        devicesInGroup={getRadios()}
        onValueChange={onDeviceStreamValueChange}
        modalVisible={modalVisible}
        disabled={false}
        hideText={true}
        closeModal={closeModal}
        placeholder={t("CHOOSE_RECEIVERS")}
        addText={t("STREAM")}
      />
      {state && state.length == 1 && !state[0].type && state[0].children
        ? state[0].children.map((item) => {
            return getItemView(item);
          })
        : state &&
          state.map((item) => {
            return getItemView(item);
          })}
    </ScreenView>
  );
};

const styles = StyleSheet.create({
  containerHeader: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    expoSound: state.statesData.get("expoSound"),
    expoSound: state.statesData.get("soundStatus"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeExpoSound: (audio) => dispatch(changeExpoSound(audio)),
    changeSoundStatus: (status) => dispatch(changeSoundStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioLibrary_AccountScreen);
