import React, { Component, useEffect } from "react";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import { TabView, TabBar } from "react-native-tab-view";

import { ThemeContext } from "../../../ThemeContext";
import Conditions_AutomatizationScreen from "./Conditions_AutomatizationScreen";
import Actions_AutomatizationScreen from "./Actions_AutomatizationScreen";
import { t } from "../../services/i18n";
import { StyledIcon, ScreenView } from "../../components/UI/styledComponents";
import AddConditionModal from "./AddConditionModal";
import AddActionModal from "./AddActionModal";
import mqttClient from "../../services/mqtt";
import { ForceTouchGestureHandler } from "react-native-gesture-handler";
import { saveGeofences } from "../../store/actions";
import * as Location from "expo-location";
import { connect } from "react-redux";
import { getDistance } from "geolib";
import shallowEqual from "shallowequal";

const actionTypes = { SCENE: 1, OBJECT: 2, PUSH: 3, EVENT: 4, MESSAGE: 5 };
const conditionTypes = {
  DAYS: 1,
  TIME: 2,
  OBJECT: 0,
  EVENT: 8,
  LOCATION_ENTER: 12,
  LOCATION_LEAVE: 13,
};

//całe consitons są na nie immutable
class AutomatizationDetailsScreen extends Component {
  static contextType = ThemeContext;

  state = {
    index: 0,
    routes: [
      { key: "conditions", title: t("conditions:CONDITIONS") },
      { key: "actions", title: t("conditions:ACTIVE") },
      //{ key: "false_actions", title: t("conditions:UNACTIVE") },
    ],
    conditionModalVisible: false,
    actionModalVisible: false,
    conditions: null,
    actions: null,
    eventAlreadyExist: false,
    automatizationOper: 0,
    edited: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.edited !== this.state.edited) {
      if (nextState.edited) {
        this.props.navigation.setOptions({
          headerLeft: () => (
            <TouchableOpacity
              style={{ padding: 10, flexDirection: "row" }}
              onPress={() => {
                this.onPressBackButton();
              }}
            >
              <StyledIcon name={"arrow-left"} color={"tabBar"} size={24} style={{ paddingRight: 10 }} />
              <StyledIcon name={"save"} color={"tabBar"} size={24} />
            </TouchableOpacity>
          ),
        });
      }
    }

    return true;
  }

  componentDidMount() {
    let { automatization } = this.props.route.params;
    if (automatization) {
      let automatizationJS = automatization.toJS();
      this.setState({
        conditions: automatizationJS.sub_conditions,
        actions: automatizationJS.actions,
        automatizationOper: automatizationJS.operator ? automatizationJS.operator : 0,
      });
    }

    this.props.navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            this.onPressHeaderButton();
          }}
        >
          <StyledIcon name={"plus"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
      headerLeft: () => (
        <TouchableOpacity
          style={{ padding: 10, flexDirection: "row" }}
          onPress={() => {
            this.onPressBackButton();
          }}
        >
          <StyledIcon name={"arrow-left"} color={"tabBar"} size={24} style={{ paddingRight: 10 }} />
        </TouchableOpacity>
      ),
    });
  }

  onPressHeaderButton = () => {
    if (this.state.index == 0) {
      this.setState({ conditionModalVisible: true });
    } else if (this.state.index == 1) {
      this.setState({ actionModalVisible: true });
    }
  };

  sendConditionAndGoBack = (withLocations) => {
    let { conditions, actions, automatizationOper } = this.state;
    let delay = 0;
    actions = actions.map((action) => {
      action.delay = delay;
      delay = delay + action.time;
      return action;
    });

    let { automatization } = this.props.route.params;

    if (!withLocations) {
      conditions = conditions.filter((el) => {
        return el && el.type != conditionTypes.LOCATION_ENTER && el.type != conditionTypes.LOCATION_LEAVE;
      });
    }

    conditions = conditions.map((cond) => {
      cond.value = cond.value ? parseFloat(cond.value.toString()) : 0;
      cond.value2 = cond.value2 ? parseFloat(cond.value2.toString()) : 0;
      return cond;
    });

    automatization = automatization.set("actions", actions).set("sub_conditions", conditions).set("operator", automatizationOper);
    mqttClient.sendCondition(automatization, 2);
    this.props.navigation.goBack();
  };

  addRegionFromConditions = (geofences, conditions, position) => {
    for (let condition in conditions) {
      if (conditions[condition].type == conditionTypes.LOCATION_LEAVE || conditions[condition].type == conditionTypes.LOCATION_ENTER) {
        geofences.push({
          identifier: conditions[condition].description + ";" + this.props.currentProfile,
          latitude: conditions[condition].value / 1000000,
          longitude: conditions[condition].value2 / 1000000,
          radius: conditions[condition].operator,
          notifyOnEnter: true,
          notifyOnExit: true,
        });
        let distance = getDistance(geofences[geofences.length - 1], position.coords);
        if (distance < conditions[condition].operator) {
          if (conditions[condition].type == conditionTypes.LOCATION_ENTER) {
            conditions[condition].state = 1;
          } else {
            conditions[condition].state = 0;
          }
        } else {
          if (conditions[condition].type == conditionTypes.LOCATION_LEAVE) {
            conditions[condition].state = 1;
          } else {
            conditions[condition].state = 0;
          }
        }
      }
    }
  };

  registerRegions = async () => {
    let { conditions } = this.state;
    let { automatization, automatizations } = this.props.route.params;
    let geofences = [];
    try {
      let position = await Location.getCurrentPositionAsync({});
      this.addRegionFromConditions(geofences, conditions, position);
      if (automatizations) {
        automatizations.map((mainCond) => {
          if (mainCond.get("id") != automatization.get("id")) {
            this.addRegionFromConditions(geofences, mainCond.get("sub_conditions").toJS(), position);
          }
        });
      }
      await this.props.onSaveGeofences(geofences);
    } catch (ex) {
      window.app.showToast(ex.message);
    }
  };

  onPressBackButton = async () => {
    let { conditions, actions, edited } = this.state;
    let { automatization } = this.props.route.params;
    let foundLocation = false;
    for (let condition in conditions) {
      if (conditions[condition].type == conditionTypes.LOCATION_ENTER || conditions[condition].type == conditionTypes.LOCATION_LEAVE) {
        foundLocation = true;
      }
    }

    if (!foundLocation) {
      if (edited) {
        this.sendConditionAndGoBack(false);
      } else {
        this.props.navigation.goBack();
      }
    } else {
      try {
        let { status } = await Location.getForegroundPermissionsAsync();
        if (status === "granted") {
          let { status } = await Location.getBackgroundPermissionsAsync();
          if (status !== "granted") {
            window.app.showToast("No access to device background location");
            this.sendConditionAndGoBack(false);
          } else {
            this.registerRegions();
            this.sendConditionAndGoBack(true);

            setTimeout(async () => {
              window.app.getAndRegisterGeofences();
            }, 100);
          }
        } else {
          window.app.showToast("No access to device location");
          this.sendConditionAndGoBack(false);
        }
      } catch (ex) {
        window.app.showToast(ex.message);
        this.sendConditionAndGoBack(false);
      }
    }
  };
  onIndexChange = (index) => {
    this.setState({ index });
  };

  onCloseHandler = (key) => {
    if (key == "condition") {
      this.setState({ conditionModalVisible: false });
    } else if (key == "action") {
      this.setState({ actionModalVisible: false });
    }
  };

  onAddCondition = (type, id) => {
    let condition = {
      active: 1,
      object_id: id,
      type: type,
      value: 0,
      operator: 1,
      state: 0,
      value2: 0,
      counter: 0,
      date_of_change: 0,
      description: Math.floor((1 + Math.random()) * 0x100000000000000).toString(16),
    };

    let eventAlreadyExist = this.state.eventAlreadyExist;

    if (type == conditionTypes.TIME) {
      //3 st * 10
      condition.object_id = 0;
      condition.value = 900;
      condition.operator = 1;
    } else if (type == conditionTypes.DAYS) {
      condition.object_id = 0;
      condition.value = 127;
      condition.operator = 11;
    } else if (type == conditionTypes.EVENT) {
      condition.object_id = 0;
      condition.value = id;
      eventAlreadyExist = true;
    } else if (type == conditionTypes.LOCATION_ENTER || type == conditionTypes.LOCATION_LEAVE) {
      condition.value = id.latitude * 1000000;
      condition.value2 = id.longitude * 1000000;
      condition.operator = id.radius;
      condition.object_id = 0;
    }

    let conditions = [...this.state.conditions];

    conditions.push(condition);
    this.setState({
      conditions,
      conditionModalVisible: false,
      eventAlreadyExist,
      edited: true,
    });
  };

  generateActions = (actionObject) => {
    const { devices, params_devices } = this.props;
    let action = "";
    let deviceID = actionObject.object_id;

    if (devices.get(deviceID)) {
      let params_device = params_devices.get(deviceID);
      let typKomponentu = devices.get(deviceID).get("typ_komponentu");

      if (typKomponentu == "rgbw" || typKomponentu == "rgb") {
        action = `/api/set/${deviceID}/setColors/${actionObject.value}`;
      } else if (typKomponentu == "event" || typKomponentu == "ircode") {
        action = `/api/set/${deviceID}/setValue/255`;
      } else if (typKomponentu == "ledww") {
        action = `/api/set/${deviceID}/setWW/${actionObject.value}`;
      } else if (typKomponentu == "reg") {
        action = `/api/set/${deviceID}/setTemperature/${actionObject.value}`;
      } else if (typKomponentu == "ip_radio") {
        action = `/api/set/${deviceID}/setIpRadio/${actionObject.value}/${actionObject.param1}/${actionObject.param2}`;
      } else if (typKomponentu == "radio") {
        action = `/api/set/${deviceID}/setRadio/${actionObject.value}/${actionObject.param1}/${actionObject.param2}`;
      } else if (typKomponentu == "roleta") {
        action = `/api/set/${deviceID}/${actionObject.param1 ? "open" : "close"}`;
      } else if (typKomponentu == "roleta_lamelki" || typKomponentu == "roleta_procenty") {
        action = `/api/set/${deviceID}/setRollerPos/${actionObject.param1}/${actionObject.param2}`;
      } else if (
        (typKomponentu == "flaga" || typKomponentu == "flaga_l" || typKomponentu == "flaga_p" || typKomponentu == "przekaznik") &&
        params_device &&
        params_device.get("czas") > 0
      ) {
        action = `/api/set/${deviceID}/setValue/255/${params_device.get("czas")}`;
      } else if (typKomponentu == "ir") {
        if (actionObject.param1 != -1 && actionObject.param1 != null) {
          action = `/api/set/${deviceID}/irRun/${actionObject.param1}`;
        }
      } else {
        action = `/api/set/${deviceID}/setValue/${actionObject.value}`;
      }
    }
    if (actionObject.param3 > 0) {
      action = action + "/" + actionObject.param3 * 100;
    }
    return action;
  };

  getSingleAction = (type, id, additional_info) => {
    let newAction = {
      active: 1,
      description: "",
      action: "",
      type: type,
      delay: 0,
      time: 0,
      object_id: id,
      value: 0,
      value2: 0,
      param1: -1,
      param2: -1,
      param3: -1,
    };
    if (type == actionTypes.EVENT) {
      //to samo
      newAction.action = `/api/setEvent/${id}`;
      newAction.value = 255;
    } else if (type == actionTypes.PUSH) {
      //pomiędzy puste bo pushText = ''
      newAction.action = `/api/pushNotification//${additional_info}`;
      newAction.object_id = 0;
    } else if (type == actionTypes.MESSAGE) {
      // addMessage/title/body
      newAction.action = `/api/addMessage//`;
      newAction.object_id = 0;
      newAction.description = "/";
    } else if (type == actionTypes.SCENE) {
      newAction.action = `/api/run/scene/${id}`;
      newAction.param1 = 0;
    } else if (type == actionTypes.OBJECT) {
      if (["roleta", "roleta_procenty", "roleta_lamelki"].includes(additional_info)) {
        newAction.param1 = -1;
        newAction.param2 = -1;
      }
      let action = this.generateActions(newAction);
      newAction.action = action;
    }
    return newAction;
  };

  onAddAction = (type, id, additional_info) => {
    // 1min - 6000 ms
    // 30 min = 30* 600
    //object
    const { devices } = this.props;
    let actions = [...this.state.actions];

    if (type == actionTypes.OBJECT) {
      additional_info.map((selectedDevice) => {
        let device = devices.get(selectedDevice.value);
        let object_id = selectedDevice.value;
        let object_info = device.get("typ_komponentu");
        let newAction = this.getSingleAction(type, object_id, object_info);
        actions.push(newAction);
      });
    } else {
      let newAction = this.getSingleAction(type, id, additional_info);
      actions.push(newAction);
    }

    this.setState({ actions, actionModalVisible: false, edited: true });
  };

  onRemoveAction = (index) => {
    let actions = [...this.state.actions];

    actions.splice(index, 1);
    this.setState({ actions, edited: true });
  };

  onRemoveCondition = (index) => {
    let conditions = [...this.state.conditions];

    conditions.splice(index, 1);
    this.setState({ conditions, edited: true });
  };

  modifyAction = (action, index) => {
    let actions = [...this.state.actions];
    actions[index] = action;
    if (action.type == actionTypes.OBJECT) {
      actions[index].action = this.generateActions(action);
    }

    this.setState({ actions, edited: true });
  };

  modifyCondition = (condition, index) => {
    let conditions = [...this.state.conditions];
    const edited = !shallowEqual(condition, conditions[index]);
    conditions[index] = condition;
    this.setState({ conditions, edited: edited });
  };

  modifyOperator = (operator) => {
    this.setState({ automatizationOper: operator, edited: true });
  };

  render() {
    const { theme } = this.context;
    const { routes, index, conditionModalVisible, actionModalVisible, eventAlreadyExist, automatizationOper } = this.state;

    const renderScene = ({ route }) => {
      const { actions, conditions } = this.state;
      switch (route.key) {
        case "conditions":
          return (
            <Conditions_AutomatizationScreen
              conditions={conditions}
              onRemoveCondition={this.onRemoveCondition}
              modifyCondition={this.modifyCondition}
              onOperatorChange={this.modifyOperator}
              operator={automatizationOper}
            />
          );
        case "actions":
          return <Actions_AutomatizationScreen actions={actions} onRemoveAction={this.onRemoveAction} modifyAction={this.modifyAction} />;
        case "false_actions":
          return <Actions_AutomatizationScreen actions={actions} onRemoveAction={this.onRemoveAction} modifyAction={this.modifyAction} />;
        default:
          return null;
      }
    };

    return (
      <View style={{ flex: 1 }}>
        <TabView
          renderTabBar={(props) => (
            <TabBar
              {...props}
              indicatorStyle={{ backgroundColor: "white" }}
              style={{
                backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
              }}
            />
          )}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={this.onIndexChange}
        />

        {conditionModalVisible && (
          <AddConditionModal
            isVisible={conditionModalVisible}
            onClose={() => this.onCloseHandler("condition")}
            onAdd={this.onAddCondition}
            eventAlreadyExist={eventAlreadyExist}
          />
        )}
        {actionModalVisible && (
          <AddActionModal isVisible={actionModalVisible} onClose={() => this.onCloseHandler("action")} onAdd={this.onAddAction} />
        )}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveGeofences: (geofences) => dispatch(saveGeofences(geofences)),
  };
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    currentProfile: currentProfile,
    automatizations: state.statesData.get("automatizations"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatizationDetailsScreen);
