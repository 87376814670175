import React, { Component, PureComponent } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import moment from "moment";
import "moment/locale/pl";
import "moment/locale/en-gb";
import * as Localization from "expo-localization";
import {
  ComponentContainer,
  PrimaryColorText,
  SecondaryColorText,
} from "../../../components/UI/styledComponents";
import ModalSimple from "../../../components/UI/ModalSimple";
import DefaultIcon from "./defaultIcon";
import {
  fetchWeatherApi,
  errorInWeather,
  successInWeatherApi,
} from "../../../store/actions";
class Weather extends PureComponent {
  state = {
    city: "",
    weather: [],
    cityDialogVisible: false,
    errorDialogVisible: false,
  };

  onChangeCityNameHandler = (value) => {
    this.setState({
      city: value,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.weatherData.get("loading") &&
      nextProps.weatherData.get("error")
    ) {
      setTimeout(() => {
        //this.setState({ errorDialogVisible: true });
      }, 500);
    }
  }

  showCityDialog = () => {
    this.setState({ cityDialogVisible: true });
  };

  handleCityDialogClose = () => {
    this.setState({ cityDialogVisible: false });
  };

  handleCityDialogOK = () => {
    let city = this.state.city;
    if (city.endsWith(" ")) {
      city = city.slice(0, -1);
    }
    this.props.onfetchWeatherApi(city);
    this.setState({ cityDialogVisible: false });
  };

  handleErrorDialogOK = () => {
    this.setState({ errorDialogVisible: false });
    this.props.clearErrorInWeather(null);
  };

  componentWillUnmount() {
    // this.props.clearWeather(null);
  }

  render() {
    const dataApi = this.props.weatherData.get("dataApi");
    const dataAmpio = this.props.weatherData.get("dataAmpio");

    let { cityForWeather } = this.props;
    let data = {
      name: cityForWeather,
      description: "",
      feels_like: "",
      temp: "",
      humidity: "",
      pressure: "",
      temp_max: "",
      temp_min: "",
      wind: "",
      wind_direction: "",
      sunrise: "",
      sunset: "",
      time: null,
    };
    for (const [key, value] of Object.entries(data)) {
      if (dataAmpio && dataAmpio[key]) {
        if (key === "temp") {
          if (Number(JSON.parse(dataAmpio[key]).state) != -100) {
            Number(JSON.parse(dataAmpio[key]).state).toFixed(1);
          } else if (dataApi && dataApi.main && dataApi.main[key]) {
            data[key] = Number(dataApi.main[key]).toFixed(1);
          }
        } else {
          data[key] = Number(JSON.parse(dataAmpio[key]).state).toFixed(1);
        }
      } else {
        if (key == "wind" && dataApi && dataApi.wind) {
          data[key] = Number(Number(dataApi.wind.speed) * 3.6).toFixed(1);
        } else if (
          key == "wind_direction" &&
          dataApi &&
          dataApi.wind &&
          dataApi.wind.deg
        ) {
          data[key] = Number(dataApi.wind.deg);
        } else if (
          key == "sunrise" &&
          dataApi &&
          dataApi.sys &&
          dataApi.sys.sunrise
        ) {
          data[key] = Number(dataApi.sys[key]) * 1000;
        } else if (
          key == "sunset" &&
          dataApi &&
          dataApi.sys &&
          dataApi.sys.sunset
        ) {
          data[key] = Number(dataApi.sys[key]) * 1000;
        } else if (key == "name" && dataApi) {
          data[key] = dataApi.name;
        } else if (key == "time" && dataApi) {
          data[key] = dataApi.dt;
        } else if (
          key == "description" &&
          dataApi &&
          dataApi.weather[0] &&
          dataApi.weather[0].description
        ) {
          data[key] = dataApi.weather[0].description;
        } else if (dataApi && dataApi.main && dataApi.main[key]) {
          data[key] = Number(dataApi.main[key]).toFixed(1);
        }
      }
    }

    return (
      <ComponentContainer
        containerStyle={[
          {
            width: this.props.middleWidth ? "50%" : "100%",
          },
          this.props.containerStyle,
        ]}
        style={{
          padding: 10,
        }}
      >
        {!cityForWeather ? (
          <TouchableOpacity
            onPress={this.showCityDialog}
            style={styles.descriptionContainer}
          >
            <PrimaryColorText>{t("weather:ENTER_CITY_NAME")}</PrimaryColorText>
          </TouchableOpacity>
        ) : !dataApi && !dataAmpio ? (
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <PrimaryColorText
              style={{
                fontSize: 30,
                fontWeight: "bold",
                paddingBottom: 15,
              }}
            >
              {cityForWeather}
            </PrimaryColorText>
            <ActivityIndicator size="large" />
          </View>
        ) : (
          <View>
            <View
              style={[
                styles.descriptionContainer,
                {
                  borderBottomColor: "#ccc",
                  borderBottomWidth: 1,
                  paddingBottom: 5,
                },
              ]}
            >
              <TouchableOpacity onPress={this.showCityDialog}>
                <PrimaryColorText
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingBottom: 5,
                  }}
                >
                  {data.name}
                </PrimaryColorText>
              </TouchableOpacity>
              {data.description ? (
                <SecondaryColorText>{data.description}</SecondaryColorText>
              ) : null}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: "70%",
                }}
              >
                {data.sunrise ? (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <DefaultIcon
                      name="weather-sunset-up"
                      library="MaterialCommunityIcons"
                    />
                    <SecondaryColorText>
                      {moment(data.sunrise).format("HH:mm")}
                    </SecondaryColorText>
                  </View>
                ) : null}
                {data.sunset ? (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <DefaultIcon
                      name="weather-sunset-down"
                      library="MaterialCommunityIcons"
                    />
                    <SecondaryColorText>
                      {moment(data.sunset).format("HH:mm")}
                    </SecondaryColorText>
                  </View>
                ) : null}
              </View>
            </View>
            <View style={styles.resultsContainer}>
              <View style={styles.leftContainer}>
                {data.temp ? (
                  <PrimaryColorText style={styles.mainTemp}>
                    {data.temp}&deg;C
                  </PrimaryColorText>
                ) : null}
                {data.feels_like ? (
                  <View style={styles.minMaxTemp}>
                    <PrimaryColorText>
                      {t("weather:FEEL_TEMP")}
                    </PrimaryColorText>
                    <PrimaryColorText>{data.feels_like}&deg;C</PrimaryColorText>
                  </View>
                ) : null}
                {data.temp_min && data.temp_max ? (
                  <View style={styles.minMaxTempContainer}>
                    <View style={styles.minMaxTempContainer}>
                      <View style={[styles.minMaxTemp, { paddingRight: 10 }]}>
                        <DefaultIcon
                          name="thermometer-0"
                          library={"FontAwesome"}
                        />
                        <PrimaryColorText>
                          {data.temp_min}&deg;C
                        </PrimaryColorText>
                      </View>
                    </View>
                    <View style={styles.minMaxTempContainer}>
                      <View style={styles.minMaxTemp}>
                        <DefaultIcon
                          name="thermometer-full"
                          library="FontAwesome"
                        />
                        <PrimaryColorText>
                          {data.temp_max}&deg;C
                        </PrimaryColorText>
                      </View>
                    </View>
                  </View>
                ) : null}
              </View>

              <View style={styles.rightContainer}>
                {data.pressure ? (
                  <View style={styles.minMaxTemp}>
                    <DefaultIcon
                      name="speedometer"
                      library="MaterialCommunityIcons"
                    />
                    <PrimaryColorText>{data.pressure} hPa</PrimaryColorText>
                  </View>
                ) : null}
                {data.wind && !data.wind_direction ? (
                  <View style={styles.minMaxTemp}>
                    <DefaultIcon
                      name="weather-windy"
                      library="MaterialCommunityIcons"
                    />
                    <PrimaryColorText>{data.wind} km/h</PrimaryColorText>
                  </View>
                ) : null}
                {data.wind && data.wind_direction ? (
                  <View style={styles.minMaxTemp}>
                    <DefaultIcon
                      name="arrow-up"
                      library="MaterialCommunityIcons"
                      style={{
                        transform: [{ rotate: data.wind_direction + "deg" }],
                      }}
                    />
                    <PrimaryColorText>{data.wind} km/h</PrimaryColorText>
                  </View>
                ) : null}

                {data.humidity ? (
                  <View style={styles.minMaxTemp}>
                    <DefaultIcon
                      name="water"
                      library="MaterialCommunityIcons"
                    />
                    <PrimaryColorText>{data.humidity} %</PrimaryColorText>
                  </View>
                ) : null}
              </View>
            </View>
            {data.time ? (
              <View style={styles.updateTime}>
                <SecondaryColorText style={styles.updateTimeFont}>
                  {moment.unix(data.time).format("HH:mm, DD.MM.YY")}
                </SecondaryColorText>
              </View>
            ) : null}
          </View>
        )}

        <ModalSimple
          isVisible={this.state.cityDialogVisible}
          title={"weather:ENTER_CITY_NAME"}
          placeholder={"weather:CITY_NAME"}
          onInputChange={this.onChangeCityNameHandler}
          onClose={this.handleCityDialogClose}
          onOK={this.handleCityDialogOK}
        />
        <ModalSimple
          isVisible={this.state.errorDialogVisible}
          title={"weather:UNKNOWN_CITY_NAME"}
          description={"weather:CANNOT_FETCH_WEATHER"}
          onOK={this.handleErrorDialogOK}
        />
      </ComponentContainer>
    );
  }
}

const styles = StyleSheet.create({
  descriptionContainer: {
    alignItems: "center",
    justifyContent: "center",
  },

  resultsContainer: {
    flexDirection: "row",
    marginTop: 5,
  },
  leftContainer: {
    flex: 3,
    alignItems: "center",
    justifyContent: "center",
  },
  rightContainer: {
    flex: 2,
    margin: 5,
    alignItems: "flex-start",

    justifyContent: "center",
  },

  mainTemp: {
    fontWeight: "bold",
    fontSize: 45,
    // paddingTop: 10
  },
  minMaxTempContainer: {
    paddingTop: 6,
    flexDirection: "row",
  },
  minMaxTemp: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  updateTime: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  updateTimeFont: {
    fontSize: 10,
    marginRight: 5,
  },
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    weatherData: state.weatherData,
    cityForWeather: state.profilesSettings
      .get(currentProfile)
      .get("cityForWeather"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchWeatherApi: (city) => dispatch(fetchWeatherApi(city)),
    clearErrorInWeather: (clear) => dispatch(errorInWeather(clear)),
    clearWeather: (clear) => dispatch(successInWeatherApi(clear)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Weather);
