import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";

class RollerAnimationView extends Component {
  state = {
    blindsLevel: this.props.blindsLevel,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      blindsLevel: nextProps.blindsLevel,
    });
  }

  render() {
    let rotation = 0;
    if (this.props.rollerMarquize) {
      rotation = 90;
    }
    if (this.props.invertPercent) {
      rotation += 180;
    }
    rotation = rotation + "deg";

    return (
      <View
        style={{
          width: 40,
          height: 41,
          borderWidth: 1,
          borderColor: "black",
          marginLeft: 20,
          transform: [{ rotate: rotation }],
        }}
      >
        {this.state.blindsLevel < 100 ? (
          <View style={styles.singleBlindDark} />
        ) : null}
        {this.state.blindsLevel < 90 ? (
          <View style={styles.singleBlindLight} />
        ) : null}
        {this.state.blindsLevel < 80 ? (
          <View style={styles.singleBlindDark} />
        ) : null}
        {this.state.blindsLevel < 70 ? (
          <View style={styles.singleBlindLight} />
        ) : null}
        {this.state.blindsLevel < 60 ? (
          <View style={styles.singleBlindDark} />
        ) : null}
        {this.state.blindsLevel < 50 ? (
          <View style={styles.singleBlindLight} />
        ) : null}
        {this.state.blindsLevel < 40 ? (
          <View style={styles.singleBlindDark} />
        ) : null}
        {this.state.blindsLevel < 30 ? (
          <View style={styles.singleBlindLight} />
        ) : null}
        {this.state.blindsLevel < 20 ? (
          <View style={styles.singleBlindDark} />
        ) : null}
        {this.state.blindsLevel < 10 ? (
          <View style={styles.singleBlindLight} />
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleBlindLight: {
    height: 3,
    backgroundColor: "#999",
    marginBottom: 1,
  },
  singleBlindDark: {
    height: 3,
    backgroundColor: "#555",
    marginBottom: 1,
  },
});

export default RollerAnimationView;
