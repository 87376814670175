import React, { Component } from "react";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";

import { View, Text, ActivityIndicator } from "react-native";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  PrimaryColorText,
  TextInputStyled,
  ComponentContainer,
  ButtonPrimary,
  SecondaryColorText,
} from "../../../components/UI/styledComponents";
import mqttClient from "../../../services/mqtt";

import {
  passwordChangedInfoReceived,
  changePassword,
} from "../../../store/actions";

class Password_SecurityScreen extends Component {
  state = {
    currentPassword: "",
    currentPasswordError: false,
    newPassword: "",
    newPasswordError: false,
    confirmPassword: "",
    confirmPasswordError: false,
    isLoading: false,
    passwordChangedInfo: "",
  };

  onInputChange = (value, key) => {
    this.setState({ [key]: value, passwordChangedInfo: "" });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.passwordChangedInfo == "" &&
      this.props.passwordChangedInfo != nextProps.passwordChangedInfo
    ) {
      if (nextProps.passwordChangedInfo == "ok") {
        this.props.changePassword(this.state.newPassword);
      }
      this.setState({
        isLoading: false,
        currentPassword: "",
        currentPasswordError: false,
        newPassword: "",
        newPasswordError: false,
        confirmPassword: "",
        confirmPasswordError: false,
        passwordChangedInfo: nextProps.passwordChangedInfo,
      });
      this.props.passwordChangedInfoReceived("");
    }
  }

  componentWillUnmount() {
    this.props.passwordChangedInfoReceived("");
  }
  confirm = () => {
    const { profileSettings } = this.props;
    const { currentPassword, newPassword, confirmPassword } = this.state;

    let currentPasswordError =
      currentPassword == profileSettings.get("loginData").get("password")
        ? false
        : true;

    let newPasswordError = true;
    // if (
    // 	newPassword.match(/[a-z]/) &&
    // 	newPassword.match(/[A-Z]/) &&
    // 	newPassword.match(/[0-9]/) &&
    // 	newPassword.length >= 8
    // ) {

    if (newPassword.length >= 6) {
      newPasswordError = false;
    }
    let confirmPasswordError = newPassword == confirmPassword ? false : true;

    if (currentPasswordError || newPasswordError || confirmPasswordError) {
      this.setState({
        currentPasswordError,
        newPasswordError,
        confirmPasswordError,
      });
    } else {
      this.setState({
        currentPasswordError,
        newPasswordError,
        confirmPasswordError,
        isLoading: true,
      });
      mqttClient.changePassword(newPassword);
    }
  };
  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      isLoading,
      passwordChangedInfo,
    } = this.state;
    return (
      <ScreenView>
        <SecondaryColorText style={{ padding: 10 }}>
          {t("PASSWORD_REQUIREMENTS")}
        </SecondaryColorText>
        <ComponentContainer>
          <PrimaryColorText>{t("CURRENT_PASSWORD")}:</PrimaryColorText>
          <TextInputStyled
            // placeholderTextColor={theme.TAB_BAR_TEXT_COLOR}
            placeholder={t("ENTER_CURRENT_PASSWORD")}
            value={currentPassword}
            secureTextEntry={true}
            onChangeText={(value) =>
              this.onInputChange(value, "currentPassword")
            }
          />
          {currentPasswordError && (
            <Text style={{ color: "red", fontStyle: "italic" }}>
              {t("CURRENT_PASSWORD_ERROR")}
            </Text>
          )}
        </ComponentContainer>
        <ComponentContainer>
          <PrimaryColorText>{t("NEW_PASSWORD")}:</PrimaryColorText>
          <TextInputStyled
            // placeholderTextColor={theme.TAB_BAR_TEXT_COLOR}
            placeholder={t("ENTER_NEW_PASSWORD")}
            value={newPassword}
            secureTextEntry={true}
            onChangeText={(value) => this.onInputChange(value, "newPassword")}
          />
          {newPasswordError && (
            <Text style={{ color: "red", fontStyle: "italic" }}>
              {t("NEW_PASSWORD_ERROR")}
            </Text>
          )}
        </ComponentContainer>
        <ComponentContainer>
          <PrimaryColorText>{t("CONFIRM_PASSWORD")}:</PrimaryColorText>
          <TextInputStyled
            // placeholderTextColor={theme.TAB_BAR_TEXT_COLOR}
            placeholder={t("CONFIRM_PASSWORD")}
            value={confirmPassword}
            secureTextEntry={true}
            onChangeText={(value) =>
              this.onInputChange(value, "confirmPassword")
            }
          />
          {confirmPasswordError && (
            <Text style={{ color: "red", fontStyle: "italic" }}>
              {t("CONFIRM_PASSWORD_ERROR")}
            </Text>
          )}
        </ComponentContainer>
        <View style={{ justifyContent: "center", width: "100%", padding: 20 }}>
          {!isLoading ? (
            <ButtonPrimary onPress={this.confirm}>
              {t("CHANGE_PASSWORD")}
            </ButtonPrimary>
          ) : (
            <ActivityIndicator size="small" />
          )}
        </View>

        {passwordChangedInfo.length ? (
          <View
            style={{
              width: "100%",
            }}
          >
            <SecondaryColorText
              style={{
                fontSize: 16,
                textAlign: "center",
                color: passwordChangedInfo == "ok" ? "green" : "red",
              }}
            >
              {passwordChangedInfo == "ok"
                ? "Hasło zostało zmienione"
                : "Wystąpił błąd podczas zmiany hasła"}
            </SecondaryColorText>
          </View>
        ) : null}
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileSettings: state.profilesSettings.get(
      state.profilesSettings.get("currentProfile")
    ),
    passwordChangedInfo: state.statesData.get("passwordChangedInfo"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    passwordChangedInfoReceived: (reset) =>
      dispatch(passwordChangedInfoReceived(reset)),
    changePassword: (newPassword) => dispatch(changePassword(newPassword)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Password_SecurityScreen);
