import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet, Platform, Switch } from "react-native";
import { connect } from "react-redux";
import mqttClient from "../../../services/mqtt";
import { t } from "../../../services/i18n";
import * as Haptics from "expo-haptics";
import PickerSelect from "../../UI/PickerSelect";
import { StyledIcon, PrimaryColorText, SliderStyled, SecondaryColorText, SwitchStyled } from "../../UI/styledComponents";
import { Feather } from "@expo/vector-icons";
import GoToModal from "../../../utils/GoToModal";
import { ThemeContext } from "../../../../ThemeContext";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ActionSheet from "../../../utils/ActionSheet";
class RegulatorComponent extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);

    this.modes = [
      {
        label: t("AUTOMATIC"),
        value: "A",
      },
      {
        label: t("SEMI_AUTOMATIC"),
        value: "S",
      },
      {
        label: t("MANUAL"),
        value: "M",
      },
      {
        label: t("HOLIDAYS"),
        value: "H",
      },
      // {
      // 	label: t("BLOCKED"),
      // 	value: "B",
      // },
    ];

    this.modesAc = [
      {
        label: t("AUTO"),
        value: "auto",
      },
      {
        label: t("COOL"),
        value: "cool",
      },
      {
        label: t("HEAT"),
        value: "heat",
      },
      {
        label: t("DRY"),
        value: "dry",
      },
      {
        label: t("FAN"),
        value: "fan",
      },
    ];

    this.fanModes = [t("CLOSE"), t("AUTO"), t("VLOW"), t("LOW"), t("MED"), t("HIGH"), t("TOP")];

    this.state = {
      setTemperature: 0,
      blockProps: false,
      mode: "A",
      showWebActionSheet: false,
      value: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        value: Number(deviceState.get("state")),
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = null;
      updateState();
    }, 5000);
  };

  clearTimeoutMine = () => {
    clearTimeout(this.timeOut);
    this.runTimeout();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        setTemperature: Number(nextProps.deviceState.get("setTemperature")),
        mode: nextProps.deviceState.get("mode"),
        value: nextProps.deviceState.get("state"),
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        setTemperature: Number(nextProps.deviceInScene.get("value")),
      };
    }
    return null;
  }

  decreaseOne = () => {
    let { setTemperature } = this.state;
    let step = this.props.device.get("param1") ? Number(this.props.device.get("param1")) : 1;
    let min = this.props.device.get("min");
    let newValue = 0;

    if (setTemperature - step >= min) {
      newValue = setTemperature - step;
    } else {
      newValue = min;
    }

    this.setState({ setTemperature: newValue, blockProps: true }, () => this.sendMessage(this.state.setTemperature, "value"));
  };

  increaseOne = () => {
    let { setTemperature } = this.state;
    let max = this.props.device.get("max");
    let step = this.props.device.get("param1") ? Number(this.props.device.get("param1")) : 1;
    let newValue = 0;

    if (setTemperature + step <= max) {
      newValue = setTemperature + step;
    } else {
      newValue = max;
    }

    this.setState({ setTemperature: newValue, blockProps: true }, () => this.sendMessage(this.state.setTemperature, "value"));
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (value) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    this.setState({ setTemperature: value });
    //this.sendMessage(value, "value", true);
  };

  onSlidingCompleteHandler = (value) => {
    this.setState({ setTemperature: value }, () => this.sendMessage(value, "value"));
  };

  onModeValueChange = (mode) => {
    this.setState({ mode, blockProps: true }, () => {
      if (Platform.OS !== "ios") {
        this.sendMessage(this.state.mode, "mode");
      } else {
        this.clearTimeoutMine();
      }
    });
  };

  onCloseMode = () => {
    if (Platform.OS === "ios") {
      this.sendMessage(this.state.mode, "mode");
    }
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        setTemperature: Number(deviceState.get("setTemperature")),
        mode: deviceState.get("mode"),
      });
    };

    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  seTempColor = (deviceState) => {
    const { theme } = this.context;
    let color = theme.TEXT_COLOR_SECONDARY;
    if (this.props.device.get("typ_komponentu") === "reg") {
      if (deviceState.get("cooling") == 1 && deviceState.get("state") == 1) {
        //chłodzenie
        color = "blue";
      } else if (deviceState.get("cooling") == 0 && deviceState.get("state") == 1) {
        // ogrzewanie
        color = "red";
      }
    } else if (this.props.device.get("typ_komponentu") === "ac") {
      if (deviceState.get("mode").toLowerCase() === "cool") {
        //chłodzenie
        color = "blue";
      } else if (deviceState.get("mode").toLowerCase() === "heat") {
        // ogrzewanie
        color = "red";
      }
    }
    return color;
  };
  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  fanPress = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  onChangeAc = (value) => {
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    if (value) {
      this.sendMessage("on", "ac");
    } else {
      this.sendMessage("off", "ac");
    }
    this.setState((prevState) => ({
      blockProps: true,
      value: !prevState.value,
    }));
  };

  handleOnPressAction = (index) => {
    if (index === 1) {
      this.sendMessage("A", "fan");
    } else if (index === 2) {
      this.sendMessage("V", "fan");
    } else if (index === 3) {
      this.sendMessage("L", "fan");
    } else if (index === 4) {
      this.sendMessage("M", "fan");
    } else if (index === 5) {
      this.sendMessage("H", "fan");
    } else if (index === 6) {
      this.sendMessage("T", "fan");
    }

    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
  };

  sendMessage = (value, type, fromSliderMoving) => {
    const { device, deviceInScene, changeScene, activeSceneControl } = this.props;
    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();
    if (deviceInScene) {
      let infoToUpdate = deviceInScene.set("value", value);
      changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
      if (type == "value") {
        this.setState({ setTemperature: value });
      }
    }

    let valueToSend = value;
    let message = "";
    if (type == "value") {
      valueToSend = Number(value).toFixed(2);
      message = `/api/set/${device.get("id")}/setTemperature/${valueToSend}`;
      if (device.get("tyo_komponentu") === "ac") {
        this.setState({ setTemperature: value });
      }
    } else if (type == "mode") {
      message = `/api/set/${device.get("id")}/setHeatingMode/${valueToSend}`;
    } else if (type == "fan") {
      message = `/api/set/${device.get("id")}/setFan/${valueToSend}`;
    } else if (type == "ac") {
      message = `/api/set/${device.get("id")}/setAc/${valueToSend}`;
    }
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  render() {
    const { device, deviceState, deviceInScene, header, readOnly, permission } = this.props;
    const { theme } = this.context;
    let { mode } = this.state;
    if (device.get("typ_komponentu") === "ac") mode = mode.toLowerCase();

    let permissionForEditSchedule = (permission & 0x02) == 0;
    let measureTemp = Number(deviceState.get("measureTemp")).toFixed(1);
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            // flex: 1,
            // justifyContent: "space-between"
          }}
        >
          <View style={{ flex: 1 }}>
            {!deviceInScene && permissionForEditSchedule && device.get("typ_komponentu") === "reg" ? (
              <GoToModal
                params={{
                  placeName: "HEATING",
                  device,
                }}
                screenName="HeatingScreen"
              >
                {header}

                <StyledIcon style={{ paddingRight: 5 }} name={"settings"} color={"primary"} size={20} />
              </GoToModal>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  // flex: 1,
                  // justifyContent: "space-between"
                }}
              >
                {header}
                <ActionSheet
                  actionRef={(o) => (this.ActionSheet = o)}
                  options={this.fanModes}
                  cancelButtonIndex={0}
                  onPress={(index) => {
                    this.handleOnPressAction(index);
                  }}
                  onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
                  showWebActionSheet={this.state.showWebActionSheet}
                />
                <TouchableOpacity onPress={() => this.fanPress()}>
                  <MaterialCommunityIcons
                    color={theme.TEXT_COLOR_PRIMARY}
                    size={30}
                    name={
                      deviceState.get("fan") === "Auto"
                        ? "fan-chevron-down"
                        : deviceState.get("fan") === "VLow"
                        ? "fan-speed-1"
                        : deviceState.get("fan") === "Low"
                        ? "fan-speed-1"
                        : deviceState.get("fan") === "Med"
                        ? "fan-speed-2"
                        : deviceState.get("fan") === "High"
                        ? "fan-speed-3"
                        : deviceState.get("fan") === "Top"
                        ? "fan-chevron-up"
                        : null
                    }
                    style={{ paddingRight: 5 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginRight: 10,
              // flex: 1,
              // justifyContent: "flex-end",
            }}
          >
            {measureTemp == -100 ? (
              <SecondaryColorText>Error</SecondaryColorText>
            ) : (
              <PrimaryColorText style={styles.tempText}>{measureTemp}&deg;C</PrimaryColorText>
            )}
            <PrimaryColorText>/</PrimaryColorText>
            <Text style={[styles.tempText, { color: this.seTempColor(deviceState) }]}>
              {Number(this.state.setTemperature).toFixed(1)}
              &deg;C
            </Text>
          </View>

          {deviceState.get("cooling") == 1 && device.get("typ_komponentu") === "reg" ? (
            <Feather style={{ paddingRight: 5 }} name={"thermometer"} color={"blue"} size={20} />
          ) : device.get("typ_komponentu") === "reg" ? (
            <Feather style={{ paddingRight: 5 }} name={"thermometer"} color={"red"} size={20} />
          ) : null}
        </View>
        {mode != "H" && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            <TouchableOpacity disabled={readOnly || mode == "H"} style={styles.iconButton} onPress={this.decreaseOne}>
              <StyledIcon name={"minus-circle"} color={"primary"} size={24} />
            </TouchableOpacity>
            <SliderStyled
              step={device.get("param1") ? Number(device.get("param1")) : 1}
              value={this.state.setTemperature}
              minimumValue={device.get("min")}
              maximumValue={device.get("max")}
              onValueChange={this.onSlidingChangeHandler}
              onSlidingComplete={this.onSlidingCompleteHandler}
              disabled={readOnly || mode == "H"}
            />
            <TouchableOpacity style={styles.iconButton} onPress={this.increaseOne} disabled={readOnly || mode == "H"}>
              <StyledIcon name={"plus-circle"} color={"primary"} size={24} />
            </TouchableOpacity>
          </View>
        )}

        {!deviceInScene && (
          <View
            style={{
              flexDirection: "row",
              marginHorizontal: 10,
              alignItems: "center",
              justifyContent: mode == "B" ? null : "space-between",
            }}
          >
            <PrimaryColorText>{t("MODE")}: </PrimaryColorText>
            {mode == "B" ? (
              <SecondaryColorText>{t("BLOCKED")}</SecondaryColorText>
            ) : (
              <PickerSelect
                placeholder={{
                  label: t("SELECT_MODE"),
                  value: null,
                }}
                items={device.get("typ_komponentu") === "ac" ? this.modesAc : this.modes}
                containerStyle={{ marginLeft: 5, flex: 1 }}
                onValueChange={this.onModeValueChange}
                onClose={this.onCloseMode}
                value={mode}
                disabled={readOnly}
              />
            )}
            {device.get("typ_komponentu") === "ac" && (
              <SwitchStyled value={this.state.value > 0} onValueChange={this.onChangeAc} style={{ marginLeft: 10 }} />
            )}
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tempText: {
    fontSize: 11,
    fontWeight: "bold",
  },
  iconButton: {
    borderColor: "black",
    padding: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
  };
};

export default connect(mapStateToProps)(RegulatorComponent);
