import React, { Component } from "react";
import { View } from "react-native";
import GoToModal from "../../../utils/GoToModal";
import { ComponentContainer, StyledIcon } from "../../UI/styledComponents";

const AccessControlComponent = ({ header, device }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      {header}
      <GoToModal
        params={{
          placeName: device.get("opis_menu"),
          device: device,
        }}
        screenName="AccessControlScreen"
      >
        <StyledIcon
          style={{ paddingRight: 5 }}
          name={"settings"}
          color={"primary"}
          size={20}
        />
      </GoToModal>
    </View>
  );
};

export default AccessControlComponent;
