import React, { Component } from "react";
import { Text, View } from "react-native";

import { ComponentHeaderText } from "../../UI/styledComponents";
import ImageForLinearInput from "../../../utils/ImageForLinearInput";

const LinearInputComponent = ({ deviceState, params_device, device, header, deviceFromCustom }) => {
  let imageAndDesc = params_device && (params_device.get("params") & 0x40000) > 0;
  let imageAndValue = params_device && (params_device.get("params") & 0x80000) > 0;

  const componentToShow = () => {
    if (deviceState.get("res")) {
      let url = params_device && params_device.get("url") ? params_device.get("url") : "%";
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {imageAndDesc && deviceState.get("desc") && (
            <ComponentHeaderText
              numberOfLines={1}
              style={{
                maxWidth: deviceFromCustom ? "100%" : "100%",
                alignItems: "flex-end",
              }}
            >
              {deviceState.get("desc")}
              {imageAndDesc && imageAndDesc ? "," : ""}
            </ComponentHeaderText>
          )}
          {imageAndValue && (
            <ComponentHeaderText
              style={{
                paddingLeft: 5,
                maxWidth: deviceFromCustom ? "100%" : "50%",
                alignItems: "flex-end",
              }}
              numberOfLines={1}
            >
              {deviceState.get("state")}
              {device.get("typ_komponentu") === "temp" ? "\u00b0C" : url}
            </ComponentHeaderText>
          )}

          <ImageForLinearInput id={deviceState.get("res")} deviceFromCustom={deviceFromCustom} />
        </View>
      );
    } else if (deviceState.get("desc")) {
      return (
        <ComponentHeaderText
          numberOfLines={1}
          style={{
            maxWidth: deviceFromCustom ? "100%" : "50%",
            alignItems: "flex-end",
          }}
        >
          {deviceState.get("desc")}
        </ComponentHeaderText>
      );
    } else {
      let url = params_device && params_device.get("url") ? params_device.get("url") : "%";

      return (
        <ComponentHeaderText
          numberOfLines={1}
          style={{
            maxWidth: deviceFromCustom ? "100%" : "50%",
            alignItems: "flex-end",
          }}
        >
          {deviceState.get("state")}
          {device.get("typ_komponentu") === "temp" ? "\u00b0C" : url}
        </ComponentHeaderText>
      );
    }
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {!deviceFromCustom && header}
      {componentToShow()}
    </View>
  );
};

export default LinearInputComponent;
