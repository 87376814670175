import React, { useContext, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { t } from "../../services/i18n";
import { useTheme } from "../../../ThemeContext";

import { connect } from "react-redux";

import { groupListForPicker } from "../../utils";
import ModalWithContent from "./ModalWithContent";
import { StyledIcon } from "./styledComponents";

const PickerSelectObjects = ({
  value,
  containerStyle,
  onValueChange,
  additionalTextStyle,
  disabled,
  additionalGroup,
  items,
  groups,
  type,
  additionalZ,
  overWriteLabel,
  order,
}) => {
  const placeholder = {
    label:
      type == "groups" || type == "startingPlace"
        ? t("SELECT_PLACE")
        : t("SELECT_OBJECT_NAME"),
    value: null,
  };
  if (overWriteLabel) {
    placeholder.label = overWriteLabel;
  }
  const { theme } = useTheme();
  const textColor = theme.TEXT_COLOR_PRIMARY;
  const textColorSecondary = theme.TEXT_COLOR_SECONDARY;
  const inputBackgroundColor = theme.APP_BACKGROUND_COLOR;
  const borderColor = theme.PLACEHOLDER_BACKGROUND_COLOR;

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const onClose = () => {
    setModalVisible(false);
  };

  const itemSelected = (el) => {
    onValueChange(el);
    setModalVisible(false);
  };

  let objectsGroups = [];

  if (type == "groups") {
    additionalGroup && objectsGroups.push(additionalGroup);
    objectsGroups.push({
      label: t("DASHBOARD"),
      value: 0,
    });
    objectsGroups.push(...groupListForPicker(groups, order));
  } else if (type == "devices") {
    objectsGroups = items;
  } else if (type == "startingPlace") {
    objectsGroups = [
      { label: t("DASHBOARD"), value: -4 },
      { label: t("FAVOURITES"), value: -3 },
      { label: t("SCENES"), value: -2 },
      { label: t("ACTIVE"), value: -1 },
      { label: t("PLACES"), value: 0 },
    ];
    objectsGroups.push(...groupListForPicker(groups, order, false));
  }
  let content = (
    <ScrollView style={{ width: "100%" }}>
      {objectsGroups.map((item) => {
        return (
          <TouchableOpacity
            style={{
              padding: 10,
              alignItems: "flex-start",
            }}
            key={item.value}
            onPress={() => itemSelected(item.value)}
          >
            <Text style={{ color: textColor }}>{item.label}</Text>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );

  let styles = StyleSheet.create({
    inputANDROID: {
      paddingLeft: 8,
      height: 26,
      borderWidth: 1,
      borderColor: borderColor,
      borderRadius: 4,
      width: "100%",
      backgroundColor: inputBackgroundColor,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });

  let selectedItem = objectsGroups.find((item) => item.value == value);
  let modalContent = modalVisible && (
    <ModalWithContent
      isVisible={modalVisible}
      title={placeholder.label}
      onClose={onClose}
    >
      {content}
    </ModalWithContent>
  );
  let pickerAndorid = (
    <View
      style={{
        width: "100%",
        alignItems: "center",
      }}
    >
      <TouchableOpacity style={styles.inputANDROID} onPress={showModal}>
        <Text
          numberOfLines={1}
          style={[
            additionalTextStyle,
            {
              color: selectedItem ? textColor : textColorSecondary,
              paddingRight: 15,
            },
          ]}
        >
          {selectedItem ? selectedItem.label : placeholder.label}
        </Text>
        <View style={{ position: "absolute", right: 0 }}>
          <StyledIcon name="chevron-down" size={18} color="secondary" />
        </View>
      </TouchableOpacity>
    </View>
  );

  return !disabled ? (
    <View style={containerStyle}>
      {pickerAndorid}

      {modalContent}
    </View>
  ) : (
    <View>
      <Text
        style={{
          fontSize: 12,
          paddingVertical: 4,
          paddingHorizontal: 8,
          height: 26,
          borderWidth: 1,
          borderColor: borderColor,
          borderRadius: 4,
          color: textColor,
          paddingRight: 25,
          backgroundColor: inputBackgroundColor,
        }}
      >
        {selectedItem ? selectedItem.label : placeholder.label}
      </Text>
    </View>
  );
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    groups: state.smartHomeData.get(currentProfile).get("groups"),
    order: state.profilesSettings.get(currentProfile).get("order"),
  };
};

export default connect(mapStateToProps)(PickerSelectObjects);
