import React, { Component } from "react";
import { View, TouchableOpacity, Keyboard, Platform } from "react-native";
import mqttClient from "../../../services/mqtt";

import { StyledIcon, SwitchStyled } from "../../UI/styledComponents";

import ModalSimple from "../../UI/ModalSimple";
import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import * as Haptics from "expo-haptics";
class SatelOutputComponent extends Component {
  state = {
    value: false,
    blockProps: false,
    pinDialogVisible: false,
    wrongPinDialogVisible: false,
    pin: "",
    showSwitch: false,
  };

  componentDidMount() {
    const { device } = this.props;
    if (device.has("ikona_0")) {
      this.setState({
        showSwitch: device.get("ikona_0") == null || device.get("ikona_1") == null,
      });
    } else if (device.has("ikona0")) {
      this.setState({
        showSwitch: device.get("ikona0") == null || device.get("ikona1") == null,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.blockProps) {
      return {
        value: nextProps.deviceState.get("state") == "0" ? false : true,
      };
    }
    return null;
  }

  onPinInputHandler = (value) => {
    this.setState({
      pin: value,
    });
  };

  handleClose = () => {
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false });
  };

  handleOK = () => {
    const { device } = this.props;
    Keyboard.dismiss();

    this.setState({ pinDialogVisible: false });
    if (this.state.pin) {
      this.sendMessage(this.state.pin);
    } else {
      setTimeout(() => {
        this.setState({
          wrongPinDialogVisible: true,
        });
      }, 500);
    }
  };

  handleOKwrongPin = () => {
    this.setState({ wrongPinDialogVisible: false });
  };

  toggleValue = () => {
    const { device } = this.props;
    if (device.get("haslo")) {
      this.sendMessage(device.get("haslo"));
    } else {
      this.setState({ pinDialogVisible: true });
    }
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  };

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        value: Number(deviceState.get("state")),
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = null;
      updateState();
    }, 5000);
  };

  clearTimeoutMine = () => {
    clearTimeout(this.timeOut);
    this.runTimeout();
  };

  sendMessage = (pin) => {
    const { device, params_device } = this.props;

    this.setState(
      (prevState) => ({
        blockProps: true,
        value: !prevState.value,
      }),
      () => {
        this.clearTimeoutMine();
        let message = this.state.value ? `/api/set/${device.get("id")}/setSatelOut/255/${pin}` : `/api/set/${device.get("id")}/setSatelOut/0/${pin}`;

        mqttClient.stateChangeToSend(message, device.get("id"));
      }
    );
  };

  render() {
    const { device, header, readOnly, deviceFromCustom, deviceFromSketch, stretchSketch, group_params, deviceState } = this.props;

    const { value, pinDialogVisible, wrongPinDialogVisible } = this.state;
    let contentWithoutTime =
      this.state.showSwitch || (!deviceFromSketch && (group_params & 0x04) > 0) ? (
        <SwitchStyled value={this.state.value} onValueChange={this.toggleValue} disabled={readOnly} />
      ) : (
        <TouchableOpacity
          onPress={this.toggleValue}
          disabled={readOnly}
          style={{
            width: deviceFromSketch ? "100%" : null,
            height: deviceFromSketch ? "100%" : null,
          }}
        >
          <ImageFromIkona0_0
            deviceFromSketch={deviceFromSketch}
            deviceFromCustom={deviceFromCustom}
            device={device}
            value={value}
            resizeMode={stretchSketch ? "stretch" : "contain"}
            style={{
              width: deviceFromSketch ? "100%" : 36,
              height: deviceFromSketch ? "100%" : 36,
            }}
          />
        </TouchableOpacity>
      );

    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!deviceFromCustom && !deviceFromSketch && header}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <StyledIcon style={{ marginRight: 4 }} name={"lock"} size={20} />

          {contentWithoutTime}
        </View>
        {pinDialogVisible && (
          <ModalSimple
            isVisible={pinDialogVisible}
            title={"AUTH"}
            description={"PASS_PIN"}
            placeholder={"PASS_PIN"}
            onInputChange={this.onPinInputHandler}
            keyboardType={"numeric"}
            secureTextEntry={true}
            onClose={this.handleClose}
            onOK={this.handleOK}
          />
        )}
        {wrongPinDialogVisible && (
          <ModalSimple isVisible={wrongPinDialogVisible} title={"AUTH"} description={"PIN_NOT"} onOK={this.handleOKwrongPin} />
        )}
      </View>
    );
  }
}

export default SatelOutputComponent;
