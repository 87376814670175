export const fallback = "en";

export const supportedLocales = {
  en: {
    name: "English",
    translationFileLoader: () => require("../../../languages/en.json"),
  },
  pl: {
    name: "Polski",
    translationFileLoader: () => require("../../../languages/pl.json"),
  },
  de: {
    name: "Deutsch",
    translationFileLoader: () => require("../../../languages/de.json"),
  },
  fr: {
    name: "Français",
    translationFileLoader: () => require("../../../languages/fr.json"),
  },
  it: {
    name: "Italiano",
    translationFileLoader: () => require("../../../languages/it.json"),
  },
  ar: {
    name: "عربي",
    translationFileLoader: () => require("../../../languages/ar.json"),
  },
  pt: {
    name: "Português",
    translationFileLoader: () => require("../../../languages/pt.json"),
  },
  es: {
    name: "Español",
    translationFileLoader: () => require("../../../languages/es.json"),
  },
  cs: {
    name: "Czech",
    translationFileLoader: () => require("../../../languages/cs.json"),
  },
};

export const defaultNamespace = "common";

export const namespaces = [
  "common",
  "progress",
  "account",
  "scenes",
  "weather",
  "radio",
  "satel",
  "active",
  "heating",
  "detection",
  "weekdays",
  "ir",
  "conditions",
  "statistics",
  "cloud",
];
