import React, { useEffect } from "react";
import { Text, View, ScrollView, ImageBackground, Modal } from "react-native";

import { connect } from "react-redux";
import { t } from "../../services/i18n";
import ModalSimple from "../UI/ModalSimple";
import { useTheme } from "../../../ThemeContext";
import { changeAppLocation } from "../../store/actions";
import mqttClient from "../../services/mqtt";
import ModalWithContent from "./ModalWithContent";
import * as ScreenOrientation from "expo-screen-orientation";
import { FlatList } from "react-native-gesture-handler";
import * as SplashScreen from "expo-splash-screen";
import { DeviceListHandler } from "../../utils";
import { changePopupObjectId } from "../../store/actions/statesData";

const handleLogoutOK = async (props) => {
  async function unlockLandscapeOrientation() {
    try {
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock["DEFAULT"]
      );
    } catch (error) {
      console.error(error, "error in 	ScreenOrientation.OrientationLock");
    }
  }

  unlockLandscapeOrientation();
  mqttClient.handleLogOut();
  props.onChangeAppLocation("auth");
};

const ScreenViewX = (props) => {
  const { theme } = useTheme();
  const {
    noConnectionCriticalError,
    noConnectionBarVisible,
    serverInfo,
    popupObjectId,
    devices,
    changePopupObjectId,
  } = props;
  return (
    <View
      style={[
        {
          backgroundColor: theme.APP_BACKGROUND_COLOR,
          flex: 1,
        },
        props.style,
      ]}
    >
      <ImageBackground
        source={props.bg ? { uri: `data:image/png;base64,${props.bg}` } : null}
        resizeMode="cover"
        style={{
          flex: 1,
          justifyContent: "center",
        }}
      >
        {noConnectionBarVisible && (
          <View
            style={{
              backgroundColor: "#b22424",
              height: 30,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Text style={{ color: "#fff" }}>{t("NO_CONNECTION")}</Text>
          </View>
        )}
        {serverInfo && serverInfo.get("userId") == -1 && (
          <View
            style={{
              backgroundColor: "#ff9966",
              height: 30,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Text style={{ color: "#fff" }}>{t("DEBUG CONNECTION")}</Text>
          </View>
        )}
        {noConnectionCriticalError != null && (
          <ModalSimple
            isVisible={noConnectionCriticalError != null}
            title={"CANNOT_CONNECT_TO_SERVER"}
            description={noConnectionCriticalError}
            onThirdOption={() => handleLogoutOK(props)}
            thirdOptionText={t("account:LOG_OUT_FROM_PROFILE")}
          />
        )}

        {popupObjectId != null && devices && (
          <ModalWithContent
            isVisible={true}
            title={popupObjectId.title ? popupObjectId.title : t("POPUP")}
            onClose={() => {
              changePopupObjectId(null);
            }}
          >
            <DeviceListHandler
              device={devices.get(`${popupObjectId.id}`)}
              popup={true}
            ></DeviceListHandler>
          </ModalWithContent>
        )}

        <ScrollView
          keyboardShouldPersistTaps="handled"
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            // alignItems: "center",
            // alignContent: "stretch",
            // justifyContent: "center",
            paddingHorizontal: 2.5,
            paddingTop: 5,
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {props.children}
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices:
      state.smartHomeData && state.smartHomeData.get(currentProfile)
        ? state.smartHomeData.get(currentProfile).get("devices")
        : null,
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    popupObjectId: state.statesData
      ? state.statesData.get("popupObjectId")
      : null,
    noConnectionCriticalError: state.statesData.get(
      "noConnectionCriticalError"
    ),
    serverInfo: state.statesData.get("server").get("serverInfo"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeAppLocation: (appLocation) =>
      dispatch(changeAppLocation(appLocation)),
    changePopupObjectId: (objectId) => dispatch(changePopupObjectId(objectId)),
  };
};

export const ScreenView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenViewX);
