import React, { useState, useContext } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import { useTheme } from "../../../../ThemeContext";
import ActionsLog_AccountScreen from "./ActionsLog_AccountScreen";
import Logbook_AccountScreen from "./Logbook_AccountScreen";

import { t } from "../../../services/i18n";

const Statistics_AccountScreen = () => {
	const { theme } = useTheme();
	const [index, setIndex] = useState(0);
	const [routes] = useState([
		{ key: "logbook", title: t("account:LOGBOOK") },
		{ key: "actionsLog", title: t("account:CONTROL") },
	]);

	const renderScene = SceneMap({
		actionsLog: ActionsLog_AccountScreen,
		logbook: Logbook_AccountScreen,
	});

	return (
		<TabView
			renderTabBar={(props) => (
				<TabBar
					{...props}
					indicatorStyle={{ backgroundColor: "white" }}
					style={{ backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR }}
				/>
			)}
			navigationState={{ index, routes }}
			renderScene={renderScene}
			onIndexChange={setIndex}
		/>
	);
};

export default Statistics_AccountScreen;
