import React from "react";
import { Text, View, Dimensions, StyleSheet, Platform } from "react-native";
import { WebView as RNWebView } from "react-native-webview";
import { WebView } from "WebView";

import * as Device from "expo-device";

const win = Dimensions.get("window");
const cdnPath = "https://code.highcharts.com/";
// const path = "./highcharts-files/";
const highchartsLayout =
  Platform.OS != "ios"
    ? {
        html: require("./highcharts-layout/index.js")(),
      }
    : require("./highcharts-layout/index.html");

// : { uri: "file:///android_asset/highcharts-layout/index.html" };

export default class HighchartsReactNative extends React.PureComponent {
  constructor(props) {
    super(props);

    // extract width and height from user viewStyle
    const userStyles = StyleSheet.flatten(this.props.viewStyle);

    this.state = {
      width: userStyles.width || win.width,
      height: userStyles.height || win.height,
      chartOptions: this.props.options,
      useCDN: this.props.useCDN || false,
      modules: (this.props.modules && this.props.modules.toString()) || [],
    };

    // catch rotation event
    // Dimensions.addEventListener("change", () => {
    // 	this.setState({
    // 		width: userStyles.width || Dimensions.get("window").width,
    // 		height: userStyles.height || Dimensions.get("window").height,
    // 	});
    // });
  }
  componentDidUpdate() {
    // send options for chart.update() as string to webview
    // if (this.webView) {
    // 	this.webView.postMessage(this.serialize(this.props.options, true));
    // }
  }
  /**
   * Convert JSON to string. When is updated, functions (like events.load)
   * is not wrapped in quotes.
   */
  serialize(chartOptions, isUpdate) {
    var hcFunctions = {},
      serializedOptions,
      i = 0;

    serializedOptions = JSON.stringify(chartOptions, function (val, key) {
      var fcId = "###HighchartsFunction" + i + "###";

      // set reference to function for the later replacement
      if (typeof key === "function") {
        hcFunctions[fcId] = key.toString();
        i++;
        return isUpdate ? key.toString() : fcId;
      }

      return key;
    });

    // replace ids with functions.
    if (!isUpdate) {
      Object.keys(hcFunctions).forEach(function (key) {
        serializedOptions = serializedOptions.replace(
          '"' + key + '"',
          hcFunctions[key]
        );
      });
    }

    return serializedOptions;
  }
  render() {
    const scriptsPath = cdnPath;

    //let acceleractionDisabled = Device.brand == "samsung" ? true : false;
    let acceleractionDisabled = false;

    const runFirst = `
           
           var modulesList = ${JSON.stringify(this.state.modules)};

           if (modulesList.length > 0) {
              modulesList = modulesList.split(',');
           }

           function loadScripts(file, callback, redraw, isModule) {

              var xhttp = new XMLHttpRequest();
              xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    
                    var hcScript = document.createElement('script');
                    hcScript.innerHTML = this.responseText;
                    document.body.appendChild(hcScript);

                    if (callback) {
                        callback.call();
                    }

                    if (redraw) {
                        Highcharts.chart("container", ${this.serialize(
                          this.props.options
                        )});
                    }
                }
              };
              xhttp.open("GET", '${scriptsPath}' + (isModule ? 'modules/' + file : file) + '.js', true);
              xhttp.send();
            }


            loadScripts('highcharts', function () {
                var redraw = modulesList.length > 0 ? false : true;

                loadScripts('highcharts-more', function () {
                    if (modulesList.length > 0) {
                        for (var i = 0; i < modulesList.length; i++) {
                            if (i === (modulesList.length - 1)) {
                                redraw = true;
                            } else {
                                redraw = false;
                            }
                            loadScripts(modulesList[i], undefined, redraw, true);
                        }
                    }
                }, redraw);
            }, false);
        `;

    return (
      <View
        style={[
          this.props.viewStyle,
          {
            width: this.state.width,
            height: this.state.height,
          },
        ]}
      >
        {Platform.OS != "web" ? (
          <RNWebView
            allowFileAccess={true}
            androidHardwareAccelerationDisabled={acceleractionDisabled}
            style={[
              this.props.webviewStyle,
              {
                backgroundColor: "transparent",
                borderColor: "red",
                borderWidth: 2,
                opacity: 0.99,
              },
            ]}
            ref={(webView) => (this.webView = webView)}
            injectedJavaScript={runFirst}
            source={highchartsLayout}
            originWhitelist={["*"]}
            automaticallyAdjustContentInsets={true}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            scalesPageToFit={true}
            useWebKit={true}
            scrollEnabled={true}
            mixedContentMode="always"
            onError={({ nativeEvent }) => {
              setError(
                `Error: ${nativeEvent.description}  Code: ${nativeEvent.code}`
              );
            }}
            onHttpError={({ nativeEvent }) => {
              setError(`Http Error: ${nativeEvent.statusCode}`);
            }}
          />
        ) : (
          <WebView
            allowFileAccess={true}
            androidHardwareAccelerationDisabled={acceleractionDisabled}
            style={[
              this.props.webviewStyle,
              { backgroundColor: "transparent" },
            ]}
            ref={(webView) => (this.webView = webView)}
            injectedJavaScript={runFirst}
            source={highchartsLayout}
            originWhitelist={["*"]}
            automaticallyAdjustContentInsets={true}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            scalesPageToFit={true}
            useWebKit={true}
            scrollEnabled={true}
            mixedContentMode="always"
          />
        )}
      </View>
    );
  }
}
