import React, { Component } from "react";
import { Text, View } from "react-native";

let numberToColor = (num) => {
	let color = {};
	num >>>= 0;
	(color.r = num & 0xff),
		(color.g = ((num & 0xff00) >>> 8) & 0xff),
		(color.b = ((num & 0xff0000) >>> 16) & 0xff);
	return `rgb(${color.r}, ${color.g}, ${color.b})`;
};

export default class AdjustableTextToFitSketch extends Component {
	state = {
		_style: {
			fontSize: 10,
			lineHeight: 10,
			width: null,
		},
		_adjusted: false,
	};
	_onLayout = ({ nativeEvent }) => {
		let { styleToPass } = this.props;
		let viewWidth = styleToPass.width;
		let textWidth = nativeEvent.layout.width;
		let textHeight = styleToPass.fontSize ? styleToPass.fontSize : null;

		if (!this.state._adjusted)
			if (textHeight) {
				this.setState({
					_style: {
						fontSize: textHeight,
						lineHeight: textHeight,
						width: 200,
					},
					_adjusted: true,
				});
			} else {
				this.setState({
					_style: {
						fontSize: Math.min(
							10 * Math.min(10, viewWidth / textWidth),
							this.props.styleToPass.height - 8
						),
						lineHeight: Math.min(
							10 * Math.min(10, viewWidth / textWidth),
							this.props.styleToPass.height - 8
						),
						width: viewWidth + 4,
					},
					_adjusted: true,
				});
			}
	};

	render() {
		const { styleToPass } = this.props;
		let color = styleToPass.color ? numberToColor(styleToPass.color) : "black";
		return (
			<View style={[styleToPass, this.props.addStyle]}>
				<Text
					onLayout={this._onLayout}
					// adjustsFontSizeToFit
					numberOfLines={1}
					style={[
						{
							borderWidth: styleToPass.color ? 0 : 1,
							borderColor: styleToPass.color ? null : "#ED695C",
							backgroundColor: styleToPass.color ? null : "#f4f4f4",
							padding: 1,
							height: "auto",
							textAlign: "center",
							textAlignVertical: "center",
						},
						this.state._style,
						{ color },
					]}
				>
					{this.props.children}
				</Text>
			</View>
		);
	}
}
