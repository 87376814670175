import * as Actions from "../actionTypes";

export const changeCurrentProfile = (profileName) => {
  return { type: Actions.CHANGE_CURRENT_PROFILE, payload: profileName };
};

export const saveProfile = (oldName, loginData, automaticLogin) => {
  return {
    type: Actions.SAVE_PROFILE,
    payload: {
      oldName,
      loginData,
      automaticLogin,
    },
  };
};

export const serverOwnerReceived = (cloud_owner) => {
  return {
    type: Actions.SERVER_OWNER_RECEIVED,
    payload: cloud_owner,
  };
};

export const deleteProfile = (profileName) => {
  return {
    type: Actions.DELETE_PROFILE,
    payload: profileName,
  };
};
export const changePassword = (newPassword) => {
  return {
    type: Actions.CHANGE_PASSWORD,
    payload: newPassword,
  };
};

export const changeStartingPlace = (startingPlace) => {
  return {
    type: Actions.CHANGE_STARTING_PLACE,
    payload: startingPlace,
  };
};

export const changeBackToStartTimeout = (timeout) => {
  return {
    type: Actions.CHANGE_BACK_TO_START_TIMEOUT,
    payload: timeout,
  };
};

export const changeCameraConnectionType = (deviceID, type) => {
  return {
    type: Actions.CHANGE_CAMERA_CONNECTION_TYPE,
    payload: { deviceID, type },
  };
};

export const addFavouriteColorToRGBModal = (deviceID, colorPosition, color) => {
  return {
    type: Actions.ADD_FAVOURITE_COLOR_RGB_MODAL,
    payload: { deviceID, colorPosition, color },
  };
};

export const removeFavouriteColorFromRGBModal = (deviceID, colorPosition) => {
  return {
    type: Actions.REMOVE_FAVOURITE_COLOR_RGB_MODAL,
    payload: { deviceID, colorPosition },
  };
};

export const addDeviceToFavourites = (deviceID) => {
  return {
    type: Actions.ADD_DEVICE_TO_FAVOURITES,
    payload: deviceID,
  };
};
export const saveFavourites = (favourites) => {
  return {
    type: Actions.SAVE_FAVOURITES,
    payload: favourites,
  };
};

export const changeProfileAutomaticLogin = (allowAutomaticLogin) => {
  return {
    type: Actions.CHANGE_PROFILE_AUTOMATIC_LOGIN,
    payload: allowAutomaticLogin,
  };
};

export const changeDontAsk = (dontAsk) => {
  return {
    type: Actions.SET_DONT_ASK,
    payload: dontAsk,
  };
};

export const removeDeviceFromFavourites = (deviceID) => {
  return {
    type: Actions.REMOVE_DEVICE_FROM_FAVOURITES,
    payload: deviceID,
  };
};

export const changeCloudConnectionAllowance = (cloudConnectionAllowance) => {
  return {
    type: Actions.CHANGE_CLOUD_CONNECTION_ALLOWANCE,
    payload: cloudConnectionAllowance,
  };
};

export const saveOrder = (orderArray, groupId) => {
  return {
    type: Actions.SAVE_ORDER,
    payload: { orderArray, groupId },
  };
};

export const saveGeofences = (geofences) => {
  return {
    type: Actions.SAVE_GEOFENCES,
    payload: geofences,
  };
};

export const resetOrder = () => {
  return {
    type: Actions.RESET_ORDER,
    payload: null,
  };
};

export const saveGreetingViewObjects = (greetingViewObjects) => {
  return {
    type: Actions.SAVE_GREETING_VIEW_OBJECTS,
    payload: greetingViewObjects,
  };
};
