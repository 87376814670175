import React, { Component } from "react";
import { View, StyleSheet, TouchableOpacity, Platform } from "react-native";
import mqttClient from "../../../services/mqtt";

import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import ImageForLinearInput from "../../../utils/ImageForLinearInput";

import { ComponentHeaderText, StyledIcon, SwitchStyled, SliderStyled } from "../../UI/styledComponents";
import * as Haptics from "expo-haptics";
let changeHexToPercent = (hexValue) => {
  let percentValue = (hexValue * 100) / 255;
  if (percentValue < 1) {
    percentValue = Math.floor(percentValue * 10) / 10;
  } else {
    percentValue = Math.round(percentValue);
  }

  return `${percentValue} %`;
};

let changePercentToHex = (percentValue) => {
  let hexValue = Math.round((percentValue * 255) / 100);
  return hexValue;
};

class LedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockProps: false,
      value: 0,
      deviceFromScene: false,
      switchValue: false,
      desc: null,
      res: null,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        value: Number(nextProps.deviceState.get("state")),
        switchValue: nextProps.deviceState.get("state") == 0 ? false : true,
        desc: nextProps.deviceState.get("desc"),
        res: nextProps.deviceState.get("res"),
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        value: Number(nextProps.deviceInScene.get("value")),
        switchValue: nextProps.deviceInScene.get("value") == 0 ? false : true,
        desc: nextProps.deviceInScene.get("desc"),
        res: nextProps.deviceInScene.get("res"),
      };
    }
    if (!nextProps.deviceInScene && nextProps.deviceState) {
      return {
        desc: nextProps.deviceState.get("desc") ? nextProps.deviceState.get("desc") : null,
        res: nextProps.deviceState.get("res") ? nextProps.deviceState.get("res") : null,
      };
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.value != nextState.value || this.state.desc != nextState.desc || this.state.res != nextState.res) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
  }

  increaseOne = () => {
    const { device, params_device } = this.props;
    let step = 1;
    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      step = device.get("param1");
      customRange = true;
    }
    const { value } = this.state;
    let newValue = value;

    if (!customRange) {
      if (value >= 0 && value <= 3) {
        newValue = value + 1;
      } else if (value > 3 && value < 254) {
        newValue = value + changePercentToHex(1);
      }
    } else {
      if (value >= device.get("min") && value < device.get("max")) {
        newValue = value + step;
      } else if (value < device.get("min")) {
        newValue = device.get("min");
      }
    }
    this.setState(
      {
        value: newValue,
        switchValue: newValue != 0 ? true : false,
        blockProps: true,
      },
      () => this.sendMessage(this.state.value)
    );
  };

  decreaseOne = () => {
    const { device, params_device } = this.props;
    let step = 1;
    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      step = device.get("param1");
      customRange = true;
    }
    const { value } = this.state;
    let newValue = value;

    if (!customRange) {
      if (value > 0 && value <= 4) {
        newValue = value - 1;
      } else if (value > 4 && value <= 255) {
        newValue = value - changePercentToHex(1);
      }
    } else {
      if (value > device.get("min") && value <= device.get("max")) {
        newValue = value - step;
      }
      if (value == device.get("min")) {
        newValue = 0;
      }
    }

    this.setState(
      {
        value: newValue,
        switchValue: newValue != 0 ? true : false,
        blockProps: true,
      },
      () => this.sendMessage(this.state.value)
    );
  };

  toggleSwitchValue = () => {
    const { device, params_device } = this.props;
    let value = this.state.switchValue ? 0 : 255;
    let switchValue = this.state.switchValue ? false : true;
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      customRange = true;
      value = this.state.switchValue ? 0 : device.get("max");
    }

    this.setState(
      {
        switchValue,
        value,
        blockProps: true,
      },
      () => {
        this.sendMessage(value);
      }
    );
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (value) => {
    const { params_device } = this.props;

    if (params_device && (params_device.get("params") & 0x2000000) == 0) {
      if (!this.state.blockProps) {
        this.setState({ blockProps: true });
      }
      this.sendMessage(value, true);
    }
  };

  onSlidingCompleteHandler = (value) => {
    this.setState({ value, switchValue: value != 0 ? true : false }, () => this.sendMessage(value));
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        value: Number(deviceState.get("state")),
        switchValue: Number(deviceState.get("state")) != 0 ? true : false,
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  //clearing old timeout and starting new one, to have always 5 sec
  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, fromSliderMoving) => {
    const { device, deviceInScene, changeScene, activeSceneControl } = this.props;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    if (deviceInScene) {
      let infoToUpdate = deviceInScene.set("value", value);
      changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }

    let message = `/api/set/${this.props.device.get("id")}/setValue/${value}`;
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  render() {
    const { device, header, readOnly, deviceInScene, deviceFromCustom, params_device } = this.props;

    let sliderMinValue = 0;
    let sliderMaxValue = 255;
    let customRange = false;

    let hideValue = false;

    if (params_device && (params_device.get("params") & 0x2000000) > 0) {
      hideValue = true;
    }

    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      customRange = true;
      sliderMinValue = device.get("min");
      sliderMaxValue = device.get("max");
    }

    let { value, switchValue } = this.state;
    let showSwitch = (params_device && (params_device.get("params") & 0x8000) > 0) || this.state.res;
    let showIcons = false;
    if (
      (device.has("ikona_0") && (device.get("ikona_0") !== null || device.get("ikona_1") !== null)) ||
      (device.has("ikona0") && (device.get("ikona0") !== null || device.get("ikona1") !== null))
    ) {
      showIcons = true;
    }

    let switchContent = this.state.res ? (
      <ImageForLinearInput id={this.state.res} deviceFromCustom={deviceFromCustom} />
    ) : showIcons ? (
      <TouchableOpacity onPress={this.toggleSwitchValue} disabled={readOnly}>
        <ImageFromIkona0_0 device={device} value={value} />
      </TouchableOpacity>
    ) : (
      <SwitchStyled style={{ marginLeft: 10 }} value={switchValue} onValueChange={this.toggleSwitchValue} disabled={readOnly} />
    );
    return (
      <View style={{ width: "100%" }}>
        {
          <View
            style={{
              flexDirection: "row",
              justifyContent: deviceFromCustom ? "center" : "space-between",
              alignItems: "center",
              marginBottom: readOnly ? 0 : 10,
            }}
          >
            {header}
            {!hideValue ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: 5,
                }}
              >
                <ComponentHeaderText style={{}}>
                  {!customRange
                    ? changeHexToPercent(this.state.value)
                    : this.state.desc
                    ? this.state.desc
                    : this.state.value + params_device.get("url")}
                </ComponentHeaderText>
                {showSwitch ? switchContent : null}
              </View>
            ) : null}
          </View>
        }
        {!readOnly ? (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                alignContent: "center",
              }}
            >
              <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.decreaseOne}>
                <StyledIcon name={"minus-circle"} color={"primary"} size={deviceFromCustom ? 18 : 24} />
              </TouchableOpacity>

              <SliderStyled
                value={value}
                minimumValue={sliderMinValue}
                maximumValue={sliderMaxValue}
                // onSlidingStart={this.onSlidingStartHandler}
                onValueChange={this.onSlidingChangeHandler}
                onSlidingComplete={this.onSlidingCompleteHandler}
                disabled={readOnly}
              />
              <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.increaseOne}>
                <StyledIcon name={"plus-circle"} size={deviceFromCustom ? 18 : 24} />
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
});

export default LedComponent;
