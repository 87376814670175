import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";
import { Map } from "immutable";

import { PrimaryColorText, StyledIcon } from "../../UI/styledComponents";
import DefaultIcon from "../../../screens/Dashboard/weather/defaultIcon";

// let deviceState = Map({
// 	state: "0",
// 	temp: 5,
// 	lux: 6,
// 	kLuxS: 7,
// 	kLuxE: 8,
// 	kLuxW: 9,
// 	wind: 3,
// 	weatherRain: 5,
// });
const ElsnerStationComponent = ({
  deviceState,
  params_device,
  device,
  header,
  deviceFromCustom,
}) => {
  return (
    <View>
      {header}

      <View
        style={{
          alignItems: "center",
        }}
      >
        <PrimaryColorText
          style={{ fontWeight: "bold", fontSize: 18, padding: 5 }}
        >
          {deviceState.get("kLuxS")} kLux
        </PrimaryColorText>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 10,
        }}
      >
        <PrimaryColorText
          style={{
            fontWeight: "bold",
            fontSize: 18,
            paddingTop: 10,
            paddingHorizontal: 10,
          }}
        >
          {deviceState.get("kLuxW")} kLux
        </PrimaryColorText>
        <View>
          <View style={{ overflow: "hidden", width: 104, height: 62 }}>
            <View
              style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                borderWidth: 4,
              }}
            ></View>
            <View
              style={{
                position: "absolute",
                zIndex: 10,
                right: 0,
                left: 0,
                bottom: -5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledIcon
                name={deviceState.get("weatherRain") ? "cloud-rain" : "cloud"}
                size={36}
                color={"secondary"}
              />
            </View>
          </View>
        </View>
        <PrimaryColorText
          style={{
            fontWeight: "bold",
            fontSize: 18,
            paddingTop: 10,
            paddingHorizontal: 10,
          }}
        >
          {deviceState.get("kLuxE")} kLux
        </PrimaryColorText>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "80%",
          alignSelf: "center",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <DefaultIcon name="weather-windy" library="MaterialCommunityIcons" />
          <PrimaryColorText style={{ padding: 5 }}>
            {(deviceState.get("wind") * 3.6).toFixed(1)} km/h
          </PrimaryColorText>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <DefaultIcon
            name="lightbulb-outline"
            library="MaterialCommunityIcons"
          />
          <PrimaryColorText>{deviceState.get("lux")} lux</PrimaryColorText>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <DefaultIcon name="thermometer" library="FontAwesome" />
          <PrimaryColorText>{deviceState.get("temp")} &deg;C</PrimaryColorText>
        </View>
      </View>
    </View>
  );
};

export default ElsnerStationComponent;
