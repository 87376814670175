import React, { Component, useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Animated,
  ActivityIndicator,
} from "react-native";
import { ScreenView } from "../../components/UI/screenViewX";
import {
  PrimaryColorText,
  ComponentContainer,
  StyledIcon,
  ButtonPrimary,
  SliderStyled,
} from "../../components/UI/styledComponents";
import DateTimePicker from "../../components/UI/dateTimePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import DefaultIcon from "../Dashboard/weather/defaultIcon";
import { FontAwesome } from "@expo/vector-icons";

import { t } from "../../services/i18n";
import mqttClient from "../../services/mqtt";

const DeviceComponent = ({ action, index, modifyAction }) => {
  let [time, setTime] = useState(action.time);
  const device = useSelector((state) =>
    state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("devices")
      .get(action.object_id)
  );
  const deviceState = useSelector((state) =>
    state.statesData.get("states").get(action.object_id)
  );
  const onSlidingChangeHandler = (value) => {};

  const onSlidingCompleteHandler = (value) => {
    setTime(value);
    modifyAction(value, "time", index);
  };

  const toggleValue = () => {
    // this.sendMessage(this.state.value);
    let message =
      deviceState.get("state") == "0"
        ? action.action
        : `/api/set/${action.object_id}/setValue/0`;
    mqttClient.stateChangeToSend(message, action.object_id);
  };

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText>
          {device ? device.get("opis_menu") : null}
        </PrimaryColorText>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {deviceState ? (
            <TouchableOpacity
              onPress={toggleValue}
              style={{ paddingHorizontal: 10 }}
            >
              {deviceState.get("state") == "0" ? (
                <FontAwesome
                  name="play-circle"
                  color={"rgb(50, 200, 80)"}
                  size={30}
                />
              ) : (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <ActivityIndicator size="small" style={{ paddingRight: 5 }} />
                  <FontAwesome
                    name="stop-circle"
                    color={"rgb(250, 85, 85)"}
                    size={30}
                  />
                </View>
              )}
            </TouchableOpacity>
          ) : null}
          <PrimaryColorText>{time / 6000}min</PrimaryColorText>
        </View>
      </View>

      <View
        style={{
          width: "100%",

          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SliderStyled
          value={time}
          step={6000}
          minimumValue={6000}
          //60mn * 600
          maximumValue={360000}
          onValueChange={onSlidingChangeHandler}
          onSlidingComplete={onSlidingCompleteHandler}
        />
      </View>
    </ComponentContainer>
  );
};
class Actions_IrrigationScreen extends Component {
  state = {
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
    }).start();
  };
  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  removeAction = (index) => {
    this.props.onRemoveAction(index);
  };
  render() {
    const { actions, modifyAction } = this.props;
    const { editMode } = this.state;
    return (
      <ScreenView>
        {actions &&
          actions.map((action, index) => {
            let ActionComponent;
            switch (action.type) {
              case 0:
                ActionComponent = DeviceComponent;
                break;

              default:
                break;
            }
            return (
              <View
                key={`${index}${action.type}${action.value}`}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Animated.View
                  style={[{ opacity: this.state.animationOpacity }]}
                >
                  <TouchableOpacity onPress={() => this.removeAction(index)}>
                    <View style={[styles.removeButton]}>
                      <Text
                        style={{
                          color: "white",
                          marginLeft: 1,
                          lineHeight: 13,
                          fontWeight: "bold",
                          fontSize: 10,
                        }}
                      >
                        —
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Animated.View>
                <Animated.View
                  style={{
                    marginLeft: this.state.animationMargin,
                    width: "100%",
                  }}
                >
                  <ActionComponent
                    action={action}
                    index={index}
                    modifyAction={modifyAction}
                  />
                </Animated.View>
              </View>
            );
          })}
        <View
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              // paddingHorizontal: 20,
              width: 100,
              backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
            }}
            onPress={this.toggleOnPressMode}
          >
            {editMode ? t("DONE") : t("DELETING")}
          </ButtonPrimary>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
  days: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",
    // flex: 1,
  },
});
export default Actions_IrrigationScreen;
