import * as Actions from "../actionTypes";
import { List, Map, fromJS, merge } from "immutable";

const organizeStateArray = (data, devices) => {
  if (data.length) {
    let newArray = [];
    let alarming = false;
    for (let i = 0; i < data.length; i++) {
      let newUpTime = data[i].upTime || data[i].upTime == 0 ? data[i].upTime : null;
      let newStan = { state: null };
      try {
        newStan = JSON.parse(data[i].stan_json);
      } catch {}

      newArray[data[i].id] = newStan;
      newArray[data[i].id].upTime = newUpTime;
      if (devices && devices.get(data[i].id)) {
        if (
          (devices.get(data[i].id).get("typ_komponentu") === "satel_alarm" || devices.get(data[i].id).get("typ_komponentu") === "alarm") &&
          newStan.state == "2"
        ) {
          alarming = true;
        }
      }
    }

    return { array: newArray, alarming: alarming };
  }
  return { array: data, alarming: false };
};

export const statesActiveReceived = (statesActive) => {
  return {
    type: Actions.STATES_ACTIVE_RECEIVED,
    payload: organizeStateArray(statesActive),
  };
};

export const statesReceived = (states, devices) => {
  return {
    type: Actions.STATES_RECEIVED,
    payload: organizeStateArray(states, devices),
  };
};

export const changeJanus = (clientId) => {
  return {
    type: Actions.CHANGE_JANUS,
    payload: clientId,
  };
};

export const changeClientId = (clientId) => {
  return {
    type: Actions.CHANGE_CLIENT_ID,
    payload: clientId,
  };
};

export const changeJanusId = (clientId) => {
  return {
    type: Actions.CHANGE_JANUS_ID,
    payload: clientId,
  };
};

export const changeRoute = (route) => {
  return {
    type: Actions.CHANGE_CURRENT_ROUTE,
    payload: route,
  };
};

export const clearStatesData = () => {
  return {
    type: Actions.CLEAR_STATES_DATA,
  };
};

export const changeExpoSound = (sound) => {
  return {
    type: Actions.CHANGE_EXPO_SOUND,
    payload: sound,
  };
};

export const changeSoundStatus = (status) => {
  return {
    type: Actions.CHANGE_SOUND_STATUS,
    payload: status,
  };
};

export const changeSelectedSoruces = (status) => {
  return {
    type: Actions.CHANGE_SELECTED_SOURCES,
    payload: status,
  };
};

export const changeClientData = (clientData) => {
  return {
    type: Actions.CHANGE_CLIENT_DATA,
    payload: clientData,
  };
};

export const changeBlockDimensions = (block) => {
  return {
    type: Actions.CHANGE_BLOCK_DIMENSIONS,
    payload: block,
  };
};

export const changeDimensions = (dimensions) => {
  return {
    type: Actions.CHANGE_DIMMENSIONS,
    payload: dimensions,
  };
};

export const changeAppLocation = (appLocation) => {
  return {
    type: Actions.CHANGE_APP_LOCATION,
    payload: appLocation,
  };
};

export const singleActiveStateReceived = (deviceID, message) => {
  return {
    type: Actions.SINGLE_ACTIVE_STATE_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const singleStateReceived = (deviceID, message) => {
  return {
    type: Actions.SINGLE_STATE_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const showInfoBar = (value) => {
  return {
    type: Actions.CHANGE_INFO_BAR_VISIBILTY,
    payload: value,
  };
};

export const changeConnectionStatus = (value) => {
  return {
    type: Actions.CHANGE_CONNECTION_STATUS,
    payload: value,
  };
};

export const showCriticalNoConnection = (value) => {
  return {
    type: Actions.CHANGE_NO_CONNECTION_CRITICAL,
    payload: value,
  };
};

export const showAlertScreen = (value) => {
  return {
    type: Actions.CHANGE_ALERT_SCREEN_VISIBILTY,
    payload: value,
  };
};

export const changeAppState = (value) => {
  return {
    type: Actions.CHANGE_APP_STATE,
    payload: value,
  };
};

export const changeLastTimer = (value) => {
  return {
    type: Actions.CHANGE_LAST_TIMER,
    payload: value,
  };
};

export const changeBlockLoginAutomatically = (block) => {
  return {
    type: Actions.CHANGE_BLOCK_LOGIN_AUTOMATICALLY,
    payload: block,
  };
};
export const changeFromSwitchProfile = (value) => {
  return {
    type: Actions.CHANGE_FROM_SWITCH_PROFILE,
    payload: value,
  };
};

export const changePopupObjectId = (value) => {
  return {
    type: Actions.CHANGE_POPUP_OBJECT_ID,
    payload: value,
  };
};

export const changeCurrentConnectionPlace = (value) => {
  return {
    type: Actions.CHANGE_CURRENT_CONNECTION_PLACE,
    payload: value,
  };
};

export const heatingScheduleReceived = (deviceID, message) => {
  return {
    type: Actions.HEATING_SCHEDULE_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};
export const irDataReceived = (deviceID, message) => {
  return {
    type: Actions.IR_DATA_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};
export const ir_statusReceived = (deviceID, message) => {
  return {
    type: Actions.IR_STATUS_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const accessControlReceived = (deviceID, message) => {
  return {
    type: Actions.ACCESS_CONTROL_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const barChartDataReceived = (deviceID, message) => {
  return {
    type: Actions.BAR_CHART_DATA_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const chartDataReceived = (deviceID, message) => {
  return {
    type: Actions.CHART_DATA_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const removeOutdatedHeatingSchedule = (deviceID) => {
  return {
    type: Actions.REMOVE_OUTDATED_HEATING_SCHEDULE,
    payload: deviceID.toString(),
  };
};
export const removeOutdatedAccessControl = (deviceID) => {
  return {
    type: Actions.REMOVE_OUTDATED_ACCESS_CONTROL,
    payload: deviceID.toString(),
  };
};

export const radioFavouritesReceived = (deviceID, message) => {
  return {
    type: Actions.RADIO_FAVOURITES_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const cameraStateReceived = (deviceID, message) => {
  return {
    type: Actions.CAMERA_STATE_RECEIVED,
    deviceID: deviceID,
    payload: message,
  };
};

export const serverInfoReceived = (serverInfo) => {
  return {
    type: Actions.SERVER_INFO_RECEIVED,
    payload: serverInfo,
  };
};

export const mqttVersionReceived = (mqttVersion) => {
  return {
    type: Actions.MQTT_VERSION_RECEIVED,
    payload: mqttVersion,
  };
};

export const diagnosticStatsReceived = (diagnosticStats) => {
  return {
    type: Actions.DIAGNOSTIC_STATS_RECEIVED,
    payload: diagnosticStats,
  };
};
export const initialLoadingStatusReceived = (status, percentage) => {
  return {
    type: Actions.INITIAL_LOADING_STATUS,
    payload: { status, percentage },
  };
};
export const serverUpdateInfoReceived = (serverUpdateInfo) => {
  return {
    type: Actions.SERVER_UPDATE_INFO_RECEIVED,
    payload: serverUpdateInfo,
  };
};
export const mqttUpdateInfoReceived = (mqttUpdateInfo) => {
  return {
    type: Actions.MQTT_UPDATE_INFO_RECEIVED,
    payload: mqttUpdateInfo,
  };
};

export const logbookReceived = (logbook) => {
  return {
    type: Actions.LOGBOOK_RECEIVED,
    payload: logbook,
  };
};
export const actions_logReceived = (logbook) => {
  return {
    type: Actions.ACTIONS_LOG_RECEIVED,
    payload: logbook,
  };
};

export const messagesCountReceived = (count) => {
  return {
    type: Actions.MESSAGES_COUNT_RECEIVED,
    payload: count,
  };
};

export const messagesReceived = (messages) => {
  return {
    type: Actions.MESSAGES_RECEIVED,
    payload: messages,
  };
};
export const irrigationsReceived = (irrigations) => {
  return {
    type: Actions.IRRIGATIONS_RECEIVED,
    payload: irrigations,
  };
};
export const automatizationsReceived = (automatizations) => {
  return {
    type: Actions.AUTOMATIZATIONS_RECEIVED,
    payload: automatizations,
  };
};

export const passwordChangedInfoReceived = (info) => {
  return {
    type: Actions.PASSWORD_CHANGED_INFO_RECEIVED,
    payload: info,
  };
};
