import React, { Component } from "react";
import { Text, ActivityIndicator, View, TouchableOpacity } from "react-native";
import { t } from "../../../services/i18n";

import {
	ButtonPrimary,
	TextInputStyled,
	PrimaryColorText,
} from "../../UI/styledComponents";
import PickerSelect from "../../UI/PickerSelect";
import ModalWithContent from "../../UI/ModalWithContent";

class AddFavouriteStationModal extends Component {
	state = {
		stationName: "",
		selectedStation: -1,
	};

	componentWillUnmount() {
		this.setState({ stationName: "", selectedStation: -1 });
	}
	onStationChange = (value) => {
		this.setState({ selectedStation: value });
	};

	onChangeStationNameHandler = (value) => {
		this.setState({
			stationName: value,
		});
	};

	showDialog = () => {
		this.setState({ sceneDialogVisible: true });
	};

	onClose = () => {
		this.setState({ sceneDialogVisible: false });
	};

	handleAdd = () => {
		this.props.onAdd(this.state.stationName, this.state.selectedStation);
		this.setState({ stationName: "", selectedStation: -1 });
	};

	// onGroupValueChange = value => {
	// 	this.setState({ selectedGroup: value });
	// };
	render() {
		const {
			isVisible,
			onClose,
			favouriteStationsList,
			isLoading,
			theme,
		} = this.props;
		const { selectedStation, stationName, placeholder } = this.state;
		let content = isLoading ? (
			<View
				style={{
					height: 100,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ActivityIndicator size="large" />
			</View>
		) : (
			<View>
				<View style={{ paddingBottom: 16 }}>
					<TextInputStyled
						// placeholderTextColor={theme.TAB_BAR_TEXT_COLOR}
						placeholder={t("ENTER_NAME")}
						value={stationName}
						onChangeText={this.onChangeStationNameHandler}
					/>
				</View>
				<View style={{ paddingBottom: 16 }}>
					<PrimaryColorText
						style={{
							paddingBottom: 4,
						}}
					>
						{t("CHOOSE_STATION")}
					</PrimaryColorText>
					<PickerSelect
						placeholder={{
							label: t("CHOOSE_STATION"),
							value: null,
						}}
						items={favouriteStationsList}
						onValueChange={this.onStationChange}
						value={selectedStation}
					/>
				</View>
				<ButtonPrimary
					onPress={this.handleAdd}
					disabled={stationName.length == 0}
				>
					{t("ADD")}
				</ButtonPrimary>
			</View>
		);
		return (
			<ModalWithContent
				isVisible={isVisible}
				title={t("FAVOURITE_STATIONS")}
				onClose={onClose}
			>
				{content}
			</ModalWithContent>
		);
	}
}

export default AddFavouriteStationModal;
