import React, { Component } from "react";
import { View, Text, ActivityIndicator, Platform, StyleSheet, VirtualizedList, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import { ThemeContext } from "../../../../ThemeContext";

import { logbookReceived } from "../../../store/actions";
import { PrimaryColorText, NoDataScreen } from "../../../components/UI/styledComponents";
import moment from "moment";

import TopBarOptions from "./TopBarOptions";

import mqttClient from "../../../services/mqtt";
let numberOfItems = 100;

let EventX = ({ item, event }) => {
  return (
    <View style={styles.singleLine}>
      <PrimaryColorText>{item.get("czas")}</PrimaryColorText>
      <PrimaryColorText>
        {t("statistics:EVENT_OCCURED")} {event ? event.get("opis") : item.get("wartosc")}
        {item.get("source") ? ` (${t("statistics:SOURCE")}: ${item.get("source").toString(16)})` : null}
      </PrimaryColorText>
    </View>
  );
};

const mapStateToPropsEvent = (state, ownProps) => {
  return {
    event: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("events").get(ownProps.item.get("wartosc")),
  };
};

let Event = connect(mapStateToPropsEvent)(EventX);

let numberToColor = (num) => {
  let color = {};
  num >>>= 0;
  (color.r = num & 0xff),
    (color.g = ((num & 0xff00) >>> 8) & 0xff),
    (color.b = ((num & 0xff0000) >>> 16) & 0xff),
    (color.w = ((num & 0xff000000) >>> 24) & 0xff);
  return `rgbw(${color.r}, ${color.g}, ${color.b}, ${color.w})`;
};

let Device = ({ item, deviceName, deviceType }) => {
  const descriptionForDevice = (value) => {
    let description = `${t("statistics:VALUE")}: `;
    if (["roleta", "roleta_procenty", "roleta_lamelki"].includes(deviceType)) {
      switch (value) {
        case 0:
          description += `${t("ROLLERS")} ${t("statistics:CLOSE")}`;
          break;
        case 1:
          description += `${t("ROLLERS")} ${t("statistics:OPEN")}`;
          break;
        case 2:
          description += `${t("ROLLERS")} ${t("statistics:MIDDLE")}`;
          break;
        case 3:
          description += `${t("ROLLERS")} ${t("statistics:CLOSE")}, ${t("LAMMELS")} ${t("statistics:CLOSE")}`;
          break;
        case 4:
          description += `${t("ROLLERS")} ${t("statistics:CLOSE")}, ${t("LAMMELS")} ${t("statistics:OPEN")}`;
          break;
        case 5:
          description += `${t("ROLLERS")} ${t("statistics:CLOSE")}, ${t("LAMMELS")} ${t("statistics:MIDDLE")}`;
          break;
        case 6:
          description += `${t("ROLLERS")} ${t("statistics:OPEN")}, ${t("LAMMELS")} ${t("statistics:CLOSE")}`;
          break;
        case 7:
          description += `${t("ROLLERS")} ${t("statistics:OPEN")}, ${t("LAMMELS")} ${t("statistics:OPEN")}`;
          break;
        case 8:
          description += `${t("ROLLERS")} ${t("statistics:OPEN")}, ${t("LAMMELS")} ${t("statistics:MIDDLE")}`;
          break;
        case 9:
          description += `${t("ROLLERS")} ${t("statistics:MIDDLE")}, ${t("LAMMELS")} ${t("statistics:CLOSE")}`;
          break;
        case 10:
          description += `${t("ROLLERS")} ${t("statistics:MIDDLE")}, ${t("LAMMELS")} ${t("statistics:OPEN")}`;
          break;
        case 11:
          description += `${t("ROLLERS")} ${t("statistics:MIDDLE")}, ${t("LAMMELS")} ${t("statistics:MIDDLE")}`;
          break;
        default:
          description = `${t("statistics:VALUE")}: ${value}`;
      }
    } else if (["rgb", "rgbw"].includes(deviceType)) {
      description = `${t("statistics:VALUE")}: ${numberToColor(value)}`;
    } else if (["alarm", "satel", "satel_alarm"].includes(deviceType)) {
      let text;
      switch (value) {
        case 0:
          text = "DISARMED"; //uzbroj
          break;
        case 1:
          text = "ARMED"; //rozbroj
          break;

        case 2:
          text = "ALARM"; //rozbrij
          break;

        case 3:
          text = "ALARM_DETECTION"; //dowole
          break;

        case 4:
          text = "ARMING"; //dowolne
          break;

        default:
          text = "UNKNOWN";
      }

      description = t("statistics:VALUE") + ": " + t(`satel:${text}`);
    } else {
      switch (value) {
        case 0:
          description = `${t("statistics:DEVICE_CHANGE_STATE")} OFF`;
          break;
        case 1:
          description = `${t("statistics:DEVICE_CHANGE_STATE")} ON`;
          break;
        default:
          description = `${t("statistics:VALUE")}: ${value}`;
      }
    }

    return description;
  };

  return (
    <View style={styles.singleLine}>
      <PrimaryColorText>{item.get("czas")}</PrimaryColorText>
      <PrimaryColorText>
        {t("statistics:DEVICE")}: {deviceName}
      </PrimaryColorText>
      <PrimaryColorText>{descriptionForDevice(item.get("wartosc"))}</PrimaryColorText>
    </View>
  );
};

class Logbook_AccountScreen extends Component {
  static contextType = ThemeContext;

  state = {
    isLoading: true,
    refreshing: false,
    fetchingMore: false,
    logbook: null,
    date: "last",
    deviceID: -1,
    eventNumber: -1,
    offset: 0,
    objectType: 0,
  };

  componentDidMount() {
    this.askForLogbook();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.logbook) {
      let logbook = nextProps.logbook;
      if (this.state.fetchingMore) {
        logbook = this.state.logbook.concat(nextProps.logbook);
      }
      this.setState({
        isLoading: false,
        refreshing: false,
        fetchingMore: false,
        logbook,
      });
    }
  }

  getFromToValues = (date) => {
    let inEpoch = moment(date).format("X");
    let daySeconds = 24 * 60 * 60;
    let from = Math.floor(inEpoch / daySeconds) * daySeconds;
    let to = Math.ceil(inEpoch / daySeconds) * daySeconds + daySeconds;

    return [from, to];
  };

  askForLogbook = () => {
    let { date, deviceID, offset, eventNumber, objectType } = this.state;
    let [from, to] = [0, 0];
    let timeRange = 5;

    //wcześniej
    if (date != "last") {
      [from, to] = this.getFromToValues(date);
      timeRange = 6;
    }
    // let message = `/api/json/logbook/${timeRange}/${objectType}/${fr  om}/${to}/${deviceID}/${numberOfItems}/${offset}/${eventNumber}`;
    let message = `/api/json/logbook/${timeRange}/${objectType}/${from}/${to}/${deviceID}/${numberOfItems}/${offset}/${eventNumber}`;
    mqttClient.askForLogbook(message);
    this.props.logbookReceived(null);
  };
  onRefresh = () => {
    this.setState(
      {
        refreshing: true,
        offset: 0,
      },
      () => {
        this.askForLogbook();
      }
    );
  };

  onEndReached = () => {
    const { logbook, offset, fetchingMore } = this.state;

    if (!fetchingMore && !(logbook.size <= offset) && !(logbook.size < numberOfItems)) {
      this.setState(
        (prevstate) => ({
          offset: prevstate.offset + numberOfItems,
          fetchingMore: true,
        }),
        () => {
          this.askForLogbook();
        }
      );
    }
  };

  optionChanged = (value, type) => {
    let deviceID = this.state.deviceID;
    let eventNumber = this.state.eventNumber;
    let objectType = this.state.objectType;
    let date = this.state.date;
    if (type == "allDevices") {
      objectType = 0;
      deviceID = -1;
      eventNumber = -1;
    } else if (type == "date") {
      date = value;
    } else if (type == "singleDevice") {
      objectType = 0;
      deviceID = value;
      eventNumber = -1;
    } else if (type == "allEvents") {
      deviceID = -1;
      objectType = 43;
      eventNumber = -1;
    } else if (type == "singleEvent") {
      deviceID = -1;
      objectType = 43;
      eventNumber = value;
    }
    this.setState({ date, deviceID, eventNumber, isLoading: true, offset: 0, objectType }, () => {
      this.askForLogbook();
    });
  };

  render() {
    const { devices } = this.props;
    const { theme } = this.context;
    const { refreshing, fetchingMore, logbook } = this.state;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        }}
      >
        <TopBarOptions optionChanged={this.optionChanged} showEvents={true} />
        {this.state.isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" />
          </View>
        ) : (
          logbook && (
            <VirtualizedList
              data={logbook.sort((l1, l2) =>
                l1.get("czas") < l2.get("czas") ? 1 : l1.get("czas") === l2.get("czas") && l1.get("id") < l2.get("id") ? 1 : -1
              )}
              refreshControl={
                <RefreshControl
                  //refresh control used for the Pull to Refresh
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                />
              }
              contentContainerStyle={{ flexGrow: 1 }}
              ListEmptyComponent={<NoDataScreen />}
              getItem={(data, index) => data.get(index)}
              getItemCount={(data) => data.size}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={10}
              renderItem={({ item, index }) =>
                item && item.get("id_obiektu") == 0 ? (
                  <Event item={item} />
                ) : devices.get(item.get("id_obiektu")) ? (
                  <Device
                    item={item}
                    deviceName={devices.get(item.get("id_obiektu")).get("opis_menu")}
                    deviceType={devices.get(item.get("id_obiektu")).get("typ_komponentu")}
                  />
                ) : null
              }
              keyExtractor={(item, index) => `${item.get("id_obiektu")} - ${index}`}
            />
          )
        )}

        {fetchingMore ? (
          <View style={{ padding: 10 }}>
            <ActivityIndicator size="small" />
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleLine: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    logbook: state.statesData.get("logbook"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logbookReceived: (reset) => dispatch(logbookReceived(reset)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logbook_AccountScreen);
