import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { t } from "../../services/i18n";
import { AppState, Text, TouchableOpacity, View, Platform, ActivityIndicator } from "react-native";
import { activateKeepAwakeAsync, deactivateKeepAwake } from "expo-keep-awake";
import moment from "moment";
import * as Linking from "expo-linking";
import { ThemeContext } from "../../../ThemeContext";
import ModalSimple from "../../components/UI/ModalSimple";
import * as Application from "expo-application";
import CLOUD_CONSTANTS from "../../../CloudConstants";
import { checkIfDeviceInAssociactionsNoDownload, fetchMetaData } from "../Initial/CloudTools";
import CloudIntegration from "../Initial/CloudIntegration";
import { ScreenView } from "../../components/UI/screenViewX";
import { ComponentContainer, ComponentHeaderText, SwitchStyled, StyledIcon } from "../../components/UI/styledComponents";
import {
  fetchWeatherApi,
  serverOwnerReceived,
  changeDontAsk,
  changeAppLogout,
  changeAppLocation,
  changeQuestionnaire,
  authDataReceived,
  associationsDataReceived,
  clearStatesData,
  changeConsentsTime,
  changeConsentsStatus,
} from "../../store/actions";
//ERROR IN WEB FROM BELOW

import * as WebBrowser from "expo-web-browser";
import Tooltip from "react-native-walkthrough-tooltip";
import { devicesForGroups, scenesForGroups, SceneInGroup, DevicesToShowByColumns } from "../../utils";

import GreetingView from "./GreetingView";
import CalendarWeatherRow from "./CalendarWeatherRow";
import mqttClient from "../../services/mqtt";
import { stringify } from "uuid";

class DashboardScreen extends PureComponent {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    window.dashboard = this;

    this.state = {
      notification: {},
      appState: AppState.currentState,
      simulationActive: false,
      appOwnerAssociate: false,
      toolTipVisible: false,
      ownerChecked: false,
      appLongerThanMonth: false,
      askAgainAfterMonth: false,
    };
  }

  currentConnectionTypeShownHandler = () => {
    const { currentConnectionType, navigation } = this.props;
    let statusIcon = "cloud";
    let statusString = "CLOUD_CONNECTION";
    if (currentConnectionType === "cloud") {
      statusIcon = "cloud-lightning";
    } else if (currentConnectionType === "loader") {
      statusIcon = "loader";
      statusString = "progress:WAITING_FOR_CONNECTION";
    } else if (currentConnectionType === "local" || currentConnectionType === "local2") {
      statusIcon = "wifi";
      statusString = "LOCAL_CONNECTION";
    }

    navigation.setOptions({
      headerLeft: () => (
        <Tooltip
          isVisible={this.state.toolTipVisible}
          content={<Text>{t(statusString)}</Text>}
          placement="bottom"
          onClose={() => this.setState({ toolTipVisible: false })}
        >
          <TouchableOpacity style={{ padding: 16, marginTop: 0, padding: 15 }} onPress={() => this.setState({ toolTipVisible: true })}>
            {statusIcon == "loader" ? (
              <ActivityIndicator size="small" color="#ffffff" />
            ) : (
              <StyledIcon name={statusIcon} color={"tabBar"} size={16} />
            )}
          </TouchableOpacity>
        </Tooltip>
      ),
    });
  };

  messageIndicatorShownHandler = () => {
    const { globalSettings, navigation, messages, messagesCount } = this.props;
    let messagesIndicatorShown = globalSettings.get("messagesIndicatorShown") == undefined ? true : globalSettings.get("messagesIndicatorShown");

    let permission = this.props.serverInfo.get("perm");
    let permissionForMessageCenter = (permission & 0x1000) == 0;
    if (!permissionForMessageCenter) messagesIndicatorShown = false;

    if (messagesIndicatorShown) {
      let messagesNumber = 0;
      if (messagesCount) {
        messagesNumber = Number(messagesCount);
      } else if (messages) {
        messages.map((message) => {
          if (message.get("state") === 0) {
            messagesNumber++;
          }
        });
      }

      let messagesIndicator =
        messagesNumber !== 0 ? (
          <View
            style={{
              position: "absolute",
              top: -5,
              right: -8,
              minWidth: 15,
              height: 15,
              borderRadius: 15,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#4ED164",
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 10,
              }}
            >
              {messagesNumber}
            </Text>
          </View>
        ) : null;

      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity style={{ marginRight: 20 }} onPress={() => navigation.navigate("Message_Center_Account")}>
            <StyledIcon name={"message-circle"} color={"tabBar"} size={24} />
            {messagesIndicator}
          </TouchableOpacity>
        ),
      });
    } else {
      navigation.setOptions({
        headerRight: () => null,
      });
    }
  };

  checkForOwner = () => {
    const { currentConnectionType, associationData, authData } = this.props;

    setTimeout(() => {
      if (
        this.props.device_id &&
        this.props.device_id !== "" &&
        this.props.owner === null &&
        this.props.dontAsk !== true &&
        Platform.OS != "web" &&
        !this.state.ownerChecked &&
        authData &&
        currentConnectionType !== "new_cloud" &&
        !checkIfDeviceInAssociactionsNoDownload(associationData, this.props.device_id)
      ) {
        this.setState({
          ownerChecked: true,
          appOwnerAssociate: true,
        });
      }
    }, 1000);
  };

  checkForInstallationDate = async () => {
    try {
      let date = await Application.getInstallationTimeAsync();
      let now = moment().format("X");
      let diff = (now - moment(date).unix()) / (60 * 60 * 24);
      if (diff > 30) {
        this.setState({ appLongerThanMonth: true });
      }

      const { globalSettings } = this.props;
      const questionnaire = globalSettings.get("questionnaire");
      if (questionnaire && questionnaire.get("date") && questionnaire.get("response") == "LATER") {
        diff = (now - questionnaire.get("date")) / (60 * 60 * 24);
        if (diff > 30) {
          this.setState({ askAgainAfterMonth: true });
        } else {
          this.setState({ askAgainAfterMonth: false });
        }
      }
    } catch {}
  };

  componentDidMount() {
    this.appStateSubscription = AppState.addEventListener("change", this._handleAppStateChange);
    const { devices, params_devices } = this.props;
    this.messageIndicatorShownHandler();
    this.currentConnectionTypeShownHandler();
    this.checkForOwner();
    this.checkForInstallationDate();
    let simulationActive = false;
    let simulationDevice = devices
      ? devices.find((el) => {
          return el && el.get("typ_komponentu") == "symulacja";
        })
      : null;
    if (simulationDevice && simulationDevice.get("id") && params_devices.get(simulationDevice.get("id"))) {
      let simulationParams = params_devices.get(simulationDevice.get("id"));
      simulationActive = Number(simulationParams.get("czas")) > 0 ? true : false;
    }
    this.setState({
      simulationActive,
    });
    this.props.cityForWeather && mqttClient.askForWeatherState();
    this.weatherInterval = setInterval(() => {
      try {
        //console.log("Fetching the API");
        this.fetchWeatherApi();
      } catch (ex) {}
    }, 1000 * 60 * 60);
  }

  fetchWeatherApi = () => {
    if (this.props.cityForWeather) {
      this.props.onfetchWeatherApi(this.props.cityForWeather);
    } else {
      return null;
    }
  };

  setKeepAwake = async (prevProps) => {
    const { globalSettings } = this.props;
    if (prevProps.globalSettings.get("keepAwakeOn") != globalSettings.get("keepAwakeOn")) {
      if (globalSettings.get("keepAwakeOn")) {
        activateKeepAwakeAsync();
      } else {
        try {
          deactivateKeepAwake();
        } catch {}
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { devices, params_devices, globalSettings, messages, messagesCount } = this.props;

    if (
      prevProps.globalSettings.get("messagesIndicatorShown") != globalSettings.get("messagesIndicatorShown") ||
      prevProps.messages != messages ||
      prevProps.messagesCount != messagesCount
    ) {
      this.messageIndicatorShownHandler();
    }
    this.setKeepAwake(prevProps);
    this.checkForOwner();
    this.currentConnectionTypeShownHandler();

    let simulationDevice = devices.find((el) => {
      return el && el.get("typ_komponentu") == "symulacja";
    });
    if (simulationDevice && simulationDevice.get("id") && params_devices.get(simulationDevice.get("id"))) {
      let simulationParams = params_devices.get(simulationDevice.get("id"));
      let simulationActive = Number(simulationParams.get("czas")) > 0 ? true : false;
      if (prevState.simulationActive != simulationActive) {
        this.setState({ simulationActive });
      }
    }
  }

  _handleAppStateChange = async (nextAppState) => {
    if (this.state.appState === "background" && nextAppState === "active") {
      this.fetchWeatherApi();
      this.setState({ appState: nextAppState });
    } else if (this.state.appState === "active" && nextAppState == "background") {
      clearInterval(this.weatherInterval);
      this.setState({ appState: nextAppState });
    }
  };

  componentWillUnmount() {
    clearInterval(this.weatherInterval);
    this.appStateSubscription.remove();
  }

  changeSimulationActivity = (value) => {
    this.setState({
      simulationActive: value,
    });
    let message = `/api/json/simulation/${value ? "active" : "deactive"}`;
    mqttClient.changeSimulationActivity(message);
  };

  renderComponentsInOrder = () => {
    const { devices, group_devices, globalSettings, scenes, order } = this.props;
    let listOfDevices = devicesForGroups(group_devices, 0, order);
    let listOfScenes = scenesForGroups(scenes, 0, order);
    let scenyDoWyswietlenie = listOfScenes
      ? listOfScenes.map((scene) => {
          return <SceneInGroup key={scene.get("sceneName")} scene={scene} />;
        })
      : null;

    let generalOrder = order && order.get("general") ? order.get("general") : ["scenes", "groups", "devices"];
    const componentsInOrder = generalOrder.map((c) => {
      switch (c) {
        case "scenes":
          return scenyDoWyswietlenie;
        case "devices":
          return <DevicesToShowByColumns key={c} listOfDevices={listOfDevices} devices={devices} />;
        case "groups":
          return null;
        default:
          return null;
      }
    });

    return componentsInOrder.map((Component) => {
      if (Component) return Component;
    });
  };

  handleFormClose = () => {
    this.props.changeQuestionnaire({
      response: "LATER",
      date: moment().unix(),
    });
    this.checkForInstallationDate();
  };

  handleFormOk = () => {
    this.props.changeQuestionnaire({
      response: "OK",
      date: moment().unix(),
    });
    setTimeout(async () => {
      let result = await WebBrowser.openBrowserAsync("https://forms.office.com/r/KGec4yrkD2");
    }, 100);
  };

  handleAssociateClose = () => {
    this.setState({
      appOwnerAssociate: false,
      ownerChecked: true,
    });
  };

  handleAssociateOk = () => {
    this.setState({
      appOwnerAssociate: false,
      ownerChecked: true,
    });
    //this.handleOpenCloud();
  };
  handleAssociateDontAsk = () => {
    this.setState({
      appOwnerAssociate: false,
    });

    this.props.changeDontAsk(true);
  };

  _addLinkingListener = () => {
    Linking.addEventListener("url", this._handleRedirect);
  };

  _removeLinkingListener = () => {
    Linking.removeEventListener("url", this._handleRedirect);
  };

  _handleRedirect = (event) => {
    if (Platform.OS === "ios") {
      WebBrowser.dismissBrowser();
    } else {
      this._removeLinkingListener();
    }
    let data = Linking.parse(event.url);
    const { authData } = this.props;
    if (data.path === "consent" && data.queryParams.status === "ok" && authData) {
      this.handleConsents(authData.toJS());
    } else if (data.path === "consent" && authData) {
      window.app.showToast(t("cloud:CONSENT_ERR"));
      this.handleConsents(authData.toJS());
    }
  };

  handleConsents = async (accessData) => {
    if (accessData && accessData.access_token) {
      let metaData = await fetchMetaData(accessData);
      if (metaData && metaData.user && metaData.user.requiredConsentsGranted === true) {
        this.props.changeConsentsTime(moment().unix());
        this.props.changeConsentsStatus(0);
        console.log("consents ok!");
      } else if (metaData && metaData.user && metaData.user.requiredConsentsGranted === false) {
        this.props.changeConsentsStatus(1);
        console.log("Need consents!");
      } else {
        console.log("Missing consents!");
      }
    } else {
      console.log("No access_token to fetch meta data");
    }
  };

  handleConsentsClose = async () => {
    await this.props.authDataReceived(null);
    await this.props.associationsDataReceived(null);
    await this.props.changeAppLogout(true);
    mqttClient.handleLogOut();
    await this.props.changeAppLocation("auth");
    await this.props.clearStatesData();
  };

  handleAskConsents = async () => {
    const { authData } = this.props;
    if (authData) {
      let consentUrl = `${CLOUD_CONSTANTS.consentUrl}?access_token=${authData.get("access_token")}&redirect_uri=${
        CLOUD_CONSTANTS.redirect_uri
      }consent`;
      this._addLinkingListener();
      setTimeout(async () => {
        let result = await WebBrowser.openBrowserAsync(consentUrl);
        this.handleConsents(authData.toJS());
      }, 100);
    }
  };

  render() {
    const { devices, authData, globalSettings, scenes, order, device_id, currentConnectionType, currentProfile, dimensions } = this.props;
    const { simulationActive, appOwnerAssociate } = this.state;
    const weatherShown = globalSettings.get("weatherShown");
    const calendarShown = globalSettings.get("calendarShown");
    const questionnaire = globalSettings.get("questionnaire");
    const simulationDashboardShown = globalSettings.get("simulationDashboardShown");

    const greetingViewShown = globalSettings.get("greetingViewShown");
    const showConsentsToSign = globalSettings.get("consentsStatus");
    let simulation = devices.find((el) => {
      return el && el.get("typ_komponentu") == "symulacja";
    });

    return (
      <ScreenView>
        {showConsentsToSign === 1 && (
          <ModalSimple
            isVisible={showConsentsToSign === 1 && authData ? true : false}
            title={"cloud:NO_CONSENTS"}
            description={"cloud:ASK_TO_CONSENTS"}
            onClose={this.handleConsentsClose}
            onOK={this.handleAskConsents}
            onCloseText={t("cloud:LOGOUT")}
          />
        )}
        {greetingViewShown && <GreetingView />}
        {weatherShown || calendarShown ? (
          <CalendarWeatherRow weatherShown={weatherShown} calendarShown={calendarShown} dimensions={dimensions} />
        ) : null}
        {simulation && simulationDashboardShown && (
          <ComponentContainer
            containerStyle={{ width: "100%" }}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ComponentHeaderText style={{ paddingLeft: 10 }}>{t("account:SIMULATION")}</ComponentHeaderText>
            <SwitchStyled value={simulationActive} onValueChange={this.changeSimulationActivity} />
          </ComponentContainer>
        )}
        {this.renderComponentsInOrder()}
        {appOwnerAssociate && (
          <ModalSimple
            isVisible={appOwnerAssociate}
            title={"cloud:NO_OWNER"}
            description={"cloud:ASK_TO_ASSOCIATE"}
            onClose={this.handleAssociateClose}
            onThirdOption={this.handleAssociateDontAsk}
            device_id={device_id}
            isWithCloudButton={true}
            onOK={this.handleAssociateClose}
          />
        )}

        {(!questionnaire || this.state.askAgainAfterMonth) &&
          Platform.OS !== "web" &&
          !appOwnerAssociate &&
          this.state.appLongerThanMonth &&
          currentProfile != "demo" && (
            <ModalSimple
              isVisible={true}
              title={"FORM"}
              description={"ARE_YOU_SATTISFIED"}
              onClose={this.handleFormOk}
              onCloseText={t("LEAVE_FEEDBACK")}
              onOK={this.handleFormClose}
              onOKText={t("NOT_NOW")}
            />
          )}
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    currentProfile: currentProfile,
    cityForWeather: state.profilesSettings.get(currentProfile).get("cityForWeather"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    order: state.profilesSettings.get(currentProfile).get("order"),
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    scenes: state.smartHomeData.get(currentProfile).get("scenes"),
    params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
    globalSettings: state.globalSettings,
    serverInfo: state.statesData.get("server").get("serverInfo"),
    currentConnectionType: state.statesData.get("currentConnection"),
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    associationData: state.cloudData ? state.cloudData.get("associationsData") : null,
    messages: state.statesData.get("messages"),
    messagesCount: state.statesData.get("messages_count"),
    owner: state.profilesSettings.get(currentProfile).get("cloud_owner"),
    device_id: state.profilesSettings.get(currentProfile).get("loginData").get("device_id"),
    dontAsk: state.profilesSettings.get(currentProfile).get("dontAsk"),
    dimensions: state.statesData.get("dimensions"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearStatesData: () => dispatch(clearStatesData()),
    authDataReceived: (authData) => dispatch(authDataReceived(authData)),
    associationsDataReceived: (associationsData) => dispatch(associationsDataReceived(associationsData)),
    changeAppLogout: (value) => dispatch(changeAppLogout(value)),
    changeAppLocation: (appLocation) => dispatch(changeAppLocation(appLocation)),
    onfetchWeatherApi: (city) => dispatch(fetchWeatherApi(city)),
    serverOwnerReceived: (cloud_owner) => dispatch(serverOwnerReceived(cloud_owner)),
    changeDontAsk: (dontAsk) => dispatch(changeDontAsk(dontAsk)),
    changeQuestionnaire: (questionnaireResponse) => dispatch(changeQuestionnaire(questionnaireResponse)),
    changeConsentsTime: (time) => dispatch(changeConsentsTime(time)),
    changeConsentsStatus: (status) => dispatch(changeConsentsStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
