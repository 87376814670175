import React, { Component } from "react";
import { Text, View, ActivityIndicator } from "react-native";

import { t } from "../../services/i18n";
import moment from "moment";
import { ComponentContainer, TextInputStyled, PrimaryColorText, ButtonPrimary } from "../../components/UI/styledComponents";
import PickerSelect from "../../components/UI/PickerSelect";
import DaysRow from "./DaysRow";
import DatePickerRow from "./DatePickerRow";
import { acc } from "react-native-reanimated";
import NfcManager, { NfcTech, NfcEvents } from "react-native-nfc-manager";
import ActionSheet from "../../utils/ActionSheet";
import { Platform } from "react-native";

class AccessControlSingle extends Component {
  types = [
    { label: t("ALWAYS"), value: 1 },
    { label: t("FROM_TO"), value: 2 },
    { label: t("BY_SCHEDULE"), value: 3 },
  ];

  securityTypes = [
    { label: t("PIN"), value: 1 },
    { label: t("CARD"), value: 2 },
  ];
  state = {
    accessObject: this.props.accessObject,
    editMode: false,
    selectedEvent: null,
    value: 0,
    showWebActionSheet: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && prevState.editMode && nextProps.deviceState && nextProps.deviceState.get("state") > 0) {
      let accessObject = Object.assign({}, prevState.accessObject);
      accessObject.card = Number(nextProps.deviceState.get("state"));
      nextProps.onAccessObjectChange(accessObject, nextProps.index);
      return {
        accessObject: accessObject,
        editMode: false,
      };
    }
    return null;
  }

  listenToNfcEventOnce = async () => {
    try {
      // register for the NFC tag with NDEF in it
      await NfcManager.requestTechnology(NfcTech.MifareIOS);
      //NfcManager.requestTechnology();
      // the resolved tag object will contain `ndefMessage` property

      const tag = await NfcManager.getTag();
      let accessObject = Object.assign({}, this.state.accessObject);
      accessObject.card = Number("0x" + tag.id.substring(0, 8));
      this.setState({ accessObject, editMode: false }, () => {
        this.props.onAccessObjectChange(this.state.accessObject, this.props.index);
      });
    } catch (ex) {
      this.setState({ editMode: false });
    } finally {
      // stop the nfc scanning

      NfcManager.cancelTechnologyRequest();
    }
  };

  onPinChange = (pin) => {
    if (pin.length > 9) {
      alert(t("MAX_LENGHT_9"));
    } else {
      let accessObject = Object.assign({}, this.state.accessObject);
      accessObject.pin = pin;
      this.setState({ accessObject }, () => {
        this.props.onAccessObjectChange(this.state.accessObject, this.props.index);
      });
    }
  };

  changeType = (value) => {
    let accessObject = Object.assign({}, this.state.accessObject);
    accessObject.type = value;
    if (value == 1) {
      accessObject = {
        pin: this.state.accessObject.pin,
        card: 0,
        type: 1,
        security: 2,
        eventNum: 0,
      };
    } else if (value == 2) {
      accessObject.schedule = {
        s: Number(moment().subtract(1, "hours").format("X")),
        e: Number(moment().format("X")),
      };
    } else if (value == 3) {
      accessObject.schedule = {
        s: "10:00",
        e: "16:00",
        dayMask: 62,
      };
    }
    this.setState({ accessObject }, () => {
      this.props.onAccessObjectChange(this.state.accessObject, this.props.index);
    });
  };

  changeSecurityType = (value) => {
    let accessObject = Object.assign({}, this.state.accessObject);
    accessObject.security = value;

    if (value == 1) {
      accessObject.pin = "0";
    } else if (value == 2) {
      accessObject.card = 0;
    }

    this.setState({ accessObject }, () => {
      this.props.onAccessObjectChange(this.state.accessObject, this.props.index);
    });
  };

  onTypeChange = (value) => {
    this.changeType(value);
  };

  onSecurityTpeChange = (value) => {
    this.changeSecurityType(value);
  };

  onTypeClose = (value) => {
    // this.changeType(value);
  };

  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;

    if (newEditMode) {
      if (Platform.OS != "web") {
        this.ActionSheet.show();
      } else {
        this.setState({ showWebActionSheet: true });
      }
    } else {
      this.setState({ editMode: false, value: null, propValue: null });
    }
  };

  handleOnPressAction = (index) => {
    this.props.singleStateReceived(this.props.device.get("id"), { state: 0 });
    this.setState({ editMode: true, value: null, propValue: null });
    if (index == 1) {
      this.listenToNfcEventOnce();
    }
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
  };

  onEventValueChange = (value) => {
    let accessObject = Object.assign({}, this.state.accessObject);
    accessObject.eventNum = Number(value);
    this.setState({ accessObject }, () => {
      this.props.onAccessObjectChange(this.state.accessObject, this.props.index);
    });
  };

  onScheduleChange = (sendValue, key) => {
    let accessObject = Object.assign({}, this.state.accessObject); //creating copy of object in state

    if (key == "s" || key == "e") {
      accessObject.schedule[key] = sendValue;
    } else if (key == "dayMask") {
      accessObject.schedule.dayMask = sendValue;
    }

    this.setState({ accessObject }, () => {
      this.props.onAccessObjectChange(this.state.accessObject, this.props.index);
    });
  };

  setEvents = () => {
    const { events } = this.props;

    let eventsToSend = [];

    events &&
      events.map((event) => {
        if (event && event.get("opis") && event.get("wartoscN")) {
          eventsToSend.push({
            label: event.get("opis"),
            value: event.get("wartoscN"),
          });
        }
      });

    return eventsToSend;
  };

  render() {
    const { accessObject, editMode, value } = this.state;
    let events = this.setEvents();
    if (!accessObject.card) accessObject.card = "";
    if (!accessObject.pin) accessObject.pin = "";

    //console.log(accessObject);
    return (
      <ComponentContainer>
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={[t("GLASS_PANEL"), t("INTERNAL_READER"), t("CLOSE")]}
          cancelButtonIndex={2}
          onPress={(index) => {
            this.handleOnPressAction(index);
          }}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ width: 120 }}>
            <PickerSelect
              style={{
                flex: 1,
              }}
              placeholder={{
                label: t("CHOOSE_OPTION"),
                value: null,
              }}
              items={this.securityTypes}
              onValueChange={this.onSecurityTpeChange}
              value={accessObject.security}
            />
          </View>
          <TextInputStyled
            keyboardType={"numeric"}
            value={accessObject.security == 1 ? accessObject.pin.toString() : accessObject.security == 2 ? accessObject.card.toString() : null}
            style={{ width: 100 }}
            editable={accessObject.security == 1 ? true : false}
            placeholder={accessObject.security == 1 ? t("PIN") : accessObject.security == 2 ? t("CARD") : "UNKNOWN"}
            onChangeText={accessObject.security == 1 ? (value) => this.onPinChange(value) : null}
          />
          {accessObject.security == 2 && editMode ? <ActivityIndicator size="small" /> : null}
          {accessObject.security == 2 ? (
            <ButtonPrimary
              style={{
                marginVertical: 10,
                paddingVertical: 5,
                minWidth: 120,
                backgroundColor: !editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
              }}
              onPress={this.toggleOnPressMode}
            >
              {editMode ? t("STOP") : t("ADD")}
            </ButtonPrimary>
          ) : null}
        </View>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 100,
            }}
          >
            {t("OPTION")}:
          </PrimaryColorText>
          <View style={{ flex: 1 }}>
            <PickerSelect
              placeholder={{
                label: t("CHOOSE_OPTION"),

                value: null,
              }}
              containerStyle={{ flex: 1 }}
              items={this.types}
              onValueChange={this.onTypeChange}
              onClose={this.onTypeClose}
              value={accessObject.type}
            />
          </View>
        </View>

        {accessObject.type == 2 || accessObject.type == 3 ? (
          <DatePickerRow
            schedule={accessObject.schedule}
            mode={accessObject.type == 2 ? "datetime" : "time"}
            onScheduleChange={this.onScheduleChange}
          />
        ) : null}
        {accessObject.type == 3 ? <DaysRow dayMask={accessObject.schedule.dayMask} onScheduleChange={this.onScheduleChange} /> : null}

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            marginTop: 10,
          }}
        >
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 100,
            }}
          >
            {t("EVENT")}:
          </PrimaryColorText>
          <View style={{ width: "50%" }}>
            <PickerSelect
              placeholder={{
                label: accessObject.eventNum > 0 ? accessObject.eventNum.toString() : t("SELECT_EVENT_NAME"),

                value: null,
              }}
              items={events}
              onValueChange={this.onEventValueChange}
              value={accessObject.eventNum}
            />
          </View>
          <TextInputStyled
            keyboardType={"numeric"}
            placeholder={accessObject.eventNum ? accessObject.eventNum.toString() : "0"}
            onEndEditing={(value) => this.onEventValueChange(value.nativeEvent.text)}
          />
        </View>
      </ComponentContainer>
    );
  }
}

export default AccessControlSingle;
