import React, { Component } from "react";
import {
	Text,
	ActivityIndicator,
	View,
	TouchableOpacity,
	ScrollView,
} from "react-native";
import { t } from "../../../services/i18n";
import { Feather } from "@expo/vector-icons";

import ModalWithContent from "../../UI/ModalWithContent";
import {
	PrimaryColorText,
	SecondaryColorText,
} from "../../UI/styledComponents";

class FavouriteStationsModal extends Component {
	onAddHandler = () => {
		this.props.onAdd(this.state.selectedDevice);
	};

	actionHandler = (num, action) => {
		this.props.onActionFromStationList(num, action);
	};

	render() {
		const { isVisible, onClose, favouriteStations, isLoading } = this.props;
		let list = [];
		if (favouriteStations) {
			let element = {};
			for (let i = 0; i < 16; i++) {
				element = (
					<View
						style={{ flexDirection: "row", alignItems: "center", height: 30 }}
						key={i}
					>
						<PrimaryColorText style={{ fontWeight: "bold", paddingRight: 4 }}>
							{i + 1}.{" "}
						</PrimaryColorText>

						{favouriteStations.some((el) => {
							return el.get("num") == i;
						}) ? (
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									flex: 1,
								}}
							>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}
									onPress={() => this.actionHandler(i, "playFav")}
								>
									<Feather
										style={{ paddingRight: 5 }}
										name={"play-circle"}
										size={22}
										color={"green"}
									/>
									<PrimaryColorText>
										{t("NAME")}:{" "}
										{favouriteStations
											.find((x) => x.get("num") == i)
											.get("name")}
										, Freq:{" "}
										{favouriteStations
											.find((x) => x.get("num") == i)
											.get("freq")}
									</PrimaryColorText>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										alignItems: "center",
										paddingHorizontal: 6,
									}}
									onPress={() => this.actionHandler(i, "removeFav")}
								>
									<Feather name={"minus-circle"} size={22} color={"red"} />
								</TouchableOpacity>
							</View>
						) : (
							<SecondaryColorText>{t("NO_FAVOURITE_RADIO")}</SecondaryColorText>
						)}
					</View>
				);

				list.push(element);
			}
		}

		return (
			<ModalWithContent
				isVisible={isVisible}
				title={t("FAVOURITE_STATIONS")}
				onClose={onClose}
				modalHeight={"90%"}
			>
				{isLoading ? (
					<View
						style={{
							height: 100,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<ActivityIndicator size="large" />
					</View>
				) : (
					<ScrollView>{list}</ScrollView>
				)}
			</ModalWithContent>
		);
	}
}

export default FavouriteStationsModal;
