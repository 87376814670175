import React, { useState, useContext } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";

import { useTheme } from "../../../ThemeContext";

import DateTimePickerModal from "react-native-modal-datetime-picker";

import { PrimaryColorText, StyledIcon } from "./styledComponents";

const InputDataPicker = ({ inputStyle, value, onPress }) => {
  const { theme } = useTheme();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          flexDirection: "row",
          // width: "100%",
          borderWidth: 1,
          paddingVertical: 4,
          paddingHorizontal: 8,
          height: 27,
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 4,
          borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
          // marginRight: 20
        },
        inputStyle,
      ]}
    >
      <PrimaryColorText
        style={{
          fontSize: 12,
        }}
      >
        {value}
      </PrimaryColorText>
      <StyledIcon name="chevron-down" size={18} color="secondary" />
    </TouchableOpacity>
  );
};

const DateTimePickerX = (props) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  // const onChange = (value) => {
  // 	console.log(value, "picker value");
  // };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date) => {
    hideDatePicker();
    props.onConfirm(date);
  };
  // const DateTimePickerModal = Platform.select({
  // 	web: () => null,
  // 	ios: () => require("react-native-modal-datetime-picker").default,
  // 	android: () => require("react-native-modal-datetime-picker").default,
  // })();
  return (
    <View
      style={{
        flex: props.overWriteFlex === 0 ? props.overWriteFlex : 1,
      }}
    >
      <InputDataPicker
        onPress={showDatePicker}
        value={props.value}
        inputStyle={props.style}
      />

      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        date={props.date}
        mode={props.mode}
        onCancel={hideDatePicker}
        onConfirm={handleConfirm}
        maximumDate={props.maximumDate}
      />
    </View>
  );
};

export default DateTimePickerX;
