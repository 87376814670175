import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  StyleSheet,
  VirtualizedList,
  RefreshControl,
} from "react-native";
import { connect, useSelector } from "react-redux";
import moment from "moment";

import { t } from "../../../services/i18n";

import { ThemeContext } from "../../../../ThemeContext";

import { actions_logReceived } from "../../../store/actions";

import mqttClient from "../../../services/mqtt";
import {
  PrimaryColorText,
  NoDataScreen,
} from "../../../components/UI/styledComponents";
import TopBarOptions from "./TopBarOptions";

let numberOfItems = 100;

let Element = ({ item }) => {
  const device = useSelector((state) =>
    state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("devices")
      .get(item.get("id_obiektu"))
  );

  const descriptionFromSource = (value) => {
    let description = "";
    switch (value) {
      case 0:
        description = "API";
        break;
      case 1:
        description = t("statistics:SCENE_BY_USER");
        break;
      case 2:
        description = t("statistics:MOBILE_APP");
        break;
      case 3:
        description = t("statistics:LOGIN_UNSUCCESSFUL");
        break;
      case 4:
        description = t("statistics:LOGIN_SUCCESSFUL");
        break;
      case 5:
        description = t("statistics:PRESENCE_SIMULATION");
        break;
      case 6:
        description = t("statistics:CONDITION");
        break;
      case 7:
        description = t("statistics:SCENE_BY_SCHEDULE");
        break;
      case 8:
        description = t("statistics:SCENE_BY_SERVER");
        break;
      default:
        description = `${t("account:UNKNOWN_EVENT")} ${value}`;
    }
    return description;
  };
  const descriptionForAction = (value) => {
    let description = "";
    switch (value) {
      case 0:
      case 1:
      case 2:
      case 5:
      case 6:
      case 7:
        description = `${t("statistics:ACTION")}: `;
        break;
      case 3:
      case 4:
        description = "IP: ";
        break;
      default:
        description = `${t("statistics:ACTION")}: `;
    }
    return description;
  };

  return (
    <View style={styles.singleLine}>
      <PrimaryColorText>{item.get("czas")}</PrimaryColorText>
      {device ? (
        <PrimaryColorText>
          {t("statistics:DEVICE")}: {device.get("opis_menu")}
        </PrimaryColorText>
      ) : null}
      <PrimaryColorText>
        {descriptionForAction(item.get("source"))}
        {item.get("action") == "10.76.100.100"
          ? t("CLOUD")
          : item.get("action")
          ? item.get("action").replace("setText", "setText ")
          : ""}
      </PrimaryColorText>
      <PrimaryColorText>
        {t("statistics:SOURCE")}: {descriptionFromSource(item.get("source"))}
      </PrimaryColorText>
      {item.get("source") != 1 ? (
        <PrimaryColorText>
          {t("statistics:USER")}: {item.get("username")}
        </PrimaryColorText>
      ) : null}
    </View>
  );
};

class ActionsLog_AccountScreen extends Component {
  static contextType = ThemeContext;

  state = {
    isLoading: true,
    refreshing: false,
    fetchingMore: false,
    actions_log: null,
    date: "last",
    deviceID: -1,
    offset: 0,
  };

  componentDidMount() {
    this.askForActionsLog();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.actions_log) {
      let actions_log = nextProps.actions_log;
      if (this.state.fetchingMore) {
        actions_log = this.state.actions_log.concat(nextProps.actions_log);
      }
      this.setState({
        isLoading: false,
        refreshing: false,
        fetchingMore: false,
        actions_log,
      });
    }
  }

  getFromToValues = (date) => {
    let inEpoch = moment(date).format("X");

    let daySeconds = 24 * 60 * 60;
    let from = Math.floor(inEpoch / daySeconds) * daySeconds;
    let to = Math.ceil(inEpoch / daySeconds) * daySeconds + daySeconds;

    return [from, to];
  };

  askForActionsLog = () => {
    let { date, deviceID, offset } = this.state;
    let [from, to] = [0, 0];
    let timeRange = 5;
    if (date != "last") {
      [from, to] = this.getFromToValues(date);
      timeRange = 6;
    }

    // let message = `/api/json/actions_log/${timeRange}/${deviceID}/${from}/${to}/${numberOfItems}/${offset}`;
    let message = `/api/json/actions_log/${timeRange}/${deviceID}/0/${to}/${numberOfItems}/${offset}`;
    mqttClient.askForActionsLog(message);
    this.props.actions_logReceived(null);
  };
  onRefresh = () => {
    this.setState(
      {
        refreshing: true,
        offset: 0,
      },
      () => {
        this.askForActionsLog();
      }
    );
  };

  onEndReached = () => {
    const { actions_log, offset, fetchingMore } = this.state;

    if (
      !fetchingMore &&
      !(actions_log.size <= offset) &&
      !(actions_log.size < numberOfItems)
    ) {
      this.setState(
        (prevstate) => ({
          offset: prevstate.offset + numberOfItems,
          fetchingMore: true,
        }),
        () => {
          this.askForActionsLog();
        }
      );
    }
  };

  optionChanged = (value, type) => {
    let deviceID = this.state.deviceID;
    let date = this.state.date;
    if (type == "allDevices") {
      deviceID = -1;
    } else if (type == "date") {
      date = value;
    } else if (type == "singleDevice") {
      deviceID = value;
    }
    this.setState({ date, deviceID, isLoading: true, offset: 0 }, () => {
      this.askForActionsLog();
    });
  };

  render() {
    const { theme } = this.context;
    const { refreshing, fetchingMore, actions_log } = this.state;

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        }}
      >
        <TopBarOptions optionChanged={this.optionChanged} />

        {this.state.isLoading ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" />
          </View>
        ) : (
          actions_log && (
            <VirtualizedList
              data={actions_log}
              refreshControl={
                <RefreshControl
                  //refresh control used for the Pull to Refresh
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                />
              }
              contentContainerStyle={{ flexGrow: 1 }}
              ListEmptyComponent={<NoDataScreen />}
              onEndReached={this.onEndReached}
              getItem={(data, index) => data.get(index)}
              getItemCount={(data) => data.size}
              renderItem={({ item }) => <Element item={item} />}
              keyExtractor={(item, index) =>
                `${item.get("id_obiektu")} - ${index}`
              }
            />
          )
        )}
        {fetchingMore ? (
          <View style={{ padding: 10 }}>
            <ActivityIndicator size="small" />
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleLine: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    actions_log: state.statesData.get("actions_log"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions_logReceived: (reset) => dispatch(actions_logReceived(reset)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionsLog_AccountScreen);
