import React from "react";
import { View, Image, StyleSheet, InteractionManager, I18nManager, Text, PanResponder, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import tinycolor from "tinycolor2";

import { t } from "../../../services/i18n";

import ModalWithContent from "../../../components/UI/ModalWithContent";
import { PrimaryColorText, ButtonPrimary, SecondaryColorText } from "../../../components/UI/styledComponents";
import { ThemeContext } from "../../../../ThemeContext";

const fn = () => true;
class RGBPicker extends React.PureComponent {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);

    this.state = {
      color: { h: 0, s: 0, v: 0 },
      pickerSize: null,
      sliderWidth: null,
    };
    this._pickerLayout = { width: 0, height: 0, x: 0, y: 0 };
    this._sliderLayout = { width: 0, height: 0, x: 0, y: 0 };
    this._pickerPage = { x: 0, y: 0 };
    this._sliderPage = { x: 0, y: 0 };
    this._isRTL = I18nManager.isRTL;
  }

  _createPanResponder = ({ onStart = fn, onMove = fn, onEnd = fn }) => {
    return PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      // nie wiem czy to potrzebne
      onPanResponderTerminationRequest: () => true,

      onPanResponderGrant: ({ nativeEvent }) => {
        return onStart(
          {
            x: nativeEvent.pageX,
            y: nativeEvent.pageY,
          },
          "start"
        );
      },
      onPanResponderMove: ({ nativeEvent }) => {
        return onMove(
          {
            x: nativeEvent.pageX,
            y: nativeEvent.pageY,
          },
          "move"
        );
      },
      onPanResponderRelease: ({ nativeEvent }) => {
        return onEnd(
          {
            x: nativeEvent.pageX,
            y: nativeEvent.pageY,
          },
          "end"
        );
      },
    });
  };

  componentDidMount = () => {
    const { colorRGB } = this.props;
    let hsvColor = tinycolor(colorRGB).toHsv();
    this.setState({ color: hsvColor });
    this._pickerResponder = this._createPanResponder({
      onStart: this.handleColorChange,
      onMove: this.handleColorChange,
      onEnd: this.handleColorChange,
    });

    this._sliderResponder = this._createPanResponder({
      onStart: this.handleValueChange,
      onMove: this.handleValueChange,
      onEnd: this.handleValueChange,
    });
  };

  _onLayoutPicker = ({ nativeEvent }) => {
    this._pickerLayout = nativeEvent.layout;
    const { width, height } = this._pickerLayout;
    const pickerSize = Math.min(width, height);
    if (this.state.pickerSize !== pickerSize) {
      this.setState({ pickerSize });
    }

    setTimeout(() => {
      this.refs.bgIconPickerContainer &&
        this.refs.bgIconPickerContainer.measure((x, y, width, height, pageX, pageY) => {
          this._pickerPage = { x: pageX, y: pageY };
        });
    }, 500);
  };

  _onLayoutSlider = ({ nativeEvent }) => {
    this._sliderLayout = nativeEvent.layout;
    const sliderWidth = this._sliderLayout.width;
    if (this.state.sliderWidth !== sliderWidth) {
      this.setState({ sliderWidth });
    }

    setTimeout(() => {
      this.refs.bgIconPickerSlider &&
        this.refs.bgIconPickerSlider.measure((x, y, width, height, pageX, pageY) => {
          this._sliderPage = { x: pageX, y: pageY };
        });
    }, 500);
  };

  handleColorChange = ({ x, y }, option) => {
    const { v } = this.state.color;
    const marginHorizontal = (this._pickerLayout.width - this.state.pickerSize) / 2;
    const marginVertical = (this._pickerLayout.height - this.state.pickerSize) / 2;

    const relativeX = x - this._pickerPage.x - marginHorizontal;
    const relativeY = y - this._pickerPage.y - marginVertical;
    const h = this._computeHValue(relativeX, relativeY);
    const s = this._computeSValue(relativeX, relativeY);

    let color = { h, s, v };
    this.setState({ color });
  };

  handleValueChange = ({ x }, option) => {
    const { h, s } = this.state.color;

    const relativeX = x - this._sliderPage.x;
    const value = relativeX / this._sliderLayout.width;
    let v = value;
    if (value < 0) {
      v = 0;
    } else if (value > 1) {
      v = 1;
    }

    let color = { h, s, v };
    this.setState({ color });
  };

  _computeHValue = (x, y) => {
    const pickerRadius = this.state.pickerSize / 2;
    const dx = x - pickerRadius;
    const dy = y - pickerRadius;
    const rad = Math.atan2(dx, dy) + Math.PI + Math.PI / 2;
    return ((rad * 180) / Math.PI) % 360;
  };

  _computeSValue = (x, y) => {
    const pickerRadius = this.state.pickerSize / 2;
    const dx = x - pickerRadius;
    const dy = y - pickerRadius;
    const odleglosc = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    const s = odleglosc / pickerRadius;
    return s >= 1 ? 1 : s;
  };

  _hValueToRad = (deg) => {
    const rad = (deg * Math.PI) / 180;
    return rad - Math.PI - Math.PI / 2;
  };

  onClose = () => {
    this.props.onCloseModal("close");
  };

  onSave = () => {
    let color = tinycolor(this.state.color).toRgbString();
    this.props.onCloseModal("save", color);
  };

  onResetColor = (type) => {
    let hsvColor;
    if (type == "original") {
      let sideMenuColor = "rgb(51,51,51)";
      hsvColor = tinycolor(sideMenuColor).toHsv();
    } else if (type == "tabBar") {
      const { theme } = this.context;
      let tabBarColor = theme.TAB_BAR_BACKGROUND_COLOR;
      hsvColor = tinycolor(tabBarColor).toHsv();
    }
    this.setState({ color: hsvColor });
  };

  render() {
    const { pickerSize, color, sliderWidth } = this.state;

    const { h, s, v } = color;
    const angle = this._hValueToRad(h);
    const selectedColor = tinycolor(color).toHexString();
    const indicatorColor = tinycolor(color).toHexString();

    const { modalVisible, landscapeOrientation } = this.props;
    const computed = makeComputedStyles({
      pickerSize,
      sliderWidth,
      selectedColor,
      indicatorColor,
      angle,
      saturation: s,
      value: v,
      isRTL: this._isRTL,
    });

    const sliderColorBeginning = tinycolor({ h, s, v: 0 }).toHexString();
    const sliderColorEnd = tinycolor({ h, s, v: 1 }).toHexString();

    const sliderHandlers = (this._sliderResponder && this._sliderResponder.panHandlers) || {};

    return (
      <ModalWithContent title={this.props.title} isVisible={modalVisible} onClose={this.onClose} modalHeight={landscapeOrientation ? "90%" : "70%"}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "90%",
          }}
        >
          <TouchableOpacity onPress={() => this.onResetColor("original")}>
            <SecondaryColorText
              style={{
                fontSize: 12,
                fontStyle: "italic",
                textAlign: "center",
                paddingRight: 10,
              }}
            >
              {t("account:RESET_TO_ORIGINAL_COLOR")}
            </SecondaryColorText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.onResetColor("tabBar")}>
            <SecondaryColorText
              style={{
                fontSize: 12,
                fontStyle: "italic",
                textAlign: "center",
                paddingRight: 10,
              }}
            >
              {t("account:RESET_TO_NAVIGATION_COLOR")}
            </SecondaryColorText>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: landscapeOrientation ? "row" : "column",
            // justifyContent: landscapeOrientation
            // 	? "flex-start"
            // 	: "space-around",
            height: "80%",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: landscapeOrientation ? "100%" : "70%",
              width: landscapeOrientation ? "45%" : "90%",
            }}
          >
            <View ref="bgIconPickerContainer" style={styles.bgIconPickerContainer} onLayout={this._onLayoutPicker}>
              {!pickerSize ? null : (
                <View {...this._pickerResponder.panHandlers} style={[styles.picker, computed.picker]} collapsable={false}>
                  <Image source={require("../../../../assets/color-wheel.png")} resizeMode="contain" style={[styles.pickerImage]} />
                  <View style={[styles.indicator, computed.pickerIndicator, computed.selectedColor]} />
                </View>
              )}
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              height: landscapeOrientation ? "100%" : "30%",
              width: landscapeOrientation ? "45%" : "90%",
            }}
          >
            <PrimaryColorText
              style={{
                fontSize: 18,
                // fontWeight: "bold",
                // paddingBottom: 10,
                // textAlign: "center"
              }}
            >
              {t("BRIGHTNESS")}
            </PrimaryColorText>
            <View
              onLayout={this._onLayoutSlider}
              {...sliderHandlers}
              ref="bgIconPickerSlider"
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingVertical: 10,
              }}
            >
              <LinearGradient colors={[sliderColorBeginning, sliderColorEnd]} style={[computed.slider, styles.slider]} start={[0, 1]} end={[1, 0]} />

              <View style={[styles.indicator, computed.sliderIndicator, computed.selectedColor]} />
            </View>
            <ButtonPrimary style={{ marginTop: 16, width: "100%" }} onPress={this.onSave}>
              OK
            </ButtonPrimary>
          </View>
        </View>
      </ModalWithContent>
    );
  }
}

const makeComputedStyles = ({ indicatorColor, selectedColor, angle, saturation, value, pickerSize, sliderWidth, isRTL }) => {
  const indicatorPickerRatio = 0.09; // computed from picker image
  const indicatorSize = indicatorPickerRatio * pickerSize;
  const pickerPadding = indicatorSize / 3;
  const indicatorRadius = (pickerSize / 2 - indicatorSize / 2) * saturation;
  const pickerRadius = pickerSize / 2;
  const dx = Math.cos(angle) * indicatorRadius;
  const dy = Math.sin(angle) * indicatorRadius;
  return {
    picker: {
      padding: pickerPadding,
      width: pickerSize,
      height: pickerSize,
    },
    pickerIndicator: {
      top: pickerRadius + dx - indicatorSize / 2,
      [isRTL ? "right" : "left"]: pickerRadius + dy - indicatorSize / 2,
      width: indicatorSize,
      height: indicatorSize,
      borderRadius: indicatorSize / 2,
    },
    slider: {
      padding: indicatorSize / 2,
      borderRadius: indicatorSize / 2,
    },
    sliderIndicator: {
      top: 11,
      [isRTL ? "right" : "left"]: sliderWidth * value - indicatorSize / 2,
      width: indicatorSize - 2,
      height: indicatorSize - 2,
      borderRadius: indicatorSize / 2,
    },
    selectedColor: {
      backgroundColor: selectedColor,
    },
  };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#34495e",
  },

  bgIconPickerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  pickerImage: {
    flex: 1,
    width: null,
    height: null,
  },
  indicator: {
    position: "absolute",
    // Shadow only works on iOS.
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 4,
    borderColor: "white",
    borderWidth: 0.7,

    // This will elevate the view on Android, causing shadow to be drawn.
    elevation: 5,
  },
  slider: {
    alignItems: "center",
    width: "100%",
  },
  selectedColorPreview: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
});

export default RGBPicker;
