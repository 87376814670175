import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";

import {
	PrimaryColorText,
	ButtonPrimary,
} from "../../../components/UI/styledComponents";
import { devicesForGroups } from "../../../utils";
import PickerSelect from "../../../components/UI/PickerSelect";
import PickerSelectObjects from "../../../components/UI/PickerSelectObjects";

import ModalWithContent from "../../../components/UI/ModalWithContent";

class AddDeviceModal extends Component {
	state = {
		selectedGroup: 0,
		selectedDevice: null,
		devicesInGroupIdArray: [],
		buttonAddDisabled: true,
	};

	onGroupValueChange = (value) => {
		this.setState({
			selectedGroup: value,
			selectedDevice: null,
			buttonAddDisabled: true,
		});
	};

	onDeviceValueChange = (value) => {
		if (value) {
			this.setState({ selectedDevice: value, buttonAddDisabled: false });
		}
	};

	onAddHandler = () => {
		this.props.onAdd(this.state.selectedDevice);
	};

	setDevices = () => {
		const { devices, group_devices, logging, params_devices } = this.props;

		let devicesToSend = [];

		let listOfDevicesForGroup = devicesForGroups(
			group_devices,
			this.state.selectedGroup
		);

		listOfDevicesForGroup.map((deviceForGroup) => {
			let deviceID = deviceForGroup.get("id_obiektu");
			if (devices.get(deviceID)) {
				let device = devices.get(deviceID);
				let typKomponentu = device.get("typ_komponentu");
				let logs = logging.find((el) => el.get("id_obiektu") == deviceID);
				if (typKomponentu == "custom") {
					let powiazane = params_devices.get(deviceID)
						? params_devices.get(deviceID).get("powiazane")
						: "";
					let powiazaneArrayWithPossibleCustom = powiazane
						? powiazane.split(",")
						: [];

					powiazaneArrayWithPossibleCustom.map((el, index) => {
						el = el.split(":").reverse();
						let deviceFromCustom = devices.get(el[0]);
						let logsFromCustom = deviceFromCustom
							? logging.find(
									(el) => el.get("id_obiektu") == deviceFromCustom.get("id")
							  )
							: null;
						if (
							deviceFromCustom &&
							deviceFromCustom.get("typ_komponentu") != "custom" &&
							!deviceFromCustom.get("bezpieczne") &&
							logsFromCustom &&
							logsFromCustom.get("typ_logowania") == 0
						) {
							devicesToSend.push({
								label: deviceFromCustom.get("opis_menu"),
								value: deviceFromCustom.get("id"),
							});
						}
					});
				}
				if (
					logs &&
					logs.get("typ_logowania") == 0 &&
					!device.get("bezpieczne")
				) {
					devicesToSend.push({
						label: device.get("opis_menu"),
						value: device.get("id"),
					});
				}
			}
		});

		return devicesToSend;
	};

	render() {
		const { isVisible, onClose } = this.props;
		const { selectedDevice, selectedGroup, buttonAddDisabled } = this.state;

		let devicesInGroup = this.setDevices();

		return (
			<ModalWithContent
				isVisible={isVisible}
				title={t("ADD_OBJECT")}
				onClose={onClose}
			>
				<View style={{ paddingBottom: 16 }}>
					<PrimaryColorText style={{ paddingBottom: 4 }}>
						{t("OBJECT_PLACE")}
					</PrimaryColorText>
					<PickerSelectObjects
						onValueChange={this.onGroupValueChange}
						value={selectedGroup}
						type={"groups"}
					/>
				</View>

				<View style={{ paddingBottom: 20 }}>
					<PrimaryColorText style={{ paddingBottom: 4 }}>
						{t("OBJECT_NAME")}
					</PrimaryColorText>
					<PickerSelectObjects
						items={devicesInGroup}
						onValueChange={this.onDeviceValueChange}
						value={selectedDevice}
						type={"devices"}
					/>
				</View>
				<ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
					{t("ADD")}
				</ButtonPrimary>
			</ModalWithContent>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		group_devices: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("group_devices"),
		devices: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("devices"),
		logging: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("logging"),
		params_devices: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("params_devices"),
	};
};

export default connect(mapStateToProps)(AddDeviceModal);
