import React, { Component } from "react";
import { View, Text, Platform, I18nManager } from "react-native";
import { t } from "../../../services/i18n";
import { Feather } from "@expo/vector-icons";

import { ComponentContainerTouchable, PrimaryColorText, StyledIcon } from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";

const Personalization_AccountScreen = (props) => {
  let elements = [
    {
      title: "Dashboard",
      icon: "home",
    },
    {
      title: "SideMenu",
      icon: "sidebar",
    },
    {
      title: "Sketch",
      icon: "user",
    },
    {
      title: "Colors",
      icon: "codepen",
    },
  ];

  if (Platform.OS === "web") {
    elements.push({
      title: "Random",
      icon: "at-sign",
    });
  } else {
    elements.push(
      {
        title: "General_Order",
        icon: "move",
      },
      {
        title: "Random",
        icon: "at-sign",
      }
    );
  }

  return (
    <ScreenView>
      {elements.map((element) => {
        return (
          <ComponentContainerTouchable
            onPress={() => {
              props.navigation.navigate(`${element.title}_Personalization`, {
                title: t(`account:${element.title.toUpperCase()}`),
              });
            }}
            key={element.title}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <StyledIcon
                name={element.icon}
                size={22}
                color={"secondary"}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 10,
                }}
              />
              <PrimaryColorText
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                {t(`account:${element.title.toUpperCase()}`)}
              </PrimaryColorText>
            </View>
            <Feather name={I18nManager.isRTL ? "chevron-left" : "chevron-right"} color={"#999"} size={24} />
          </ComponentContainerTouchable>
        );
      })}
    </ScreenView>
  );
};

export default Personalization_AccountScreen;
