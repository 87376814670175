import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ActivityIndicator,
  Platform,
  StyleSheet,
  VirtualizedList,
  RefreshControl,
  I18nManager,
} from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import { FontAwesome, Feather } from "@expo/vector-icons";
import { ThemeContext } from "../../../../ThemeContext";
import {
  messagesReceived,
  messagesCountReceived,
} from "../../../store/actions";
import {
  ComponentContainerTouchable,
  ButtonPrimary,
  PrimaryColorText,
  NoDataScreen,
} from "../../../components/UI/styledComponents";
import ModalSimple from "../../../components/UI/ModalSimple";

import mqttClient from "../../../services/mqtt";
let numberOfItems = 10;

class Message_Center_AccountScreen extends Component {
  static contextType = ThemeContext;

  state = {
    isLoading: true,
    refreshing: false,
    fetchingMore: false,
    messages: null,
    readAllDialogVisible: false,
    deleteAllDialogVisible: false,
    offset: 0,
  };

  onDetailsPressed = (message) => {
    if (message.get("state") === 0) {
      let indexToUpdate = this.props.messages.findIndex(
        (x) => x.get("id") === message.get("id")
      );
      let newInfos = this.props.messages;
      newInfos = newInfos.setIn([indexToUpdate, "state"], 1);
      this.props.messagesReceived(newInfos);

      mqttClient.readMessage(message.get("id"));
    }
    this.props.navigation.navigate("Message_Details", {
      title: message.get("title"),
      message: message,
      aspectRatio: this.props.params_devices.get(message.get("title"))
        ? this.props.params_devices.get(message.get("title")).get("param1")
        : 1,
    });
  };

  onMarkAllAsRead = () => {
    this.setReadAllDialogVisible(false);
    let messageCopy = this.state.messages;
    if (this.state.messages) {
      this.state.messages.map((el, key) => {
        messageCopy = messageCopy.setIn([key, "state"], 1);
      });

      this.setState({ messages: messageCopy });
      mqttClient.readAllMessages();
    }
  };

  onMarkAllDeleted = () => {
    this.setDeleteAllDialogVisible(false);
    mqttClient.deleteAllMessages();
    mqttClient.getMessagesCount();
    this.setState({
      messages: null,
    });
  };

  componentDidMount() {
    this.askForMessages();
  }

  prepareMessages = async (newMessages) => {
    let messages = newMessages;
    if (this.state.fetchingMore && this.state.messages) {
      messages = this.state.messages.concat(newMessages);
    }

    this.setState({
      isLoading: false,
      refreshing: false,
      fetchingMore: false,
      messages,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.messages) {
      this.prepareMessages(nextProps.messages);
    }
  }

  setReadAllDialogVisible = (state) => {
    this.setState({
      readAllDialogVisible: state,
    });
  };

  setDeleteAllDialogVisible = (state) => {
    this.setState({
      deleteAllDialogVisible: state,
    });
  };

  askForMessages = () => {
    let { offset } = this.state;

    mqttClient.getMessagesPaginate(numberOfItems, offset);
  };
  onRefresh = () => {
    this.setState(
      {
        refreshing: true,
        offset: 0,
      },
      () => {
        this.askForMessages();
      }
    );
  };

  onEndReached = () => {
    const { messages, offset, fetchingMore } = this.state;
    if (
      !fetchingMore &&
      !(messages.size <= offset) &&
      !(messages.size < numberOfItems)
    ) {
      this.setState(
        (prevstate) => ({
          offset: prevstate.offset + numberOfItems,
          fetchingMore: true,
        }),
        () => {
          this.askForMessages();
        }
      );
    }
  };

  render() {
    const { theme } = this.context;
    const {
      refreshing,
      fetchingMore,
      messages,
      readAllDialogVisible,
      deleteAllDialogVisible,
    } = this.state;

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        }}
      >
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            marginTop: 5,
            marginBottom: 15,
            flexDirection: "row",
          }}
        >
          <ButtonPrimary
            style={{ marginRight: 5 }}
            onPress={() => this.setReadAllDialogVisible(true)}
          >
            {t("account:MARK_ALL_AS_READ")}
          </ButtonPrimary>
          <ButtonPrimary
            style={{ marginLeft: 5 }}
            onPress={() => this.setDeleteAllDialogVisible(true)}
          >
            {t("account:DELETE_ALL")}
          </ButtonPrimary>
        </View>
        {this.state.isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" />
          </View>
        ) : (
          messages && (
            <VirtualizedList
              data={messages}
              refreshControl={
                <RefreshControl
                  //refresh control used for the Pull to Refresh
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                />
              }
              contentContainerStyle={{ flexGrow: 1 }}
              ListEmptyComponent={<NoDataScreen />}
              getItem={(data, index) => data.get(index)}
              getItemCount={(data) => data.size}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) =>
                item ? (
                  <ComponentContainerTouchable
                    key={item.get("id")}
                    containerStyle={{ width: "100%" }}
                    style={{ alignItems: "center" }}
                    onPress={() => this.onDetailsPressed(item)}
                  >
                    <View style={{ width: 20 }}>
                      {item.get("state") === 0 ? (
                        <FontAwesome
                          name={"circle"}
                          color={"#4ED164"}
                          size={14}
                        />
                      ) : null}
                    </View>
                    <View style={{ flex: 1, paddingRight: 20 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <PrimaryColorText
                          style={{
                            fontWeight: item.get("state") === 0 ? "bold" : null,
                          }}
                        >
                          {item.get("type") == 1 &&
                          this.props.devices &&
                          item &&
                          this.props.devices.get(item.get("title"))
                            ? this.props.devices
                                .get(item.get("title"))
                                .get("opis_menu")
                            : item
                            ? item.get("title")
                            : null}
                        </PrimaryColorText>
                        <PrimaryColorText>
                          {item.get("date_of_change")}
                        </PrimaryColorText>
                      </View>

                      <View>
                        {item.get("type") === 0 ? (
                          <PrimaryColorText
                            numberOfLines={1}
                            style={{
                              fontWeight:
                                item.get("state") === 0 ? "bold" : null,
                            }}
                          >
                            {item.get("body")}
                          </PrimaryColorText>
                        ) : null}
                        {item.get("type") === 1 && item.get("image") ? (
                          <Image
                            style={{
                              width: "100%",
                              height: undefined,
                              aspectRatio: this.props.params_devices.get(
                                item.get("title")
                              )
                                ? this.props.params_devices
                                    .get(item.get("title"))
                                    .get("param1")
                                : 0.7,
                            }}
                            source={{
                              uri: `data:image/jpg;base64,${item.get("image")}`,
                            }}
                          />
                        ) : null}
                      </View>
                    </View>
                    {item.get("type") === 0 && (
                      <Feather
                        name={
                          I18nManager.isRTL ? "chevron-left" : "chevron-right"
                        }
                        color={"#999"}
                        size={24}
                      />
                    )}
                  </ComponentContainerTouchable>
                ) : null
              }
              keyExtractor={(item, index) => {
                return item ? `${item.get("id")} - ${index}` : `${index}`;
              }}
            />
          )
        )}

        {fetchingMore ? (
          <View style={{ padding: 10 }}>
            <ActivityIndicator size="small" />
          </View>
        ) : null}

        {readAllDialogVisible && (
          <ModalSimple
            isVisible={readAllDialogVisible}
            title={"account:MARK_ALL_AS_READ"}
            description={"account:READ_MESSAGES_SURE"}
            onOK={this.onMarkAllAsRead}
            onClose={() => this.setReadAllDialogVisible(false)}
          />
        )}
        {deleteAllDialogVisible && (
          <ModalSimple
            isVisible={deleteAllDialogVisible}
            title={"account:DELETE_ALL"}
            description={"account:DELETE_ALL_SURE"}
            onOK={this.onMarkAllDeleted}
            onClose={() => this.setDeleteAllDialogVisible(false)}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleLine: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    messages: state.statesData.get("messages"),
    devices: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("devices"),
    params_devices: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("params_devices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    messagesReceived: (messages) => dispatch(messagesReceived(messages)),
    messagesCountReceived: (messages) =>
      dispatch(messagesCountReceived(messages)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message_Center_AccountScreen);
