import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Platform,
} from "react-native";

import { fromJS } from "immutable";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";
import TextTicker from "react-native-text-ticker";

import {
  ComponentHeaderText,
  StyledIcon,
  SliderStyled,
  PrimaryColorText,
} from "../../UI/styledComponents";
import FavouriteStationsModal from "./FavouriteStationsModal";
import AddFavouriteStationModal from "./AddFavouriteStationModal";
import RadioButtonsBar from "./RadioButtonsBar";
import PickerSelect from "../../UI/PickerSelect";
import ModalWithContent from "../../UI/ModalWithContent";

const buttonList = [
  {
    label: "None",
    value: 0,
  },
  {
    label: "Previous",
    value: 1,
  },
  {
    label: "Pause",
    value: 2,
  },
  {
    label: "Mute",
    value: 3,
  },
  {
    label: "Unmute",
    value: 4,
  },
  {
    label: "Resume",
    value: 5,
  },
  {
    label: "Next",
    value: 6,
  },
];

const modesAcrylic = [
  {
    label: "None",
    value: "0",
  },
  {
    label: "Line-in",
    value: "40",
  },
  {
    label: "Bluetooth",
    value: "41",
  },
  {
    label: "USB",
    value: "11",
  },
  {
    label: "Optical",
    value: "43",
  },
];

const modesHeos = [
  {
    label: "TV Audio",
    value: "inputs/tvaudio",
  },
  {
    label: "Tuner",
    value: "inputs/tuner",
  },
  {
    label: "Blue Ray",
    value: "inputs/blueray",
  },
  {
    label: "USB",
    value: "inputs/usbdac",
  },
  {
    label: "Media Player",
    value: "inputs/mediaplayer",
  },
  {
    label: "Game",
    value: "inputs/game",
  },
  {
    label: "Cable/Sat",
    value: "inputs/cable_sat",
  },
  {
    label: "AUX1",
    value: "inputs/aux1",
  },
  {
    label: "AUX2",
    value: "inputs/aux2",
  },
  {
    label: "Bluetooth",
    value: "Bluetooth",
  },
  {
    label: "HD Radio",
    value: "inputs/hdradio",
  },
];

const modesYamaha = [
  {
    label: "Net radio",
    value: "net_radio",
  },
  {
    label: "Napster",
    value: "naspter",
  },
  {
    label: "Spotify",
    value: "spotify",
  },
  {
    label: "Juke",
    value: "juke",
  },
  {
    label: "Qobuz",
    value: "qobuz",
  },
  {
    label: "Tidal",
    value: "tidal",
  },
  {
    label: "Deezer",
    value: "deezer",
  },
  {
    label: "Server",
    value: "server",
  },
  {
    label: "Bluetooth",
    value: "bluetooth",
  },
  {
    label: "Airplay",
    value: "airplay",
  },
  {
    label: "MusicCast link",
    value: "mc_link",
  },
];

const placeholder = {
  label: "Stream",
  value: null,
};

let changeVolumeToPercent = (volumeValue, param) => {
  if (param === 3) {
    let percentValue = Math.round((volumeValue * 100) / 60);
    return `${percentValue} %`;
  } else {
    let percentValue = Math.round((volumeValue * 100) / 100);
    return `${percentValue} %`;
  }
};

class IpRadioComponent extends Component {
  constructor(props) {
    super(props);
    this.timeOut = null;
    this.state = {
      volume: 0,
      mode: -1,
      artist: null,
      title: null,
      mute: 0,
      status: null,
      selectedButtonInScene: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        volume: Number(nextProps.deviceState.get("state")),
        mode: nextProps.deviceState.get("mode"),
        mute: Number(nextProps.deviceState.get("mute")),
        artist: nextProps.deviceState.get("artist"),
        title: nextProps.deviceState.get("title"),
        status: nextProps.deviceState.get("status"),
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        volume: Number(nextProps.deviceInScene.get("value")),
        mode: Number(nextProps.deviceInScene.get("param1")),
        selectedButtonInScene: Number(nextProps.deviceInScene.get("param2")),
      };
    }

    return null;
  }

  componentDidMount() {
    const { deviceState } = this.props;
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);

    this.setState({
      favouritesListDialogVisible: false,
      addFavouriteStationDialogVisible: false,
    });
  }

  increaseOne = () => {
    if (this.state.volume < 100) {
      this.setState(
        (prevState) => ({
          volume: prevState.volume + 1,
          blockProps: true,
        }),
        () => this.sendMessage(this.state.volume, "volume")
      );
    }
  };

  decreaseOne = () => {
    if (this.state.volume > 0) {
      this.setState(
        (prevState) => ({
          volume: prevState.volume - 1,
          blockProps: true,
        }),
        () => this.sendMessage(this.state.volume, "volume")
      );
    }
  };

  onActionFromButtons = (action, buttonIndex) => {
    //this.setState({ buttonIndex });
    this.sendMessage(action, "buttons");
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (volume) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    //this.sendMessage(volume, "volume", true);
  };

  onSlidingCompleteHandler = (volume) => {
    this.setState({ volume: Number(volume) }, () =>
      this.sendMessage(volume, "volume")
    );
  };

  onModeChange = (mode) => {
    this.setState({ mode, blockProps: true }, () => {
      if (Platform.OS !== "ios") {
        this.sendMessage(this.state.mode, "mode");
      }
    });
  };

  onModeClose = () => {
    if (Platform.OS === "ios") {
      this.sendMessage(this.state.mode, "mode");
    }
  };

  onButtonForSceneChange = (value) => {
    this.setState({ selectedButtonInScene: value });

    if (this.props.deviceInScene) {
      let infoToUpdate = this.props.deviceInScene;
      infoToUpdate = infoToUpdate.set("param2", Number(value));
      this.props.changeScene(
        infoToUpdate,
        this.props.deviceInScene.get("temporary_id")
      );
    }
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        volume: Number(deviceState.get("state")),
        mode: deviceState.get("mode"),
        mute: Number(deviceState.get("mute")),
        artist: deviceState.get("artist"),
        title: deviceState.get("title"),
        status: deviceState.get("status"),
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };
  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, type, fromSliderMoving) => {
    const { device, deviceInScene, activeSceneControl } = this.props;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    let message = "";
    if (type == "volume") {
      message = `/api/set/${device.get("id")}/setVolume/${value}`;
    } else if (type == "mode") {
      message = `/api/set/${device.get("id")}/setInput/${value}`;
    } else if (type == "buttons") {
      message = `/api/set/${device.get("id")}/setIpRadioButton/${value}`;
    }
    if (type != "setFavInScenes") {
      activeSceneControl != false &&
        mqttClient.stateChangeToSend(message, device.get("id"));
    }
    if (deviceInScene) {
      let infoToUpdate = deviceInScene;
      infoToUpdate = infoToUpdate.set("value", this.state.volume);
      infoToUpdate = infoToUpdate.set("param1", this.state.mode);
      infoToUpdate = infoToUpdate.set(
        "param2",
        this.state.selectedButtonInScene
      );
      this.props.changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }
  };

  onStationForSceneChange = (value) => {
    this.setState({ selectedStationInScene: value }, () => {
      this.sendMessage(this.state.selectedStationInScene, "setFavInScenes");
    });
  };

  onSettingsChangeHandler = (value, el) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }

    this.sendMessage(value, el, true);
  };

  render() {
    const { device, deviceInScene, header, readOnly } = this.props;
    let { mode, volume, artist, title, mute, status, selectedButtonInScene } =
      this.state;

    let modes = modesAcrylic;
    if (device.get("param1") === 3) {
      modes = modesYamaha;
    } else if (device.get("param1") == 4) {
      modes = null;
      mode = "1";
    } else if (device.get("param1") == 2) {
      modes = null;
      mode = "1";
    } else if (device.get("param1") == 5) {
      modes = modesHeos;
    }

    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {deviceInScene ? (
            <View style={{ flex: 1 }}>{header}</View>
          ) : (
            <TouchableOpacity
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              {header}
            </TouchableOpacity>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ComponentHeaderText style={{ marginRight: 10 }}>
              {changeVolumeToPercent(volume, device.get("param1"))}
            </ComponentHeaderText>
            {modes ? (
              <PickerSelect
                placeholder={placeholder}
                items={modes}
                disabled={readOnly}
                containerStyle={{ width: 90 }}
                onValueChange={this.onModeChange}
                onClose={this.onModeClose}
                value={mode}
              />
            ) : null}
          </View>
        </View>

        <View
          style={
            deviceInScene
              ? {
                  alignItems: "center",
                  width: "100%",
                }
              : {}
          }
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              marginBottom: !deviceInScene ? 10 : 0,
            }}
          >
            <TouchableOpacity
              style={styles.iconButton}
              onPress={this.decreaseOne}
            >
              <StyledIcon name={"volume-1"} color={"primary"} size={24} />
            </TouchableOpacity>

            <SliderStyled
              disabled={readOnly}
              value={volume}
              maximumValue={device.get("param1") == 3 ? 60 : 100}
              // onSlidingStart={this.onSlidingStartHandler}
              onValueChange={this.onSlidingChangeHandler}
              onSlidingComplete={this.onSlidingCompleteHandler}
            />
            <TouchableOpacity
              disabled={readOnly}
              style={styles.iconButton}
              onPress={this.increaseOne}
            >
              <StyledIcon name={"volume-2"} color={"primary"} size={24} />
            </TouchableOpacity>
          </View>
          {!(mode == "40" || mode == "43" || mode == "0") ? (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 10,
                marginBottom: !deviceInScene ? 10 : 0,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <TextTicker
                duration={10000}
                loop
                bounce
                repeatSpacer={50}
                marqueeDelay={500}
              >
                {status && status.toLowerCase() !== "unknown" ? (
                  <PrimaryColorText style={{ fontSize: 14 }}>
                    {t("radio:" + status.toUpperCase()) + ": "}
                  </PrimaryColorText>
                ) : null}
                {artist && artist.toLowerCase() !== "unknown" ? (
                  <PrimaryColorText style={{ fontSize: 14 }}>
                    {artist + " - "}
                  </PrimaryColorText>
                ) : null}
                {title && title.toLowerCase() !== "unknown" ? (
                  <PrimaryColorText style={{ fontSize: 14 }}>
                    {title}
                  </PrimaryColorText>
                ) : null}
              </TextTicker>
            </View>
          ) : null}
          {!deviceInScene ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => this.onActionFromButtons("prev", 1)}
                disabled={readOnly}
                style={{ transform: [{ rotate: "180deg" }] }}
              >
                <StyledIcon
                  name={"fast-forward"}
                  color={"secondary"}
                  size={22}
                  ios
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onActionFromButtons("pause", 2)}
                disabled={readOnly}
              >
                <StyledIcon name={"pause"} color={"secondary"} size={22} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={
                  mute === 1
                    ? () => this.onActionFromButtons("mute:0", 3)
                    : () => this.onActionFromButtons("mute:1", 4)
                }
                disabled={readOnly}
              >
                {mute === 1 ? (
                  <StyledIcon name={"volume-2"} color={"secondary"} size={22} />
                ) : (
                  <StyledIcon name={"volume-x"} color={"secondary"} size={22} />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onActionFromButtons("resume", 5)}
                disabled={readOnly}
              >
                <StyledIcon name={"play"} color={"secondary"} size={22} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onActionFromButtons("next", 6)}
                disabled={readOnly}
              >
                <StyledIcon
                  name={"fast-forward"}
                  color={"secondary"}
                  size={22}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PickerSelect
                placeholder={{
                  label: "Select action",
                  value: 0,
                }}
                items={buttonList}
                onValueChange={this.onButtonForSceneChange}
                value={selectedButtonInScene}
              />
            </View>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    noConnectionCriticalError: state.statesData.get(
      "noConnectionCriticalError"
    ),
  };
};

export default connect(mapStateToProps, null)(IpRadioComponent);
